import React, { useState } from 'react';
import { Button, Form, Container, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AdminConnexion } from '../../actions/request/AdminRequest';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { baseurl } from '../../utils/url/baseurl';
import HCaptcha from '@hcaptcha/react-hcaptcha';

const AdminLoginPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loadingAdmin = useSelector((state) => state.admins.loadingAdmin);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false); // État pour contrôler la visibilité du mot de passe

    const [captchaToken, setCaptchaToken] = useState(null); // Stocke le token du captcha
    const [selectedLanguage, setSelectedLanguage] = useState("fr"); // Langue sélectionnée
    const keayrecaptcha = baseurl.ApiKeyRecaptcha;

    const onVerify = (token) => {
        setCaptchaToken(token); // Stocke le token généré par hCaptcha
        // console.log("Captcha Token:", token);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Ajoutez ici la logique pour gérer la connexion
        console.log('Email:', email);
        console.log('Mot de passe:', password);
        if (!email && !password) {
            toast.error("Veillez reiseignez tous les champs ", { position: "bottom-right" })
            return;
        }
        if (!captchaToken) {
            toast.error("Veuillez valider le reCAPTCHA avant de continuer !", { position: "bottom-right" });
            return;
        }
        dispatch(AdminConnexion(email, password, navigate, toast));
    };

    return (
        <Container fluid className="d-flex justify-content-center align-items-center vh-100 bg-primary">
            <div className="login-card">
                <h4 className="text-center mb-4 text-white">Connexion Administrateur</h4>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label className="text-white">Email</Form.Label>
                        <Form.Control
                            style={{ height: "40px" }}
                            type="email"
                            placeholder="Entrez votre email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="login-input"
                        />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label className="text-white">Mot de passe</Form.Label>

                        <Form.Control
                            style={{ height: "40px" }}
                            type={showPassword ? "text" : "password"} // Change le type selon l'état de la case à cocher
                            placeholder="Entrez votre mot de passe"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="login-input"
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Check
                            type="checkbox"
                            label="Voir le mot de passe"
                            className="ms-2"
                            onChange={() => setShowPassword(!showPassword)} // Change l'état de visibilité
                        />
                    </Form.Group>

                    <div className="col-md-12 form-group">
                        {/*<ReCAPTCHA
                                                        sitekey={keayrecaptcha} // Remplacez par votre clé reCAPTCHA
                                                        onChange={setRecaptchaValue}
                                                    />*/}
                        <HCaptcha
                            // sitekey="a4064d96-aed6-4a86-80a2-c1f2b434ace4" // Remplacez par votre clé de site hCaptcha
                            sitekey={keayrecaptcha} // Remplacez par votre clé de site hCaptcha
                            onVerify={onVerify} // Appelée lorsque le captcha est résolu
                            hl={selectedLanguage}
                        />
                    </div>

                    <Button variant="light" type="submit" className="w-100 mt-3 text-center justify-content-center">
                        {
                            loadingAdmin ?
                                <div className="justify-content-center"><Spinner /></div> :
                                <div>Se connecter</div>
                        }
                    </Button>
                </Form>
            </div>
        </Container>
    );
};

export default AdminLoginPage;
