import axios from "axios";
import { baseurl } from "../../utils/url/baseurl";
import { saveDataToFile } from "../DataLocal";
import { getAndCheckLocalStorage } from "../../utils/storage/localvalueFuction";
import { localStorageData } from "../../utils/storage/localvalue";
import { FETCH_EXCHANGE_RATES_FAILURE, FETCH_EXCHANGE_RATES_REQUEST, FETCH_EXCHANGE_RATES_SUCCESS, FETCH_EXCHANGE_RATES_SUCCESS_2 } from "../../app/actions/actions";

// Fonction pour récupérer le taux de change avec buyRate



// All insurance of plateforme
export function fetchExchangesAll() {
    return async (dispatch) => {
        dispatch({ type: FETCH_EXCHANGE_RATES_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/exchange-rates/get_exchange-rates`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_EXCHANGE_RATES_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_EXCHANGE_RATES_SUCCESS_2, payload: response.data.data });
            // saveDataToFile(response.data.data, localStorageData.CompanyAiports);
        }).catch((error) => {
            //console.log(error);
            dispatch({ type: FETCH_EXCHANGE_RATES_FAILURE, payload: error.message });
        });
    }
}



export async function fetchExchangeRateGetCurency(currency) {

    try {
        const response = await axios.get(`${baseurl.url}/api/v1/exchange-rates/get_exchange-rate/${currency}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        });
        const buyRate = response.data.data.buyRate;
        saveDataToFile(buyRate, localStorageData.ExchangeRateGetNow);
        console.log(buyRate)
        return { buyRate };
    } catch (error) {
        console.error('Erreur lors de la récupération du taux de change :', error);
        return { buyRate: getAndCheckLocalStorage(localStorageData.ExchangeRateGetNow)  };
    }
}




