import React, { useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const OccupancyRateChart = ({ data, type }) => {
  console.log("Données reçues :", data);

  const processedData = useMemo(() => {
    // Création dynamique des mois (sans année)
    const months = Array.from({ length: 12 }, (_, index) => ({
      month: new Date(0, index).toLocaleString('default', { month: 'long' }).toUpperCase(),
    }));

    // Initialiser un objet pour stocker les taux d'occupation par mois
    const occupancyData = {};

    // Filtrer les données en fonction du type (véhicule ou propriété)
    const filteredData = data.filter(item =>
      type === 'vehicle' ? item.item?.vehiclebrand : item.item.address
    );

    // Ajouter les données aux mois correspondants
    filteredData.forEach(item => {
      item.dates.forEach(dateRange => {
        const startDate = new Date(dateRange.startDate);
        if (isNaN(startDate)) {
          console.error("Date invalide :", dateRange.startDate);
          return;
        }

        const monthLabel = startDate.toLocaleString('default', { month: 'long' }).toUpperCase();
        
        if (!occupancyData[monthLabel]) {
          occupancyData[monthLabel] = { month: monthLabel };
        }

        // Ajouter le taux d'occupation sous une clé propre à chaque item
        occupancyData[monthLabel][item?.item?.brand || item?.item?.name] = item.occupancyRate;
      });
    });

    // Convertir en tableau formaté pour Recharts
    return months.map(monthObj => occupancyData[monthObj.month] || monthObj);
  }, [data, type]);

  // Fonction pour générer des couleurs distinctes pour chaque ligne
  const generateColor = (index) => {
    const colors = [
      '#FF0000', '#00FF00', '#0000FF', '#FF00FF', '#FFFF00', 
      '#00FFFF', '#FF8000', '#8000FF', '#00FF80', '#FF0080', 
      '#800000', '#008080', '#808000', '#800080', '#008000',
      '#8B4513', '#2E8B57', '#B22222', '#A52A2A', '#DAA520',
      '#556B2F', '#FF6347', '#FF1493', '#DC143C', '#4682B4'
    ];
    return colors[index % colors.length];
  };

  return (
    <div style={{ width: '100%', height: 400 }}>
      <h6>{type === 'vehicle' ? 'Taux d\'occupation des véhicules' : 'Taux d\'occupation des propriétés'}</h6>
      <ResponsiveContainer width="100%" height="90%">
        <LineChart
          data={processedData}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" label={{ value: 'Mois', position: 'insideBottomRight', offset: 0 }} />
          <YAxis label={{ value: 'Taux d\'occupation (%)', angle: -90, position: 'insideLeft' }} />
          <Tooltip content={<CustomTooltip />} />
          <Legend />

          {/* Tracer une ligne pour chaque véhicule/propriété */}
          {data.map((item, index) => (
            <Line
              key={item.item.brand || item.item.name} 
              type="monotone"
              dataKey={item.item.brand || item.item.name} // Lier la clé au nom du véhicule/propriété
              name={item.item.brand || item.item.name}
              stroke={generateColor(index)}
              activeDot={{ r: 8 }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

// Tooltip personnalisé pour afficher les détails
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{
        background: 'white',
        border: '1px solid #ccc',
        padding: '10px'
      }}>
        <p>{label}</p>
        {payload.map(item => (
          <p key={item.name} style={{ color: item.color }}>
            {item.name}: {item.value ? item.value.toFixed(2) : 0}%
          </p>
        ))}
      </div>
    );
  }
  return null;
};

export default OccupancyRateChart;
