import React from 'react';
import { Collapse, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaCar, FaTools, FaUsers } from 'react-icons/fa';
import { MdSpaceDashboard, MdOutlineNotificationsActive, MdTaskAlt, MdHomeWork, MdKeyboardArrowDown, MdKeyboardArrowRight, MdOutlineDateRange, MdDataUsage, MdSettings, MdList, MdOutlineSupport, MdOutlineDiscount, MdOutlinePrivacyTip, MdLandslide, MdAirplaneTicket, MdMoneyOff, MdCurrencyExchange, MdCarRental, MdEngineering, MdCarpenter, MdMyLocation, MdOutlinePendingActions, MdCarCrash } from 'react-icons/md';
import { PiUsersThreeThin } from "react-icons/pi";
import { RiFileDamageFill, RiIndeterminateCircleFill, RiLuggageDepositLine } from "react-icons/ri";
import { BiSupport } from "react-icons/bi";
import { ROUTES } from '../config/routingUrl';
import { getAndCheckLocalStorage } from '../utils/storage/localvalueFuction';
import { profileRoleType } from '../utils/dataApi/dataFormApi';
import { localStorageKeys } from '../utils/storage/localvalue';


const SideBarLeftComponent = ({ setShowOffcanvas, openMenu, toggleSubMenu, isActive }) => {
    return (
        <Nav className="flex-column p-3 pt-5" style={{ paddingTop: "50px", paddingBottom: "60px" }}>
            <Nav.Item >
                <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}`) ? 'active' : ''}`}>
                    <MdSpaceDashboard className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Tableau de bord</span>
                </Nav.Link>
            </Nav.Item>


            <Nav.Item >
                <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.NOTIFICATIONS_LIST}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.NOTIFICATIONS_LIST}`) ? 'active' : ''}`}>
                    <MdOutlineNotificationsActive className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Notifications</span>
                </Nav.Link>
            </Nav.Item>


            {
                (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT
                ) && (
                    <Nav.Item >
                        <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.DASHBOARDREPORT}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.DASHBOARDREPORT}`) ? 'active' : ''}`}>
                            <MdTaskAlt className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Rappports</span>
                        </Nav.Link>
                    </Nav.Item>
                )
            }
            {/* Menu Patrimoine */}
            {
                /* Utilisateur */
                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ?
                    <Nav.Item>
                        <Nav.Link onClick={() => toggleSubMenu('patrimoine')} className="d-flex align-items-center">
                            <MdHomeWork className="me-2" />
                            <span style={{ fontSize: '1.25rem' }}>Patrimoine</span>
                            {openMenu.patrimoine ? <MdKeyboardArrowDown className="ms-auto" /> : <MdKeyboardArrowRight className="ms-auto" />}
                        </Nav.Link>
                        <Collapse in={openMenu.patrimoine}>
                            <Nav className="flex-column ms-3">
                                <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.CARS_LIST}`} className={isActive(`/${ROUTES.DASHBOARD}/${ROUTES.CARS_LIST}`) ? 'active-link' : ''}>
                                    <FaCar className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Véhicules</span>
                                </Nav.Link>
                                <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.PROPERTYS_LIST}`} className={isActive(`/${ROUTES.DASHBOARD}/${ROUTES.PROPERTYS_LIST}`) ? 'active-link' : ''}>
                                    <MdHomeWork className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Propriétés</span>
                                </Nav.Link>
                            </Nav>
                        </Collapse>
                    </Nav.Item>
                    : ""
            }
            {/* Menu Réservations */}
            {
                /* Utilisateur */
                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.CUSTOMER ||
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT ?
                    <Nav.Item>
                        <Nav.Link onClick={() => toggleSubMenu('reservations')} className="d-flex align-items-center">
                            <MdOutlineDateRange className="me-2" />
                            <span style={{ fontSize: '1.25rem' }}>Réservations</span>
                            {openMenu.reservations ? <MdKeyboardArrowDown className="ms-auto" /> : <MdKeyboardArrowRight className="ms-auto" />}
                        </Nav.Link>
                        <Collapse in={openMenu.reservations}>
                            <Nav className="flex-column ms-3">
                                <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATIONS_REQUEST_LIST}`} className={isActive(`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATIONS_REQUEST_LIST}`) ? 'active-link' : ''}>
                                    <span style={{ fontSize: '1.25rem' }}><MdList /> Listes </span>
                                </Nav.Link>
                                <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATIONS_LIST_CALENDAR}`} className={isActive(`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATIONS_LIST_CALENDAR}`) ? 'active-link' : ''}>
                                    <span style={{ fontSize: '1.25rem' }}> <MdDataUsage /> Calendrier </span>
                                </Nav.Link>
                            </Nav>
                        </Collapse>
                    </Nav.Item>
                    : ""
            }
            {
                /* Utilisateur */
                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT ?
                    <Nav.Item>
                        <Nav.Link onClick={() => toggleSubMenu('maintenances')} className="d-flex align-items-center">
                            <FaTools className="me-2" />
                            <span style={{ fontSize: '1.25rem' }}>Entretiens et réparations</span>
                            {openMenu.maintenances ? <MdKeyboardArrowDown className="ms-auto" /> : <MdKeyboardArrowRight className="ms-auto" />}
                        </Nav.Link>
                        <Collapse in={openMenu.maintenances}>
                            <Nav className="flex-column ms-3">
                                <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.MAINTENANCE_LIST_PLATEFORME}`} className={isActive(`/${ROUTES.DASHBOARD}/${ROUTES.MAINTENANCE_LIST_PLATEFORME}`) ? 'active-link' : ''}>
                                    <MdList className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Listes</span>
                                </Nav.Link>
                                <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.MAINTENANCE_LIST_CALENDAR}`} className={isActive(`/${ROUTES.DASHBOARD}/${ROUTES.MAINTENANCE_LIST_CALENDAR}`) ? 'active-link' : ''}>
                                    <MdDataUsage className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Calendrier</span>
                                </Nav.Link>
                            </Nav>
                        </Collapse>
                    </Nav.Item>
                    : ""
            }
            {
                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT
                    ?
                    <>
                        <Nav.Item>
                            <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.INSURANCES_LIST_PLATEFORM}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.INSURANCES_LIST_PLATEFORM}`) ? 'active-link' : ''}`}>
                                <MdOutlineSupport className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Assurances</span>
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.DAMAGES_LIST_PLATEFORM}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.DAMAGES_LIST_PLATEFORM}`) ? 'active-link' : ''}`}>
                                <RiFileDamageFill className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Dommages</span>
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.CODEPROMOS_LIST}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.CODEPROMOS_LIST}`) ? 'active-link' : ''}`}>
                                <MdOutlineDiscount className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Code Promos</span>
                            </Nav.Link>
                        </Nav.Item>

                        {
                            getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER
                                ?
                                <Nav.Item>
                                    <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.TERMS_AND_CONDITIONS}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.TERMS_AND_CONDITIONS}`) ? 'active-link' : ''}`}>
                                        <MdOutlinePrivacyTip className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Termes & conditions</span>
                                    </Nav.Link>
                                </Nav.Item>
                                : ""
                        }

                    </>
                    : ""
            }
            {
                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.CUSTOMER
                    ?
                    <Nav.Item>
                        <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.PROFILE}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.PROFILE}`) ? 'active-link' : ''}`}>
                            <MdSettings className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Paramètre</span>
                        </Nav.Link>
                    </Nav.Item>
                    : ""
            }
            {
                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT ?
                    <>
                        <Nav.Item style={{ paddingBottom: "60px" }}>
                            <Nav.Link onClick={() => toggleSubMenu('settings')} className="d-flex align-items-center">
                                <MdSettings className="me-2" />
                                <span style={{ fontSize: '1.25rem' }}>Administrateur</span>
                                {openMenu.settings ? <MdKeyboardArrowDown className="ms-auto" /> : <MdKeyboardArrowRight className="ms-auto" />}
                            </Nav.Link>
                            <Collapse in={openMenu.settings}>
                                <Nav className="flex-column ms-3">
                                    <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.CUSTOMERS_LIST_PLATEFORM}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.CUSTOMERS_LIST_PLATEFORM}`) ? 'active-link' : ''}`}>
                                        <PiUsersThreeThin className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Clients</span>
                                    </Nav.Link>
                                    <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.USERS_LIST_PLATEFORM}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.USERS_LIST_PLATEFORM}`) ? 'active-link' : ''}`}>
                                        <FaUsers className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Utilisateurs</span>
                                    </Nav.Link>
                                    <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.CONTACT_SEND_LIST}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.CONTACT_SEND_LIST}`) ? 'active-link' : ''}`}>
                                        <BiSupport className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Contacts</span>
                                    </Nav.Link>
                                    <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.CODEPOSTAL_LIST}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.CODEPOSTAL_LIST}`) ? 'active-link' : ''}`}>
                                        <MdLandslide className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Indicatifs</span>
                                    </Nav.Link>
                                    <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.TYPE_ASSURANCE_LIST}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.TYPE_ASSURANCE_LIST}`) ? 'active-link' : ''}`}>
                                        <MdOutlineSupport className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Catégorie d{"'"}assurances</span>
                                    </Nav.Link>
                                    <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.TYPE_DAMAGE_LIST}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.TYPE_DAMAGE_LIST}`) ? 'active-link' : ''}`}>
                                        <RiFileDamageFill className="me-2" /> <span style={{ fontSize: '1.25rem' }}>Catégories de dommages</span>
                                    </Nav.Link>
                                    <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.AIRPORTS_LIST}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.AIRPORTS_LIST}`) ? 'active-link' : ''}`}>
                                        <MdAirplaneTicket className="me-2" /> <span style={{ fontSize: '1.25rem' }}> Compagnies aériennes</span>
                                    </Nav.Link>
                                    <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.TYPE_EXPENSES_LIST}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.TYPE_EXPENSES_LIST}`) ? 'active-link' : ''}`}>
                                        <MdMoneyOff className="me-2" /> <span style={{ fontSize: '1.25rem' }}> Catégorie de dépenses</span>
                                    </Nav.Link>
                                    <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.EXCHANGE_LIST}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.EXCHANGE_LIST}`) ? 'active-link' : ''}`}>
                                        <MdCurrencyExchange className="me-2" /> <span style={{ fontSize: '1.25rem' }}> Taux de change</span>
                                    </Nav.Link>
                                    <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.TYPE_MAINTENANCES_LIST}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.TYPE_MAINTENANCES_LIST}`) ? 'active-link' : ''}`}>
                                        <FaTools className="me-2" /> <span style={{ fontSize: '1.25rem' }}> Type d{"'"}entretiens et réparations</span>
                                    </Nav.Link>
                                    <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.BRAND_VEHICLE_LIST}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.BRAND_VEHICLE_LIST}`) ? 'active-link' : ''}`}>
                                        <MdCarRental className="me-2" /> <span style={{ fontSize: '1.25rem' }}> Marque de véhicule</span>
                                    </Nav.Link>
                                    <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.INTERIOR_VEHICLE_LIST}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.INTERIOR_VEHICLE_LIST}`) ? 'active-link' : ''}`}>
                                        <RiIndeterminateCircleFill className="me-2" /> <span style={{ fontSize: '1.25rem' }}> Type d{"'"}intérieure</span>
                                    </Nav.Link>
                                    <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.ENGINE_VEHICLE_LIST}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.ENGINE_VEHICLE_LIST}`) ? 'active-link' : ''}`}>
                                        <MdEngineering className="me-2" /> <span style={{ fontSize: '1.25rem' }}> Moteurs</span>
                                    </Nav.Link>
                                    <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.TYPE_PROPERTY_LIST}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.TYPE_PROPERTY_LIST}`) ? 'active-link' : ''}`}>
                                        <MdMyLocation className="me-2" /> <span style={{ fontSize: '1.25rem' }}> Type de proprités</span>
                                    </Nav.Link>
                                    <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.TYPE_VEHICLE_LIST}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.TYPE_VEHICLE_LIST}`) ? 'active-link' : ''}`}>
                                        <MdCarCrash className="me-2" /> <span style={{ fontSize: '1.25rem' }}> Type de véhicules</span>
                                    </Nav.Link>
                                    <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.MOTIFS_RESERVATION_CANCECELLED_LIST}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.MOTIFS_RESERVATION_CANCECELLED_LIST}`) ? 'active-link' : ''}`}>
                                        <MdOutlinePendingActions className="me-2" /> <span style={{ fontSize: '1.25rem' }}> Motifs de réservations</span>
                                    </Nav.Link>
                                    <Nav.Link onClick={() => setShowOffcanvas(false)} as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.DEPOSITS_LIST}`} className={`d-flex align-items-center ${isActive(`/${ROUTES.DASHBOARD}/${ROUTES.DEPOSITS_LIST}`) ? 'active-link' : ''}`}>
                                    <RiLuggageDepositLine className="me-2" /> <span style={{ fontSize: '1.25rem' }}> Accomptes</span>
                                    </Nav.Link>

                                </Nav>
                            </Collapse>
                        </Nav.Item>
                    </> : ""
            }
        </Nav>
    )
}

export default SideBarLeftComponent;