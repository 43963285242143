import { FETCH_ENGINE_TYPES_FAILURE, FETCH_ENGINE_TYPES_REQUEST, FETCH_ENGINE_TYPES_SUCCESS, FETCH_ENGINE_TYPES_SUCCESS_2, FETCH_ENGINE_TYPE_FAILURE, FETCH_ENGINE_TYPE_REQUEST, FETCH_ENGINE_TYPE_SUCCESS } from "../actions/actions";



// engineTypeReducer.js
const initialState = {
    engineTypes: [],
    engineTypes2: [],
    engineType: {},
    engineTypeSelect: {},
    loadingEngineType:false,
    loadingEngineTypeSelect:false,
    loadingEngineTypes:false,
    errorEngineTypes:null,
    errorEngineType:null
    // ... autres états spécifiques à engineTypeReducer
  };
  
  const engineTypeReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ENGINE_TYPE_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingEngineType:true,
          errorEngineTypes:""
          // Autres manipulations d'état pour la demande de récupération des engineTypes
        };
        case FETCH_ENGINE_TYPE_SUCCESS:
        return {
          ...state,
          loadingEngineType:false,
          errorEngineTypes:"",
          engineType:action.payload
        };
        case FETCH_ENGINE_TYPE_FAILURE:
        return {
          ...state,
          loadingEngineType:false,
          errorEngineTypes:action.payload
        };


        // ENGINE_TYPE
        // case FETCH_ENGINE_TYPE_REQUEST_SELECT:
        // // Dispatch une action pour définir loading sur true dans le globalReducer
        // return {
        //   ...state,
        //   loadingEngineTypeSelect:true,
        //   errorEngineTypes:""
        //   // Autres manipulations d'état pour la demande de récupération des engineTypes
        // };
        // case FETCH_ENGINE_TYPE_SUCCESS_SELECT:
        // return {
        //   ...state,
        //   loadingEngineTypeSelect:false,
        //   errorEngineTypes:"",
        //   engineTypeSelect:action.payload
        // };
        // case FETCH_ENGINE_TYPE_FAILURE_SELECT:
        // return {
        //   ...state,
        //   loadingEngineTypeSelect:false,
        //   errorEngineTypes:action.payload
        // };






      case FETCH_ENGINE_TYPES_REQUEST:
        return {
          ...state,
          loadingEngineTypes:true,
          errorEngineTypes:""
        };
       
      case FETCH_ENGINE_TYPES_SUCCESS:
        return {
          ...state,
          engineTypes: action.payload,
          loadingEngineTypes:false,
          errorEngineTypes:""
          // Autres manipulations d'état pour le succès de la récupération des engineTypes
        };
        case FETCH_ENGINE_TYPES_SUCCESS_2:
        return {
          ...state,
          engineTypes2: action.payload,
          loadingEngineTypes:false,
          errorEngineTypes:""
          // Autres manipulations d'état pour le succès de la récupération des engineTypes
        };
        
      case FETCH_ENGINE_TYPES_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingEngineTypes:false,
          errorEngineTypes:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des engineTypes
        };
      // ... autres cas pour d'autres actions liées aux engineTypes

        
      default:
        return state;
    }
  };
  
  export default engineTypeReducer;
  