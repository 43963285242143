import React from 'react';
import { facebookLink, logoApplication, youtubeLink } from '../utils/dataApi/dataFormApi';
import { Link } from 'react-router-dom';
import { ROUTES } from '../config/routingUrl';

const FooterWeb = () => {
    return (

        <footer className="text-center text-lg-start bg-body-tertiary text-muted bg-light">

            <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom ">
                <div>
                    <a href="" className="me-4 text-reset">
                        <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="" className="me-4 text-reset">
                        <i className="fab fa-twitter"></i>
                    </a>
                    <a href="" className="me-4 text-reset">
                        <i className="fab fa-google"></i>
                    </a>
                    <a href="" className="me-4 text-reset">
                        <i className="fab fa-instagram"></i>
                    </a>
                    <a href="" className="me-4 text-reset">
                        <i className="fab fa-linkedin"></i>
                    </a>
                    <a href="" className="me-4 text-reset">
                        <i className="fab fa-github"></i>
                    </a>
                </div>

            </section>
            <section className="">
                <div className="container text-center text-md-start mt-5">
                    <div className="row mt-3">
                        <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                            <h6 className="text-uppercase fw-bold mb-4">
                                <img src={logoApplication} className="img-fluid rounded-md" style={{ width: "40px", height: "40px" }} /> Rezaplus
                            </h6>
                            <p className="text-body">
                                nous vous proposons une large gamme de véhicules adaptés à tous vos besoins.
                                Que ce soit pour un voyage d{"'"}affaires, des vacances en famille ou une
                                escapade entre amis, nous avons le véhicule parfait pour vous.
                            </p>
                        </div>
                        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                            <h6 className="text-uppercase fw-bold mb-4">
                                Liens Utilies
                            </h6>
                            <p>
                                <Link to={`/`} className="text-body">Acceuil</Link>
                            </p>
                            <p>
                                <Link to={`/${ROUTES.CAR_LIST_WEB}`} className="text-body">Rechercher offre de réservation</Link>
                            </p>
                        </div>

                        <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                            <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                            <p> 
                                <Link to={`/${ROUTES.ABOUTUS}`} className="text-body">En savoir plus</Link></p>
                            <p> <Link to={`/${ROUTES.CONTACT}`} className="text-body">Nous contacter</Link></p>
                                <p><i className="fas fa-home me-3"></i></p>
                                <p className="text-body">
                                    {/*<i className="fas fa-envelope me-3"></i>
                                rezaplus2024@gmail.com */}
                                </p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="text-center py-1 text-body" style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
                © 2024 Copyright:
                <a className=" fw-bold" href="#">Rezaplus</a>
            </div>
        </footer>
    );
}

export default FooterWeb;
