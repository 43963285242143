import axios from "axios";
import { FETCH_CONTACTS_FAILURE, FETCH_CONTACTS_REQUEST, FETCH_CONTACTS_SUCCESS, FETCH_CONTACTS_SUCCESS_2, FETCH_CONTACT_FAILURE, FETCH_CONTACT_REQUEST, FETCH_CONTACT_SUCCESS } from "../../app/actions/actions";
import { baseurl } from "../../utils/url/baseurl";
import { getAndCheckLocalStorage } from "../../utils/storage/localvalueFuction";
import { localStorageData, localStorageKeys } from "../../utils/storage/localvalue";
import { profileRoleType } from "../../utils/dataApi/dataFormApi";
import { getDataFromFile } from "../DataLocal";
import { toast } from "react-toastify";


// Récupérer tous les codes promo
export function fetchContactsAll() {
    return async (dispatch) => {
        const contactsAll = getDataFromFile(localStorageData.ContactAll) || [];
        dispatch({ type: FETCH_CONTACTS_SUCCESS, payload: contactsAll })
        dispatch({ type: FETCH_CONTACTS_SUCCESS_2, payload: contactsAll })
        dispatch({ type: FETCH_CONTACTS_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/contacts/get_contacts`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_CONTACTS_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_CONTACTS_SUCCESS_2, payload: response.data.data });
        }).catch((error) => {
            //console.log(error);
            dispatch({ type: FETCH_CONTACTS_FAILURE, payload: error.message });
        });
    }
}






// Récupérer un code promo par ID
export function fetchContactById(idContact) {
    return async (dispatch) => {
        dispatch({ type: FETCH_CONTACT_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/contacts/get_contact/${idContact}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_CONTACT_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                dispatch({ type: FETCH_CONTACT_FAILURE, payload: error.message })
                //console.log(error);
            });
    }
}






// Créer un nouveua message 
export function createContact(data) {
    return async (dispatch) => {
        dispatch({ type: FETCH_CONTACT_REQUEST });
        await axios.post(`${baseurl.url}/api/v1/contacts/add`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_CONTACT_SUCCESS, payload: response.data.data });
            toast.success(response?.data?.message || "Message envoyé avec succès", { position: "bottom-right" });
            if (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER) {
            } else {
                fetchContactsAll();
            }
        }).catch((error) => {
            dispatch({ type: FETCH_CONTACT_FAILURE, payload: error.message });
            toast.error(error?.response?.data.message || "Message non envoyé", { position: "bottom-right" });
            if (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER) {
            } else {
                fetchContactsAll();
            }
            //console.log(error);
        });
    }
}






// Créer un nouveua message 
export function sendMessageContact(data, toast) {
    return async (dispatch) => {
        dispatch({ type: FETCH_CONTACT_REQUEST });
        await axios.post(`${baseurl.url}/api/v1/contacts/send-response`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_CONTACT_SUCCESS, payload: response.data.data });
            toast.success(response?.data?.message || "Message envoyé avec succès", { position: "bottom-right" });
            if (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER) {
            } else {
                fetchContactsAll();
            }
        }).catch((error) => {
            dispatch({ type: FETCH_CONTACT_FAILURE, payload: error.message });
            toast.error(error?.response?.data.message || "Message non envoyé", { position: "bottom-right" });
            if (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER) {
            } else {
                fetchContactsAll();
            }
            //console.log(error);
        });
    }
}
