import { FETCH_EXCHANGE_RATES_FAILURE, FETCH_EXCHANGE_RATES_REQUEST, FETCH_EXCHANGE_RATES_SUCCESS, FETCH_EXCHANGE_RATES_SUCCESS_2, FETCH_EXCHANGE_RATE_FAILURE, FETCH_EXCHANGE_RATE_REQUEST, FETCH_EXCHANGE_RATE_SUCCESS } from "../actions/actions";



// exchangerateReducer.js
const initialState = {
    exchangerates: [],
    exchangerates2: [],
    exchangerate: {},
    exchangerateSelect: {},
    loadingExchangerate:false,
    loadingExchangerateSelect:false,
    loadingExchangerates:false,
    errorExchangerates:null,
    errorExchangerate:null
    // ... autres états spécifiques à exchangerateReducer
  };
  
  const exchangerateReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_EXCHANGE_RATE_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingExchangerate:true,
          errorExchangerates:""
          // Autres manipulations d'état pour la demande de récupération des exchangerates
        };
        case FETCH_EXCHANGE_RATE_SUCCESS:
        return {
          ...state,
          loadingExchangerate:false,
          errorExchangerates:"",
          exchangerate:action.payload
        };
        case FETCH_EXCHANGE_RATE_FAILURE:
        return {
          ...state,
          loadingExchangerate:false,
          errorExchangerates:action.payload
        };


      case FETCH_EXCHANGE_RATES_REQUEST:
        return {
          ...state,
          loadingExchangerates:true,
          errorExchangerates:""
        };
       
      case FETCH_EXCHANGE_RATES_SUCCESS:
        return {
          ...state,
          exchangerates: action.payload,
          loadingExchangerates:false,
          errorExchangerates:""
          // Autres manipulations d'état pour le succès de la récupération des exchangerates
        };
        case FETCH_EXCHANGE_RATES_SUCCESS_2:
        return {
          ...state,
          exchangerates2: action.payload,
          loadingExchangerates:false,
          errorExchangerates:""
          // Autres manipulations d'état pour le succès de la récupération des exchangerates
        };
        
      case FETCH_EXCHANGE_RATES_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingExchangerates:false,
          errorExchangerates:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des exchangerates
        };
      // ... autres cas pour d'autres actions liées aux exchangerates

        
      default:
        return state;
    }
  };
  
  export default exchangerateReducer;
  