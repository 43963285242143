import axios from "axios";
import { FETCH_MAINTENANCE_CATEGORYS_FAILURE, FETCH_MAINTENANCE_CATEGORYS_REQUEST, FETCH_MAINTENANCE_CATEGORYS_SUCCESS, FETCH_MAINTENANCE_CATEGORYS_SUCCESS_2, FETCH_MAINTENANCE_CATEGORY_FAILURE, FETCH_MAINTENANCE_CATEGORY_REQUEST, FETCH_MAINTENANCE_CATEGORY_SUCCESS } from "../../app/actions/actions";
import { localStorageData } from "../../utils/storage/localvalue";
import { baseurl } from "../../utils/url/baseurl";
import { saveDataToFile } from "../DataLocal";
import { toast } from "react-toastify";



// Create MaintenanceCategory
export const MaintenanceCategoryCreate = (
    data,
    toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_MAINTENANCE_CATEGORY_REQUEST });
        await axios
            .post(
                `${baseurl.url}/api/v1/maintenancecategorys/create`,
                data,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                toast.success(" Nouvelle categorie de maintenance s créer avec succès");
                dispatch({ type: FETCH_MAINTENANCE_CATEGORY_SUCCESS });
                dispatch(fetchMaintenanceCategorysAll());

            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || "Catégorie de maintenance mis à jour", { position: "bottom-right" });
                dispatch({ type: FETCH_MAINTENANCE_CATEGORY_FAILURE, payload: error.message });
            });
    };
}



// update insurance 
export const MaintenanceCategoryUpdateById = (idMaintenanceCategory, data) => {
    return async (dispatch) => {
        console.log(data)
        dispatch({ type: FETCH_MAINTENANCE_CATEGORY_REQUEST });
        await axios
            .put(`${baseurl.url}/api/v1/maintenancecategorys/update/${idMaintenanceCategory}`, data, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                dispatch({ type: FETCH_MAINTENANCE_CATEGORY_SUCCESS });
                toast.success(response?.data?.message || "Catégorie de maintenance mis à jour", { position: "bottom-right" });
                dispatch(fetchMaintenanceCategorysAll());
            })
            .catch((error) => {
                dispatch({ type: FETCH_MAINTENANCE_CATEGORY_FAILURE, payload: error.message });
                toast.error(error?.response?.data?.message || "Catégorie de maintenance non mis à jour", { position: "bottom-right" })
            });
    };
}



export const MaintenanceCategoryDeleteById = (idMaintenanceCategory) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_MAINTENANCE_CATEGORY_REQUEST });
        await axios
            .delete(`${baseurl.url}/api/v1/maintenancecategorys/delete/${idMaintenanceCategory}`, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                dispatch({ type: FETCH_MAINTENANCE_CATEGORY_SUCCESS });
                toast.success(response?.data?.message || "Catégorie de maintenance supprimer avec succès", { position: "bottom-right" });
                dispatch(fetchMaintenanceCategorysAll());
            })
            .catch((error) => {
                dispatch({ type: FETCH_MAINTENANCE_CATEGORY_FAILURE, payload: error.message });
                toast.error(error?.response?.data?.message || "Catégorie de maintenance non supprimer", { position: "bottom-right" })
            });
    };
}





// All insurance of plateforme
export function fetchMaintenanceCategorysAll() {
    return async (dispatch) => {
        dispatch({ type: FETCH_MAINTENANCE_CATEGORYS_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/maintenancecategorys/get_maintenancecategorys`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_MAINTENANCE_CATEGORYS_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_MAINTENANCE_CATEGORYS_SUCCESS_2, payload: response.data.data });
            saveDataToFile(response.data.data, localStorageData.MaintenanceCategorys);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_MAINTENANCE_CATEGORYS_FAILURE, payload: error.message });
            });
    }
}


// get insurance by id 
export function fetchMaintenanceCategoryById(idMaintenanceCategory) {
    return async (dispatch) => {
        dispatch({ type: FETCH_MAINTENANCE_CATEGORY_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/maintenancecategorys/get_maintenancecategory/${idMaintenanceCategory}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_MAINTENANCE_CATEGORY_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                dispatch({ type: FETCH_MAINTENANCE_CATEGORY_FAILURE, payload: error.message })
                //console.log(error);
            });
    }
}

