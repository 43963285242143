export const formatDateView = (dateString) => {
    return new Date(dateString).toLocaleDateString('fr-FR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
};




export const calculateDuration = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const durationInMs = end - start;
    const durationInHours = durationInMs / (1000 * 60 * 60);

    let days = Math.floor(durationInHours / 24);
    let hours = Math.floor(durationInHours % 24);
    let minutes = Math.floor((durationInHours % 1) * 60);

    // Si on dépasse l'heure ou les minutes, on arrondit au jour suivant
    if (hours > 0 || minutes > 0) {
        days += 1;  // Ajout d'un jour supplémentaire si on dépasse
        hours = 0;  // On remet les heures à 0
        minutes = 0; // On remet les minutes à 0
    }
    return { days, hours, minutes };
};

