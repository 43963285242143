import axios from "axios";
import { FETCH_EXPENSE_CATEGORYS_FAILURE, FETCH_EXPENSE_CATEGORYS_REQUEST, FETCH_EXPENSE_CATEGORYS_SUCCESS, FETCH_EXPENSE_CATEGORYS_SUCCESS_2, FETCH_EXPENSE_CATEGORY_FAILURE, FETCH_EXPENSE_CATEGORY_REQUEST, FETCH_EXPENSE_CATEGORY_SUCCESS, } from "../../app/actions/actions";
import { ROUTES } from "../../config/routingUrl";
import { dureeDeVie, getAndCheckLocalStorage, setWithExpiration } from "../../utils/storage/localvalueFuction";
import { localStorageData, localStorageKeys } from "../../utils/storage/localvalue";
import { baseurl, checkWordInURL } from "../../utils/url/baseurl";
import { saveDataToFile } from "../DataLocal";
import { toast } from "react-toastify";










// All reservation of plateforme
export function fetchExpenseCategorysAll() {
    return async (dispatch) => {
        dispatch({ type: FETCH_EXPENSE_CATEGORYS_REQUEST });

        await axios.get(`${baseurl.url}/api/v1/expensecategorys/get_expensecategorys`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        })
            .then((response) => {
                // console.log(response.data.data);
                dispatch({ type: FETCH_EXPENSE_CATEGORYS_SUCCESS, payload: response.data.data });
                dispatch({ type: FETCH_EXPENSE_CATEGORYS_SUCCESS_2, payload: response.data.data });
            })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_EXPENSE_CATEGORYS_FAILURE, payload: error.message });
            });
    }
}




// get reservation by id 
export function fetchExpenseCategorysById(idExpenseCategory) {
    return async (dispatch) => {
        dispatch({ type: FETCH_EXPENSE_CATEGORY_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/expenses/get_expense/${idExpenseCategory}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_EXPENSE_CATEGORYS_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                dispatch({ type: FETCH_EXPENSE_CATEGORY_FAILURE, payload: error.message })
                //console.log(error);
            });
    }
}






// ✅ Ajouter une nouvelle catégorie de dépense
export function ExpenseCategoryCreate(expenseCategoryData) {
    return async (dispatch) => {
        dispatch({ type: FETCH_EXPENSE_CATEGORY_REQUEST });

        await axios.post(`${baseurl.url}/api/v1/expensecategorys/create`, expenseCategoryData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        })
            .then((response) => {
                dispatch({ type: FETCH_EXPENSE_CATEGORY_SUCCESS, payload: response.data.data });
                toast.success(response?.data?.message || "Catégorie de dépense ajouter avec succès", { position: "bottom-right" })
                dispatch(fetchExpenseCategorysAll());
            })
            .catch((error) => {
                dispatch({ type: FETCH_EXPENSE_CATEGORY_FAILURE, payload: error.message });
                toast.error(error?.response?.data?.message || "Catégorie de dépense non ajouter", { position: "bottom-right" })
                dispatch(fetchExpenseCategorysAll());

            });
    }
}

// ✅ Modifier une catégorie de dépense
export function ExpenseCategoryUpdateById(idExpenseCategory, expenseCategoryData) {
    return async (dispatch) => {
        dispatch({ type: FETCH_EXPENSE_CATEGORY_REQUEST });

        await axios.put(`${baseurl.url}/api/v1/expensecategorys/update/${idExpenseCategory}`, expenseCategoryData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        })
            .then((response) => {
                dispatch({ type: FETCH_EXPENSE_CATEGORY_SUCCESS, payload: response.data.data });
                toast.success(response?.data?.message || "Catégorie de dépense mis à jour avec succès", { position: "bottom-right" })
                dispatch(fetchExpenseCategorysAll());
            })
            .catch((error) => {
                dispatch({ type: FETCH_EXPENSE_CATEGORY_FAILURE, payload: error.message });
                toast.error(error?.response?.data?.message || "Catégorie de dépense non mis à jour", { position: "bottom-right" })
                dispatch(fetchExpenseCategorysAll());

            });
    }
}

// ✅ Supprimer une catégorie de dépense
export function ExpenseCategoryDeletedById(idExpenseCategory) {
    return async (dispatch) => {
        dispatch({ type: FETCH_EXPENSE_CATEGORY_REQUEST });

        await axios.delete(`${baseurl.url}/api/v1/expensecategorys/delete/${idExpenseCategory}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        })
            .then((response) => {
                dispatch({ type: FETCH_EXPENSE_CATEGORY_SUCCESS, payload: idExpenseCategory });
                toast.success(response?.data?.message || "Catégorie de dépense supprimer avec succès", { position: "bottom-right" })
                dispatch(fetchExpenseCategorysAll());
            })
            .catch((error) => {
                dispatch({ type: FETCH_EXPENSE_CATEGORY_FAILURE, payload: error.message });
                toast.error(error?.response?.data?.message || "Catégorie de dépense non supprimer", { position: "bottom-right" })
                dispatch(fetchExpenseCategorysAll());

            });
    }
}



