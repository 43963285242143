



// // taux de change en question
// import { fetchExchangeRateGetCurency } from "../../actions/request/ExchangeRateRequest";

import moment from "moment";
import { ROUTES } from "../../config/routingUrl";

// async function getTauxEuroToDinar() {
//   const { buyRate } = await fetchExchangeRateGetCurency("EUR") || 0;
//   console.log('Taux Euro à Dinar :', buyRate);
//   return buyRate;
// }

// // Exemple d'appel
// getTauxEuroToDinar().then((rate) => {
//   console.log('Valeur récupérée :', rate);
// });




// export const logoApplication = "logoApplication.png";
export const logoApplication = "https://res.cloudinary.com/dt6ammifo/image/upload/v1730033828/logoApplication_zvt36g.png";

export const emptyImage = "https://media.istockphoto.com/id/1409329028/fr/vectoriel/aucune-image-disponible-espace-r%C3%A9serv%C3%A9-ic%C3%B4ne-miniature-illustration-design.jpg?s=612x612&w=0&k=20&c=2G3XRVmxPifjkDYAzuAA-5QygJVWZ6LrFdgQHqOsGrw=";


export const bgHome = "bg_service.jpg";
export const bgHome2 = "bgHome2.jpg";
export const bgHome2_2 = "bgHome2.1.jpg";
export const iconSmile = "smile.png";


export const facebookLink = "https://web.facebook.com/profile.php?id=61563997472335"
export const youtubeLink = "https://www.youtube.com"
export const ErrorIllustration = "Error.png";
export const currentDate = moment().startOf('day');
export const ageDateNaissanceMax = 18;
export const maxLengthNumberLitteStart = 500;
export const maxLengthNumberLong = 200000;
export const maxLengthLitteStart = 50;
export const maxLengthLitte = 100;
export const maxLengthLong = 500;


export const patrimoineTypes = [
  { name: "Véhicule", type: "VEHICLE" },
  { name: "Propriété", type: "PROPERTY" },
];

export const profileRoleType = {
  CUSTOMER: "CUSTOMER",
  USER: "USER",
  //adminstrator
  ADMIN: "ADMIN",
  SUPER_ADMIN: "SUPER_ADMIN",
  SUPPORT: "SUPPORT",
}

 export const restrictedPages = {
  [profileRoleType.CUSTOMER]: [
      ROUTES.CAR_DETAIL_USER_PATRIMOINE_LIST,
      ROUTES.REPORTING_LIST,
      ROUTES.INSURANCES_LIST_ADMIN,
      ROUTES.INSURANCES_LIST_PLATEFORM,
      ROUTES.INSURANCES_LIST_PLATEFORM_EDIT,
      ROUTES.INSURANCES_LIST_PLATEFORM_DETAIL,
      ROUTES.DAMAGES_LIST_PLATEFORM,
      ROUTES.DAMAGES_LIST_PLATEFORM_DETAIL,
      ROUTES.DAMAGES_PLATEFORM_ADD,
      ROUTES.RESERVATION_FORM_PAGE_WEB,
      ROUTES.DASHBOARDREPORT,
      ROUTES.CODEPROMOS_LIST,
      ROUTES.PAYMENTS_DETAIL_PLATEFORM,
      ROUTES.PAYMENTS_LIST_ADMIN,
      ROUTES.PAYMENTS_LIST_PLATEFORM,
      ROUTES.CAR_DETAIL_USER_PATRIMOINE_LIST,
      ROUTES.CARS_ADD,
      ROUTES.PROPERTYS_ADD,
      ROUTES.PROPERTYS_LIST,
      ROUTES.USERS_LIST_PLATEFORM,
      ROUTES.CONTACT_SEND_LIST,
      ROUTES.CUSTOMERS_LIST_PLATEFORM,
      ROUTES.TYPE_EXPENSES_LIST,
      ROUTES.TYPE_ASSURANCE_LIST,
      ROUTES.TYPE_VEHICLE_LIST,
      ROUTES.TYPE_PROPERTY_LIST,
      ROUTES.TYPE_MAINTENANCES_LIST,
      ROUTES.TYPE_DAMAGE_LIST,
      ROUTES.INTERIOR_VEHICLE_LIST,
      ROUTES.ENGINE_VEHICLE_LIST,
      ROUTES.AIRPORTS_LIST,
      ROUTES.EXCHANGE_LIST,
      ROUTES.MOTIFS_RESERVATION_CANCECELLED_LIST,
  ],
  [profileRoleType.USER]: [
      ROUTES.USERS_LIST_PLATEFORM,
      ROUTES.CONTACT_SEND_LIST,
      ROUTES.CUSTOMERS_LIST_PLATEFORM,
      ROUTES.INSURANCES_LIST_ADMIN,
      ROUTES.TYPE_EXPENSES_LIST,
      ROUTES.TYPE_ASSURANCE_LIST,
      ROUTES.TYPE_VEHICLE_LIST,
      ROUTES.TYPE_PROPERTY_LIST,
      ROUTES.TYPE_MAINTENANCES_LIST,
      ROUTES.TYPE_DAMAGE_LIST,
      ROUTES.INTERIOR_VEHICLE_LIST,
      ROUTES.ENGINE_VEHICLE_LIST,
      ROUTES.AIRPORTS_LIST,
      ROUTES.EXCHANGE_LIST,
      ROUTES.MOTIFS_RESERVATION_CANCECELLED_LIST,
  ]
};



export const colorApp = {
  bg_primary: "bg-primary",
  bg_danger: "bg-danger",
  bg_secondary: "bg-dark",
  btn_primary: "btn-primary",
  btn_delete: "btn-danger",
  btn_grey: "btn-secondary",
  btn_light: "btn-light",
  btn_info: "btn-info",
  btn_secondary: "btn-dark",
  btn_outline_secondary: "btn-outline-dark",
  btn_outline_primary: "btn-outline-warning",
  text_primary: "text-warning",
  text_secondary: "text-dark",
}


export const profilePictureDefault = "https://img.freepik.com/vecteurs-premium/homme-noir-barbu-clignotant-souriant-illustration-avatar-vectoriel-2d-portrait-personnage-dessin-anime-africain-americain-adulte-etincelant-portrait-visage-personnage-dessin-anime-amical-flirtant-homme-couleur-plate-image-profil-utilisateur-isolee-blanc_151150-19800.jpg?w=740";



export const roleAdmin = {
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
  SUPPORT: "SUPPORT",
  DOCTOR: "DOCTOR",
  OPERATOR: "OPERATOR",
  MANAGER: "MANAGER",
}





export const genderOptions = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
];



export const languagesList = [
  { value: "fr", label: "Français", picture: "https://flagcdn.com/fr.svg" },
  { value: "en", label: "Anglais", picture: "https://flagcdn.com/gb.svg" },
  { value: "es", label: "Espagnol", picture: "https://flagcdn.com/es.svg" },
  { value: "de", label: "Allemand", picture: "https://flagcdn.com/de.svg" },
  { value: "zh", label: "Chinois", picture: "https://flagcdn.com/cn.svg" },
  { value: "ja", label: "Japonais", picture: "https://flagcdn.com/jp.svg" },
  { value: "ru", label: "Russe", picture: "https://flagcdn.com/ru.svg" },
  { value: "ar", label: "Arabe", picture: "https://flagcdn.com/ae.svg" },
  { value: "pt", label: "Portugais", picture: "https://flagcdn.com/pt.svg" },
  { value: "it", label: "Italien", picture: "https://flagcdn.com/it.svg" }
];



export const sectionsTermsAndConditions = [
  {
    key: "generalConditions",
    label: "Conditions Générales",
    fields: [
      { key: "description", label: "Description", type: "text" },
      { key: "responsibility", label: "Responsabilité", type: "text" },
      // { key: "images", label: "Images (URLs)", type: "array" },
      // { key: "language", label: "Langue", type: "text" },
    ],
  },
  {
    key: "eligibility",
    label: "Éligibilité",
    fields: [
      { key: "minimumAge", label: "Âge Minimum", type: "text" },
      { key: "requiredDocuments", label: "Documents Requis", type: "array" },
      // { key: "images", label: "Images (URLs)", type: "array" },
      // { key: "language", label: "Langue", type: "text" },
    ],
  },
  {
    key: "reservationAndPayment",
    label: "Réservations et Paiements",
    fields: [
      { key: "steps", label: "Étapes", type: "array" },
      { key: "cancellationPolicy", label: "Politique d'Annulation", type: "text" },
      { key: "securePayment", label: "Paiement Sécurisé", type: "text" },
      // { key: "images", label: "Images (URLs)", type: "array" },
      // { key: "language", label: "Langue", type: "text" },
    ],
  },
  {
    key: "vehicleRental",
    label: "Location de Véhicules",
    fields: [
      { key: "fuelPolicy", label: "Politique de Carburant", type: "text" },
      { key: "mileageLimit", label: "Limite de Kilométrage", type: "text" },
      { key: "extraMileageCharge", label: "Coût par Kilomètre Supplémentaire", type: "text" },
      { key: "prohibitedUses", label: "Utilisations Interdites", type: "array" },
      { key: "insuranceDetails", label: "Détails de l'Assurance", type: "text" },
      // { key: "images", label: "Images (URLs)", type: "array" },
      // { key: "language", label: "Langue", type: "text" },
    ],
  },
  {
    key: "propertyRental",
    label: "Location de Propriétés",
    fields: [
      { key: "minimumStay", label: "Durée Minimale", type: "text" },
      { key: "guestPolicy", label: "Politique des Invités", type: "text" },
      { key: "maintenancePolicy", label: "Politique d'Entretien", type: "text" },
      { key: "penalties", label: "Pénalités", type: "text" },
      // { key: "images", label: "Images (URLs)", type: "array" },
      // { key: "language", label: "Langue", type: "text" },
    ],
  },
  {
    key: "extraFees",
    label: "Frais Supplémentaires",
    fields: [
      { key: "deliveryFee", label: "Frais de Livraison", type: "text" },
      { key: "additionalServices", label: "Services Supplémentaires (Nom et Prix)", type: "array" },
      // { key: "images", label: "Images (URLs)", type: "array" },
      // { key: "language", label: "Langue", type: "text" },
    ],
  },
  {
    key: "privacyPolicy",
    label: "Données de Confidentialité",
    fields: [
      { key: "dataCollected", label: "Données Collectées", type: "array" },
      { key: "dataUsage", label: "Utilisation des Données", type: "text" },
      { key: "securityMeasures", label: "Mesures de Sécurité", type: "text" },
      // { key: "images", label: "Images (URLs)", type: "array" },
      // { key: "language", label: "Langue", type: "text" },
    ],
  },
  {
    key: "disputeResolution",
    label: "Résolution des Litiges",
    fields: [
      { key: "amicableResolution", label: "Résolution Amicable", type: "text" },
      { key: "jurisdiction", label: "Juridiction", type: "text" },
      // { key: "images", label: "Images (URLs)", type: "array" },
      // { key: "language", label: "Langue", type: "text" },
    ],
  },
  {
    key: "forceMajeure",
    label: "Force Majeure",
    fields: [
      { key: "acceptedEvents", label: "Événements Acceptés", type: "array" },
      // { key: "images", label: "Images (URLs)", type: "array" },
      // { key: "language", label: "Langue", type: "text" },
    ],
  },
  {
    key: "seo",
    label: "SEO",
    fields: [
      { key: "keywords", label: "Mots-Clés", type: "array" },
      { key: "description", label: "Description", type: "text" },
    ],
  },
  {
    key: "agencyCustomizations",
    label: "Personnalisation de l'Agence",
    fields: [
      { key: "loyaltyProgram", label: "Programme de Fidélité", type: "text" },
      { key: "partialRefundPolicy", label: "Politique de Remboursement Partiel", type: "text" },
      { key: "multilingualSupport", label: "Langues Supportées", type: "array" },
      // { key: "images", label: "Images (URLs)", type: "array" },
      // { key: "language", label: "Langue", type: "text" },
    ],
  },
];






//   formaData terme et condition
export const formatDataTermsAndConditions = {
  agencyName: "Agence de Réservation Algérie",
  agencyContact: "rezaplus2024@gmail.com",
  languages: ["fr", "en", "ar"],
  generalConditions: [
    {
      description: "Tous les utilisateurs doivent respecter les règles établies par l'agence.",
      responsibility: "La plateforme agit uniquement comme un intermédiaire entre les utilisateurs et les agences.",
      images: ["https://example.com/images/general1.jpg"],
      language: "fr",
    },
  ],
  eligibility: [
    {
      minimumAge: 18,
      requiredDocuments: ["Permis de conduire", "Carte d'identité"],
      multipleAccounts: false,
      images: ["https://example.com/images/eligibility1.jpg"],
      language: "fr",
    },
  ],
  reservationAndPayment: [
    {
      steps: ["Créer un compte", "Choisir un véhicule ou une propriété", "Effectuer le paiement"],
      cancellationPolicy: "Les annulations sont possibles jusqu'à 24 heures avant la réservation.",
      securePayment: "Transactions sécurisées via Stripe ou PayPal.",
      images: ["https://example.com/images/payment1.jpg"],
      language: "fr",
    },
  ],
  vehicleRental: [
    {
      fuelPolicy: "Rendre le véhicule avec le même niveau de carburant.",
      mileageLimit: 300,
      extraMileageCharge: 15,
      prohibitedUses: ["Conduite hors route", "Utilisation commerciale non autorisée"],
      insuranceDetails: "Une assurance tous risques est incluse.",
      images: ["https://example.com/images/vehicle1.jpg"],
      language: "fr",
    },
  ],
  propertyRental: [
    {
      minimumStay: 2,
      guestPolicy: "Maximum 4 invités par propriété.",
      maintenancePolicy: "Restituer la propriété dans l'état initial.",
      penalties: "Les dommages seront facturés en fonction de l'évaluation.",
      images: ["https://example.com/images/property1.jpg"],
      language: "fr",
    },
  ],
  extraFees: [
    {
      deliveryFee: 500,
      additionalServices: [{ serviceName: "Nettoyage", fee: 300 }],
      images: ["https://example.com/images/extraFees1.jpg"],
      language: "fr",
    },
  ],
  privacyPolicy: [
    {
      dataCollected: ["Nom", "Adresse email", "Coordonnées bancaires"],
      dataUsage: "Vos données sont utilisées uniquement pour traiter les réservations.",
      securityMeasures: "Toutes les données sont cryptées.",
      images: ["https://example.com/images/privacy1.jpg"],
      language: "fr",
    },
  ],
  disputeResolution: [
    {
      amicableResolution: true,
      jurisdiction: "Tribunaux algériens",
      images: ["https://example.com/images/dispute1.jpg"],
      language: "fr",
    },
  ],
  forceMajeure: [
    {
      acceptedEvents: ["Catastrophes naturelles", "Conflits sociaux", "Épidémies"],
      images: ["https://example.com/images/forceMajeure1.jpg"],
      language: "fr",
    },
  ],
  seo: [
    {
      keywords: ["réservation de véhicules", "location de propriétés", "algérie"],
      description: "Une plateforme de réservation fiable pour véhicules et propriétés en Algérie.",
    },
  ],
  agencyCustomizations: [
    {
      loyaltyProgram: "Programme de fidélité offrant des remises sur les futures réservations.",
      partialRefundPolicy: "Les remboursements partiels sont possibles en cas d'annulation.",
      multilingualSupport: ["fr", "en", "ar"],
      images: ["https://example.com/images/customizations1.jpg"],
      language: "fr",
    },
  ],
};