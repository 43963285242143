import React, { useEffect, useState } from 'react';
import { Card, Spinner, Row, Col, Button, Table, Badge } from 'react-bootstrap';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MdAttachFile, MdPayment } from 'react-icons/md';
import { fetchPaymentById } from '../../../actions/request/PaymentRequest';
import { formatDateView } from '../../../utils/functionData/dateFunction';






const renderStatusBadge = (status) => {
    switch (status) {
        case 'Completed':
            return <Badge bg="success">Effectué</Badge>;
        case 'Failed':
            return <Badge bg="danger">Échoué</Badge>;
        case 'Pending':
            return <Badge bg="warning">En attente</Badge>;
    }
};



const PaymentDetailsPage = () => {
    const paymentId = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const payment = useSelector((state) => state.payments.payment);
    const loadingPayment = useSelector((state) => state.payments.loadingPayment);

    useEffect(() => {
        dispatch(fetchPaymentById(paymentId?.id));
    }, [dispatch, paymentId?.id]);



    return (
        <div className="container-fluid mt-5 justify-content-center">
            {loadingPayment ? (
                <div className="text-center " style={{ height: "500px", display: "flex", justifyContent: "center" }}>
                    <Spinner animation="border" variant="primary" />
                    <p>Chargement des détails du paiement...</p>
                </div>
            ) : (
                payment && (
                    <Card className="p-4">
                        <Row className="mb-4">
                            <Col md={12} className="text-start">
                                <h3>Détails du paiement</h3>
                                <p>Date : {formatDateView(payment.paymentDate)}</p>
                            </Col>
                        </Row>
                        <Table bordered className="mb-4">
                            <tbody>
                                <tr>
                                    <td><strong>Montant du Paiement (DZD)</strong></td>
                                    <td>{payment.amount} {payment.currency?.code}</td>
                                </tr>
                                <tr>
                                    <td><strong>Statut</strong></td>
                                    <td>
                                        {renderStatusBadge(payment.status)}
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong>Date du Paiement</strong></td>
                                    <td>{formatDateView(payment?.paymentDate)}</td>
                                </tr>
                                <tr>
                                    <td><strong>Ref Reservation</strong></td>
                                    <td>{payment?.reservation?.ordre}</td>
                                </tr>
                                <tr>
                                    <td><strong>Element</strong></td>
                                    <td>{payment.relatedObjectType}</td>
                                </tr>
                                {payment.paymentFile && (
                                    <tr>
                                        <td><strong>Pièce Jointe</strong></td>
                                        <td>
                                            <Button variant="link" href={payment.paymentFile} target="_blank">
                                                <MdAttachFile /> Voir le fichier
                                            </Button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>

                        <Row className="mt-4">
                            <Col md={{ span: 4, offset: 8 }}   >
                                <Card className="p-3 " style={{ display: "flex" }} >
                                    <div><h4>Total </h4></div>
                                    <hr />
                                    <div>
                                        <h5><strong>{payment?.amount} {payment.currency?.code}</strong></h5>
                                    </div>
                                </Card>
                            </Col>
                        </Row>

                        <div className="text-center mt-4">
                            <Link to="#" onClick={() => navigate(-1)}>Retour</Link>
                        </div>
                    </Card>
                )
            )}
        </div>
    );
};

export default PaymentDetailsPage;