import axios from "axios";
import { FETCH_INTERIOR_TYPES_FAILURE, FETCH_INTERIOR_TYPES_REQUEST, FETCH_INTERIOR_TYPES_SUCCESS, FETCH_INTERIOR_TYPES_SUCCESS_2, FETCH_INTERIOR_TYPE_FAILURE, FETCH_INTERIOR_TYPE_REQUEST, FETCH_INTERIOR_TYPE_SUCCESS } from "../../app/actions/actions";
import { ROUTES } from "../../config/routingUrl";
import { dureeDeVie, setWithExpiration } from "../../utils/storage/localvalueFuction";
import { localStorageData, localStorageKeys } from "../../utils/storage/localvalue";
import { baseurl } from "../../utils/url/baseurl";
import { saveDataToFile } from "../DataLocal";
import { toast } from "react-toastify";




// All insurance of plateforme
export function fetchInteriorTypesAll() {
    return async (dispatch) => {
        dispatch({ type: FETCH_INTERIOR_TYPES_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/interiortypes/get_interiortypes`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_INTERIOR_TYPES_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_INTERIOR_TYPES_SUCCESS_2, payload: response.data.data });
            // saveDataToFile(response.data.data, localStorageData.InteriorTypes);
        }).catch((error) => {
            //console.log(error);
            dispatch({ type: FETCH_INTERIOR_TYPES_FAILURE, payload: error.message });
        });
    }
}


// get insurance by id 
export function fetchInteriorTypeById(idInteriorType) {
    return async (dispatch) => {
        dispatch({ type: FETCH_INTERIOR_TYPE_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/interiortypes/get_interiortype/${idInteriorType}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_INTERIOR_TYPE_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                dispatch({ type: FETCH_INTERIOR_TYPE_FAILURE, payload: error.message })
                //console.log(error);
            });
    }
}





// ✅ Ajouter un type d’intérieur
export function InteriorTypeCreate(interiorTypeData) {
    return async (dispatch) => {
        dispatch({ type: FETCH_INTERIOR_TYPE_REQUEST });

        await axios.post(`${baseurl.url}/api/v1/interiortypes/create`, interiorTypeData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        })
            .then((response) => {
                dispatch({ type: FETCH_INTERIOR_TYPE_SUCCESS, payload: response.data.data });
                toast.success("Type d’intérieur ajouté avec succès !");
                dispatch(fetchInteriorTypesAll()); // 🔄 Recharger la liste
            })
            .catch((error) => {
                dispatch({ type: FETCH_INTERIOR_TYPE_FAILURE, payload: error.message });
                toast.error("Erreur lors de l'ajout du type d’intérieur !");
            });
    };
}

// ✅ Modifier un type d’intérieur
export function InteriorTypeUpdateById(idInteriorType, interiorTypeData) {
    return async (dispatch) => {
        dispatch({ type: FETCH_INTERIOR_TYPE_REQUEST });

        await axios.put(`${baseurl.url}/api/v1/interiortypes/update/${idInteriorType}`, interiorTypeData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        })
            .then((response) => {
                dispatch({ type: FETCH_INTERIOR_TYPE_SUCCESS, payload: response.data.data });
                toast.success(response?.data?.message || "Type d’intérieur mis à jour avec succès !", { position: "bottom-right" });
                dispatch(fetchInteriorTypesAll()); // 🔄 Recharger la liste
            })
            .catch((error) => {
                dispatch({ type: FETCH_INTERIOR_TYPE_FAILURE, payload: error.message });
                toast.error(error?.response?.data?.message || "Erreur lors de la mise à jour du type d’intérieur !", { position: "bottom-right" });
            });
    };
}



// ✅ Supprimer un type d’intérieur
export function InteriorTypeDeleteById(idInteriorType) {
    return async (dispatch) => {
        dispatch({ type: FETCH_INTERIOR_TYPE_REQUEST });

        await axios.delete(`${baseurl.url}/api/v1/interiortypes/delete/${idInteriorType}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        })
            .then((response) => {
                dispatch({ type: FETCH_INTERIOR_TYPE_SUCCESS, payload: idInteriorType });
                toast.success(response?.data?.message || "Type d’intérieur supprimé avec succès !", { position: "bottom-right" });
                dispatch(fetchInteriorTypesAll()); // 🔄 Recharger la liste
            })
            .catch((error) => {
                dispatch({ type: FETCH_INTERIOR_TYPE_FAILURE, payload: error.message });
                toast.error(error?.response?.data?.message || "Erreur lors de la suppression du type d’intérieur !", { position: "bottom-right" });
            });
    };
}

