import axios from "axios";
import { FETCH_DAMAGE_TYPES_FAILURE, FETCH_DAMAGE_TYPES_REQUEST, FETCH_DAMAGE_TYPES_SUCCESS, FETCH_DAMAGE_TYPES_SUCCESS_2, FETCH_DAMAGE_TYPE_FAILURE, FETCH_DAMAGE_TYPE_REQUEST, FETCH_DAMAGE_TYPE_SUCCESS } from "../../app/actions/actions";
import { ROUTES } from "../../config/routingUrl";
import { dureeDeVie, setWithExpiration } from "../../utils/storage/localvalueFuction";
import { localStorageData, localStorageKeys } from "../../utils/storage/localvalue";
import { baseurl } from "../../utils/url/baseurl";
import { saveDataToFile } from "../DataLocal";
import { toast } from "react-toastify";



// Create DamageType
export const DamageTypeCreate = (
    data) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_DAMAGE_TYPE_REQUEST });
        await axios
            .post(
                `${baseurl.url}/api/v1/damage_types/create`,
                data,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                toast.success(response?.data?.message || "Catégorie de dommage ajouter avec succès", { position: "bottom-right" });
                dispatch({ type: FETCH_DAMAGE_TYPE_SUCCESS });
                dispatch(fetchDamageTypesAll());


            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || "Catégorie de domage non ajouté", { position: "bottom-right" })
                dispatch({ type: FETCH_DAMAGE_TYPE_FAILURE, payload: error.message });
                dispatch(fetchDamageTypesAll());

            });
    };
}



// update insurance 
export const DamageTypeUpdateById = (idDamageType, data, toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_DAMAGE_TYPE_REQUEST });
        await axios
            .put(`${baseurl.url}/api/v1/damage_types/update/${idDamageType}`, data, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                dispatch({ type: FETCH_DAMAGE_TYPE_SUCCESS });
                toast.success(response?.data?.message || "Type de domage mis à jour", { position: "bottom-right" });
                dispatch(fetchDamageTypesAll());

            })
            .catch((error) => {
                dispatch({ type: FETCH_DAMAGE_TYPE_FAILURE, payload: error.message });
                toast.error(error?.response?.data?.message || "Véhicule non mis à jour", { position: "bottom-right" })
                dispatch(fetchDamageTypesAll());

            });
    };
}





// All insurance of plateforme
export function fetchDamageTypesAll() {
    return async (dispatch) => {
        dispatch({ type: FETCH_DAMAGE_TYPES_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/damage_types/get_damage_types`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_DAMAGE_TYPES_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_DAMAGE_TYPES_SUCCESS_2, payload: response.data.data });
            saveDataToFile(response.data.data, localStorageData.DamageTypes);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_DAMAGE_TYPES_FAILURE, payload: error.message });
            });
    }
}


// get insurance by id 
export function fetchDamageTypeById(idDamageType) {
    return async (dispatch) => {
        dispatch({ type: FETCH_DAMAGE_TYPE_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/damage_types/get_damage_type/${idDamageType}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_DAMAGE_TYPE_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                dispatch({ type: FETCH_DAMAGE_TYPE_FAILURE, payload: error.message })
                //console.log(error);
            });
    }
}

