import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Form, Button, Container, Row, Col, Pagination, Badge, Modal, Spinner, Offcanvas } from "react-bootstrap";
import { fetchVehiclesAll, fetchVehiclesAllResearch, fetchVehiclesAllResearchAvailable } from "../../../actions/request/VehicleRequest";
import { fetchPropertysAll, fetchPropertysAllResearch, fetchPropertysAllResearchAvailable } from "../../../actions/request/PropertyRequest";
import { FaSearch } from 'react-icons/fa';
import "./CarListFilterList.css";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../config/routingUrl";
import { FETCH_PROPERTY_SUCCESS, FETCH_VEHICLE_SUCCESS } from "../../../app/actions/actions";
import { currentDate, emptyImage } from "../../../utils/dataApi/dataFormApi";
import Select from 'react-select';
import moment from 'moment';
import { MdCarRental, MdHomeFilled, MdLocationOn, MdSearch } from "react-icons/md";
import axios from "axios";
import { baseurl } from "../../../utils/url/baseurl";
import { dureeDeVie, setWithExpiration } from "../../../utils/storage/localvalueFuction";
import { localStorageKeys } from "../../../utils/storage/localvalue";
import { RiFilter3Line } from "react-icons/ri";
import { Range } from 'react-range'; // Import de react-range
import { fetchVehicleBrandsAll } from "../../../actions/request/VehicleBrandRequest";
import { fetchVehicleModelsAll } from "../../../actions/request/VehicleModelRequest";
import { fetchengineTypesAll } from "../../../actions/request/EngineTypeRequest";
import { fetchInteriorTypesAll } from "../../../actions/request/InteriorTypeRequest";



export const ContainerFilter = ({
    filterType,
    setFilterType,
    pickupLocation,
    handlePickupLocationChange,
    pickupSuggestions,
    handlePickupSelection,
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
    availableStartDate,
    setAvailableStartDate,
    availableStartTime,
    setAvailableStartTime,
    availableEndDate,
    setAvailableEndDate,
    availableEndTime,
    setAvailableEndTime,
    hourOptions,
    handleSearch,
    // anné de contruction
    minYearBuild,
    setminYearBuild,
    maxYearBuild,
    setmaxYearBuild,
    // nombre de passagers
    minPassenger,
    setminPassenger,
    maxPassenger,
    setmaxPassenger,
    // kilometrage
    minMileage,
    setminMileage,
    maxMileage,
    setmaxMileage,
    // Categorie
    interiorType,// moteur
    setinteriorType,// moteur
    engineType,// marque
    setengineType,// marque
    vehiclebrand,
    setvehiclebrand,
    optionsItem,
    setoptionsItem,
    model,
    setmodel
}) => {


    const vehiclebrandList = useSelector((state) => state.vehiclebrands.vehiclebrands);
    const interiorTypes = useSelector((state) => state.interiorTypes.interiorTypes);
    const engineTypes = useSelector((state) => state.engineTypes.engineTypes);
    const vehiclemodels = useSelector((state) => state.vehiclemodels.vehiclemodels);

    const vehiclebrandOptions = vehiclebrandList && vehiclebrandList.length > 0 ? vehiclebrandList.map((vehhiclebrand) => ({
        label: vehhiclebrand.name,
        value: vehhiclebrand.name
    })) : []

    const enginetypeOptions = engineTypes && engineTypes.length > 0 ? engineTypes.map((engine) => ({
        label: engine?.name,
        value: engine?._id
    })) : []

    const interiortypeOptions = interiorTypes && interiorTypes.length > 0 ? interiorTypes.map((interiortype) => ({
        label: interiortype?.name,
        value: interiortype?._id
    })) : []

    const vehiclemodelsOptions = vehiclemodels && vehiclemodels.length > 0 ? vehiclemodels.map((interiortype) => ({
        label: interiortype?.name,
        value: interiortype?._id
    })) : []

    // Empêcher la sélection de dates passées
    const today = new Date().toISOString().split('T')[0];
    // État pour gérer les valeurs du slider
    const [priceRange, setPriceRange] = useState([minPrice || 500, maxPrice || 200000]);
    const [passengerRange, setPassengerRange] = useState([minPassenger || 1, maxPassenger || 200]);
    const [mileageRange, setMileageRange] = useState([minMileage || 0, maxMileage || 10000]);
    const [yearRange, setYearRange] = useState([minYearBuild || 1900, maxYearBuild || new Date().getFullYear()]);

    return (
        <Form className="mb-4">
            <h5>Filtres</h5>

            {/* Sélecteur de type (Véhicule ou Propriété) */}
            <Form.Group>
                <Form.Label>Type de filtre</Form.Label>
                <div className="d-flex gap-2">
                    {/* Bouton pour Véhicules */}
                    <div>
                        <Button
                            size="sm"
                            variant={filterType === "vehicle" ? "primary" : "outline-primary"}
                            onClick={() => setFilterType("vehicle")}
                            className="d-flex align-items-center"
                        >
                            <MdCarRental className="me-2" /> Véhicules
                        </Button>
                    </div>

                    {/* Bouton pour Propriétés */}
                    <div>
                        <Button
                            size="sm"
                            variant={filterType === "property" ? "primary" : "outline-primary"}
                            onClick={() => setFilterType("property")}
                            className="d-flex align-items-center"
                        >
                            <MdHomeFilled className="me-2" /> Propriétés
                        </Button>
                    </div>
                </div>
            </Form.Group>

            {/* Champ de recherche pour la localisation */}
            <Form.Group className="mb-3 relative">
                <Form.Label>Localisation</Form.Label>
                <Form.Control style={{ height: "40px" }}
                    type="text"
                    id="agenceDepart"
                    name="agenceDepart"
                    placeholder="Saisissez lieu en Algérie"
                    value={pickupLocation}
                    onChange={handlePickupLocationChange}
                />
                {pickupSuggestions.length > 0 && (
                    <ul className="rounded p-1 border" style={{ position: 'absolute', zIndex: 1000, backgroundColor: 'white', maxWidth: "400px", listStyleType: 'none', padding: 0, maxHeight: "200px", overflow: "auto" }}>
                        {pickupSuggestions.map((suggestion, index) => (
                            <li className="border"
                                key={index}
                                style={{ padding: '5px', cursor: 'pointer' }}
                                onClick={() => handlePickupSelection(suggestion)}
                            >
                                <MdLocationOn /> {`${suggestion.name} - ${suggestion.formatted_address}`}
                            </li>
                        ))}
                    </ul>
                )}
            </Form.Group>



            <Row>
                {/* Date et Heure de début */}
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>Date de début</Form.Label>
                        <Form.Control style={{ height: "40px" }}
                            type="date"
                            placeholder="début"
                            min={today}
                            value={availableStartDate}
                            onChange={(e) => setAvailableStartDate(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>Heure de début</Form.Label>
                        <Select
                            value={availableStartTime}
                            onChange={(selectedOption) => setAvailableStartTime(selectedOption)}
                            options={hourOptions}
                        />
                    </Form.Group>
                </Col>
                {/* Date et Heure de fin */}

                <Col md={6}><Form.Group>
                    <Form.Label>Date de fin</Form.Label>
                    <Form.Control style={{ height: "40px" }}
                        type="date"
                        placeholder="fin"
                        min={availableStartDate || today}
                        value={availableEndDate}
                        onChange={(e) => setAvailableEndDate(e.target.value)}
                        disabled={!availableStartDate}
                        isClearable
                    />
                </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>Heure de fin</Form.Label>
                        <Select
                            value={availableEndTime}
                            onChange={(selectedOption) => setAvailableEndTime(selectedOption)}
                            options={hourOptions}
                            isDisabled={!availableStartDate}
                            isClearable
                        />
                    </Form.Group>
                </Col>
            </Row>

            {/* Filtre Prix */}
            <div className="px-2 py-1">
                <Form.Group className="mb-3">
                    <Form.Label>Prix</Form.Label>
                    <Range
                        step={500}
                        min={500}
                        max={200000}
                        values={priceRange}
                        onChange={(values) => {
                            setPriceRange(values);
                            setMinPrice(values[0]);
                            setMaxPrice(values[1]);
                        }}
                        renderTrack={({ props, children }) => (
                            <div
                                {...props}
                                style={{
                                    height: '6px',
                                    width: '100%',
                                    backgroundColor: '#ddd',
                                    borderRadius: '3px',
                                    alignSelf: 'center',
                                }}
                            >
                                {children}
                            </div>
                        )}
                        renderThumb={({ props }) => (
                            <div
                                {...props}
                                style={{
                                    height: '20px',
                                    width: '20px',
                                    backgroundColor: '#129103',
                                    borderRadius: '50%',
                                    border: '2px solid #fff',
                                    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
                                    cursor: 'grab',
                                }}
                            />
                        )}
                    />
                    <div className="d-flex justify-content-between mt-2">
                        <span>Min: {priceRange[0]} DZD</span>
                        <span>Max: {priceRange[1]} DZD</span>
                    </div>
                </Form.Group>

                {/* Filtre Nombre de Passagers */}
                {
                    filterType === "vehicle" && (
                        <Form.Group className="mb-3">
                            <Form.Label>Nombre de Siège</Form.Label>
                            <Range
                                step={1}
                                min={1}
                                max={200}
                                values={passengerRange}
                                onChange={(values) => {
                                    setPassengerRange(values);
                                    setminPassenger(values[0]);
                                    setmaxPassenger(values[1]);
                                }}
                                renderTrack={({ props, children }) => (
                                    <div
                                        {...props}
                                        style={{
                                            height: '6px',
                                            width: '100%',
                                            backgroundColor: '#ddd',
                                            borderRadius: '3px',
                                            alignSelf: 'center',
                                        }}
                                    >
                                        {children}
                                    </div>
                                )}
                                renderThumb={({ props }) => (
                                    <div
                                        {...props}
                                        style={{
                                            height: '20px',
                                            width: '20px',
                                            backgroundColor: '#129103',
                                            borderRadius: '50%',
                                            border: '2px solid #fff',
                                            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
                                            cursor: 'grab',
                                        }}
                                    />
                                )}
                            />
                            <div className="d-flex justify-content-between mt-2">
                                <span>Min: {passengerRange[0]}</span>
                                <span>Max: {passengerRange[1]}</span>
                            </div>
                        </Form.Group>
                    )
                }


                {/* Filtre Kilométrage */}
                {
                    filterType === "vehicle" && (
                        <Form.Group className="mb-3">
                            <Form.Label>Kilométrage</Form.Label>
                            <Range
                                step={100}
                                min={0}
                                max={10000}
                                values={mileageRange}
                                onChange={(values) => {
                                    setMileageRange(values);
                                    setminMileage(values[0]);
                                    setmaxMileage(values[1]);
                                }}
                                renderTrack={({ props, children }) => (
                                    <div
                                        {...props}
                                        style={{
                                            height: '6px',
                                            width: '100%',
                                            backgroundColor: '#ddd',
                                            borderRadius: '3px',
                                            alignSelf: 'center',
                                        }}
                                    >
                                        {children}
                                    </div>
                                )}
                                renderThumb={({ props }) => (
                                    <div
                                        {...props}
                                        style={{
                                            height: '20px',
                                            width: '20px',
                                            backgroundColor: '#129103',
                                            borderRadius: '50%',
                                            border: '2px solid #fff',
                                            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
                                            cursor: 'grab',
                                        }}
                                    />
                                )}
                            />
                            <div className="d-flex justify-content-between mt-2">
                                <span>Min: {mileageRange[0]} km</span>
                                <span>Max: {mileageRange[1]} km</span>
                            </div>
                        </Form.Group>


                    )
                }

                {/* Filtre Année de Construction */}
                {
                    /* 
                    filterType === "property" && (
                        <Form.Group className="mb-3">
                            <Form.Label>Année de Construction</Form.Label>
                            <Range
                                step={1}
                                min={1900}
                                max={new Date().getFullYear()}
                                values={yearRange}
                                onChange={(values) => {
                                    setYearRange(values);
                                    setminYearBuild(values[0]);
                                    setmaxYearBuild(values[1]);
                                }}
                                renderTrack={({ props, children }) => (
                                    <div
                                        {...props}
                                        style={{
                                            height: '6px',
                                            width: '100%',
                                            backgroundColor: '#ddd',
                                            borderRadius: '3px',
                                            // alignSelf: 'center',
                                        }}
                                    >
                                        {children}
                                    </div>
                                )}
                                renderThumb={({ props }) => (
                                    <div
                                        {...props}
                                        style={{
                                            height: '20px',
                                            width: '20px',
                                            backgroundColor: '#129103',
                                            borderRadius: '50%',
                                            border: '2px solid #fff',
                                            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
                                            cursor: 'grab',
                                        }}
                                    />
                                )}
                            />
                            <div className="d-flex justify-content-between mt-2">
                                <span>Min: {yearRange[0]}</span>
                                <span>Max: {yearRange[1]}</span>
                            </div>
                        </Form.Group>
                    )
                    */
                }
            </div>
            {
                filterType == "vehicle" && (
                    <Row className="">
                        <Col md={12}>
                            <div>Plus d{"'"}options</div>
                        </Col>

                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Moteur</Form.Label>
                                <Select
                                    value={engineType}
                                    onChange={(selectedOption) => setengineType(selectedOption)}
                                    options={enginetypeOptions}
                                    placeholder="choix"
                                    isClearable
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Marque</Form.Label>
                                <Select
                                    value={vehiclebrand}
                                    onChange={(selectedOption) => setvehiclebrand(selectedOption)}
                                    options={vehiclebrandOptions}
                                    placeholder="choix"
                                    isClearable
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Catégorie</Form.Label>
                                <Select
                                    value={interiorType}
                                    onChange={(selectedOption) => setinteriorType(selectedOption)}
                                    options={interiortypeOptions}
                                    placeholder="choix"
                                    isClearable
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Modèle</Form.Label>
                                <Select
                                    value={model}
                                    onChange={(selectedOption) => setmodel(selectedOption)}
                                    options={vehiclemodelsOptions}
                                    placeholder="choix"
                                    isClearable
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                )
            }




            {/* Bouton de recherche */}
            <div>
                <Button
                    variant="primary"
                    className="mt-3 w-100 justify-content-center"
                    onClick={handleSearch}
                >
                    <FaSearch /> <span>Rechercher</span>
                </Button>
            </div>

        </Form>
    );
};





const CarListFilterList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loadingVehicles = useSelector((state) => state.vehicles.loadingVehicles);
    const loadingPropertys = useSelector((state) => state.propertys.loadingPropertys);
    const vehicles = useSelector((state) => state.vehicles.vehiclesAvailable);
    const propertys = useSelector((state) => state.propertys.propertysAvailable);
    const vehiclemodels = useSelector((state) => state.vehiclemodels.vehiclemodels);
    const [availableStartDate, setAvailableStartDate] = useState('');
    const [availableStartTime, setAvailableStartTime] = useState({ value: '00:00', label: '00:00' });
    const [availableEndDate, setAvailableEndDate] = useState('');
    const [availableEndTime, setAvailableEndTime] = useState({ value: '00:00', label: '00:00' });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(12);
    const [filterType, setFilterType] = useState("vehicle");
    // priw
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(0);
    // année de construction
    const [minYearBuild, setminYearBuild] = useState(1900);
    const [maxYearBuild, setmaxYearBuild] = useState(0);
    // nombre de passagers
    const [minPassenger, setminPassenger] = useState(0);
    const [maxPassenger, setmaxPassenger] = useState(0);
    // kilometrage
    const [minMileage, setminMileage] = useState(0)
    const [maxMileage, setmaxMileage] = useState(0)
    // Categorie
    const [interiorType, setinteriorType] = useState("");
    // moteur
    const [engineType, setengineType] = useState("");
    // marque
    const [vehiclebrand, setvehiclebrand] = useState('');
    // option des propriété et des véhicule en question
    const [optionsItem, setoptionsItem] = useState()
    // Model
    const [model, setmodel] = useState(null)


    const [filteredVehicles, setFilteredVehicles] = useState([]);
    const [filteredProperties, setFilteredProperties] = useState([]);


    // Création des options d'heures avec intervalles de 30 minutes
    const hourOptions = Array.from({ length: 24 }, (_, i) => {
        const hour = i.toString().padStart(2, '0');
        return [
            { value: `${hour}:00`, label: `${hour}:00` },
            { value: `${hour}:30`, label: `${hour}:30` }
        ];
    }).flat();



    useEffect(() => {
        setminMileage(0);
        setMinPrice(500);
        setMaxPrice(200000)
        setminYearBuild(1900);
        setmaxYearBuild(new Date().getFullYear())
        dispatch(fetchVehiclesAllResearchAvailable(currentDate, currentDate));
        dispatch(fetchPropertysAllResearchAvailable(currentDate, currentDate));
        dispatch(fetchVehicleBrandsAll());
        dispatch(fetchVehicleModelsAll());
        dispatch(fetchengineTypesAll());
        dispatch(fetchInteriorTypesAll());
    }, [dispatch]);

    // useEffect(() => {
    //     // Mettre à jour le maxPrice après le chargement des véhicules
    //     if (vehicles.length > 0) {
    //         setMaxPrice(Math.max(...vehicles.map(v => v.basePricePerDay), 0));
    //     }
    // }, [vehicles]);

    // useEffect(() => {
    //     // Mettre à jour le maxPrice après le chargement des véhicules
    //     if (vehicles.length > 0) {
    //         setmaxMileage(Math.max(...vehicles.map(v => v.mileage), 0));
    //     }
    // }, [vehicles]);

    useEffect(() => {
        // Filtrage basé sur le prix
        const vehiclesFiltered = vehicles || []
        // /*.sort((a, b) => b.basePricePerDay - a.basePricePerDay) */
        //     .filter((vehicle) => {
        //         const price = vehicle.basePricePerDay || 0;
        //         return price >= minPrice && price <= maxPrice;
        //     });

        const propertiesFiltered = propertys || []
        // /*.sort((a, b) => b.basePricePerDay - a.basePricePerDay) */
        //     .filter((property) => {
        //         const price = property.price || 0; // Assurez-vous que `price` est défini
        //         return price >= minPrice && price <= maxPrice;
        //     });

        setFilteredVehicles(vehiclesFiltered);
        setFilteredProperties(propertiesFiltered);
    }, [vehicles, propertys]);



    const currentItems = filterType === "vehicle"
        ? filteredVehicles.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
        : filteredProperties.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const combineDateAndTime = (date, time) => {
        return moment(`${date}T${time.value}:00.000+00:00`).toISOString();
    };

    const returnValueFromSelect = (element) => {
        return element.value || "";
    };

    const handleSearch = () => {
        setShowOffCanvas(false)
        setCurrentPage(1);
        // Combiner les dates et heures
        const startDateTime = combineDateAndTime(availableStartDate, availableStartTime);
        const endDateTime = combineDateAndTime(availableEndDate, availableEndTime);

        // Envoyer les dates combinées avec la requête de recherche
        dispatch(fetchPropertysAllResearchAvailable(startDateTime || currentDate, endDateTime || currentDate, "", "", formData.lat, formData.lng, minPrice, maxPrice, "", "", "", optionsItem));
        dispatch(fetchVehiclesAllResearchAvailable(startDateTime || currentDate, endDateTime || currentDate, "", "", model?.value, vehiclebrand?.value, minPassenger, maxPassenger, minMileage, maxMileage, minPrice, maxPrice, formData.lat, formData.lng, optionsItem, interiorType?.Value, engineType?.value));

        const filteredVehicles = vehicles.filter((vehicle) => {
            const price = vehicle.basePricePerDay || 0;
            return price >= minPrice && price <= maxPrice;

        });

        const filteredProperties = propertys.filter((property) => {
            const price = property.price || 0; // Assurez-vous que `price` est défini
            return price >= minPrice && price <= maxPrice;
        });


        // Mettre à jour les véhicules et propriétés filtrés dans l'état local
        setFilteredVehicles(filteredVehicles);
        setFilteredProperties(filteredProperties);
    };

    // Empêcher la sélection de dates passées
    const today = new Date().toISOString().split('T')[0];

    const [formData, setFormData] = useState({
        agenceDepart: '',
        lat: '',
        lng: ''
    });

    const [pickupLocation, setPickupLocation] = useState('');
    const [pickupSuggestions, setPickupSuggestions] = useState([]);

    // Obtenir les suggestions de lieu de départ
    const getPlacesByCountryStartLocation = async (query) => {
        try {
            const response = await axios.get(`${baseurl.url}/api/v1/places`, {
                params: { query: query },
            });
            const places = response.data.results;
            return places.map(place => place);
        } catch (error) {
            console.error("Erreur lors de la récupération des lieux : ", error);
        }
    };

    const handlePickupLocationChange = async (e) => {
        const value = e.target.value;
        setPickupLocation(value);
        setTempLocation(value); // Change la valeur temporairement
        if (value.length == 0 || value == "") {
            setFormData(prevState => ({
                ...prevState,
                agenceDepart: ``,
                lat: '',
                lng: ''
            }));
            setPickupSuggestions([]); // Réinitialiser la liste des suggestions
        }
        else if (value.length > 2) {
            const suggestions = await getPlacesByCountryStartLocation(value);
            setPickupSuggestions(suggestions);
        } else {
            setPickupSuggestions([]); // Réinitialiser la liste des suggestions
        }
    };

    const handlePickupSelection = (suggestion) => {
        setPickupLocation(`${suggestion.name} - ${suggestion.formatted_address}`);
        setFormData(prevState => ({
            ...prevState,
            agenceDepart: `${suggestion.name} - ${suggestion.formatted_address}`,
            lat: suggestion?.geometry?.location?.lat,
            lng: suggestion?.geometry?.location?.lng
        }));

        setWithExpiration(localStorageKeys.agenceDepArt, `${suggestion.name} - ${suggestion.formatted_address}`, dureeDeVie);
        setWithExpiration(localStorageKeys.latSelect, suggestion?.geometry?.location?.lat, dureeDeVie);
        setWithExpiration(localStorageKeys.longSelect, suggestion?.geometry?.location?.lng, dureeDeVie);


        setPickupSuggestions([]);
    };

    const handleCleanSugestions = (event) => {
        // Vérifie si le clic vient bien de l'extérieur des champs input
        if (!event.target.closest('input')) {
            setPickupSuggestions([]);
        }
    };



    const [showOffCanvas, setShowOffCanvas] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

    // Vérification de la largeur de l'écran pour détecter le mode mobile
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 900);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);



    const [tempLocation, setTempLocation] = useState("");



    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setPickupLocation(tempLocation);
        }, 300); // Attends 300ms avant de setter réellement la valeur

        return () => clearTimeout(timeoutId);
    }, [tempLocation]);


    return (
        <Container fluid className="mt-5 mb-5" onClick={handleCleanSugestions}>

            <Row>
                {/* Filtres */}

                {
                    !isMobile &&
                    (
                        <Col md={3} lg={3}>
                            <ContainerFilter
                                filterType={filterType}
                                setFilterType={setFilterType}
                                pickupLocation={pickupLocation}
                                handlePickupLocationChange={handlePickupLocationChange}
                                pickupSuggestions={pickupSuggestions}
                                handlePickupSelection={handlePickupSelection}
                                minPrice={minPrice}
                                setMinPrice={setMinPrice}
                                maxPrice={maxPrice}
                                setMaxPrice={setMaxPrice}
                                availableStartDate={availableStartDate}
                                setAvailableStartDate={setAvailableStartDate}
                                availableStartTime={availableStartTime}
                                setAvailableStartTime={setAvailableStartTime}
                                availableEndDate={availableEndDate}
                                setAvailableEndDate={setAvailableEndDate}
                                availableEndTime={availableEndTime}
                                setAvailableEndTime={setAvailableEndTime}
                                hourOptions={hourOptions}
                                handleSearch={handleSearch}
                                // filtre
                                minMileage={minMileage}
                                setminMileage={setminMileage}
                                maxMileage={maxMileage}
                                setmaxMileage={setmaxMileage}
                                // Passagers
                                minPassenger={minPassenger}
                                setminPassenger={setminPassenger}
                                maxPassenger={maxPassenger}
                                setmaxPassenger={setmaxPassenger}
                                // Année de construction
                                minYearBuild={minMileage}
                                setminYearBuild={setminMileage}
                                maxYearBuild={maxYearBuild}
                                setmaxYearBuild={setmaxYearBuild}
                                // marques du véhicule
                                vehiclebrand={vehiclebrand}
                                setvehiclebrand={setvehiclebrand}
                                // moteur de l'engin
                                engineType={engineType}
                                setengineType={setengineType}
                                // Catgorie du véhicule
                                interiorType={interiorType}
                                setinteriorType={setinteriorType}
                                // option du véhicule ou de la propriété
                                optionsItem={optionsItem}
                                setoptionsItem={setoptionsItem}
                                model={model}
                                setmodel={setmodel}

                            />
                        </Col>
                    )
                }

                {/* Liste des éléments (Véhicules ou Propriétés) */}
                <Col sm={!isMobile ? 9 : 12} md={!isMobile ? 9 : 12} lg={!isMobile ? 9 : 12}>
                    <Row>
                        <Col md={12} className="mb-3 mt-3">
                            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div></div>
                                <div>
                                    {
                                        isMobile && (
                                            <Button type="button" variant="primary" size="sm" onClick={() => { setShowOffCanvas(true) }}> <RiFilter3Line /> Recherche personnalisée</Button>
                                        )
                                    }
                                </div>
                            </div>
                        </Col>
                        {currentItems.length > 0 && currentItems.filter((item) => item.isVisible == true).length > 0 ? (
                            currentItems.filter((item) => item.isVisible == true).map((item) => (
                                <Col sm={!isMobile ? 4 : 6} md={!isMobile ? 4 : 6} key={item._id} className="mb-4 ">

                                    <Card className="h-100 shadow-sm ">
                                        {item && item.images && item.images.length > 0 ? (
                                            <Card.Img variant="top" className="img-fluid rounded-lg" style={{ height: "200px", width: "100%", objectFit: "fill" }} src={item.images[0]} alt={item.name || item.brand} />
                                        ) : (
                                            <Card.Img variant="top" className="img-fluid rounded-lg" style={{ height: "200px", width: "100%", objectFit: "fill" }} src={emptyImage} alt={item.name || item.brand} />
                                        )}
                                        <Card.Body className=" icon-bx-wraper hover-overlay-effect">
                                            <Card.Title className="text-start ">
                                                <h6 className="line-clamp-2 text-start text-truncate">{item?.brand || item?.name}</h6>
                                            </Card.Title>
                                            <Card.Text className="text-truncate ">
                                                {item?.color ? (
                                                    <>
                                                        <strong>Couleur : </strong>{item.color}<br />
                                                        <strong>Prix : </strong>{item.basePricePerDay} DZD<br />
                                                    </>
                                                ) : (
                                                    <div className="text-truncate">
                                                        <strong>Adresse : </strong><span className="text-truncate">{item.address}</span><br />
                                                        <strong>Prix : </strong>{item.basePricePerDay} DZD<br />
                                                    </div>
                                                )}
                                                <div className="effect bg-primary"></div>
                                            </Card.Text>
                                            <Button
                                                variant="primary"
                                                size="sm"
                                                className="btn w-100 border-light justify-content-center"
                                                onClick={() => {
                                                    if (item?.color) {
                                                        dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: item });
                                                        dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: {} });
                                                        navigate(`/${ROUTES.CAR_DETAIL_VIEW}/${item._id}/name/${item.brand}/description/${item.brand}`);
                                                    } else {
                                                        dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: {} });
                                                        dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: item });
                                                        navigate(`/${ROUTES.CAR_DETAIL_VIEW}/${item._id}/name/${item.name}/description/${item.name}`);
                                                    }
                                                }}
                                            >
                                                <div>{"Voir l'offre"}</div>
                                            </Button>

                                        </Card.Body>

                                    </Card>

                                </Col>
                            ))
                        ) : (
                            <p className="text-center">Aucun résultat ne correspond aux critères de recherche.</p>
                        )}
                    </Row>

                    {/* Pagination */}
                    <Pagination className="justify-content-center">
                        {Array.from({ length: Math.ceil((filterType === "vehicle" ? filteredVehicles.length : filteredProperties.length) / itemsPerPage) }, (_, i) => (
                            <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => paginate(i + 1)}>
                                {i + 1}
                            </Pagination.Item>
                        ))}
                    </Pagination>
                </Col>
            </Row>


            {/*Loading  */}
            <Modal
                show={loadingVehicles || loadingPropertys}
                size="sm"
                centered
                style={{
                    background: "transparent",
                    border: "none",
                    boxShadow: "none",
                }}
                backdrop="static"
                keyboard={false}
                contentClassName="bg-transparent border-0 shadow-none" // Supprime le fond du modal
            >
                <Modal.Body
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "transparent", // Supprime toute couleur de fond
                        border: "none",
                        boxShadow: "none",
                        padding: "0px", // Supprime tout padding qui pourrait ajouter un fond blanc
                    }}
                >
                    <Spinner
                        animation="border"
                        variant="primary"
                        style={{
                            width: "3rem",
                            height: "3rem",
                            zIndex: 9999, // Pour s'assurer qu'il est bien au-dessus
                            filter: "drop-shadow(0px 0px 5px rgba(0,0,0,0.3))" // Ajoute un léger contour pour visibilité
                        }}
                    />
                </Modal.Body>
            </Modal>



            {/*Canvas filtre de recherche en question */}
            <Offcanvas show={showOffCanvas} onHide={() => setShowOffCanvas(false)} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <RiFilter3Line /> <span> Recherche personnalisée</span>

                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ContainerFilter
                        filterType={filterType}
                        setFilterType={setFilterType}
                        pickupLocation={pickupLocation}
                        handlePickupLocationChange={handlePickupLocationChange}
                        pickupSuggestions={pickupSuggestions}
                        handlePickupSelection={handlePickupSelection}
                        minPrice={minPrice}
                        setMinPrice={setMinPrice}
                        maxPrice={maxPrice}
                        setMaxPrice={setMaxPrice}
                        availableStartDate={availableStartDate}
                        setAvailableStartDate={setAvailableStartDate}
                        availableStartTime={availableStartTime}
                        setAvailableStartTime={setAvailableStartTime}
                        availableEndDate={availableEndDate}
                        setAvailableEndDate={setAvailableEndDate}
                        availableEndTime={availableEndTime}
                        setAvailableEndTime={setAvailableEndTime}
                        hourOptions={hourOptions}
                        handleSearch={handleSearch}
                    />
                </Offcanvas.Body>
            </Offcanvas>



        </Container>
    );
};

export default CarListFilterList;
