import axios from "axios";
import { FETCH_ACCESS_FAILURE, FETCH_ACCESS_REQUEST, FETCH_ACCESS_SUCCESS, } from "../../app/actions/actions";
import { ROUTES } from "../../config/routingUrl";
import { dureeDeVie, setWithExpiration } from "../../utils/storage/localvalueFuction";
import { localStorageData, localStorageKeys } from "../../utils/storage/localvalue";
import { baseurl } from "../../utils/url/baseurl";
import { saveDataToFile } from "../DataLocal";
import { toast } from "react-toastify";
import { fetchUsersAll } from "./UserRequest";
import { fetchCustomersAll } from "./CustomerRequest";

// Create Access
export const AccessAccessUnDeleteItemById = (
    idItem) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_ACCESS_REQUEST });
        await axios
            .put(
                `${baseurl.url}/api/v1/access/delete/item/${idItem}`,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                toast.success(response?.data?.message || "Operation éffectué avec succès", { position: "bottom-right" });
                dispatch({ type: FETCH_ACCESS_SUCCESS });
                // window.location.href = `/${ROUTES.LOGIN}`;
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || "Operation non éffectué", { position: "bottom-right" });
                dispatch({ type: FETCH_ACCESS_FAILURE, payload: error.message });
            });
    };
}




export const AccessDeleteAccompteyId = (
    idItem) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_ACCESS_REQUEST });
        await axios
            .put(
                `${baseurl.url}/api/v1/access/delete-accompte/${idItem}`,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                toast.success(response?.data?.message || "Operation éffectué avec succès", { position: "bottom-right" });
                dispatch({ type: FETCH_ACCESS_SUCCESS });
                dispatch(fetchUsersAll())
                dispatch(fetchCustomersAll())
                // window.location.href = `/${ROUTES.LOGIN}`;
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || "Operation non éffectué", { position: "bottom-right" });
                dispatch({ type: FETCH_ACCESS_FAILURE, payload: error.message });
                dispatch(fetchUsersAll())
                dispatch(fetchCustomersAll())
            });
    };
}

