import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Button, Form, Card, Breadcrumb, Accordion } from 'react-bootstrap';
import Select from 'react-select';
import { fetchVehicleById, fetchVehiclesAll, fetchVehiclesAllByOwnerId } from '../../actions/request/VehicleRequest';
import { fetchReservationTypesAll } from '../../actions/request/ReservationTypeRequest';
import { fetchCustomersAll } from '../../actions/request/CustomerRequest';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { MdSend, } from 'react-icons/md';
import { fetchCodePostalsAll } from '../../actions/request/CodePostalRequest';
import { ROUTES } from '../../config/routingUrl';
import { fetchPropertyById, fetchPropertysAll, fetchPropertysAllByOwnerId } from '../../actions/request/PropertyRequest';
import { FETCH_PROPERTY_SUCCESS, FETCH_VEHICLE_SUCCESS } from '../../app/actions/actions';
import { handleImageUploadCloud } from '../../actions/upload/UploadFileCloud';
import PropertyDetails from '../../components/web/patrimoine/PropertyDetails';
import VehicleDetails from '../../components/web/patrimoine/VehicleDetails';
import axios from 'axios';
import { baseurl } from '../../utils/url/baseurl';
import { logoApplication } from '../../utils/dataApi/dataFormApi';
import CarInfoDetail from '../../components/web/cardetail/CarInfoDetail';

const CardDetailPage = () => {

    const navigate = useNavigate();
    const paramVehicle = useParams();
    const dispatch = useDispatch();
    const vehicle = useSelector((state) => state.vehicles.vehicle);
    const property = useSelector((state) => state.propertys.property);
    const reservationTypes = useSelector((state) => state.reservationTypes.reservationTypes);
    const customers = useSelector((state) => state.customers.customers);
    const codepostals = useSelector((state) => state.codepostals.codepostals);

    const vehicles = useSelector((state) => state.vehicles.vehicles);
    const propertys = useSelector((state) => state.propertys.propertys);


    const [selectedType, setSelectedType] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [specialRequests, setSpecialRequests] = useState('');
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [codePostal, setCodePostal] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");




    // infos more
    const [idCard, setidCard] = useState("");
    const [idCardFile, setidCardFile] = useState("");


    const [driverLicenseNumber, setdriverLicenseNumber] = useState("");
    const [driverLicenseFile, setdriverLicenseFile] = useState("");


    const [dateOfBirth, setdateOfBirth] = useState("");


    const [pickupLocation, setpickupLocation] = useState("");
    const [returnLocation, setreturnLocation] = useState("")


    const [isLoading, setisLoading] = useState(false);




    const handleStartDateChange = (e) => {
        const newStartDate = e.target.value;
        setStartDate(newStartDate);
        if (endDate) {
            const start = new Date(newStartDate);
            const end = new Date(endDate);
            if (start > end) {
                setEndDate('');
                toast.info("La date de fin est inférieure à la date de début", { position: "bottom-right" });
            }
        }
    };

    const handleEndDateChange = (e) => {
        const newEndDate = e.target.value;
        setEndDate(newEndDate);
        if (startDate) {
            const start = new Date(startDate);
            const end = new Date(newEndDate);
            if (end < start) {
                setStartDate('');
                toast.info("La date de début est supérieure à la date de fin", { position: "bottom-right" });
            }
        }
        const currentDate = new Date();
        if (new Date(newEndDate) < currentDate) {
            setStartDate('');
            setEndDate('');
            toast.info("La date de fin est dans le passé", { position: "bottom-right" });
        }
    };


    const handleFileDriverFile = (e) => {
        setdriverLicenseFile(e.target.files);
    };


    const handleFileIdCardFile = (e) => {
        setidCardFile(e.target.files);
    };


    const [activeKey, setActiveKey] = useState(null); // État pour gérer la section active

    const handleToggle = (key) => {
        setActiveKey(activeKey === key ? null : key); // Active ou désactive la section
    };


    const section1Ref = useRef(null);

    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };




    const [pickupSuggestions, setPickupSuggestions] = useState([]);
    const [returnSuggestions, setReturnSuggestions] = useState([]);


    const getPlacesByCountryStartLocation = async (query) => {
        try {
            const response = await axios.get(`${baseurl.url}/api/v1/places`, {
                params: { query: query },
            });
            console.log(response.data.results);
            const places = response.data.results;
            return places.map(place => ({
                name: place.name,
                address: place.formatted_address
            }));
        } catch (error) {
            console.error("Erreur lors de la récupération des lieux : ", error);
        }
    };

    const getPlacesByCountryEndLocation = async (query) => {
        try {
            const response = await axios.get(`${baseurl.url}/api/v1/places`, {
                params: { query: query },
            });
            // Afficher les lieux retournés
            console.log(response.data.results);
            const places = response.data.results;
            return places.map(place => ({
                name: place.name,
                address: place.formatted_address
            }));
        } catch (error) {
            console.error("Erreur lors de la récupération des lieux : ", error);
        }
    };

    const handlePickupLocationChange = async (e) => {
        const value = e.target.value;
        setpickupLocation(value);
        if (value.length > 2) { // Faire la requête si la saisie dépasse 2 caractères
            const suggestions = await getPlacesByCountryStartLocation(value);
            setReturnSuggestions([]);
            setPickupSuggestions(suggestions);

        } else {
            setPickupSuggestions([]); // Réinitialiser la liste des suggestions
        }
    };

    // Lorsqu'on tape dans l'input du lieu de restitution
    const handleReturnLocationChange = async (e) => {
        const value = e.target.value;
        setreturnLocation(value);
        if (value.length > 2) {
            const suggestions = await getPlacesByCountryEndLocation(value);
            setPickupSuggestions([]);
            setReturnSuggestions(suggestions);
        } else {
            setReturnSuggestions([]);
        }
    };


    const handleCleanSugestions = () => {
        setPickupSuggestions([]);
        setReturnSuggestions([]);
    }







    useEffect(() => {


        // dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: {} })
        // dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: {} })
        dispatch(fetchVehicleById(paramVehicle?.id || ""));
        dispatch(fetchPropertyById(paramVehicle?.id || ""));
        dispatch(fetchReservationTypesAll());
        dispatch(fetchCustomersAll());
        dispatch(fetchCodePostalsAll());

        


    }, [dispatch, paramVehicle]);

    // useEffect(() => {
    //     dispatch(fetchVehiclesAllByOwnerId(vehicle?.addBy));
    //     dispatch(fetchPropertysAllByOwnerId(vehicle?.addBy));
    // }, [dispatch, paramVehicle])
    


   

    







    return (
        <div className="container mb-5" style={{ paddingBottom: "20px" }} onClick={handleCleanSugestions}>
            <Row className="my-4">

                <Container>
                    <Col md={12}>
                        <Breadcrumb>
                            <Breadcrumb.Item to="#">
                                <Link to={`/`}>Accueil</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>{vehicle && vehicle.brand ? vehicle.brand : "" || ""}  {property && property.name ? property.name : "" || ""}</Breadcrumb.Item>
                        </Breadcrumb>
                        <hr />
                    </Col>
                </Container>

                {
                    property && property._id && <PropertyDetails property={property} />
                }


                {
                    vehicle && vehicle._id && <VehicleDetails vehicle={vehicle} />
                }
            </Row>
           





        </div>
    );
};

export default CardDetailPage;




