import React from 'react'
import { MdPerson } from 'react-icons/md';

const HomeTestimonial = () => {
    const imgicon = "";
    return (
        <>
            <div className="section-full bg-gray content-inner-1" id="client">
                <div className="container">
                    <div className="section-head text-center wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s">
                        <h2 className="title">Ce que disent nos clients en Algérie</h2>
                        <p>Découvrez les témoignages de nos clients algériens qui ont réservé des bateaux, des hôtels, des véhicules et des remorques via notre plateforme.</p>
                    </div>
                    <div className="section-content">
                        <div className="testimonial-one swiper-container">
                            <div className="swiper-wrapper">
                                {/* Témoignage - Bateau */}
                                <div className="swiper-slide">
                                    <div className="testimonial-1 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                        <div className="testimonial-text">
                                            <p>"J'ai loué un bateau pour une excursion à Tipaza et c'était un pur bonheur ! L'équipe était très professionnelle et le bateau en excellent état. Je recommande fortement."</p>
                                        </div>
                                        <div className="testimonial-detail clearfix">
                                            <div className="testimonial-pic quote-left radius shadow">
                                                <MdPerson size={60} color={"grey"}/>
                                            </div>
                                            <strong className="testimonial-name">Mohamed Benali</strong>
                                            <span className="testimonial-position">Passionné de navigation</span>
                                        </div>
                                    </div>
                                </div>

                                {/* Témoignage - Hôtel */}
                                <div className="swiper-slide">
                                    <div className="testimonial-1 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">
                                        <div className="testimonial-text">
                                            <p>"J'ai réservé un hôtel à Oran pour mes vacances en famille. Le service était impeccable et le personnel très accueillant. Merci pour cette belle expérience."</p>
                                        </div>
                                        <div className="testimonial-detail clearfix">
                                            <div className="testimonial-pic quote-left radius shadow">
                                                <MdPerson size={60} color={"grey"}/>
                                            </div>
                                            <strong className="testimonial-name">Amina Khelifi</strong>
                                            <span className="testimonial-position">Voyageuse régulière</span>
                                        </div>
                                    </div>
                                </div>

                                {/* Témoignage - Véhicule */}
                                <div className="swiper-slide">
                                    <div className="testimonial-1 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
                                        <div className="testimonial-text">
                                            <p>"J'avais besoin d'une voiture pour un déplacement professionnel à Alger. La réservation a été rapide et efficace. Le véhicule était propre et en très bon état. Service top !"</p>
                                        </div>
                                        <div className="testimonial-detail clearfix">
                                            <div className="testimonial-pic quote-left radius shadow">
                                                <MdPerson size={60} color={"grey"}/>
                                            </div>
                                            <strong className="testimonial-name">Yassine Boukacem</strong>
                                            <span className="testimonial-position">Homme d{"'"}affaires</span>
                                        </div>
                                    </div>
                                </div>

                                {/* Témoignage - Remorque */}
                                <div className="swiper-slide">
                                    <div className="testimonial-1 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.8s">
                                        <div className="testimonial-text">
                                            <p>"J'avais besoin d'une remorque pour transporter des équipements à Constantine. Le prix était abordable et la réservation s'est faite en quelques clics. Très satisfait du service !"</p>
                                        </div>
                                        <div className="testimonial-detail clearfix">
                                            <div className="testimonial-pic quote-left radius shadow">
                                                <MdPerson size={60} color={"grey"}/>
                                            </div>
                                            <strong className="testimonial-name">Karim Saadi</strong>
                                            <span className="testimonial-position">Professionnel du transport</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="testimonial-pagination text-center m-t50">
                                <div className="btn-prev swiper-button-prev7"><i className="las la-arrow-left"></i></div>
                                <div className="btn-next swiper-button-next7"><i className="las la-arrow-right"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeTestimonial;
