import axios from "axios";
import { FETCH_COMMENTS_FAILURE, FETCH_COMMENTS_REQUEST, FETCH_COMMENTS_SUCCESS, FETCH_COMMENTS_SUCCESS_2, FETCH_COMMENT_FAILURE, FETCH_COMMENT_REQUEST, FETCH_COMMENT_SUCCESS } from "../../app/actions/actions";
import { baseurl } from "../../utils/url/baseurl";
import { getAndCheckLocalStorage } from "../../utils/storage/localvalueFuction";
import { localStorageKeys } from "../../utils/storage/localvalue";
import { profileRoleType } from "../../utils/dataApi/dataFormApi";
import { toast } from "react-toastify";




// Récupérer tous les codes promo
export function fetchCommentsAll(agency, client, reservation, vehicle, property, fromDate, toDate, rating) {
    return async (dispatch) => {
        // Construire les paramètres de la requête
        const params = new URLSearchParams();
        if (vehicle) params.append("vehicle", vehicle);
        if (client) params.append("client", client);
        if (property) params.append("property", property);
        if (reservation) params.append("reservation", reservation);
        if (rating) params.append("rating", rating);
        if (agency) params.append("agency", agency);
        if (fromDate) params.append("fromDate", fromDate);
        if (toDate) params.append("toDate", toDate);

        dispatch({ type: FETCH_COMMENTS_REQUEST });

        await axios.get(`${baseurl.url}/api/v1/comments/get_comments?${params.toString()}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_COMMENTS_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_COMMENTS_SUCCESS_2, payload: response.data.data });
        }).catch((error) => {
            //console.log(error);
            dispatch({ type: FETCH_COMMENTS_FAILURE, payload: error.message });
        });
    }
}





// Récupérer tous le commentaire
export function fetchComment(agency, client, reservation, vehicle, property, fromDate, toDate, rating) {
    return async (dispatch) => {
        // Construire les paramètres de la requête
        const params = new URLSearchParams();
        if (vehicle) params.append("vehicle", vehicle);
        if (property) params.append("property", property);
        if (client) params.append("client", client);
        if (reservation) params.append("reservation", reservation);
        if (rating) params.append("rating", rating);
        if (agency) params.append("agency", agency);
        if (fromDate) params.append("fromDate", fromDate);
        if (toDate) params.append("toDate", toDate);

        dispatch({ type: FETCH_COMMENT_REQUEST });

        await axios.get(`${baseurl.url}/api/v1/comments/get_comment?${params.toString()}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_COMMENT_SUCCESS, payload: response.data.data });
        }).catch((error) => {
            //console.log(error);
            dispatch({ type: FETCH_COMMENT_FAILURE, payload: error.message });
        });
    }
}





// Récupérer un code promo par ID
export function fetchCommentById(idComment) {
    return async (dispatch) => {
        dispatch({ type: FETCH_COMMENT_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/comments/get_comment/${idComment}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_COMMENT_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                dispatch({ type: FETCH_COMMENT_FAILURE, payload: error.message })
                //console.log(error);
            });
    }
}




// Mettre à jour un code promo
export function fetchCommentByUpdateId(idComment, data, toast) {
    return async (dispatch) => {
        dispatch({ type: FETCH_COMMENT_REQUEST });
        await axios.put(`${baseurl.url}/api/v1/comments/edit/${idComment}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_COMMENT_SUCCESS, payload: response.data.data });
            toast.success(response?.data?.message|| "Commentaire mis à jour", { position: "bottom-right" });
            // if (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER) {
            //     dispatch(fetchCommentsOfIdUserAll(getAndCheckLocalStorage(localStorageKeys.userId)));
            // } else {
            //     fetchCommentsAll();
            // }
        })
            .catch((error) => {
                dispatch({ type: FETCH_COMMENT_FAILURE, payload: error.message })
                toast.error(error?.response?.data?.message||"Commentaire non mis à jour", { position: "bottom-right" });
                //console.log(error);
            });
    }
}



// Créer un nouveau code promo
export function createComment(data) {
    return async (dispatch) => {
        dispatch({ type: FETCH_COMMENT_REQUEST });
        await axios.post(`${baseurl.url}/api/v1/comments/send`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_COMMENT_SUCCESS, payload: response.data.data });
            toast.success(response?.data?.message|| "Commentaire posté avec succès", { position: "bottom-right" });
            // if (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER) {
            //     dispatch(fetchCommentsAll(getAndCheckLocalStorage(localStorageKeys.userId)));
            // } else {
            //     fetchCommentsAll();
            // }
        }).catch((error) => {
            dispatch({ type: FETCH_COMMENT_FAILURE, payload: error?.response?.data?.message });
            toast.error(error?.response?.data?.message|| "Commentaire non posté", { position: "bottom-right" });
            //console.log(error);
        });
    }
}



export function replyToComment(idComment, data, toast) {
    return async (dispatch) => {
        dispatch({ type: FETCH_COMMENT_REQUEST });
        await axios.post(`${baseurl.url}/api/v1/comments/reply/${idComment}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_COMMENT_SUCCESS, payload: response.data.data });
            toast.success(response?.data?.message|| "Reponse envoyé avec succès", { position: "bottom-right" });
            // if (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER) {
            //     dispatch(fetchCommentsOfIdUserAll(getAndCheckLocalStorage(localStorageKeys.userId)));
            // } else {
            //     fetchCommentsAll();
            // }
        }).catch((error) => {
            dispatch({ type: FETCH_COMMENT_FAILURE, payload: error.message });
            toast.error(error?.response?.data?.message|| "Reponse non envoyé", { position: "bottom-right" });
            //console.log(error);
        });
    }
}
