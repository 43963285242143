import React, { useCallback, useEffect, useState } from 'react';
import { Image, Nav, Navbar, Container, Button, Card, Dropdown, Modal, Offcanvas, Badge } from 'react-bootstrap';
import { MdReport, MdDashboard, MdOutlineSpaceDashboard, MdReportOff, MdTaskAlt, MdOutlinePrivacyTip, MdSettings, MdExitToApp } from 'react-icons/md';

import { Link, Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../config/routingUrl';
import { logoApplication, profileRoleType, restrictedPages } from '../utils/dataApi/dataFormApi';
import "./SidebarAdmin.css";
import ScrollToTop from './ScrollToTop';
import { localStorageData, localStorageKeys } from '../utils/storage/localvalue';
import { getAndCheckLocalStorage, handleClearLocalStorage } from '../utils/storage/localvalueFuction';
import { useDispatch, useSelector } from 'react-redux';
import { UserUpdateById, fetchUserById } from '../actions/request/UserRequest';
import { fetchCustomerById } from '../actions/request/CustomerRequest';
import { FaAnglesRight, FaAnglesLeft } from "react-icons/fa6";
import SideBarLeftComponent from './SideBarLeftComponent';
import { FETCH_CUSTOMERS_REQUEST, FETCH_CUSTOMER_FAILURE, FETCH_CUSTOMER_REQUEST, FETCH_CUSTOMER_SUCCESS, FETCH_USER_FAILURE, FETCH_USER_REQUEST, FETCH_USER_SUCCESS } from '../app/actions/actions';
import axios from 'axios';
import { baseurl, checkWordInURL } from '../utils/url/baseurl';
import useVerifyUserStatus from '../workers/accompteConnected/useVerifyUserStatus';



const SidebarAdmin = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const userItem = useSelector((state) => state.users.user);
    const customerItem = useSelector((state) => state.customers.customer);

    const isConnected = useVerifyUserStatus(getAndCheckLocalStorage(localStorageKeys.userId) || getAndCheckLocalStorage(localStorageKeys.adminId))

    const accesstoken =
        getAndCheckLocalStorage(localStorageKeys.userId) ||
        getAndCheckLocalStorage(localStorageKeys.adminId);

    //    const {_id , images , starDate,endDate,client} =  location.state;

    const [openMenu, setOpenMenu] = useState({});
    const [isSidebarOpen, setSidebarOpen] = useState(true);
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);

    // Fonction pour savoir si l'URL actuelle correspond à un lien
    const isActive = (path) => location.pathname === path;

    const toggleSubMenu = (menu) => {
        setOpenMenu((prevState) => ({
            ...prevState,
            [menu]: !prevState[menu]
        }));
    };


    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    // Fonction pour détecter si on est sur mobile
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 1000);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const [modalDisconnect, setmodalDisconnect] = useState(false);
    const handleShowModalDisconnect = () => { setmodalDisconnect(true) }
    const handleCloseModelDisconnect = () => { setmodalDisconnect(false) }


    const handleDisconnect = () => {
        handleClearLocalStorage();
        window.localStorage.removeItem(localStorageKeys.adminId)
        window.localStorage.removeItem(localStorageKeys.userId)
        window.localStorage.removeItem(localStorageKeys.profileRole)
        window.localStorage.removeItem(localStorageKeys.userCoverPicture)
        window.localStorage.removeItem(localStorageKeys.adminCoverPicture)
        window.localStorage.removeItem(localStorageKeys.userName)
        window.localStorage.removeItem(localStorageKeys.userRole)
        if (
            getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.CUSTOMER ||
            getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER) {
            navigate(`/${ROUTES.LOGIN}`)
        }
        else if (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
            getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
            getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT) {
            navigate(`/`)
        }
        else {
            navigate(`/`)
        }
    }


    useEffect(() => {
        
        // dispatch(fetchUserById(getAndCheckLocalStorage(localStorageKeys.userId)))
        // dispatch(fetchCustomerById(getAndCheckLocalStorage(localStorageKeys.userId)))
        dispatch(fetchUserByGet())
        if(checkWordInURL(ROUTES.DASHBOARD)){
            console.log("Ok")
        }else{
            console.log("Failed")
        }
    }, [dispatch])






    function fetchUserByGet() {
        return async (dispatch) => {
            dispatch({ type: FETCH_USER_REQUEST });
            dispatch({ type: FETCH_CUSTOMER_REQUEST });
            await axios.get(
                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ?
                    `${baseurl.url}/api/v1/users/get_user/${getAndCheckLocalStorage(localStorageKeys.userId)}`
                    :
                    `${baseurl.url}/api/v1/customers/get_customer/${getAndCheckLocalStorage(localStorageKeys.userId)}`
                , {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }).then((response) => {
                    console.log(response.data.data);
                    const responseData = response.data.data;
                    if (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER) {
                        dispatch({ type: FETCH_USER_SUCCESS, payload: response.data.data });
                    }
                    else if (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.CUSTOMER) {
                        dispatch({ type: FETCH_CUSTOMER_SUCCESS, payload: response.data.data });
                    }
                })
                .catch((error) => {
                    dispatch({ type: FETCH_USER_FAILURE, payload: error.message })
                    dispatch({ type: FETCH_CUSTOMER_FAILURE, payload: error.message })
                    console.log(error);
                });
        }
    }






    // 🔹 Vérification de l'accès utilisateur
    useEffect(() => {
        const accesstoken =
            getAndCheckLocalStorage(localStorageKeys.userId) ||
            getAndCheckLocalStorage(localStorageKeys.adminId);

        const profileRole = getAndCheckLocalStorage(localStorageKeys.profileRole);

        if (!accesstoken ) {
            navigate(`/${ROUTES.LOGIN}`);
            return;
        }


        // 🔹 Définition des pages interdites selon le rôle
        

        // Vérifier si l'utilisateur essaie d'accéder à une page interdite
        const currentPath = location.pathname.replace(`/${ROUTES.DASHBOARD}/`, '');

        if (restrictedPages[profileRole]?.includes(currentPath)) {
            navigate(-1);
        }
    }, [navigate, location]);

    //  composant de baguation



    return (
        <div className="" wtyle>
            {/* Barre de navigation */}
            <Navbar bg="white" className="border" variant="" expand="lg" fixed="top" style={{ width: "100%" }}>
                <Container fluid >
                    <Navbar.Brand as={Link} to="/">
                        <Image src={logoApplication} style={{ maxHeight: "40px", maxWidth: "40px" }} alt="Logo" />
                    </Navbar.Brand>

                    {/* Bouton pour afficher le menu sur mobile */}
                    {
                        isMobile && (
                            <Button variant="primary" size="sm" onClick={() => setShowOffcanvas(true)}>
                                ☰
                            </Button>
                        )
                    }

                    <Navbar.Toggle aria-controls="navbar-nav" />
                    {!isMobile && (
                        <Navbar.Collapse id="navbar-nav">
                            <Nav className="ms-auto">
                                <Dropdown>
                                    <Dropdown.Toggle variant='white' className="shadow-none" size='sm'>
                                        {

                                            (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT
                                            ) && (
                                                <Image
                                                    src={"https://img.freepik.com/vecteurs-libre/cercle-utilisateurs-defini_78370-4704.jpg"}
                                                    style={{ maxHeight: "50px", maxWidth: "50px", borderRadius: "50%" }}
                                                />
                                            )
                                        }

                                        {

                                            (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.CUSTOMER
                                            ) && (
                                                <Image
                                                    src={userItem?.profilePicture || customerItem?.profilePicture || "https://img.freepik.com/vecteurs-libre/cercle-utilisateurs-defini_78370-4704.jpg"}
                                                    style={{ maxHeight: "50px", maxWidth: "50px", borderRadius: "50%" }}
                                                />
                                            )
                                        }

                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {
                                            (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT
                                            ) && (
                                                <Dropdown.Item>{getAndCheckLocalStorage(localStorageKeys.userName)}</Dropdown.Item>
                                            )
                                        }

                                        {
                                            (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.CUSTOMER
                                            ) && (
                                                <Dropdown.Item>{userItem?.firstname || ""} {userItem?.lastname || ""} {customerItem?.firstname || ""} {customerItem?.lastname || ""} </Dropdown.Item>
                                            )
                                        }

                                        {
                                            getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER && (
                                                <Dropdown.Item><Badge bg="secondary">Agence </Badge></Dropdown.Item>
                                            )
                                        }
                                        {
                                            getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.CUSTOMER && (
                                                <Dropdown.Item><Badge bg="secondary">Client</Badge></Dropdown.Item>
                                            )
                                        }
                                        {
                                            getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN && (
                                                <Dropdown.Item><Badge bg="secondary">Super Administrateur</Badge></Dropdown.Item>
                                            )
                                        }
                                        {
                                            getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN && (
                                                <Dropdown.Item><Badge bg="secondary">Administrateur</Badge></Dropdown.Item>
                                            )
                                        }
                                        {
                                            getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT && (
                                                <Dropdown.Item><Badge bg="secondary">Suport</Badge></Dropdown.Item>
                                            )
                                        }
                                        {
                                            getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.CUSTOMER
                                                ?
                                                <Dropdown.Item as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.PROFILE}`}>
                                                    <MdSettings size={20} /> Paramètre
                                                </Dropdown.Item>
                                                : ""
                                        }
                                        <Dropdown.Item onClick={handleShowModalDisconnect}>
                                            <MdExitToApp size={20} /> Se déconnecter
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Nav>
                        </Navbar.Collapse>
                    )}
                </Container>
            </Navbar>

            {/* Sidebar */}
            <div className={`sidebar bg-white border-r d-none d-lg-block ${isSidebarOpen ? 'open' : ''}`} style={{ width: isSidebarOpen ? '250px' : '0', height: 'calc(100vh - 56px)', overflowY: 'auto', transition: '0.3s', position: 'fixed', top: '56px', zIndex: 5 }}>
                {isSidebarOpen && (
                    <SideBarLeftComponent
                        setShowOffcanvas={setShowOffcanvas}
                        openMenu={openMenu}
                        toggleSubMenu={toggleSubMenu}
                        isActive={isActive}
                    />
                )}
            </div>

            {/* Page Content */}
            <div className="content bg-white border container" style={{ marginLeft: isMobile ? '0' : (isSidebarOpen ? '250px' : '0'), transition: '0.3s', width: '100%', paddingTop: '60px', marginTop: "20px", position: 'relative', zIndex: 10 }}>
                {/* Bouton pour ouvrir/fermer le Sidebar */}
                {
                    !isMobile && (
                        <button className="btn btn-sm btn-link m-5 d-none d-lg-inline" onClick={toggleSidebar}>
                            {isSidebarOpen ? <FaAnglesLeft /> : <FaAnglesRight />}
                        </button>
                    )
                }


                {/* Contenu principal (Outlet pour afficher les sous-pages) */}
                <div style={{ minHeight: "600px" }} className="mx-1" >
                    <ScrollToTop />
                    <Outlet />
                    <Card>
                        <Card.Footer></Card.Footer>
                    </Card>
                </div>
            </div>



            <Modal show={modalDisconnect} onHide={handleCloseModelDisconnect} centered >
                <Modal.Header>Voulez vous vou déconnecter</Modal.Header>
                <Modal.Footer>
                    <Button variant="white" size="sm" onClick={handleCloseModelDisconnect}>Annuler</Button>
                    <Button variant="primary" size="sm" onClick={() => {
                        handleCloseModelDisconnect();
                        handleDisconnect();
                    }}>Se Déconnecter</Button>
                </Modal.Footer>
            </Modal>





            {/* Offcanvas pour mobile */}
            <Offcanvas show={showOffcanvas} onHide={() => setShowOffcanvas(false)} placement="start">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <Image src={logoApplication} style={{ maxHeight: "40px", maxWidth: "40px" }} alt="Logo" />
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <SideBarLeftComponent
                        setShowOffcanvas={setShowOffcanvas}
                        openMenu={openMenu}
                        toggleSubMenu={toggleSubMenu}
                        isActive={isActive}
                    />
                </Offcanvas.Body>
            </Offcanvas>

        </div>
    );
};

export default SidebarAdmin;
