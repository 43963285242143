import {FETCH_ACCESS_FAILURE, FETCH_ACCESS_REQUEST, FETCH_ACCESS_SUCCESS } from "../actions/actions";



// accessReducer.js
const initialState = {
  accesss: [],
  accesss2: [],
  access: {},
  accessSelect: {},
  loadingAccess: false,
  loadingAccessSelect: false,
  loadingAccesss: false,
  errorAccesss: null,
  errorAccess: null
  // ... autres états spécifiques à accessReducer
};

const accessReducer = (state = initialState, action) => {
  switch (action.type) {

    case FETCH_ACCESS_REQUEST:
      // Dispatch une action pour définir loading sur true dans le globalReducer
      return {
        ...state,
        loadingAccess: true,
        errorAccesss: ""
        // Autres manipulations d'état pour la demande de récupération des accesss
      };

    case FETCH_ACCESS_SUCCESS:
      return {
        ...state,
        loadingAccess: false,
        errorAccesss: "",
        access: action.payload
      };

    case FETCH_ACCESS_FAILURE:
      return {
        ...state,
        loadingAccess: false,
        errorAccesss: action.payload
      };


    
    default:
      return state;
  }
};

export default accessReducer;
