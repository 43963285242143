

export const baseurl = {
    url: process.env.REACT_APP_BASE_URL,
    token: process.env.REACT_APP_TOKEN,
    TypeToken: process.env.REACT_APP_TYPE_TOKEN,
    cloudinaryUploadPreset: process.env.REACT_APP_CLOUNDIANRY_UPLOAD_PRESET,
    cloudinaryCloudName: process.env.REACT_APP_CLOUNDIANRY_NAME,
    ApiKeyGoogle: process.env.REACT_APP_API_KEY_GOOGLE,
    ApikeyCheckout:process.env.REACT_APP_API_KEY_STRIPE,
    ApiKeyRecaptcha: process.env.REACT_APP_RECAPTCHA
}


export const checkWordInURL = (word) => {
    const url = window.location.href;  // Récupère l'URL complète
    const pathname = window.location.pathname; // Récupère uniquement le pathname
    
    console.log('URL complète :', url);
    console.log('Pathname :', pathname);
    console.log('Mot recherché :', word);
    
    const includesWord = pathname.includes(word);
    console.log(`L'URL inclut "${word}" ?`, includesWord);
  
    return includesWord;  // Retourne true si le mot est trouvé, sinon false
  };
  
