import axios from "axios";
import { FETCH_ENGINE_TYPES_FAILURE, FETCH_ENGINE_TYPES_REQUEST, FETCH_ENGINE_TYPES_SUCCESS, FETCH_ENGINE_TYPES_SUCCESS_2, FETCH_ENGINE_TYPE_FAILURE, FETCH_ENGINE_TYPE_REQUEST, FETCH_ENGINE_TYPE_SUCCESS } from "../../app/actions/actions";
import { ROUTES } from "../../config/routingUrl";
import { dureeDeVie, setWithExpiration } from "../../utils/storage/localvalueFuction";
import { localStorageData, localStorageKeys } from "../../utils/storage/localvalue";
import { baseurl } from "../../utils/url/baseurl";
import { saveDataToFile } from "../DataLocal";
import { toast } from "react-toastify";




// All insurance of plateforme
export function fetchengineTypesAll() {
    return async (dispatch) => {
        dispatch({ type: FETCH_ENGINE_TYPES_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/enginetypes/get_enginetypes`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_ENGINE_TYPES_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_ENGINE_TYPES_SUCCESS_2, payload: response.data.data });
            // saveDataToFile(response.data.data, localStorageData.engineTypes);
        }).catch((error) => {
            //console.log(error);
            dispatch({ type: FETCH_ENGINE_TYPES_FAILURE, payload: error.message });
        });
    }
}


// get insurance by id 
export function fetchengineTypeById(idengineType) {
    return async (dispatch) => {
        dispatch({ type: FETCH_ENGINE_TYPE_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/enginetypes/get_enginetype/${idengineType}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_ENGINE_TYPE_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                dispatch({ type: FETCH_ENGINE_TYPE_FAILURE, payload: error.message })
                //console.log(error);
            });
    }
}






// ✅ Ajouter un type de moteur
export function EngineTypeCreate(engineTypeData) {
    return async (dispatch) => {
        dispatch({ type: FETCH_ENGINE_TYPE_REQUEST });

        await axios.post(`${baseurl.url}/api/v1/enginetypes/add`, engineTypeData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        })
            .then((response) => {
                dispatch({ type: FETCH_ENGINE_TYPE_SUCCESS, payload: response.data.data });
                toast.success(response?.data?.message || "Type de moteur ajouté avec succès !", { position: "bottom-right" });
                dispatch(fetchengineTypesAll()); // 🔄 Recharger la liste
            })
            .catch((error) => {
                dispatch({ type: FETCH_ENGINE_TYPE_FAILURE, payload: error.message });
                toast.error(error?.response?.data?.message || "Erreur lors de l'ajout du type de moteur !");
            });
    };
}

// ✅ Modifier un type de moteur
export function EngineTypeUpdateById(idEngineType, engineTypeData) {
    return async (dispatch) => {
        dispatch({ type: FETCH_ENGINE_TYPE_REQUEST });

        await axios.put(`${baseurl.url}/api/v1/enginetypes/update/${idEngineType}`, engineTypeData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        })
            .then((response) => {
                dispatch({ type: FETCH_ENGINE_TYPE_SUCCESS, payload: response.data.data });
                toast.success(response?.data?.message || "Type de moteur mis à jour avec succès !", { position: "bottom-right" });
                dispatch(fetchengineTypesAll()); // 🔄 Recharger la liste
            })
            .catch((error) => {
                dispatch({ type: FETCH_ENGINE_TYPE_FAILURE, payload: error.message });
                toast.error(error?.response?.data?.message || "Erreur lors de la mise à jour du type de moteur !", { position: "bottom-right" });
            });
    };
}

// ✅ Supprimer un type de moteur
export function EngineTypeDeleteById(idEngineType) {
    return async (dispatch) => {
        dispatch({ type: FETCH_ENGINE_TYPE_REQUEST });

        await axios.delete(`${baseurl.url}/api/v1/enginetypes/delete/${idEngineType}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        })
            .then((response) => {
                dispatch({ type: FETCH_ENGINE_TYPE_SUCCESS, payload: idEngineType });
                toast.success(response?.data?.message || "Type de moteur supprimé avec succès !", { position: "bottom-right" });
                dispatch(fetchengineTypesAll()); // 🔄 Recharger la liste
            })
            .catch((error) => {
                dispatch({ type: FETCH_ENGINE_TYPE_FAILURE, payload: error.message });
                toast.error(error?.response?.data?.message || "Erreur lors de la suppression du type de moteur !", { position: "bottom-right" });
            });
    };
}