import { FETCH_INTERIOR_TYPES_FAILURE, FETCH_INTERIOR_TYPES_REQUEST, FETCH_INTERIOR_TYPES_SUCCESS, FETCH_INTERIOR_TYPES_SUCCESS_2, FETCH_INTERIOR_TYPE_FAILURE, FETCH_INTERIOR_TYPE_REQUEST, FETCH_INTERIOR_TYPE_SUCCESS } from "../actions/actions";



// interiorTypeReducer.js
const initialState = {
    interiorTypes: [],
    interiorTypes2: [],
    interiorType: {},
    interiorTypeSelect: {},
    loadingInteriorType:false,
    loadingInteriorTypeSelect:false,
    loadingInteriorTypes:false,
    errorInteriorTypes:null,
    errorInteriorType:null
    // ... autres états spécifiques à interiorTypeReducer
  };
  
  const interiorTypeReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_INTERIOR_TYPE_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingInteriorType:true,
          errorInteriorTypes:""
          // Autres manipulations d'état pour la demande de récupération des interiorTypes
        };
        case FETCH_INTERIOR_TYPE_SUCCESS:
        return {
          ...state,
          loadingInteriorType:false,
          errorInteriorTypes:"",
          interiorType:action.payload
        };
        case FETCH_INTERIOR_TYPE_FAILURE:
        return {
          ...state,
          loadingInteriorType:false,
          errorInteriorTypes:action.payload
        };


        // INTERIOR_TYPE
        // case FETCH_INTERIOR_TYPE_REQUEST_SELECT:
        // // Dispatch une action pour définir loading sur true dans le globalReducer
        // return {
        //   ...state,
        //   loadingInteriorTypeSelect:true,
        //   errorInteriorTypes:""
        //   // Autres manipulations d'état pour la demande de récupération des interiorTypes
        // };
        // case FETCH_INTERIOR_TYPE_SUCCESS_SELECT:
        // return {
        //   ...state,
        //   loadingInteriorTypeSelect:false,
        //   errorInteriorTypes:"",
        //   interiorTypeSelect:action.payload
        // };
        // case FETCH_INTERIOR_TYPE_FAILURE_SELECT:
        // return {
        //   ...state,
        //   loadingInteriorTypeSelect:false,
        //   errorInteriorTypes:action.payload
        // };






      case FETCH_INTERIOR_TYPES_REQUEST:
        return {
          ...state,
          loadingInteriorTypes:true,
          errorInteriorTypes:""
        };
       
      case FETCH_INTERIOR_TYPES_SUCCESS:
        return {
          ...state,
          interiorTypes: action.payload,
          loadingInteriorTypes:false,
          errorInteriorTypes:""
          // Autres manipulations d'état pour le succès de la récupération des interiorTypes
        };
        case FETCH_INTERIOR_TYPES_SUCCESS_2:
        return {
          ...state,
          interiorTypes2: action.payload,
          loadingInteriorTypes:false,
          errorInteriorTypes:""
          // Autres manipulations d'état pour le succès de la récupération des interiorTypes
        };
        
      case FETCH_INTERIOR_TYPES_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingInteriorTypes:false,
          errorInteriorTypes:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des interiorTypes
        };
      // ... autres cas pour d'autres actions liées aux interiorTypes

        
      default:
        return state;
    }
  };
  
  export default interiorTypeReducer;
  