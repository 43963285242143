import React from 'react'
import CarBarner from '../../components/web/cars/CarBarner'
import CarListFilterList from '../../components/web/cars/CarListFilterList'

const CariLstPage = () => {
  return (
    <div className="page-content bg-white">


      <div className="dlab-bnr-inr style-1 overlay-black-middle" style={{ backgroundImage: `url(guide-voiture.jpg)` }}>
        <div className="container">
          <div className="dlab-bnr-inr-entry text-center">
            <h3 className="text-white">Trouvez votre véhicule ou propriété idéale</h3>
            <p className="text-white-50 mt-2">Comparez les meilleures offres en quelques clics</p>
            <div className="dlab-separator"></div>
          </div>
        </div>
      </div>

      {/*<CarBarner /> */}
      <CarListFilterList />

    </div>
  )
}

export default CariLstPage
