import React from 'react';
import { Car, Hotel, Ship, Truck, ShieldCheck, Headphones, Timer, Gift } from 'lucide-react';

const HomeService = () => {
    return (
        <>
            <section className="content-inner-2">
                <div className="container">
                    <div className="row">
                        {/* Service - Réservation de véhicules */}
                        <div className="col-xl-3 col-lg-4 col-md-6 m-b30">
                            <div className="icon-bx-wraper style-1 hover-overlay-effect">
                                <div className="icon-md m-b40">
                                    <Car size={40} color="0de614" />
                                </div>
                                <div className="icon-content">
                                    <h5 className="mb-5">Réservation de véhicules</h5>
                                    <p>Réservez une voiture facilement pour vos trajets personnels ou professionnels. Profitez d{"'"}une large gamme de véhicules disponibles en Algérie.</p>
                                </div>
                                <div className="effect bg-primary"></div>
                            </div>
                        </div>

                        {/* Service - Réservation d'hôtels */}
                        <div className="col-xl-3 col-lg-4 col-md-6 m-b30">
                            <div className="icon-bx-wraper style-1 hover-overlay-effect">
                                <div className="icon-md m-b40">
                                    <Hotel size={40} color="0de614" />
                                </div>
                                <div className="icon-content">
                                    <h5 className="mb-5">Réservation d{"'"}hôtels</h5>
                                    <p>Besoin d’un hébergement ? Trouvez et réservez les meilleurs hôtels en Algérie pour un séjour confortable et sécurisé.</p>
                                </div>
                                <div className="effect bg-primary"></div>
                            </div>
                        </div>

                        {/* Service - Réservation de bateaux */}
                        <div className="col-xl-3 col-lg-4 col-md-6 m-b30">
                            <div className="icon-bx-wraper style-1 hover-overlay-effect">
                                <div className="icon-md m-b40">
                                    <Ship size={40} color="0de614" />
                                </div>
                                <div className="icon-content">
                                    <h5 className="mb-5">Location de bateaux</h5>
                                    <p>Envie d’une sortie en mer ? Louez un bateau avec ou sans équipage et explorez les magnifiques côtes algériennes.</p>
                                </div>
                                <div className="effect bg-primary"></div>
                            </div>
                        </div>

                        {/* Service - Location de remorques */}
                        <div className="col-xl-3 col-lg-4 col-md-6 m-b30">
                            <div className="icon-bx-wraper style-1 hover-overlay-effect">
                                <div className="icon-md m-b40">
                                    <Truck size={40} color="0de614" />
                                </div>
                                <div className="icon-content">
                                    <h5 className="mb-5">Location de remorques</h5>
                                    <p>Besoin de transporter du matériel ou des marchandises ? Louez une remorque robuste et adaptée à vos besoins.</p>
                                </div>
                                <div className="effect bg-primary"></div>
                            </div>
                        </div>

                        {/* Service - Sécurité et assurance */}
                        <div className="col-xl-3 col-lg-4 col-md-6 m-b30">
                            <div className="icon-bx-wraper style-1 hover-overlay-effect">
                                <div className="icon-md m-b40">
                                    <ShieldCheck size={40} color="0de614" />
                                </div>
                                <div className="icon-content">
                                    <h5 className="mb-5">Sécurité et assurance</h5>
                                    <p>Profitez d’un service sécurisé avec des options d’assurance pour tous vos véhicules, hôtels et équipements loués.</p>
                                </div>
                                <div className="effect bg-primary"></div>
                            </div>
                        </div>

                        {/* Service - Assistance 24/7 */}
                        <div className="col-xl-3 col-lg-4 col-md-6 m-b30">
                            <div className="icon-bx-wraper style-1 hover-overlay-effect">
                                <div className="icon-md m-b40">
                                    <Headphones size={40} color="0de614" />
                                </div>
                                <div className="icon-content">
                                    <h5 className="mb-5">Assistance 24/7</h5>
                                    <p>Une question ou un souci ? Notre support est disponible 24h/24 et 7j/7 pour vous aider en cas de besoin.</p>
                                </div>
                                <div className="effect bg-primary"></div>
                            </div>
                        </div>

                        {/* Service - Réservation rapide et flexible */}
                        <div className="col-xl-3 col-lg-4 col-md-6 m-b30">
                            <div className="icon-bx-wraper style-1 hover-overlay-effect">
                                <div className="icon-md m-b40">
                                    <Timer size={40} color="0de614" />
                                </div>
                                <div className="icon-content">
                                    <h5 className="mb-5">Réservation rapide et flexible</h5>
                                    <p>Réservez en quelques clics et profitez d’une annulation flexible pour plus de tranquillité.</p>
                                </div>
                                <div className="effect bg-primary"></div>
                            </div>
                        </div>

                        {/* Service - Offres spéciales et réductions */}
                        <div className="col-xl-3 col-lg-4 col-md-6 m-b30">
                            <div className="icon-bx-wraper style-1 hover-overlay-effect">
                                <div className="icon-md m-b40">
                                    <Gift size={40} color="0de614" />
                                </div>
                                <div className="icon-content">
                                    <h5 className="mb-5">Offres spéciales et réductions</h5>
                                    <p>Profitez de promotions exclusives et de réductions pour vos réservations de véhicules, hôtels et autres services.</p>
                                </div>
                                <div className="effect bg-primary"></div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
};

export default HomeService;
