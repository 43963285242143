import React from 'react';
import HomeVehicleContainerForm from './HomeVehicleContainerForm';
import HomePropertyContainerForm from './HomePropertyContainerForm';
import { Col, Row } from 'react-bootstrap';

const HomeBarner = () => {
  return (
    <div style={{ position: "relative", width: "100%", height: "100vh" }}>
      {/* Image de fond */}
      <div
        style={{
          backgroundImage: "url('bg-barnerhomesreservation.jpg')",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover", /* Correction : Utilisation de "cover" au lieu de "fill" */
          width: "100%",
          minHeight: "150vh",
        }}
      />

      {/* Overlay sombre */}
      <div
        style={{
          width: "100%",
          minHeight: "140vh",
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      />

      {/* Contenu au-dessus de l'overlay */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom:0,
          top:0,
          width: "100%",
          minHeight: "140vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 100,
        }}
      >
        <Row style={{ width: "90%" }} className="mt-5">
          <Col xs={12} md={6} className="mb-3">
            <HomeVehicleContainerForm />
          </Col>
          <Col xs={12} md={6}>
            <HomePropertyContainerForm />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default HomeBarner;
