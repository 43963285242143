import axios from "axios";
import { FETCH_VEHICLE_BRANDS_FAILURE, FETCH_VEHICLE_BRANDS_REQUEST, FETCH_VEHICLE_BRANDS_SUCCESS, FETCH_VEHICLE_BRANDS_SUCCESS_2, FETCH_VEHICLE_BRAND_FAILURE, FETCH_VEHICLE_BRAND_REQUEST, FETCH_VEHICLE_BRAND_SUCCESS, } from "../../app/actions/actions";
import { ROUTES } from "../../config/routingUrl";
import { dureeDeVie, setWithExpiration } from "../../utils/storage/localvalueFuction";
import { localStorageData, localStorageKeys } from "../../utils/storage/localvalue";
import { baseurl } from "../../utils/url/baseurl";
import { saveDataToFile } from "../DataLocal";
import { toast } from "react-toastify";



// Create VehicleBrand
export const VehicleBrandCreate = (
    data) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_VEHICLE_BRAND_REQUEST });
        await axios
            .post(
                `${baseurl.url}/api/v1/vehiclebrands/create`,
                data,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                dispatch({ type: FETCH_VEHICLE_BRAND_SUCCESS });
                toast.success(response?.data?.message || "Marque ajouté avec succès", { position: "bottom-right" });
                dispatch(fetchVehicleBrandsAll())
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || "Marque non ajouté", { position: "bottom-right" });
                dispatch({ type: FETCH_VEHICLE_BRAND_FAILURE, payload: error.message });
                dispatch(fetchVehicleBrandsAll())
            });
    };
}



// update insurance 
export const VehicleBrandUpdateById = (idVehicleBrand, data) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_VEHICLE_BRAND_REQUEST });
        await axios
            .put(`${baseurl.url}/api/v1/vehiclebrands/update/${idVehicleBrand}`, data, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                dispatch({ type: FETCH_VEHICLE_BRAND_SUCCESS });
                toast.success(response?.data?.message || "Marque mis à jour avec succès", { position: "bottom-right" });
                dispatch(fetchVehicleBrandsAll())
            })
            .catch((error) => {
                dispatch({ type: FETCH_VEHICLE_BRAND_FAILURE, payload: error.message });
                toast.error(error?.response?.data?.message || "Marque non mis à jour", { position: "bottom-right" });
                dispatch(fetchVehicleBrandsAll())
            });
    };
}





// All insurance of plateforme
export function fetchVehicleBrandsAll() {
    return async (dispatch) => {
        dispatch({ type: FETCH_VEHICLE_BRANDS_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/vehiclebrands/get_vehiclebrands`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_VEHICLE_BRANDS_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_VEHICLE_BRANDS_SUCCESS_2, payload: response.data.data });
            saveDataToFile(response.data.data, localStorageData.VehicleBrands);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_VEHICLE_BRANDS_FAILURE, payload: error.message });
            });
    }
}


// get insurance by id 
export function fetchVehicleBrandById(idVehicleBrand) {
    return async (dispatch) => {
        dispatch({ type: FETCH_VEHICLE_BRAND_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/vehiclebrands/get_vehiclebrand/${idVehicleBrand}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_VEHICLE_BRAND_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                dispatch({ type: FETCH_VEHICLE_BRAND_FAILURE, payload: error.message })
                //console.log(error);
            });
    }
}

