import React, { useState } from 'react';
import { Table, Image, Button, Alert, Form, Row, Col, Badge, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../config/routingUrl';
import { RiEye2Fill, RiFileDamageFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import Select from "react-select";
import { fetchDamageById, fetchDamagesAll, fetchDamagesAllByPropertyId, fetchDamagesAllByUserId } from '../../../actions/request/DamageRequest';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { emptyImage, profileRoleType } from '../../../utils/dataApi/dataFormApi';
import { fetchPropertysAll, fetchPropertysAllByOwnerId, fetchPropertysFilters } from '../../../actions/request/PropertyRequest';
import { fetchVehiclesAll, fetchVehiclesAllByOwnerId, fetchVehiclesAllFilter } from '../../../actions/request/VehicleRequest';
import { MdDeleteOutline, MdOutlineSearch } from 'react-icons/md';
import { fetchInsurancesAll, fetchInsurancesAllByPropertyId, fetchInsurancesAllByUserId, fetchInsurancesAllByVehicleId } from '../../../actions/request/InssuranceRequest';
import { fetchPaymentsAll } from '../../../actions/request/PaymentRequest';
import { fetchReservationsAll, fetchReservationsAllByidClientId, fetchReservationsAllByidUserId } from '../../../actions/request/ReservationRequest';
import { FETCH_DAMAGE_SUCCESS } from '../../../app/actions/actions';

const DamageVehicleListPage = () => {


    const renderStatusBadge = (status) => {
        switch (status) {
            case 'Reported':
                return <Badge bg="danger">Signaler</Badge>;
            case 'Resolved':
                return <Badge bg="success">Résolu</Badge>;
            case 'In Progress':
                return <Badge bg="danger">Annuler</Badge>;
            case 'Paid':
                return <Badge bg="success">Payer</Badge>;
            default:
                return <Badge bg="secondary">{status}</Badge>;
        }
    };


    const navigate = useNavigate();

    const dispatch = useDispatch();
    const damages = useSelector((state) => state.damages.damages);
    const propertys = useSelector((state) => state.propertys.propertys);
    const vehicles = useSelector((state) => state.vehicles.vehicles);
    // const insurancesData = useSelector((state) => state.insurances.insurances);

    const
        callFunctionElements = () => {
            setvehicleSelect("")
            setpropertySelect("")
            if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.USER) {
                dispatch(fetchDamagesAllByUserId(getAndCheckLocalStorage(localStorageKeys.userId)));
                dispatch(fetchInsurancesAllByUserId(getAndCheckLocalStorage(localStorageKeys.userId)));
                dispatch(fetchPropertysAllByOwnerId(getAndCheckLocalStorage(localStorageKeys.userId)));
                dispatch(fetchVehiclesAllByOwnerId(getAndCheckLocalStorage(localStorageKeys.userId)));
                dispatch(fetchPaymentsAll());
                dispatch(fetchReservationsAllByidUserId(getAndCheckLocalStorage(localStorageKeys.userId)));
            } else if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.CUSTOMER) {
                dispatch(fetchReservationsAllByidClientId(getAndCheckLocalStorage(localStorageKeys.userId)));
            }
            else {
                dispatch(fetchDamagesAll());
                dispatch(fetchInsurancesAll());
                dispatch(fetchPropertysFilters());
                dispatch(fetchVehiclesAllFilter());
                dispatch(fetchPaymentsAll());
                dispatch(fetchReservationsAll());
            }
        }

    useEffect(() => {
        callFunctionElements();
    }, [])


    const [selectedVehicle, setSelectedVehicle] = useState(null); // Filtre par véhicule
    const [startDate, setStartDate] = useState(''); // Filtre par date de début
    const [endDate, setEndDate] = useState(''); // Filtre par date de fin



    const [propertySelect, setpropertySelect] = useState();
    const [vehicleSelect, setvehicleSelect] = useState();


    //  filtre des option
    const vehicleOptions = vehicles && vehicles.length > 0 ? vehicles.map((item) => {
        return ({
            value: item._id, label: item.brand
        })
    }) : []

    const propertyOptions = propertys && propertys.length > 0 ? propertys.map((item) => {
        return ({
            value: item._id, label: item.name
        })
    }) : []


    const handleSearchFilter = () => {
        if (vehicleSelect) {
            dispatch(fetchDamagesAllByPropertyId(vehicleSelect?.value));
        } else if (propertySelect) {
            dispatch(fetchDamagesAllByPropertyId(propertySelect?.value));
        }
        else {
            if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.USER) {
                dispatch(fetchDamagesAllByUserId(getAndCheckLocalStorage(localStorageKeys.userId)));
            } else if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.CUSTOMER) {
            }
            else {
                dispatch(fetchDamagesAll());
            }
        }
    }




    return (
        <div>

            <Card>
                <Card.Header>
                    <h3 className="mb-4"> <RiFileDamageFill /> Historique des dommages </h3>
                </Card.Header>
                <Card.Body style={{ minHeight: "500px" }}>

                    <ul className="list-group">
                        <li className="list-group-item" style={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between" }}>
                            <div className="flex" style={{ display: "flex", flexWrap: "wrap", maxWidth: "100%" }}>
                                <Form>
                                    <Row>
                                        {
                                            vehicleOptions && vehicleOptions.length > 0 && (
                                                <Col md={6}>
                                                    <Form.Group controlId="startHour" style={{ minWidth: "200px" }}>
                                                        <Form.Label>Véhicule</Form.Label>
                                                        <Select
                                                            value={vehicleSelect}
                                                            onChange={(selectedOption) => setvehicleSelect(selectedOption)}
                                                            options={vehicleOptions}
                                                            isClearable
                                                            isSearchable
                                                            placeholder="Choix"
                                                            isDisabled={!!propertySelect}
                                                        />
                                                    </Form.Group>
                                                </Col>)
                                        }

                                        {
                                            propertyOptions && propertyOptions.length > 0 && (
                                                <Col md={6}>
                                                    <Form.Group controlId="startHour" style={{ minWidth: "200px" }}>
                                                        <Form.Label>Propriété</Form.Label>
                                                        <Select
                                                            value={propertySelect}
                                                            isClearable
                                                            isSearchable
                                                            onChange={(selectedOption) => setpropertySelect(selectedOption)}
                                                            options={propertyOptions}
                                                            placeholder="Choix"
                                                            isDisabled={!!vehicleSelect}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            )
                                        }

                                    </Row>
                                </Form>

                            </div>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>

                                <div className="m-1">
                                    <label>{""}</label>
                                    <div>
                                        <Button variant='primary' size='sm' className="m-1" onClick={handleSearchFilter} >
                                            <MdOutlineSearch />
                                        </Button>
                                        {
                                            (
                                                vehicleSelect || propertySelect
                                            ) && (
                                                <Button variant="danger" size="sm" className="m-1 text-white" onClick={callFunctionElements}>
                                                    <MdDeleteOutline /> Effacer
                                                </Button>
                                            )
                                        }
                                    </div>

                                </div>

                            </div>
                        </li>
                    </ul>



                    {/* Tableau des rapports filtrés */}

                    {
                        damages && damages.length === 0 ? (
                            <p className="text-center">Aucun dommage .</p>
                        ) : (
                            <Table striped bordered hover responsive >
                                <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Reservation</th>
                                        <th>Catégorie</th>
                                        <th>Cout (DZD)</th>
                                        <th>Photos Avant Utilisation</th>
                                        <th>Photos Après Utilisation</th>
                                        <th>Commentaires</th>
                                        <th>Statut</th>
                                        <th>Facture de Réparation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {damages && damages.length > 0 && damages.map((report, index) => (
                                        <tr key={report.id}>
                                            <td >
                                                {
                                                    report && report.property && report.property.images && report.property.images && report.property.images.length > 0 &&
                                                    <img className="rounded-md" src={report.property.images[0] || emptyImage} style={{ height: "70px", width: "70px" }} />
                                                }
                                                {
                                                    report && report.vehicle && report.vehicle.images && report.vehicle.images && report.vehicle.images.length > 0 &&
                                                    <img className="rounded-md" src={report.vehicle.images[0] || emptyImage} style={{ height: "70px", width: "70px" }} />
                                                }
                                            </td>
                                            <td>
                                                {
                                                    report && report.reservation && report.reservation.ordre ? report.reservation.ordre : ""
                                                }
                                            </td>
                                            <td>
                                                {
                                                    report && report.type && report.type.name ?
                                                        report.type.name : ""
                                                }
                                            </td>
                                            <td>
                                                <strong>{report.estimatedCost}</strong>
                                            </td>
                                            <td>
                                                {

                                                }
                                                <Image
                                                    src={`${report.beforeUsePhoto[0] || emptyImage}`}
                                                    alt={`Avant - ${report.beforeUsePhoto[0]}`}
                                                    fluid
                                                    thumbnail
                                                    style={{ width: '80px', height: '60px' }}
                                                />
                                            </td>
                                            <td>
                                                <Image
                                                    src={`${report.afterUsePhoto[0] || emptyImage}`}
                                                    alt={`Après - ${report.afterUsePhoto[0]}`}
                                                    fluid
                                                    thumbnail
                                                    style={{ width: '80px', height: '60px' }}
                                                />
                                            </td>
                                            <td>{report.description}</td>
                                            <td>
                                                {renderStatusBadge(report.status)}
                                            </td>
                                            <td>

                                                <Button variant="primary" size="sm" onClick={() => {
                                                    dispatch({ type: FETCH_DAMAGE_SUCCESS, payload: report });
                                                    navigate(`/${ROUTES.DASHBOARD}/${ROUTES.DAMAGES_LIST_PLATEFORM_DETAIL}/${report._id}`)
                                                }}>
                                                    <RiEye2Fill /> <span className="text-sm"> Detail </span>
                                                </Button>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>)
                    }

                </Card.Body>
            </Card>


            {/* Filtres */}

        </div>
    );
};

export default DamageVehicleListPage;
