import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Badge, Modal, Button, Table, Row, Col, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import { fetchMaintenancesAll } from '../../../actions/request/MaintenanceRequest';
import { fetchPropertysAll, fetchPropertysAllByOwnerId, fetchPropertysFilters } from '../../../actions/request/PropertyRequest';
import { fetchVehiclesAll, fetchVehiclesAllByOwnerId, fetchVehiclesAllFilter } from '../../../actions/request/VehicleRequest';
import { MdDeleteOutline, MdSearch } from 'react-icons/md';
import { fetchMotifsAll } from '../../../actions/request/MotifRequest';
import { profileRoleType } from '../../../utils/dataApi/dataFormApi';

const localizer = momentLocalizer(moment);

const renderStatusBadge = (status) => {
    switch (status) {
        case 'PLANNED':
            return <Badge bg="success">Planifiée</Badge>;
        case 'IN_PROGRESS':
            return <Badge bg="warning">En cours</Badge>;
        case 'COMPLETED':
            return <Badge bg="primary">Terminée</Badge>;
        case 'CANCELLED':
            return <Badge bg="danger">Annulée</Badge>;
        default:
            return <Badge bg="secondary">{status}</Badge>;
    }
};

const MaintenanceCalendarPage = () => {
    const dispatch = useDispatch();
    const vehicles = useSelector((state) => state.vehicles.vehicles);
    const propertys = useSelector((state) => state.propertys.propertys);
    const maintenances = useSelector((state) => state.maintenances.maintenances);
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [selectedProperty, setSelectedProperty] = useState(null);

    const callFunctionElements = () => {
        setSelectedVehicle("")
        setSelectedProperty("")
        // setstartDate("")
        // setEndDate("")

        const userId = getAndCheckLocalStorage(localStorageKeys.userId);
        dispatch(fetchMotifsAll());
        if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.USER) {
            dispatch(fetchPropertysAllByOwnerId(getAndCheckLocalStorage(localStorageKeys.userId)));
            dispatch(fetchVehiclesAllByOwnerId(getAndCheckLocalStorage(localStorageKeys.userId)));

            dispatch(fetchMaintenancesAll(getAndCheckLocalStorage(localStorageKeys.userId)));
            dispatch(fetchMotifsAll());

        } else if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.CUSTOMER) {
        }
        else {
            dispatch(fetchMaintenancesAll());
            dispatch(fetchPropertysFilters());
            dispatch(fetchVehiclesAllFilter());
        }
    }

    useEffect(() => {
        callFunctionElements();
    }, [dispatch]);

    const vehicleOptions = vehicles?.map(item => ({ value: item._id, label: item.brand })) || [];
    const propertyOptions = propertys?.map(item => ({ value: item._id, label: item.name })) || [];

    const handleClose = () => setShowModal(false);
    const handleShow = (event) => {
        setSelectedEvent(event);
        setShowModal(true);
    };

    const formatMaintenancesForCalendar = () => {
        return maintenances && maintenances.length > 0 ? maintenances.map(maintenance => ({
            title: `${maintenance?.vehicle?.brand || maintenance?.property?.name || ""} `,
            start: new Date(maintenance.startDate),
            end: new Date(maintenance.endDate),
            allDay: true,
            resource: maintenance,
        })) : [];
    };

    useEffect(() => {
        setEvents(formatMaintenancesForCalendar());
    }, [maintenances]);

    const eventStyleGetter = (event) => {
        const backgroundColor = event.resource.status === 'PLANNED' ? '#43aa8b' :
            event.resource.status === 'IN_PROGRESS' ? '#f9c74f' :
                event.resource.status === 'COMPLETED' ? '#00bb2e' :
                    event.resource.status === 'CANCELLED' ? '#f94144' : '#777';

        return { style: { backgroundColor, color: 'white', borderRadius: '5px' } };
    };

    const handleFilter = () => {
        const userId = getAndCheckLocalStorage(localStorageKeys.userId);
        if (selectedVehicle) {
            dispatch(fetchMaintenancesAll(userId, startDate, endDate, selectedProperty.value, selectedVehicle.value));
        } else if (selectedProperty) {
            dispatch(fetchMaintenancesAll(userId, startDate, endDate, selectedProperty.value, selectedVehicle.value));
        } else {
            dispatch(fetchMaintenancesAll("",startDate, endDate, selectedProperty.value, selectedVehicle.value));
        }
    };

    return (
        <div className="">
            <Card>
                <Card.Header><h3>Calendrier des maintenances</h3></Card.Header>
                <Card.Body>

                    {/* Ajout du système de filtres */}
                    <div className="filter-container mb-4">
                        <Row>
                            {vehicleOptions.length > 0 && (
                                <Col md={3}>
                                    <Select
                                        value={selectedVehicle}
                                        onChange={setSelectedVehicle}
                                        isClearable
                                        options={vehicleOptions}
                                        placeholder="Sélectionnez un véhicule"
                                        isDisabled={!!selectedProperty}
                                    />
                                </Col>
                            )}

                            {propertyOptions.length > 0 && (
                                <Col md={3}>
                                    <Select
                                        value={selectedProperty}
                                        onChange={setSelectedProperty}
                                        isClearable
                                        options={propertyOptions}
                                        placeholder="Sélectionnez une propriété"
                                        isDisabled={!!selectedVehicle}
                                    />
                                </Col>
                            )}

                            <Col md={3}>
                                <div className="p-1">
                                    <label>{"Début"}</label>
                                    <input type="date" style={{ height: "40px" }} placeholder='début' className="form-control" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                </div>
                                <div className="p-1">
                                    <label>{"Fin"}</label>
                                    <input type="date" style={{ height: "40px" }} placeholder='fin' className="form-control" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                </div>
                            </Col>


                            <Col md={2}>
                                <Button onClick={handleFilter} className="mt-3" size="sm"> <MdSearch /> </Button>
                                {
                                    (
                                        selectedVehicle || selectedProperty || startDate || endDate
                                    ) && (
                                        <Button variant="danger" size="sm" className="m-1 text-white" onClick={callFunctionElements}>
                                            <MdDeleteOutline /> Effacer
                                        </Button>
                                    )
                                }
                            </Col>

                        </Row>
                    </div>

                    <div className="calendar-container">
                        <BigCalendar
                            localizer={localizer}
                            events={events}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ height: 500 }}
                            eventPropGetter={eventStyleGetter}
                            views={['month', 'week', 'day']}
                            defaultView="month"
                            popup={true}
                            onSelectEvent={handleShow}
                        />
                    </div>


                    {/* Tableau des maintenances */}
                    <div className="maintenance-table mt-4">
                        <h5></h5>
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Patrimoine</th>
                                    <th>Description</th>
                                    <th>Coût</th>
                                    <th>Dates</th>
                                    <th>Statut</th>
                                </tr>
                            </thead>
                            <tbody>
                                {maintenances.length > 0 ? (
                                    maintenances.map((maintenance, index) => (
                                        <tr key={index} onClick={() => handleShow({ resource: maintenance })} style={{ cursor: "pointer" }}>
                                            <td>{maintenance?.vehicle?.brand || maintenance?.property?.name || ""}</td>
                                            <td>{maintenance?.description || ""}</td>
                                            <td>{maintenance?.cost || 0}</td>
                                            <td>{moment(maintenance.startDate).format('DD/MM/YYYY')} - {moment(maintenance.endDate).format('DD/MM/YYYY')}</td>
                                            <td>{renderStatusBadge(maintenance.status)}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr><td colSpan="6" className="text-center">Aucune maintenance enregistrée</td></tr>
                                )}
                            </tbody>
                        </Table>
                    </div>



                    {selectedEvent && (
                        <Modal show={showModal} onHide={handleClose} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Détails de la maintenance</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="maintenance-info">
                                    <strong>Objet :</strong> {selectedEvent.resource?.vehicle?.brand || selectedEvent.resource?.property?.name || ""}<br />
                                    <strong>Description :</strong> {selectedEvent.resource?.description || ""}<br />
                                    <strong>Coût :</strong> {selectedEvent.resource?.cost || ""}<br />
                                    <strong>Dates :</strong> {moment(selectedEvent.start).format('DD MMM YYYY HH:MM')} - {moment(selectedEvent.end).format('DD MMM YYYY HH:MM')}<br />
                                    <strong>Statut :</strong> {renderStatusBadge(selectedEvent.resource?.status)}<br />
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" size='sm' onClick={handleClose}>
                                    Fermer
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    )}

                </Card.Body>
            </Card>
        </div>
    );
};

export default MaintenanceCalendarPage;
