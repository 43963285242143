import { FETCH_CONTACTS_FAILURE, FETCH_CONTACTS_REQUEST, FETCH_CONTACTS_SUCCESS, FETCH_CONTACTS_SUCCESS_2, FETCH_CONTACT_FAILURE, FETCH_CONTACT_REQUEST, FETCH_CONTACT_SUCCESS } from "../actions/actions";



// contactReducer.js
const initialState = {
  contacts: [],
  contacts2: [],
  contact: {},
  contactSelect: {},
  loadingContact: false,
  loadingContactSelect: false,
  loadingContacts: false,
  errorContacts: null,
  errorContact: null
  // ... autres états spécifiques à contactReducer
};

const contactReducer = (state = initialState, action) => {
  switch (action.type) {

    case FETCH_CONTACT_REQUEST:
      // Dispatch une action pour définir loading sur true dans le globalReducer
      return {
        ...state,
        loadingContact: true,
        errorContacts: ""
        // Autres manipulations d'état pour la demande de récupération des contacts
      };

    case FETCH_CONTACT_SUCCESS:
      return {
        ...state,
        loadingContact: false,
        errorContacts: "",
        contact: action.payload
      };

    case FETCH_CONTACT_FAILURE:
      return {
        ...state,
        loadingContact: false,
        errorContacts: action.payload
      };


    case FETCH_CONTACTS_REQUEST:
      return {
        ...state,
        loadingContacts: true,
        errorContacts: ""
      };

    case FETCH_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: action.payload,
        loadingContacts: false,
        errorContacts: ""
        // Autres manipulations d'état pour le succès de la récupération des contacts
      };

    case FETCH_CONTACTS_SUCCESS_2:
      return {
        ...state,
        contacts2: action.payload,
        loadingContacts: false,
        errorContacts: ""
        // Autres manipulations d'état pour le succès de la récupération des contacts
      };

    case FETCH_CONTACTS_FAILURE:
      // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
      return {
        ...state,
        loadingContacts: false,
        errorContacts: action.payload
        // Autres manipulations d'état pour l'échec de la récupération des contacts
      };
    // ... autres cas pour d'autres actions liées aux contacts

    default:
      return state;
  }
};

export default contactReducer;
