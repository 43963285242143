import React, { useState } from 'react';
import { FaHotel, FaCar, FaPlane, FaCalendarAlt, FaShoppingBag, FaHome, FaEnvelope, FaUser, FaListAlt } from 'react-icons/fa';
import { BiSupport } from "react-icons/bi";
import Select from 'react-select';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { MdSend } from 'react-icons/md';
import { createContact } from '../../actions/request/ContactRequest';
import { maxLengthLitte, maxLengthLong } from '../../utils/dataApi/dataFormApi';

const categories = [
    { value: 'hotel', label: 'Hôtels' },
    { value: 'voiture', label: 'Location de voitures' },
    { value: 'vol', label: 'Billets d\'avion' },
    { value: 'activites', label: 'Activités et excursions' },
    { value: 'croisiere', label: 'Croisières' },
    { value: 'villa', label: 'Location de villas' },
    { value: 'train', label: 'Billets de train' },
    { value: 'camping', label: 'Camping et aventure' },
    { value: 'restaurant', label: 'Restaurants' },
    { value: 'spa', label: 'Spas et bien-être' },
    { value: 'transport', label: 'Transports privés' },
    { value: 'guide', label: 'Guides touristiques' },
    { value: 'parc', label: 'Parcs d\'attractions' },
    { value: 'yacht', label: 'Location de yachts' },
    { value: 'ecotourisme', label: 'Écotourisme' },
    { value: 'evenement', label: 'Événements et conférences' },
    { value: 'sport', label: 'Expériences sportives' },
    { value: 'cinema', label: 'Cinémas et spectacles' },
    { value: 'shopping', label: 'Shopping et lifestyle' },
];

const services = [
    { icon: <FaHotel size={24} />, text: "Réservation d'hôtels" },
    { icon: <FaCar size={24} />, text: "Location de voitures" },
    { icon: <FaPlane size={24} />, text: "Billets d'avion" },
    { icon: <FaCalendarAlt size={24} />, text: "Événements et conférences" },
    { icon: <FaShoppingBag size={24} />, text: "Shopping et lifestyle" },
    { icon: <FaHome size={24} />, text: "Location de vacances" }
];


const MAX_MESSAGE_LENGTH = maxLengthLong;  // 🔹 Limite du message
const MAX_NAME_LENGTH = maxLengthLitte;  // 🔹 Limite du nom




const ContactPage = () => {
    const dispatch = useDispatch();
    const loadingContact = useSelector((state) => state?.contacts?.loadingContact);

    const { quill, quillRef } = useQuill();

    // State global pour récupérer toutes les entrées utilisateur
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        category: null,
        message: ''
    });

    // Limite la taille du champ Nom complet
    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "name" && value.length > MAX_NAME_LENGTH) {
            toast.error("Vous avez atteint la limite de caractère recommandé", { position: "bottom-right" })
            return;  // Empêche d'ajouter plus de caractères
        }

        setFormData({
            ...formData,
            [name]: value
        });
    };


    // Gère la sélection de catégorie
    const handleCategoryChange = (selectedOption) => {
        setFormData({
            ...formData,
            category: selectedOption
        });
    };

    // Gère l'envoi du formulaire
    const handleSubmit = (e) => {
        e.preventDefault();

        const messageText = quill ? quill.root.innerText : "";
        // Met à jour le message avec Quill si l'éditeur est initialisé
        if (quill) {
            setFormData((prevData) => ({
                ...prevData,
                message: quill.root.innerHTML
            }));
        }

        // 🔹 Vérification de la longueur du message
        if (messageText.length > MAX_MESSAGE_LENGTH) {
            toast.error(`Le message ne doit pas dépasser ${MAX_MESSAGE_LENGTH} caractères`, { position: "bottom-right" });
            return;
        }

        if (!formData.name || !formData.email || !formData.category?.value || !quill.root.innerHTML) {
            toast.error("Veuillez remplir tous les champs", { position: "bottom-right" });
            return;
        }

        const formDataSend = {
            name: formData?.name,
            email: formData?.email,
            category: formData?.category?.value || "",
            message: formData?.message,
        }

        dispatch(createContact(formDataSend))

        // console.log("📩 Données envoyées :", formData);
        // alert("Votre message a été envoyé avec succès !");
    };



    return (
        <div className="page-content bg-white">

            {/* Bannière avec message engageant */}
            <div className="dlab-bnr-inr style-1 overlay-black-middle" style={{ backgroundImage: `url(images/banner/bnr1.jpg)` }}>
                <div className="container">
                    <div className="dlab-bnr-inr-entry">
                        <h1 className="text-white">Nous contacter</h1>
                        <p className="text-white">
                            Nous sommes à votre écoute ! Partagez vos suggestions et vos préoccupations,
                            nous nous engageons à améliorer continuellement nos services.
                        </p>
                        <div className="dlab-separator"></div>
                    </div>
                </div>
            </div>

            <section className="content-inner bg-gray bottom-shape">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 m-b40">
                            <div className="section-head">
                                <div className="icon-full">
                                    <BiSupport size={40} color={"white"} />
                                </div>
                                <h3 className="title">Contact</h3>
                            </div>
                            <p className="m-b50">
                                Votre avis compte ! Aidez-nous à améliorer nos services en partageant vos
                                commentaires et suggestions.
                            </p>
                            <div className="row">
                                {services.map((service, index) => (
                                    <div key={index} className="col-md-4 col-sm-6 mb-4">
                                        <div className="icon-bx-wraper style-2 bg-white p-3 text-center rounded shadow">
                                            <div className="icon-bx-sm radius mx-auto mb-3">
                                                <span className="icon-cell">{service.icon}</span>
                                            </div>
                                            <div className="icon-content">
                                                <p className="mb-0">{service.text}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Formulaire de contact */}
                        <div className="col-xl-6 m-b40">
                            <div className="contact-area1">
                                <form className="dlab-form style-1 dzForm" onSubmit={handleSubmit}>
                                    <div className="dzFormMsg"></div>

                                    <div className="row sp10">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label><FaUser className="me-2" />Nom complet <span className="text-danger">*</span></label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                    className="form-control bg-white text-dark"
                                                    style={{ height: "40px" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label><FaEnvelope className="me-2" />Email <span className="text-danger">*</span></label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                    className="form-control bg-white text-dark"
                                                    style={{ height: "40px" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label><FaListAlt className="me-2" />Sélectionnez une catégorie  <span className="text-danger">*</span></label>
                                                <Select
                                                    options={categories}
                                                    value={formData.category}
                                                    onChange={handleCategoryChange}
                                                    placeholder="Choisissez une catégorie"
                                                    className="bg-white text-dark"
                                                    styles={{ control: (styles) => ({ ...styles, height: "40px" }) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label>Votre Message <span className="text-danger">*</span></label>
                                                <div ref={quillRef} className="bg-white text-dark" style={{ height: "200px" }} />
                                                <small>{quill?.root.innerText.length || 0} / {MAX_MESSAGE_LENGTH} caractères</small>
                                            </div>
                                        </div>

                                        <div className="col-sm-12">
                                            {
                                                loadingContact ?
                                                    <button type="button" className="btn btn-primary w-100 d-block btn-rounded">
                                                        <Spinner variant='light' size='sm' />  Envois en cours ...
                                                    </button>
                                                    :
                                                    <button type="submit" className="btn btn-primary w-100 d-block btn-rounded">
                                                        <MdSend />   Envoyer le message
                                                    </button>
                                            }

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </div>
    );
};

export default ContactPage;
