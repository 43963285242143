import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Container, Button, Card } from 'react-bootstrap';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { PropertyCreate } from '../../../actions/request/PropertyRequest';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import { toast } from 'react-toastify';
import { fetchPropertyTypesAll } from '../../../actions/request/PropertyTypeRequest';
import axios from 'axios';
import { baseurl } from '../../../utils/url/baseurl';
import { MdClose, MdLocationOn, MdRemove } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import LocationComponentMap from '../../../components/admin/reservations/LocationComponentMap';
import { maxLengthLitte, maxLengthLitteStart, maxLengthNumberLong } from '../../../utils/dataApi/dataFormApi';

function NewPropertyPage() {

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const propertyTypes = useSelector((state) => state.propertyTypes.propertyTypes);

    const [newProperty, setNewProperty] = useState({
        type: '',
        address: "",
        lat: null,
        lng: null,
        name: '',
        size: 0,
        price: '',
        pricePerMonth: 0,
        yearBuilt: 0,
        images: [],
        owner: getAndCheckLocalStorage(localStorageKeys.userId),
        ownerName: '',
        addBy: getAndCheckLocalStorage(localStorageKeys.userId),
        details: {},

        // infos supplementaire
        deliveryFee: 0,
        // commissionPercentage: 0,
        basePricePerDay: 0,
        numberProperty: "",

        options: {
            hasParking: false,
            hasBalcony: false,
            hasElevator: false,
            hasSwimmingPool: false,
            hasGarden: false,
            hasSecurity: false,
            hasFurnished: false,
            hasGym: false,
            hasAirConditioning: false,
            hasHeating: false,
            hasFireplace: false,
            hasBasement: false,
            hasTerrace: false,
            hasInternet: false,
            squareMeters: '',
            hasCinemaRoom: false,
            hasMeetingRoom: false,
            hasLaundryRoom: false,
            hasSatelliteTV: false,
            hasSolarPanels: false,
            hasDoubleGlazing: false,
            hasAlarmSystem: false,
            hasVideoSurveillance: false,
            hasElectricGate: false,
            hasSmartHome: false
        }
    });

    const [typeOptions, setTypeOptions] = useState([]);
    const [errors, setErrors] = useState({});
    const [uploading, setUploading] = useState(false);

    // Gestion des types de propriétés
    useEffect(() => {
        dispatch(fetchPropertyTypesAll());
    }, [dispatch]);

    useEffect(() => {
        if (propertyTypes && propertyTypes.length > 0) {
            setTypeOptions(propertyTypes.map(pt => ({ label: pt.name, value: pt._id })));
        }
    }, [propertyTypes]);

    // Fonction de téléchargement des images vers Cloudinary
    const handleImageChange = async (e) => {
        const files = Array.from(e.target.files);
        const uploadPromises = files.map(file => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', baseurl.cloudinaryUploadPreset);

            return axios.post(`https://api.cloudinary.com/v1_1/${baseurl.cloudinaryCloudName}/image/upload`, formData)
                .then(response => {
                    toast.success('Image téléchargée avec succès', { position: "bottom-right" });
                    return response.data.secure_url;
                })
                .catch(error => {
                    toast.error('Erreur lors du téléchargement de l\'image', { position: "bottom-right" });
                    console.error('Erreur lors du téléchargement de l\'image :', error);
                    return null;
                });
        });

        const uploadedImageUrls = await Promise.all(uploadPromises);
        setNewProperty(prevProperty => ({
            ...prevProperty,
            images: [...prevProperty.images, ...uploadedImageUrls.filter(url => url !== null)]
        }));
    };

    // Validation du formulaire
    const validateForm = () => {
        let formErrors = {};
        if (!newProperty.name) formErrors.type = "Le nom ou la designation obligatoire";
        if (!newProperty.type) formErrors.type = "Le type est requis";
        if (!newProperty.address) formErrors.address = "L'adresse est requise";
        if (!newProperty.size) formErrors.size = "La superficie est requise";
        if (!newProperty.ownerName) formErrors.size = "Le propriétaire requis";
        if (!newProperty.basePricePerDay) formErrors.numberProperty = "Le prix journalierst requis";
        if (!newProperty.numberProperty) formErrors.numberProperty = "Numéro d'identification de la propriété est requis";
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    // Fonction de traitement d'ajout de propriété
    const handleAddProperty = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                setUploading(true);
                dispatch(PropertyCreate(getAndCheckLocalStorage(localStorageKeys.userId), newProperty.type || "", newProperty, navigate, toast));
                // toast.success("Propriété ajoutée avec succès");
                setUploading(false)
            } catch (error) {
                console.error("Erreur lors de l'ajout de la propriété:", error);
            } finally {
                setUploading(false);
            }
        }
    };



    // ✅ Fonction pour gérer les changements des cases à cocher
    const handleCheckboxChange = (e) => {
        setNewProperty({
            ...newProperty,
            options: { ...newProperty.options, [e.target.name]: e.target.checked }
        });
    };




    // Fonction pour gérer les changements dans les champs de type nombre et empêcher les valeurs négatives
    const handleNumberInputChange = (e, field) => {
        let value = e.target.value;

        // Vérifie si la valeur est bien un nombre positif, ne commence pas par plusieurs zéros et n'est pas vide
        if (!/^(0|[1-9]\d*)?$/.test(value)) {
            return; // 🔴 Ignore la saisie si ce n'est pas un nombre valide
        }

        // Convertit la valeur en nombre
        value = parseFloat(value);

        // Remplace les valeurs invalides ou supérieures à la limite maximale
        if (isNaN(value) || value < 0) {
            value = ''; // ✅ Remplace les valeurs négatives ou invalides par une chaîne vide
        } else if (value > maxLengthNumberLong) {
            value = maxLengthNumberLong; // ✅ Limite la valeur maximale à 1 000 000
        }

        // Met à jour l'état du champ spécifié
        setNewProperty(prevState => ({
            ...prevState,
            [field]: value
        }));
    };


    const [pickupLocation, setPickupLocation] = useState('');
    const [pickupSuggestions, setPickupSuggestions] = useState([]);

    // Obtenir les suggestions de lieu de départ
    const getPlacesByCountryStartLocation = async (query) => {
        try {
            const response = await axios.get(`${baseurl.url}/api/v1/places`, {
                params: { query: query },
            });
            const places = response.data.results;
            return places.map(place => place);
        } catch (error) {
            console.error("Erreur lors de la récupération des lieux : ", error);
        }
    };
    const handlePickupLocationChange = async (e) => {
        const value = e.target.value;
        setPickupLocation(value);
        if (value.length > 2) {
            const suggestions = await getPlacesByCountryStartLocation(value);
            setPickupSuggestions(suggestions);
        } else {
            setPickupSuggestions([]); // Réinitialiser la liste des suggestions
        }
    };
    const handlePickupSelection = (suggestion) => {
        // Met à jour le pickupLocation
        setPickupLocation(`${suggestion.name} - ${suggestion.formatted_address}`);
        // Met à jour newVehicle en une seule fois avec toutes les nouvelles valeurs
        setNewProperty(prevState => ({
            ...prevState,
            address: `${suggestion.name} - ${suggestion.formatted_address}`,
            lat: suggestion?.geometry?.location?.lat,
            lng: suggestion?.geometry?.location?.lng
        }));

        // Vérifie les valeurs dans suggestion
        // console.log(suggestion?.geometry?.location?.lat); // Affiche la latitude
        // console.log(suggestion?.geometry?.location?.lng); // Affiche la longitude
        // console.log(`${suggestion.name} - ${suggestion.formatted_address}`); // Affiche l'adresse complète

        // Vide les suggestions après la sélection
        setPickupSuggestions([]);
    };

    const handleCleanSugestions = () => {
        setPickupSuggestions([]);
    }





    return (
        <div className=" mb-5" onClick={handleCleanSugestions}>
            <Card>
                <Card.Header>
                    <h3 className="mb-4">Ajouter une nouvelle propriété</h3>
                </Card.Header>
                <Card.Body>

                    <Form onSubmit={handleAddProperty}>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formPropertyType">
                                    <Form.Label>Type de propriété</Form.Label>
                                    <Select
                                        options={typeOptions}
                                        value={typeOptions.find(option => option.value === newProperty.type)}
                                        onChange={(selectedOption) => setNewProperty({ ...newProperty, type: selectedOption ? selectedOption.value : '' })}
                                        placeholder=""
                                    />
                                    {errors.type && <div className="invalid-feedback d-block">{errors.type}</div>}
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formPropertyAddress">
                                    <Form.Label>Nom/Désignation de la propriété</Form.Label>
                                    <Form.Control
                                        style={{ height: "40px" }}
                                        type="text"
                                        placeholder=""
                                        value={newProperty.name}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value.length <= maxLengthLitte) { // Limite à 100 caractères
                                                setNewProperty({ ...newProperty, name: value });
                                            }
                                        }}
                                        isInvalid={!!errors.address}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>


                            <Col md={6}>
                                <Form.Group controlId="formPropertySize">
                                    <Form.Label>Superficie totale (m²)</Form.Label>
                                    <Form.Control
                                        style={{ height: "40px" }}

                                        type="text"
                                        placeholder=""
                                        value={newProperty.size}
                                        onChange={(e) => { handleNumberInputChange(e, "size") }}
                                        isInvalid={!!errors.size}
                                    />
                                    {errors.size && <div className="invalid-feedback d-block">{errors.size}</div>}
                                </Form.Group>
                            </Col>


                            <Col md={6}>
                                <Form.Group controlId="formPropertyYearBuilt">
                                    <Form.Label>Année de construction</Form.Label>
                                    <Form.Control
                                        style={{ height: "40px" }}
                                        type="text"
                                        placeholder=""
                                        value={newProperty.yearBuilt}

                                        onChange={(e) => { handleNumberInputChange(e, "yearBuilt") }}
                                        isInvalid={!!errors.yearBuilt}
                                    />
                                    {errors.yearBuilt && <div className="invalid-feedback d-block">{errors.yearBuilt}</div>}
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="formPropertyYearBuilt">
                                    <Form.Label>N° de la propriété </Form.Label>
                                    <Form.Control
                                        style={{ height: "40px" }}
                                        type="text"
                                        placeholder=""
                                        value={newProperty.numberProperty}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value.length <= maxLengthLitte) { // Limite à 100 caractères
                                                setNewProperty({ ...newProperty, numberProperty: value });
                                            }
                                        }}
                                        isInvalid={!!errors.numberProperty}
                                    />
                                    {errors.numberProperty && <div className="invalid-feedback d-block">{errors.numberProperty}</div>}
                                </Form.Group>
                            </Col>


                            <Col md={6}>
                                <Form.Group controlId="formPropertyOwnerName">
                                    <Form.Label>Nom du propriétaire</Form.Label>
                                    <Form.Control
                                        style={{ height: "40px" }}
                                        type="text"
                                        placeholder=""
                                        value={newProperty.ownerName}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value.length <= maxLengthLitte) { // Limite à 100 caractères
                                                setNewProperty({ ...newProperty, ownerName: value });
                                            }
                                        }}
                                        isInvalid={!!errors.ownerName}
                                    />
                                    {errors.ownerName && <div className="invalid-feedback d-block">{errors.ownerName}</div>}
                                </Form.Group>
                            </Col>


                            <Col md={6}>
                                <Form.Group controlId="formPropertyOwnerName">
                                    <Form.Label>Prix Journalier</Form.Label>
                                    <Form.Control
                                        style={{ height: "40px" }}
                                        type="text"
                                        min={0}
                                        max={1000000}
                                        placeholder=""
                                        value={newProperty.basePricePerDay}
                                        onChange={(e) => { handleNumberInputChange(e, "basePricePerDay") }}
                                        isInvalid={!!errors.basePricePerDay}
                                    />
                                    {errors.basePricePerDay && <div className="invalid-feedback d-block">{errors.basePricePerDay}</div>}
                                </Form.Group>
                            </Col>
                            {/*
                            <Col md={4}>
                                <Form.Group controlId="formPropertyOwnerName">
                                    <Form.Label>Commision ( % )</Form.Label>
                                    <Form.Control
                                        style={{ height: "40px" }}
                                        type="text"
                                        min={0}
                                        max={100}
                                        placeholder=""
                                        value={newProperty.commissionPercentage}
                                        onChange={(e) => { handleNumberInputChange(e, "commissionPercentage") }}
                                    />
                                </Form.Group>
                            </Col> */}

                            <Col md={6}>
                                <Form.Group controlId="formPropertyOwnerName">
                                    <Form.Label>Frais Supplementaires  </Form.Label>
                                    <Form.Control
                                        style={{ height: "40px" }}
                                        type="text"
                                        min={0}
                                        max={1000000}
                                        placeholder=""
                                        value={newProperty.deliveryFee}
                                        onChange={(e) => { handleNumberInputChange(e, "deliveryFee") }}
                                        isInvalid={!!errors.deliveryFee}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <Form.Group controlId="formPropertyImages">
                                    <Form.Label>Photos de l{"'"}état initial</Form.Label>
                                    <Form.Control

                                        type="file"
                                        required
                                        accept=".jpg,.jpeg,.png"
                                        multiple
                                        onChange={handleImageChange}
                                    />
                                    <div className="mt-2">
                                        {newProperty.images.length > 0 && (
                                            <div>
                                                <h5>Images sélectionnées :</h5>
                                                <ul className="flex" style={{ display: "flex", flexWrap: "wrap" }}>
                                                    {newProperty.images.map((image, index) => (
                                                        <li key={index} className="p-1">
                                                            <span className="text-danger text-xs"
                                                                onClick={() => {
                                                                    // On supprime l'image correspondant à l'index
                                                                    const updatedImages = newProperty.images.filter((_, i) => i !== index);
                                                                    setNewProperty({ ...newProperty, images: updatedImages });
                                                                }}
                                                            >
                                                                <MdClose />
                                                            </span>
                                                            <div>
                                                                <img src={image} style={{ height: "50px", width: "50px" }} />
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>

                                </Form.Group>
                            </Col>


                            <Col md={12}>
                                <Form.Group controlId="formPropertyAddress">
                                    <Form.Label>Adresse</Form.Label>
                                    <Form.Control
                                        style={{ height: "40px" }}
                                        type="text"
                                        placeholder=""
                                        value={pickupLocation}
                                        onChange={(e) => handlePickupLocationChange(e)}
                                        isInvalid={!!errors.address}
                                    />
                                    {errors.address && <div className="invalid-feedback d-block">{errors.address}</div>}
                                    {/* Liste déroulante des suggestions */}
                                    {pickupSuggestions && pickupSuggestions.length > 0 && (
                                        <ul className="rounded p-1 border" style={{ position: 'absolute', zIndex: 1000, backgroundColor: 'white', maxwidth: "400px", listStyleType: 'none', padding: 0, maxHeight: "200px", overflow: "auto" }}>
                                            {pickupSuggestions.map((suggestion, index) => (
                                                <li className="border"
                                                    key={index}
                                                    style={{ padding: '5px', cursor: 'pointer' }}
                                                    onClick={() => handlePickupSelection(suggestion)}
                                                >
                                                    <MdLocationOn /> {`${suggestion.name} - ${suggestion.formatted_address}`}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </Form.Group>
                                {
                                    newProperty?.lat && newProperty?.lng && (
                                        <Col md={12} className="my-3">
                                            <LocationComponentMap pickLocation={newProperty?.pickLocation} latStart={newProperty?.lat} lngStart={newProperty?.lng} />
                                        </Col>
                                    )
                                }
                            </Col>



                            <Col md={12} className="mt-5">
                                <h5>Options supplémentaires</h5>
                                <Row>
                                    {[
                                        { name: "hasParking", label: "Parking privé" },
                                        { name: "hasBalcony", label: "Balcon" },
                                        { name: "hasElevator", label: "Ascenseur" },
                                        { name: "hasSwimmingPool", label: "Piscine" },
                                        { name: "hasGarden", label: "Jardin" },
                                        { name: "hasSecurity", label: "Sécurité 24h/24" },
                                        { name: "hasFurnished", label: "Meublé" },
                                        { name: "hasGym", label: "Salle de sport" },
                                        { name: "hasAirConditioning", label: "Climatisation" },
                                        { name: "hasHeating", label: "Chauffage central" },
                                        { name: "hasFireplace", label: "Cheminée" },
                                        { name: "hasBasement", label: "Cave/Sous-sol" },
                                        { name: "hasTerrace", label: "Terrasse" },
                                        { name: "hasInternet", label: "Connexion Internet" },
                                        { name: "hasCinemaRoom", label: "Salle de cinéma" },
                                        { name: "hasMeetingRoom", label: "Salle de réunion" },
                                        { name: "hasLaundryRoom", label: "Buanderie" },
                                        { name: "hasSatelliteTV", label: "Antenne TV/Satellite" },
                                        { name: "hasSolarPanels", label: "Panneaux solaires" },
                                        { name: "hasDoubleGlazing", label: "Double vitrage" },
                                        { name: "hasAlarmSystem", label: "Système d’alarme" },
                                        { name: "hasVideoSurveillance", label: "Vidéo surveillance" },
                                        { name: "hasElectricGate", label: "Portail électrique" },
                                        { name: "hasSmartHome", label: "Domotique intelligente" }
                                    ].map((option) => (
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Check
                                                    key={option.name}
                                                    type="checkbox"
                                                    label={option.label}
                                                    name={option.name}
                                                    checked={newProperty.options[option.name]}
                                                    onChange={handleCheckboxChange}
                                                    className="me-3"
                                                />
                                            </Form.Group>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>



                            <div className="col-md-12 " style={{ display: "flex", alignItems: "center" }}>
                                <input style={{ height: "30px", width: "30px" }} className="p-2"
                                    type="checkbox"
                                    name="isVisible" title="Afficher sur la plateforme"
                                    checked={newProperty.isVisible}
                                    onChange={(e) => { setNewProperty({ ...newProperty, isVisible: e.target.checked }); }}
                                />
                                <label className="p-2"> Mise en ligne sur la plateforme </label>
                            </div>
                        </Row>


                        {
                            uploading ?
                                <p>Chargement ...</p>
                                :
                                <Button
                                    variant="primary"
                                    size='sm'
                                    onClick={handleAddProperty}
                                    disabled={uploading}
                                    className="mt-5"
                                >
                                    {uploading ? "Téléchargement..." : "Ajouter"}
                                </Button>
                        }
                    </Form>


                </Card.Body>

            </Card>


        </div>
    );
}

export default NewPropertyPage;
