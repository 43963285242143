import React, { useEffect, useState, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { fetchUserById } from '../../actions/request/UserRequest';
import { useNavigate, useParams } from 'react-router-dom';
import { currentDate, emptyImage, logoApplication, profilePictureDefault } from '../../utils/dataApi/dataFormApi';
import Select from "react-select";
import moment from 'moment';
import { MdCarRental, MdCardTravel, MdHomeFilled, MdLocationOn, MdMail, MdPerson } from 'react-icons/md';
import { fetchPropertysAllByOwnerId, fetchPropertysAllResearch, fetchPropertysAllResearchAvailable } from '../../actions/request/PropertyRequest';
import { Button, Col, Container, Form, Pagination, Row, Card, Badge, Modal, Spinner, Offcanvas } from 'react-bootstrap';
import { ROUTES } from '../../config/routingUrl';
import { fetchVehiclesAllByOwnerIdAvailable, fetchVehiclesAllResearch, fetchVehiclesAllResearchAvailable } from '../../actions/request/VehicleRequest';
import { FaSearch } from 'react-icons/fa';
import { FETCH_PROPERTY_SUCCESS, FETCH_VEHICLE_SUCCESS } from '../../app/actions/actions';
import { RiFilter3Line } from 'react-icons/ri';
import { dureeDeVie, setWithExpiration } from '../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../utils/storage/localvalue';
import axios from 'axios';
import { baseurl } from '../../utils/url/baseurl';
import { ContainerFilter } from '../../components/web/cars/CarListFilterList';

const CarUserPage = () => {
    const paramUser = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.users.user);
    const loadingVehicles = useSelector((state) => state.vehicles.loadingVehicles);
    const loadingPropertys = useSelector((state) => state.propertys.loadingPropertys);
    const vehicles = useSelector((state) => state.vehicles.vehiclesAvailable);
    const propertys = useSelector((state) => state.propertys.propertysAvailable);

    const [availableStartDate, setAvailableStartDate] = useState('');
    const [availableStartTime, setAvailableStartTime] = useState({ value: '00:00', label: '00:00' });
    const [availableEndDate, setAvailableEndDate] = useState('');
    const [availableEndTime, setAvailableEndTime] = useState({ value: '00:00', label: '00:00' });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(12);
    const [filterType, setFilterType] = useState("vehicle");

    // priw
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(0);
    // année de construction
    const [minYearBuild, setminYearBuild] = useState(1900);
    const [maxYearBuild, setmaxYearBuild] = useState(0);
    // nombre de passagers
    const [minPassenger, setminPassenger] = useState(0);
    const [maxPassenger, setmaxPassenger] = useState(0);
    // kilometrage
    const [minMileage, setminMileage] = useState(0)
    const [maxMileage, setmaxMileage] = useState(0)
    // Categorie
    const [interiorType, setinteriorType] = useState("");
    // moteur
    const [engineType, setengineType] = useState("");
    // marque
    const [vehiclebrand, setvehiclebrand] = useState('');
    // option des propriété et des véhicule en question
    const [optionsItem, setoptionsItem] = useState()
    // Model
    const [model, setmodel] = useState(null)

    const [filteredVehicles, setFilteredVehicles] = useState([]);
    const [filteredProperties, setFilteredProperties] = useState([]);

    const hourOptions = Array.from({ length: 24 }, (_, i) => {
        const hour = i.toString().padStart(2, '0');
        return [
            { value: `${hour}:00`, label: `${hour}:00` },
            { value: `${hour}:30`, label: `${hour}:30` }
        ];
    }).flat();

    useEffect(() => {
        dispatch(fetchUserById(paramUser?.id));
        dispatch(fetchVehiclesAllResearchAvailable(currentDate, currentDate, paramUser?.id));
        dispatch(fetchPropertysAllResearchAvailable(currentDate, currentDate, paramUser?.id));
    }, [dispatch]);

    // useEffect(() => {
    //     // Mettre à jour le maxPrice après le chargement des véhicules
    //     if (vehicles.length > 0) {
    //         setMaxPrice(Math.max(...vehicles.map(v => v.basePricePerDay), 0));
    //     }
    // }, [vehicles]);

    useEffect(() => {
        // Filtrage basé sur le prix
        const vehiclesFiltered = vehicles || []/*.sort((a, b) => b.basePricePerDay - a.basePricePerDay) */
        // .filter((vehicle) => {
        //     const price = vehicle.basePricePerDay || 0;
        //     return price >= minPrice && price <= maxPrice;
        // });

        const propertiesFiltered = propertys || []/*.sort((a, b) => b.basePricePerDay - a.basePricePerDay) */
        // .filter((property) => {
        //     const price = property.price || 0; // Assurez-vous que `price` est défini
        //     return price >= minPrice && price <= maxPrice;
        // });

        setFilteredVehicles(vehiclesFiltered);
        setFilteredProperties(propertiesFiltered);
    }, [vehicles, propertys, minPrice, maxPrice]);



    const currentItems = filterType === "vehicle"
        ? filteredVehicles.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
        : filteredProperties.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const combineDateAndTime = (date, time) => {
        return moment(`${date}T${time.value}:00.000+00:00`).toISOString();
    };

    const handleSearch = () => {
        setShowOffCanvas(false);
        setCurrentPage(1);


        // Combiner les dates et heures
        const startDateTime = combineDateAndTime(availableStartDate, availableStartTime);
        const endDateTime = combineDateAndTime(availableEndDate, availableEndTime);

        // Envoyer les dates combinées avec la requête de recherche
        // dispatch(fetchPropertysAllResearch(startDateTime, endDateTime, paramUser?.id));
        // dispatch(fetchVehiclesAllResearch(startDateTime, endDateTime, paramUser?.id));
        dispatch(fetchPropertysAllResearchAvailable(startDateTime, endDateTime, paramUser?.id, "", formData.lat, formData.lng, minPrice, maxPrice, "", "", "", optionsItem));
        dispatch(fetchVehiclesAllResearchAvailable(startDateTime, endDateTime, paramUser?.id, "", model?.value, vehiclebrand?.value, minPassenger, maxPassenger, minMileage, maxMileage, minPrice, maxPrice, formData.lat, formData.lng, optionsItem, interiorType?.Value, engineType?.value));


        const filteredVehicles = vehicles.filter((vehicle) => {
            const price = vehicle.basePricePerDay || 0;
            return price >= minPrice && price <= maxPrice;

        });

        const filteredProperties = propertys.filter((property) => {
            const price = property.price || 0; // Assurez-vous que `price` est défini
            return price >= minPrice && price <= maxPrice;
        });


        // Mettre à jour les véhicules et propriétés filtrés dans l'état local
        setFilteredVehicles(filteredVehicles);
        setFilteredProperties(filteredProperties);
        // Fermer Offcanvas après un léger délai pour éviter de perturber la saisie
        setTimeout(() => {
            setShowOffCanvas(false);
        }, 300);
    };

    const today = new Date().toISOString().split('T')[0];

    const [formData, setFormData] = useState({
        agenceDepart: '',
    });
    const [pickupLocation, setPickupLocation] = useState('');
    const [pickupSuggestions, setPickupSuggestions] = useState([]);

    // Obtenir les suggestions de lieu de départ
    const getPlacesByCountryStartLocation = async (query) => {
        try {
            const response = await axios.get(`${baseurl.url}/api/v1/places`, {
                params: { query: query },
            });
            const places = response.data.results;
            return places.map(place => place);
        } catch (error) {
            console.error("Erreur lors de la récupération des lieux : ", error);
        }
    };

    const handlePickupLocationChange = async (e) => {
        const value = e.target.value;
        setPickupLocation(value);
        setTempLocation(value); // Change la valeur temporairement
        if (value.length > 2) {
            const suggestions = await getPlacesByCountryStartLocation(value);
            setPickupSuggestions(suggestions);
        } else {
            setPickupSuggestions([]); // Réinitialiser la liste des suggestions
        }
    };

    const handlePickupSelection = (suggestion) => {
        setPickupLocation(`${suggestion.name} - ${suggestion.formatted_address}`);
        setFormData(prevState => ({
            ...prevState,
            agenceDepart: `${suggestion.name} - ${suggestion.formatted_address}`,
        }));

        setWithExpiration(localStorageKeys.agenceDepArt, `${suggestion.name} - ${suggestion.formatted_address}`, dureeDeVie);
        setWithExpiration(localStorageKeys.latSelect, suggestion?.geometry?.location?.lat, dureeDeVie);
        setWithExpiration(localStorageKeys.longSelect, suggestion?.geometry?.location?.lng, dureeDeVie);


        setPickupSuggestions([]);
    };

    const handleCleanSugestions = (event) => {
        // Vérifie si le clic vient bien de l'extérieur des champs input
        if (!event.target.closest('input')) {
            setPickupSuggestions([]);
        }
    };


    const [showOffCanvas, setShowOffCanvas] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

    // Vérification de la largeur de l'écran pour détecter le mode mobile
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 900);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const [tempLocation, setTempLocation] = useState("");

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setPickupLocation(tempLocation);
        }, 300); // Attends 300ms avant de setter réellement la valeur

        return () => clearTimeout(timeoutId);
    }, [tempLocation]);







    return (
        <div className="container-fluid" onClick={handleCleanSugestions}>
            {/*<div className=" mt-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card p-3 mb-5 bg-white rounded">
                            <div className="card-header text-center bg-white">
                                <img
                                    src={user?.profilePicture || logoApplication}
                                    alt="Profile"
                                    className="rounded-circle img-fluid"
                                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                />
                                <div>
                                    <h4 className="mt-3">
                                        {user && user.companyName ? (
                                            <p className="mb-1 text-truc">{user.companyName}</p>
                                        ) : (
                                            <p className="mb-1">{user.firstname || ""} {user.lastname || ""}</p>
                                        )}
                                    </h4>
                                </div>

                            </div>
                            <div className="card-body">
                                <ul className="list-group list-group-flush">
                                    {
                                        user.email && (
                                            <li className="list-group-item">
                                                <strong><MdMail /> Email: </strong>{user.email || ""}
                                            </li>
                                        )
                                    }
                                    {
                                        user.createdAt && (
                                            <li className="list-group-item">
                                                <strong>Date d{"'"}inscrit: </strong>
                                                {user && user.createdAt ? moment(user.createdAt).format("DD-MM-YYYY") : ""}
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}


            <div class="icon-bx-wraper style-3 m-b30">
                <img class="photo img-fluid rounded-lg" src={user?.profilePicture || logoApplication} style={{ minHeight: "100px", maxHeight: "100px", objectFit: 'fill' }} alt="" />
                <div class="content-box">
                    <h5 class="media-heading m-b10">{user && user.companyName ? (
                        <span className="mb-1 text-truc">{user.companyName}</span>
                    ) : (
                        <span className="mb-1">{user.firstname || ""} {user.lastname || ""}</span>
                    )}</h5>
                    <ul class="used-car-dl-info">
                        <li style={{ opacity: 0 }}><i class="fas fa-map-marker-alt" ></i> UK London</li>
                        <li style={{ opacity: 0 }}><i class="fas fa-phone-alt" ></i> {user?.codePostal?.indicatif || ""} </li>
                        <li style={{ opacity: 0 }}><i class="far fa-envelope" ></i> {user.email || ""}</li>
                    </ul>
                    {/*<div class="clearfix m-t20">
                            <a href="#" class="btn btn-primary btn-xs m-b5">Send Details on Sms</a>
                            <a href="#" class="btn btn-primary btn-xs m-b5">Locate</a>
                        </div> */}
                </div>
            </div>

            <Container fluid className="mt-5 mb-5" onClick={handleCleanSugestions}>
                <Row>
                    {
                        !isMobile &&
                        (
                            <Col md={3} lg={3}>
                                <ContainerFilter
                                    filterType={filterType}
                                    setFilterType={setFilterType}
                                    pickupLocation={pickupLocation}
                                    handlePickupLocationChange={handlePickupLocationChange}
                                    pickupSuggestions={pickupSuggestions}
                                    handlePickupSelection={handlePickupSelection}
                                    minPrice={minPrice}
                                    setMinPrice={setMinPrice}
                                    maxPrice={maxPrice}
                                    setMaxPrice={setMaxPrice}
                                    availableStartDate={availableStartDate}
                                    setAvailableStartDate={setAvailableStartDate}
                                    availableStartTime={availableStartTime}
                                    setAvailableStartTime={setAvailableStartTime}
                                    availableEndDate={availableEndDate}
                                    setAvailableEndDate={setAvailableEndDate}
                                    availableEndTime={availableEndTime}
                                    setAvailableEndTime={setAvailableEndTime}
                                    hourOptions={hourOptions}
                                    handleSearch={handleSearch}
                                    // filtre
                                    minMileage={minMileage}
                                    setminMileage={setminMileage}
                                    maxMileage={maxMileage}
                                    setmaxMileage={setmaxMileage}
                                    // Passagers
                                    minPassenger={minPassenger}
                                    setminPassenger={setminPassenger}
                                    maxPassenger={maxPassenger}
                                    setmaxPassenger={setmaxPassenger}
                                    // Année de construction
                                    minYearBuild={minMileage}
                                    setminYearBuild={setminMileage}
                                    maxYearBuild={maxYearBuild}
                                    setmaxYearBuild={setmaxYearBuild}
                                    // marques du véhicule
                                    vehiclebrand={vehiclebrand}
                                    setvehiclebrand={setvehiclebrand}
                                    // moteur de l'engin
                                    engineType={engineType}
                                    setengineType={setengineType}
                                    // Catgorie du véhicule
                                    interiorType={interiorType}
                                    setinteriorType={setinteriorType}
                                    // option du véhicule ou de la propriété
                                    optionsItem={optionsItem}
                                    setoptionsItem={setoptionsItem}
                                    model={model}
                                    setmodel={setmodel}
                                />
                            </Col>
                        )
                    }

                    {/* Liste des éléments (Véhicules ou Propriétés) */}
                    <Col sm={!isMobile ? 9 : 12} md={!isMobile ? 9 : 12} lg={!isMobile ? 9 : 12}>
                        <Row>
                            <Col md={12} className="mb-3 mt-3">
                                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <div></div>
                                    <div>
                                        {
                                            isMobile && (
                                                <Button type="button" variant="primary" size="sm" onClick={() => { setShowOffCanvas(true) }}> <RiFilter3Line /> Recherche personnalisée</Button>
                                            )
                                        }
                                    </div>
                                </div>
                            </Col>
                            {currentItems.length > 0 ? (
                                currentItems.map((item) => (
                                    <Col sm={!isMobile ? 4 : 6} md={!isMobile ? 4 : 6} key={item._id} className="mb-4 ">

                                        <Card className="h-100 shadow-sm ">
                                            {item && item.images && item.images.length > 0 ? (
                                                <Card.Img variant="top" className="img-fluid rounded-lg" style={{ height: "200px", width: "100%", objectFit: "fill" }} src={item.images[0]} alt={item.name || item.brand} />
                                            ) : (
                                                <Card.Img variant="top" className="img-fluid rounded-lg" style={{ height: "200px", width: "100%", objectFit: "fill" }} src={emptyImage} alt={item.name || item.brand} />
                                            )}
                                            <Card.Body className=" icon-bx-wraper hover-overlay-effect">
                                                <Card.Title className="text-start ">
                                                    <h6 className="line-clamp-2 text-start text-truncate">{item?.brand || item?.name}</h6>
                                                </Card.Title>
                                                <Card.Text className="text-truncate ">
                                                    {item?.color ? (
                                                        <>
                                                            <strong>Couleur : </strong>{item.color}<br />
                                                            <strong>Prix : </strong>{item.basePricePerDay} DZD<br />
                                                        </>
                                                    ) : (
                                                        <div className="text-truncate">
                                                            <strong>Adresse : </strong><span className="text-truncate">{item.address}</span><br />
                                                            <strong>Prix : </strong>{item.basePricePerDay} DZD<br />
                                                        </div>
                                                    )}
                                                    <div className="effect bg-primary"></div>
                                                </Card.Text>
                                                <Button
                                                    variant="primary"
                                                    size="sm"
                                                    className="btn w-100 border-light justify-content-center"
                                                    onClick={() => {
                                                        if (item?.color) {
                                                            dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: item });
                                                            dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: {} });
                                                            navigate(`/${ROUTES.CAR_DETAIL_VIEW}/${item._id}/name/${item.brand}/description/${item.brand}`);
                                                        } else {
                                                            dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: {} });
                                                            dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: item });
                                                            navigate(`/${ROUTES.CAR_DETAIL_VIEW}/${item._id}/name/${item.name}/description/${item.name}`);
                                                        }
                                                    }}
                                                >
                                                    <div>{"Voir l'offre"}</div>
                                                </Button>

                                            </Card.Body>

                                        </Card>

                                    </Col>
                                ))
                            ) : (
                                <p className="text-center">Aucun résultat ne correspond aux critères de recherche.</p>
                            )}
                        </Row>

                        {/* Pagination */}
                        <Pagination className="justify-content-center">
                            {Array.from({ length: Math.ceil((filterType === "vehicle" ? filteredVehicles.length : filteredProperties.length) / itemsPerPage) }, (_, i) => (
                                <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => paginate(i + 1)}>
                                    {i + 1}
                                </Pagination.Item>
                            ))}
                        </Pagination>
                    </Col>
                </Row>






            </Container>

            {/* loading */}

            {/*Loading  */}
            <Modal
                show={loadingVehicles || loadingPropertys}
                size="sm"
                centered
                style={{
                    background: "transparent",
                    border: "none",
                    boxShadow: "none",
                }}
                backdrop="static"
                keyboard={false}
                contentClassName="bg-transparent border-0 shadow-none" // Supprime le fond du modal
            >
                <Modal.Body
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "transparent", // Supprime toute couleur de fond
                        border: "none",
                        boxShadow: "none",
                        padding: "0px", // Supprime tout padding qui pourrait ajouter un fond blanc
                    }}
                >
                    <Spinner
                        animation="border"
                        variant="primary"
                        style={{
                            width: "3rem",
                            height: "3rem",
                            zIndex: 9999, // Pour s'assurer qu'il est bien au-dessus
                            filter: "drop-shadow(0px 0px 5px rgba(0,0,0,0.3))" // Ajoute un léger contour pour visibilité
                        }}
                    />
                </Modal.Body>
            </Modal>





            {/*Canvas filtre de recherche en question */}
            <Offcanvas show={showOffCanvas} onHide={() => setShowOffCanvas(false)} placement="end" >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <RiFilter3Line /> <span> Recherche personnalisée</span>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ContainerFilter
                        filterType={filterType}
                        setFilterType={setFilterType}
                        pickupLocation={pickupLocation}
                        handlePickupLocationChange={handlePickupLocationChange}
                        pickupSuggestions={pickupSuggestions}
                        handlePickupSelection={handlePickupSelection}
                        minPrice={minPrice}
                        setMinPrice={setMinPrice}
                        maxPrice={maxPrice}
                        setMaxPrice={setMaxPrice}
                        availableStartDate={availableStartDate}
                        setAvailableStartDate={setAvailableStartDate}
                        availableStartTime={availableStartTime}
                        setAvailableStartTime={setAvailableStartTime}
                        availableEndDate={availableEndDate}
                        setAvailableEndDate={setAvailableEndDate}
                        availableEndTime={availableEndTime}
                        setAvailableEndTime={setAvailableEndTime}
                        hourOptions={hourOptions}
                        handleSearch={handleSearch}
                    />
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

export default CarUserPage;