import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Container, Button, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { VehicleCreate, VehicleUpdateById } from '../../../actions/request/VehicleRequest';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { fetchVehicleTypesAll } from '../../../actions/request/VehicleTypeRequest';
import axios from 'axios';
import { baseurl } from '../../../utils/url/baseurl';
import Select from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchVehicleBrandsAll } from '../../../actions/request/VehicleBrandRequest';
import { fetchMainteanceShedulesAll } from '../../../actions/request/MainteanceSheduleRequest';
import { fetchVehicleModelsAll } from '../../../actions/request/VehicleModelRequest';
import { MdAdd, MdClose, MdDeleteOutline, MdImage, MdLocationOn, MdMap, MdMoney, MdRemove } from 'react-icons/md';
import { toast } from 'react-toastify';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import LocationComponentMap from '../../../components/admin/reservations/LocationComponentMap';
import { fetchengineTypesAll } from '../../../actions/request/EngineTypeRequest';
import { fetchInteriorTypesAll } from '../../../actions/request/InteriorTypeRequest';
import { maxLengthLitteStart, maxLengthNumberLong } from '../../../utils/dataApi/dataFormApi';


function UpdateVehiclePage() {

    const navigate = useNavigate();

    const paramsvehicle = useParams();
    const dispatch = useDispatch();
    const vehicleTypesList = useSelector((state) => state.vehicleTypes.vehicleTypes);
    const vehiclebrandList = useSelector((state) => state.vehiclebrands.vehiclebrands);
    const maintenanceshedulesList = useSelector((state) => state.maintenanceShedules.maintenanceShedules);
    const vehiclemodels = useSelector((state) => state.vehiclemodels.vehiclemodels);
    const interiorTypes = useSelector((state) => state.interiorTypes.interiorTypes);
    const engineTypes = useSelector((state) => state.engineTypes.engineTypes);

    const [newVehicle, setNewVehicle] = useState({
        brand: '',
        model: '',
        basePricePerDay: '',
        passenger: 0,
        address: "",
        lat: null,
        lng: null,
        // commissionPercentage: '',
        deliveryFee: '',
        vehiclebrand: "",
        maintenanceshedule: "",
        year: '',
        color: '',
        registrationNumber: '',
        numberIdVehicle: '',
        mileage: '',
        owner: getAndCheckLocalStorage(localStorageKeys.userId),
        type: '',
        images: [],
        addBy: getAndCheckLocalStorage(localStorageKeys.userId),

        deliveryFee10: 0,
        deliveryFee50: 0,
        deliveryFee100: 0,
        deliveryFee150: 0,
        deliveryFee200: 0,

        engineType: "",
        interiorType: "",

        vignetteImpot: "",
        numbervignetteImpot:"",

        // ✅ **Ajout des options du véhicule**
        options: {
            gps: false,
            airConditioning: false,
            automaticTransmission: false,
            manualTransmission: false,
            turbo: false,
            sunroof: false,
            reverseCamera: false,
            speedRegulator: false,
            horsepower: 0 // Champ numérique pour les chevaux-vapeur (CV)
        },


    });






    const [typeOptions, setTypeOptions] = useState([]);
    const [colorOptions, setColorOptions] = useState([
        { value: 'Rouge', label: 'Rouge' },
        { value: 'Bleu', label: 'Bleu' },
        { value: 'Vert', label: 'Vert' },
        { value: 'Jaune', label: 'Jaune' },
        { value: 'Noir', label: 'Noir' },
        { value: 'Blanc', label: 'Blanc' },
        { value: 'Gris', label: 'Gris' },
        { value: 'Orange', label: 'Orange' },
        { value: 'Marron', label: 'Marron' },
        { value: 'Violet', label: 'Violet' },
        { value: 'Rose', label: 'Rose' },
    ]);

    const vehiclemodelsOptions = vehiclemodels && vehiclemodels.length > 0 ? vehiclemodels.map((vehhiclebrand) => ({
        label: vehhiclebrand.name,
        value: vehhiclebrand.name
    })) : []

    const vehiclebrandOptions = vehiclebrandList && vehiclebrandList.length > 0 ? vehiclebrandList.map((vehhiclebrand) => ({
        label: vehhiclebrand.name,
        value: vehhiclebrand.name
    })) : []

    const maintenancesheduleOptions = maintenanceshedulesList && maintenanceshedulesList.length > 0 ? maintenanceshedulesList.map((vehhiclebrand) => ({
        label: vehhiclebrand.name,
        value: vehhiclebrand.name
    })) : []

    const enginetypeOptions = engineTypes && engineTypes.length > 0 ? engineTypes.map((engine) => ({
        label: engine?.name,
        value: engine?._id
    })) : []

    const interiortypeOptions = interiorTypes && interiorTypes.length > 0 ? interiorTypes.map((interiortype) => ({
        label: interiortype?.name,
        value: interiortype?._id
    })) : []





    const [errors, setErrors] = useState({});
    const [uploading, setUploading] = useState(false);



    async function fetchVehicleGet() {
        setUploading(true);
        await axios.get(`${baseurl.url}/api/v1/vehicles/get_vehicle/${paramsvehicle.id || ""}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.typeToken} ${baseurl.token}`
            }
        }).then((response) => {
            // console.log(response.data.data);
            const reponseData = response.data.data;
            setNewVehicle(
                {
                    type: reponseData.type._id,
                    brand: reponseData.brand,
                    model: reponseData.model,
                    basePricePerDay: reponseData.basePricePerDay,
                    commissionPercentage: reponseData.commissionPercentage,
                    deliveryFee: reponseData.deliveryFee,

                    vehiclebrand: reponseData.vehiclebrand,
                    maintenanceshedule: reponseData.maintenanceshedule,

                    year: reponseData.year,
                    color: reponseData.color,
                    registrationNumber: reponseData.registrationNumber,
                    numberIdVehicle: reponseData.numberIdVehicle,
                    mileage: reponseData.mileage,
                    owner: reponseData.owner,
                    images: reponseData.images,
                    addBy: reponseData.addBy,
                    isVisible: reponseData.isVisible,
                    address: reponseData.address,
                    passenger: reponseData.passenger,

                    deliveryFee10: reponseData.deliveryFee10,
                    deliveryFee50: reponseData.deliveryFee50,

                    deliveryFee100: reponseData.deliveryFee100,

                    deliveryFee150: reponseData.deliveryFee150,
                    deliveryFee200: reponseData.deliveryFee200,
                    engineType: reponseData.engineType?._id,
                    interiorType: reponseData.interiorType?._id,

                    lat: reponseData.lat,
                    lng: reponseData.lng,

                    vignetteImpot: reponseData.vignetteImpot,
                    numbervignetteImpot:reponseData?.numbervignetteImpot,
                    // ✅ **Ajout des options du véhicule**
                    options: {
                        gps: reponseData?.options?.gps,
                        airConditioning: reponseData?.options?.airConditioning,
                        automaticTransmission: reponseData?.options?.automaticTransmission,
                        manualTransmission: reponseData?.options?.manualTransmission,
                        turbo: reponseData?.options?.turbo,
                        sunroof: reponseData?.options?.sunroof,
                        reverseCamera: reponseData?.options?.reverseCamera,
                        speedRegulator: reponseData?.options?.speedRegulator,
                        horsepower: reponseData?.options?.horsepower||0 // Champ numérique pour les chevaux-vapeur (CV)
                    },


                }
            );
            setPickupLocation(reponseData.address);

            setUploading(false);
            // setNewVehicle(response.data.data);
            // dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                toast.error("Véhicule introuvable", { position: "bottom-right" })
                // console.log(error);
                setUploading(false);
            });
    }



    // Gestion des types de véhicules
    useEffect(() => {
        dispatch(fetchVehicleTypesAll());
        dispatch(fetchVehicleBrandsAll());
        dispatch(fetchMainteanceShedulesAll());
        dispatch(fetchVehicleModelsAll());
        dispatch(fetchengineTypesAll())
        dispatch(fetchInteriorTypesAll())
        // get vehicle by id 
        fetchVehicleGet();


    }, [dispatch]);

    useEffect(() => {
        if (vehicleTypesList && vehicleTypesList.length > 0) {
            setTypeOptions(vehicleTypesList.map((type) => ({
                label: type.name,
                value: type._id
            })));
        }
    }, [vehicleTypesList]);

    // Fonction de téléchargement des images vers Cloudinary
    const handleImageChange = async (e) => {
        const files = Array.from(e.target.files);
        const uploadPromises = files.map(file => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', baseurl.cloudinaryUploadPreset);

            return axios.post(`https://api.cloudinary.com/v1_1/${baseurl.cloudinaryCloudName}/image/upload`, formData)
                .then(response => {
                    toast.success('Image téléchargée avec succès', { position: "bottom-right" });
                    return response.data.secure_url;
                })
                .catch(error => {
                    toast.error('Erreur lors du téléchargement de l\'image', { position: "bottom-right" });
                    console.error('Erreur lors du téléchargement de l\'image :', error);
                    return null;
                });
        });

        const uploadedImageUrls = await Promise.all(uploadPromises);
        setNewVehicle(prevVehicle => ({
            ...prevVehicle,
            images: [...prevVehicle.images, ...uploadedImageUrls.filter(url => url !== null)]
        }));

        // console.log("URLs des images téléchargées : ", uploadedImageUrls);
    };

    // Validation du formulaire
    const validateForm = () => {
        let formErrors = {};
        if (!newVehicle.brand) formErrors.brand = "le Nom ou la designation est requise";
        if (!newVehicle.model) formErrors.model = "Le modèle est requis";
        if (!newVehicle.vehiclebrand) formErrors.vehiclebrand = "La marque est requise";
        if (!newVehicle.registrationNumber) formErrors.registrationNumber = "Le numéro d'immatriculation est requis";
        if (!newVehicle.year) formErrors.year = "L'année est requise";
        if (!newVehicle.mileage) formErrors.mileage = "Le kilométrage est requis";
        if (!newVehicle.color) formErrors.color = "La couleur est requise";
        if (!newVehicle.type) formErrors.type = "Le type de véhicule est requis";
        if (!newVehicle.basePricePerDay) formErrors.basePricePerDay = "Le prix journalier est requis";
        if (!newVehicle.address) formErrors.address = "Addresse est requise";
        if (!newVehicle.passenger) formErrors.address = "Nombre de passager est requis ";
        if (!newVehicle.interiorType) formErrors.interiorType = "Type d'intérieure est requis ";
        if (!newVehicle.engineType) formErrors.engineType = "Type de moteur est requis ";
        // if (!newVehicle.deliveryFee10) formErrors.deliveryFee10 = "Frais de livraison au dela de 10 Km ";
        // if (!newVehicle.deliveryFee50) formErrors.deliveryFee50 = "Frais de livraison au dela de 50 Km ";
        // if (!newVehicle.deliveryFee100) formErrors.deliveryFee100 = "Frais de livraison au dela de 100 Km ";
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    // Fonction de traitement d'ajout de véhicule
    const handleAddVehicle = async () => {
        if (validateForm()) {

            try {
                setUploading(true);
                // console.log(newVehicle)
                dispatch(VehicleUpdateById(paramsvehicle.id || "", getAndCheckLocalStorage(localStorageKeys.userId), newVehicle.type || "", newVehicle, navigate, toast));
            } catch (error) {
                console.error("Erreur lors de l'ajout du véhicule:", error);
            } finally {
                setUploading(false);
            }
        }
    };



    // Ajouter dynamiquement un palier de kilométrage
    const [nextKilometer, setNextKilometer] = useState(110); // Prochain palier à ajouter


    // Ajouter de commande en question
    const addKilometerFee = () => {
        if (nextKilometer <= 200) {  // ✅ Bloquer au-delà de 200 km
            setNewVehicle((prev) => ({
                ...prev,
                [`deliveryFee${nextKilometer}`]: 0, // Ajoute un nouveau palier avec une valeur initiale de 0
            }));
            setNextKilometer(prev => prev + 10); // Incrémente de 10 km pour le prochain ajout
        } else {
            toast.error("Limite de kilométrage atteinte", { position: "bottom-right" })
        }
    };

    // Fonction pour gérer les changements dans les champs de type nombre et empêcher les valeurs négatives
    const handleNumberInputChange = (e, field) => {
        let value = e.target.value;
    
        // Vérifie si la valeur est bien un nombre positif, ne commence pas par plusieurs zéros et n'est pas vide
        if (!/^(0|[1-9]\d*)?$/.test(value)) {
            return; // 🔴 Ignore la saisie si ce n'est pas un nombre valide
        }
    
        // Convertit la valeur en nombre
        value = parseFloat(value);
    
        // Remplace les valeurs invalides ou supérieures à 200000 par 0 ou ajuste à la limite maximale
        if (isNaN(value) || value < 0) {
            value = ''; // ✅ Remplace les valeurs négatives ou invalides par 0
        } else if (value > maxLengthNumberLong) {
            value = maxLengthNumberLong; // ✅ Limite la valeur maximale à 200000
            toast.error(`Limite la valeur maximale à ${maxLengthNumberLong}`,{position:"bottom-right"})
        }
    
        // Gère les champs imbriqués (ex: options.horsepower)
        if (field.includes(".")) {
            const [parent, child] = field.split(".");
            setNewVehicle(prevState => ({
                ...prevState,
                [parent]: {
                    ...prevState[parent],
                    [child]: value
                }
            }));
        } else {
            // Gère les champs normaux (ex: mileage)
            setNewVehicle(prevState => ({
                ...prevState,
                [field]: value
            }));
        }
    };
    

    const allowedDistances = ["10", "50", "100", "150", "200"]; // Liste des distances autorisées
    // Génération dynamique des champs de frais de livraison
    const deliveryFeeKeys = Object.keys(newVehicle).filter(key => key.startsWith('deliveryFee'));




    const [pickupLocation, setPickupLocation] = useState('');
    const [pickupSuggestions, setPickupSuggestions] = useState([]);

    // Obtenir les suggestions de lieu de départ
    const getPlacesByCountryStartLocation = async (query) => {
        try {
            const response = await axios.get(`${baseurl.url}/api/v1/places`, {
                params: { query: query },
            });
            const places = response.data.results;
            return places.map(place => place);
        } catch (error) {
            console.error("Erreur lors de la récupération des lieux : ", error);
        }
    };
    const handlePickupLocationChange = async (e) => {
        const value = e.target.value;
        setPickupLocation(value);
        if (value.length > 2) {
            const suggestions = await getPlacesByCountryStartLocation(value);
            setPickupSuggestions(suggestions);
        } else {
            setPickupSuggestions([]); // Réinitialiser la liste des suggestions
        }
    };
    const handlePickupSelection = (suggestion) => {
        // Met à jour le pickupLocation
        setPickupLocation(`${suggestion.name} - ${suggestion.formatted_address}`);
        // Met à jour newVehicle en une seule fois avec toutes les nouvelles valeurs
        setNewVehicle(prevState => ({
            ...prevState,
            address: `${suggestion.name} - ${suggestion.formatted_address}`,
            lat: suggestion?.geometry?.location?.lat,
            lng: suggestion?.geometry?.location?.lng
        }));

        // Vérifie les valeurs dans suggestion
        // console.log(suggestion?.geometry?.location?.lat); // Affiche la latitude
        // console.log(suggestion?.geometry?.location?.lng); // Affiche la longitude
        // console.log(`${suggestion.name} - ${suggestion.formatted_address}`); // Affiche l'adresse complète

        // Vide les suggestions après la sélection
        setPickupSuggestions([]);
    };

    const handleCleanSugestions = () => {
        setPickupSuggestions([]);
    }





    return (
        <div className="mb-5" onClick={handleCleanSugestions}>
            <Form>
                <Card>
                    <Card.Header>
                        <h3 className="mb-4">Modifier cet vehicule</h3>
                    </Card.Header>
                    <Card.Body>

                        <Row>
                            <Col md={4}>
                                <Form.Group controlId="formVehicleBrand">
                                    <Form.Label>Nom/désignation</Form.Label>
                                    <Form.Control style={{ height: "40px" }}
                                        type="text"
                                        placeholder=""
                                        value={newVehicle.brand}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value.length <= maxLengthLitteStart) { // Limite à 50 caractères
                                                setNewVehicle({ ...newVehicle, brand: value });
                                            } else {
                                                // console.warn("Le champ ne peut pas dépasser 50 caractères.");
                                            }
                                        }}
                                        isInvalid={!!errors.brand}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.brand}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="formVehicleType">
                                    <Form.Label>Marque</Form.Label>
                                    <Select isClearable
                                        required
                                        options={vehiclebrandOptions}
                                        value={vehiclebrandOptions.find(option => option.value === newVehicle.vehiclebrand)}
                                        onChange={(selectedOption) => setNewVehicle({ ...newVehicle, vehiclebrand: selectedOption ? selectedOption.value : '' })}
                                        placeholder=""
                                    />
                                    {errors.vehiclebrand && <div className="invalid-feedback d-block">{errors.vehiclebrand}</div>}
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Form.Group controlId="formVehicleType">
                                    <Form.Label>Modèle</Form.Label>
                                    <Form.Control style={{ height: "40px" }}
                                        type="text"
                                        placeholder=""
                                        value={newVehicle.model}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value.length <= maxLengthLitteStart) { // Limite à 50 caractères
                                                setNewVehicle({ ...newVehicle, model: value });
                                            } else {
                                                // console.warn("Le champ ne peut pas dépasser 50 caractères.");
                                            }
                                        }}
                                        isInvalid={!!errors.model}
                                    />
                                    {errors.type && <div className="invalid-feedback d-block">{errors.model}</div>}
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Form.Group controlId="formVehicleType">
                                    <Form.Label>Calendrier maintenance</Form.Label>
                                    <Select isClearable
                                        options={maintenancesheduleOptions}
                                        value={maintenancesheduleOptions.find(option => option.value === newVehicle.maintenanceshedule)}
                                        onChange={(selectedOption) => setNewVehicle({ ...newVehicle, maintenanceshedule: selectedOption ? selectedOption.value : '' })}
                                        placeholder=""
                                    />
                                    {errors.type && <div className="invalid-feedback d-block">{errors.maintenanceshedule}</div>}
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Form.Group controlId="formVehicleColor">
                                    <Form.Label>Couleur</Form.Label>
                                    <Select isClearable
                                        options={colorOptions}
                                        value={colorOptions.find(option => option.value === newVehicle.color)}
                                        onChange={(selectedOption) => setNewVehicle({ ...newVehicle, color: selectedOption ? selectedOption.value : '' })}
                                        placeholder=""
                                    />
                                    {errors.color && <div className="invalid-feedback d-block">{errors.color}</div>}
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Form.Group controlId="formVehicleType">
                                    <Form.Label>Type de véhicule</Form.Label>
                                    <Select isClearable
                                        options={typeOptions}
                                        value={typeOptions.find(option => option.value === newVehicle.type)}
                                        onChange={(selectedOption) => setNewVehicle({ ...newVehicle, type: selectedOption ? selectedOption.value : '' })}
                                        placeholder=""
                                    />
                                    {errors.type && <div className="invalid-feedback d-block">{errors.type}</div>}
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="formVehicleType">
                                    <Form.Label>Type de moteur</Form.Label>
                                    <Select isClearable
                                        options={enginetypeOptions}
                                        value={enginetypeOptions.find(option => option.value === newVehicle.engineType)}
                                        onChange={(selectedOption) => setNewVehicle({ ...newVehicle, engineType: selectedOption ? selectedOption.value : '' })}
                                        placeholder=""
                                    />
                                    {errors.engineType && <div className="invalid-feedback d-block">{errors.engineType}</div>}
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="formVehicleType">
                                    <Form.Label>Type d{"'"}interieure</Form.Label>
                                    <Select isClearable
                                        options={interiortypeOptions}
                                        value={interiortypeOptions.find(option => option.value === newVehicle.interiorType)}
                                        onChange={(selectedOption) => setNewVehicle({ ...newVehicle, interiorType: selectedOption ? selectedOption.value : '' })}
                                        placeholder=""
                                    />
                                    {errors.interiorType && <div className="invalid-feedback d-block">{errors.interiorType}</div>}
                                </Form.Group>
                            </Col>

                            <Col md={3}>
                                <Form.Group controlId="formVehicleYear">
                                    <Form.Label>Année</Form.Label>
                                    <Form.Control
                                        as="select"
                                        style={{ height: "40px" }}
                                        value={newVehicle.year}
                                        onChange={(e) => handleNumberInputChange(e, "year")}
                                    >
                                        <option value="">Sélectionnez l{"'"}année</option>
                                        {Array.from({ length: new Date().getFullYear() - 1900 + 1 }, (_, i) => (
                                            <option key={1900 + i} value={1900 + i}>
                                                {1900 + i}
                                            </option>
                                        ))}
                                    </Form.Control>
                                    {errors.year && <div className="invalid-feedback d-block">{errors.year}</div>}
                                </Form.Group>
                            </Col>


                            <Col md={3}>
                                <Form.Group controlId="formVehicleRegistrationNumber">
                                    <Form.Label>N° d{"'"}immatriculation</Form.Label>
                                    <Form.Control style={{ height: "40px" }}
                                        type="text"
                                        placeholder=""
                                        value={newVehicle.registrationNumber}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value.length <= maxLengthLitteStart) { // Limite à 50 caractères
                                                setNewVehicle({ ...newVehicle, registrationNumber: value });
                                            } else {
                                                // console.warn("Le champ ne peut pas dépasser 50 caractères.");
                                            }
                                        }}
                                        isInvalid={!!errors.registrationNumber}
                                    />
                                    {errors.registrationNumber && <div className="invalid-feedback d-block">{errors.registrationNumber}</div>}
                                </Form.Group>
                            </Col>

                            <Col md={3}>
                                <Form.Group controlId="formVehicleRegistrationNumber">
                                    <Form.Label>N° d{"'"}Identification ( VIN )</Form.Label>
                                    <Form.Control style={{ height: "40px" }}
                                        type="text"
                                        placeholder=""
                                        value={newVehicle.numberIdVehicle}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value.length <= maxLengthLitteStart) { // Limite à 50 caractères
                                                setNewVehicle({ ...newVehicle, numberIdVehicle: value });
                                            } else {
                                                // console.warn("Le champ ne peut pas dépasser 50 caractères.");
                                            }
                                        }}
                                        
                                        isInvalid={!!errors.numberIdVehicle}
                                    />
                                    {errors.numberIdVehicle && <div className="invalid-feedback d-block">{errors.numberIdVehicle}</div>}
                                </Form.Group>
                            </Col>

                            <Col md={3}>
                                <Form.Group controlId="formVehicleYear">
                                    <Form.Label>Vignette impôt (Année)</Form.Label>
                                    <Form.Control
                                        as="select"
                                        style={{ height: "40px" }}
                                        value={newVehicle.year}
                                        onChange={(e) => handleNumberInputChange(e, "year")}
                                    >
                                        <option value="">Sélectionnez l{"'"}année</option>
                                        {Array.from({ length: new Date().getFullYear() - 1900 + 1 }, (_, i) => (
                                            <option key={1900 + i} value={1900 + i}>
                                                {1900 + i}
                                            </option>
                                        ))}
                                    </Form.Control>
                                    {errors.year && <div className="invalid-feedback d-block">{errors.year}</div>}
                                </Form.Group>
                            </Col>
                            

                            <Col md={3}>
                                <Form.Group controlId="formVehicleRegistrationNumber">
                                    <Form.Label>N° de vignette </Form.Label>
                                    <Form.Control style={{ height: "40px" }}
                                        type="text"
                                        placeholder=""
                                        value={newVehicle.numbervignetteImpot}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value.length <= maxLengthLitteStart) { // Limite à 50 caractères
                                                setNewVehicle({ ...newVehicle, numbervignetteImpot: value });
                                            } else {
                                                // console.warn("Le champ ne peut pas dépasser 50 caractères.");
                                            }
                                        }}
                                    />
                                </Form.Group>
                            </Col>


                            

                            {/* Addrese du véhicule */}
                            <Col md={3}>
                                <Form.Group controlId="formVehicleMileage">
                                    <Form.Label> Nombre de places </Form.Label>
                                    <Form.Control style={{ height: "40px" }}
                                        type="text"
                                        placeholder=""
                                        min={1}
                                        max={300}
                                        value={newVehicle.passenger}
                                        onChange={(e) => { handleNumberInputChange(e, "passenger") }}
                                    />
                                    {errors.passenger && <div className="invalid-feedback d-block">{errors.passenger}</div>}
                                </Form.Group>
                            </Col>




                            <Col md={3}>
                                <Form.Group controlId="formVehicleMileage">
                                    <Form.Label>Kilométrage </Form.Label>
                                    <Form.Control style={{ height: "40px" }}
                                        type="text"
                                        placeholder=""
                                        value={newVehicle.mileage}
                                        onChange={(e) => { handleNumberInputChange(e, "mileage") }}
                                    />
                                    {errors.mileage && <div className="invalid-feedback d-block">{errors.mileage}</div>}
                                </Form.Group>
                            </Col>

                            {/* 🔢 Nombre de CV (champ numérique) */}
                            <Col md={3}>
                            <Form.Group controlId="horsepower">
                                <Form.Label>Nombre de chevaux (CV)</Form.Label>
                                <Form.Control
                                    type="text"
                                    style={{ height: "40px" }}
                                    value={newVehicle.options.horsepower}
                                    onChange={(e) => {handleNumberInputChange(e,"options.horsepower")}}
                                />
                            </Form.Group>
                        </Col>









                            {/*
                            <Col md={4}>
                                <Form.Group controlId="formVehicleRegistrationNumber">
                                    <Form.Label>Commission ( % )</Form.Label>
                                    <Form.Control style={{ height: "40px" }}
                                        type="text"
                                        min={0}
                                        max={100}
                                        placeholder=""
                                        value={newVehicle.commissionPercentage}
                                        onChange={(e) => { handleNumberInputChange(e, "commissionPercentage") }}
                                        isInvalid={!!errors.commissionPercentage}
                                    />
                                </Form.Group>
                            </Col>
                        */}
                            <Col md={12} className="mt-5">
                                <h5><MdMap /> Localisation</h5>
                                <Row>
                                    <Col md={12}>
                                        <Form.Group controlId="formVehicleMileage">
                                            <Form.Label>Addresse </Form.Label>
                                            <Form.Control style={{ height: "40px" }}
                                                type="text"
                                                placeholder=""
                                                value={pickupLocation}
                                                onChange={(e) => { handlePickupLocationChange(e) }}
                                            />
                                            {errors.address && <div className="invalid-feedback d-block">{errors.address}</div>}
                                            {/* Liste déroulante des suggestions */}
                                            {pickupSuggestions && pickupSuggestions.length > 0 && (
                                                <ul className="rounded p-1 border" style={{ position: 'absolute', zIndex: 1000, backgroundColor: 'white', maxwidth: "500px", listStyleType: 'none', padding: 0, maxHeight: "200px", overflow: "auto" }}>
                                                    {pickupSuggestions.map((suggestion, index) => (
                                                        <li className="border"
                                                            key={index}
                                                            style={{ padding: '5px', cursor: 'pointer' }}
                                                            onClick={() => handlePickupSelection(suggestion)}
                                                        >
                                                            <MdLocationOn />  {`${suggestion.name} - ${suggestion.formatted_address}`}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    {
                                        newVehicle?.lat && newVehicle?.lng && (
                                            <Col md={12} className="my-3">
                                                <LocationComponentMap pickLocation={newVehicle?.pickLocation} latStart={newVehicle?.lat} lngStart={newVehicle?.lng} />
                                            </Col>
                                        )
                                    }
                                </Row>
                            </Col>


                            <Col md={12} className="mt-5 mb-5 border-b border-t">
                                <h5 className="my-2"><MdMoney /> Prix (DZD) </h5>
                                <Row>


                                    <Col md={12}>
                                        <Form.Group controlId="formVehicleRegistrationNumber">
                                            <Form.Label>Prix Journalier</Form.Label>
                                            <Form.Control style={{ height: "40px" }}
                                                type="text"
                                                min={1000}
                                                max={1000000}
                                                placeholder=""
                                                value={newVehicle.basePricePerDay}
                                                onChange={(e) => { handleNumberInputChange(e, "basePricePerDay") }}
                                                isInvalid={!!errors.basePricePerDay}
                                            />
                                            {errors.basePricePerDay && <div className="invalid-feedback d-block">{errors.basePricePerDay}</div>}
                                        </Form.Group>
                                    </Col>

                                    {/* Générer dynamiquement les champs des frais de livraison */}
                                    {deliveryFeeKeys
                                        .filter(key => {
                                            const kmValue = key.replace("deliveryFee", ""); // Extraire la valeur numérique
                                            return allowedDistances.includes(kmValue); // Vérifier si elle est dans la liste autorisée
                                        })
                                        .map((key) => {
                                            const kmValue = key.replace("deliveryFee", ""); // Extraire la valeur numérique

                                            return (
                                                <Col md={3} key={key}>
                                                    <Form.Group controlId={`form${key}`}>
                                                        <Form.Label>Frais au-delà de {kmValue} Km</Form.Label>
                                                        <Form.Control
                                                            style={{ height: "40px" }}
                                                            type="text"
                                                            min={0}
                                                            max={1000000}
                                                            value={newVehicle[key]}
                                                            onChange={(e) => handleNumberInputChange(e, key)}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            );
                                        })
                                    }

                                    {/* Bouton pour ajouter un nouveau palier (jusqu'à 200 Km max) */}
                                    {/*nextKilometer <= 200 && (
                                        <Col md={2} className="mt-3 text-center">
                                            <div>
                                                <Button
                                                    variant="primary"
                                                    size="sm"
                                                    onClick={addKilometerFee}
                                                    title="Ajout nouveau palier de kilométrage pour la réservation"
                                                >
                                                    <MdAdd /> Ajouter 
                                                </Button>
                                            </div>
                                        </Col>
                                    )*/}
                                </Row>
                            </Col>







                            <Col md={12}>
                                <h5 className="my-2"><MdImage /> Photos <span className="text-danger">* </span></h5>


                                <Form.Group controlId="formImages">
                                    <Form.Label>Photos de l{"'"}état initial</Form.Label>
                                    <Form.Control
                                        type="file"
                                        accept=".jpg,.jpeg,.png"
                                        multiple
                                        onChange={handleImageChange}
                                    />
                                    <div className="mt-2">
                                        {newVehicle.images.length > 0 && (
                                            <div>
                                                <h5>Images sélectionnées :</h5>
                                                <ul className="flex" style={{ display: "flex", flexWrap: "wrap" }}>
                                                    {newVehicle.images.map((image, index) => (
                                                        <li key={index} className="p-1">
                                                            <span className="text-danger text-xs"
                                                                onClick={() => {
                                                                    // On supprime l'image correspondant à l'index
                                                                    const updatedImages = newVehicle.images.filter((_, i) => i !== index);
                                                                    setNewVehicle({ ...newVehicle, images: updatedImages });
                                                                }}
                                                            >
                                                                <MdClose />
                                                            </span>
                                                            <div>
                                                                <img src={image} style={{ height: "50px", width: "50px" }} />
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </Form.Group>
                            </Col>

                            <Col md={12} className="mt-5">
                                <h5>Options supplémentaires</h5>
                                <Row>
                                    {/* ✅ GPS */}
                                    <Col md={3}>
                                        <Form.Group controlId="gps">
                                            <Form.Check
                                                type="checkbox"
                                                label="GPS"
                                                checked={newVehicle.options.gps}
                                                onChange={(e) => setNewVehicle({
                                                    ...newVehicle,
                                                    options: { ...newVehicle.options, gps: e.target.checked }
                                                })}
                                            />
                                        </Form.Group>
                                    </Col>

                                    {/* ✅ Climatisation */}
                                    <Col md={3}>
                                        <Form.Group controlId="airConditioning">
                                            <Form.Check
                                                type="checkbox"
                                                label="Climatisation"
                                                checked={newVehicle.options.airConditioning}
                                                onChange={(e) => setNewVehicle({
                                                    ...newVehicle,
                                                    options: { ...newVehicle.options, airConditioning: e.target.checked }
                                                })}
                                            />
                                        </Form.Group>
                                    </Col>

                                    {/* ✅ Transmission automatique */}
                                    <Col md={3}>
                                        <Form.Group controlId="automaticTransmission">
                                            <Form.Check
                                                type="checkbox"
                                                label="Transmission automatique"
                                                checked={newVehicle.options.automaticTransmission}
                                                onChange={(e) => setNewVehicle({
                                                    ...newVehicle,
                                                    options: { ...newVehicle.options, automaticTransmission: e.target.checked,manualTransmission:false }
                                                })}
                                            />
                                        </Form.Group>
                                    </Col>

                                    {/* ✅ Transmission manuelle */}
                                    <Col md={3}>
                                        <Form.Group controlId="manualTransmission">
                                            <Form.Check
                                                type="checkbox"
                                                label="Transmission manuelle"
                                                checked={newVehicle.options.manualTransmission}
                                                onChange={(e) => setNewVehicle({
                                                    ...newVehicle,
                                                    options: { ...newVehicle.options, manualTransmission: e.target.checked,automaticTransmission:false }
                                                })}
                                            />
                                        </Form.Group>
                                    </Col>

                                    {/* ✅ Turbo */}
                                    <Col md={3}>
                                        <Form.Group controlId="turbo">
                                            <Form.Check
                                                type="checkbox"
                                                label="Turbo"
                                                checked={newVehicle.options.turbo}
                                                onChange={(e) => setNewVehicle({
                                                    ...newVehicle,
                                                    options: { ...newVehicle.options, turbo: e.target.checked }
                                                })}
                                            />
                                        </Form.Group>
                                    </Col>

                                    {/* ✅ Toit ouvrant */}
                                    <Col md={3}>
                                        <Form.Group controlId="sunroof">
                                            <Form.Check
                                                type="checkbox"
                                                label="Toit ouvrant"
                                                checked={newVehicle.options.sunroof}
                                                onChange={(e) => setNewVehicle({
                                                    ...newVehicle,
                                                    options: { ...newVehicle.options, sunroof: e.target.checked }
                                                })}
                                            />
                                        </Form.Group>
                                    </Col>

                                    {/* ✅ Caméra de recul */}
                                    <Col md={3}>
                                        <Form.Group controlId="reverseCamera">
                                            <Form.Check
                                                type="checkbox"
                                                label="Caméra de recul"
                                                checked={newVehicle.options.reverseCamera}
                                                onChange={(e) => setNewVehicle({
                                                    ...newVehicle,
                                                    options: { ...newVehicle.options, reverseCamera: e.target.checked }
                                                })}
                                            />
                                        </Form.Group>
                                    </Col>

                                    {/* ✅ Régulateur de vitesse */}
                                    <Col md={3}>
                                        <Form.Group controlId="speedRegulator">
                                            <Form.Check
                                                type="checkbox"
                                                label="Régulateur de vitesse"
                                                checked={newVehicle.options.speedRegulator}
                                                onChange={(e) => setNewVehicle({
                                                    ...newVehicle,
                                                    options: { ...newVehicle.options, speedRegulator: e.target.checked }
                                                })}
                                            />
                                        </Form.Group>
                                    </Col>

                                    
                                </Row>
                            </Col>
                            <div className="col-md-12 " style={{ display: "flex", alignItems: "center" }}>
                                <input style={{ height: "30px", width: "30px" }} className="p-2"
                                    type="checkbox"
                                    name="isVisible" title="Afficher sur la plateforme"
                                    checked={newVehicle.isVisible}
                                    onChange={(e) => { setNewVehicle({ ...newVehicle, isVisible: e.target.checked }); }}
                                />
                                <label className="p-2"> Mise en ligne sur la plateforme </label>

                            </div>

                            
                        </Row>

                        <div className="" style={{ display: "flex", justifyContent: "start" }}>
                            {
                                uploading ?
                                    <p>Chargement ....</p>
                                    :
                                    <Button
                                        className="mt-5"
                                        variant="primary"
                                        onClick={handleAddVehicle}
                                        size="sm"
                                        disabled={uploading}
                                    >
                                        {uploading ? "Téléchargement..." : "Mise a jour"}
                                    </Button>
                            }
                        </div>
                    </Card.Body>
                    <Card.Footer>


                    </Card.Footer>
                </Card>




            </Form>
        </div>
    );
}

export default UpdateVehiclePage;
