import React, { useState } from 'react';
import { Tab, Tabs, Form, Button, Image, Spinner, Card, Badge } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCodePostalsAll } from '../../../actions/request/CodePostalRequest';
import { useEffect } from 'react';
import { UserCreate } from '../../../actions/request/UserRequest';
import { toast } from 'react-toastify';
import Select from "react-select";
import ProfileAccount from './ProfileAccount';
import { MdPersonOutline, MdSettings } from 'react-icons/md';
import ProfilePassword from './ProfilePassword';
import ProfileReservation from './ProfileReservation';
import { profileRoleType } from '../../../utils/dataApi/dataFormApi';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import SettingsSignature from './SettingsSignature';


function ProfileUserPage() {
    const [key, setKey] = useState('profile');
    const dispatch = useDispatch();

    // const loadingUser = useSelector((state) => state.users.loadingUser);
    // const codepostals = useSelector((state) => state.codepostals.codepostals);

    // const [formData, setFormData] = useState({
    //     firstname: '',
    //     lastname: '',
    //     companyName: '',
    //     email: '',
    //     phone: '',
    //     codePostal: '',
    //     gender: 'Male',
    //     dateOfBirth: '',
    //     profilePicture: '',
    //     profession: '',
    //     address: '',
    //     addressPostal: '',
    //     numberTVA: '',
    //     role: 'SUPER_ADMIN',
    //     description: '',
    //     location: '',
    // });

    useEffect(() => {
        // Fetch codePostals and companies from API
        dispatch(fetchCodePostalsAll());
    }, [dispatch]);

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({
    //         ...formData,
    //         [name]: value,
    //     });
    // };

    // const handleSelectChange = (selectedOption, { name }) => {
    //     setFormData({
    //         ...formData,
    //         [name]: selectedOption ? selectedOption.value : '',
    //     });
    // };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     // Submit logic here
    //     dispatch(UserCreate(formData, toast))
    // };

    // // Prepare options for react-select
    // const codePostalOptions = codepostals.map((code) => ({
    //     value: code._id,
    //     label: `${code.indicatif} - ${code.country}`,
    // }));

    // const genderOptions = [
    //     { value: 'Male', label: 'Homme' },
    //     { value: 'Female', label: 'Femme' },
    // ];

    // const roleOptions = [
    //     { value: 'INDIVIDUAL', label: 'Individu' },
    //     { value: 'COMPANY', label: 'Entreprise' },
    //     { value: 'ADMIN', label: 'Administrateur' },
    //     { value: 'SUPER_ADMIN', label: 'Super Administrateur' },
    // ];

    return (
        <div className="m-b40">
            <Card>
                <Card.Header> <h3 className="mb-4"><MdSettings /> Paramètre {
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER && (
                        <Badge bg="secondary">Agence </Badge>
                    )
                }
                {
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.CUSTOMER && (
                        <Badge bg="secondary">Client</Badge>
                    )
                }
                {
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN && (
                        <Badge bg="secondary">Super Administrateur</Badge>
                    )
                }
                {
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN && (
                        <Badge bg="secondary">Administrateur</Badge>
                    )
                }
                {
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT && (
                        <Badge bg="secondary">Suport</Badge>
                    )
                } </h3> </Card.Header>
                <Card.Body>


                    <Tabs
                        id="profile-tabs"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                    >


                        <Tab eventKey="profile" title="Information sur votre compte">
                            <ProfileAccount />
                        </Tab>

                        {/*
                            getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER && (
                                <Tab eventKey="reservations" title="Réservation">
                                    <ProfileReservation />
                                </Tab>
                            )
                        */}

                        {
                            getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER && (
                                <Tab eventKey="signature" title="Signature électronique">
                                   <SettingsSignature/>
                                </Tab>
                            )
                        }

                        <Tab eventKey="change-password" title="Changer le Mot de Passe">
                            <ProfilePassword />
                        </Tab>

                    </Tabs>


                </Card.Body>
            </Card>



        </div>
    );
}

export default ProfileUserPage;
