import { FETCH_MAINTENANCES_FAILURE, FETCH_MAINTENANCES_REQUEST, FETCH_MAINTENANCES_SUCCESS, FETCH_MAINTENANCES_SUCCESS_2, FETCH_MAINTENANCE_FAILURE, FETCH_MAINTENANCE_REQUEST, FETCH_MAINTENANCE_SUCCESS } from "../actions/actions";



// maintenanceReducer.js
const initialState = {
    maintenances: [],
    maintenances2: [],
    maintenance: {},
    maintenanceSelect: {},
    loadingMaintenance:false,
    loadingMaintenanceSelect:false,
    loadingMaintenances:false,
    errorMaintenances:null,
    errorMaintenance:null
    // ... autres états spécifiques à maintenanceReducer
  };
  
  const maintenanceReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_MAINTENANCE_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingMaintenance:true,
          errorMaintenances:""
          // Autres manipulations d'état pour la demande de récupération des maintenances
        };
        case FETCH_MAINTENANCE_SUCCESS:
        return {
          ...state,
          loadingMaintenance:false,
          errorMaintenances:"",
          maintenance:action.payload
        };
        case FETCH_MAINTENANCE_FAILURE:
        return {
          ...state,
          loadingMaintenance:false,
          errorMaintenances:action.payload
        };


      case FETCH_MAINTENANCES_REQUEST:
        return {
          ...state,
          loadingMaintenances:true,
          errorMaintenances:""
        };
       
      case FETCH_MAINTENANCES_SUCCESS:
        return {
          ...state,
          maintenances: action.payload,
          loadingMaintenances:false,
          errorMaintenances:""
          // Autres manipulations d'état pour le succès de la récupération des maintenances
        };
        case FETCH_MAINTENANCES_SUCCESS_2:
        return {
          ...state,
          maintenances2: action.payload,
          loadingMaintenances:false,
          errorMaintenances:""
          // Autres manipulations d'état pour le succès de la récupération des maintenances
        };
        
      case FETCH_MAINTENANCES_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingMaintenances:false,
          errorMaintenances:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des maintenances
        };
      // ... autres cas pour d'autres actions liées aux maintenances

        
      default:
        return state;
    }
  };
  
  export default maintenanceReducer;
  