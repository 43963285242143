import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Modal, Button, Nav, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap';
import { FaRegUserCircle } from 'react-icons/fa';
import { MdExitToApp } from 'react-icons/md';
import { toast } from 'react-toastify';
import { ROUTES } from '../../config/routingUrl';
import { getAndCheckLocalStorage, handleClearLocalStorage } from '../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../utils/storage/localvalue';
import { logoApplication } from '../../utils/dataApi/dataFormApi';
import "./HeaderWeb.css";

const HeaderWeb = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [modalDisconnect, setModalDisconnect] = useState(false);
    const [showOffCanvas, setShowOffCanvas] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);

    // Vérification de la largeur de l'écran pour détecter le mode mobile
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 992);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleShowModalDisconnect = () => setModalDisconnect(true);
    const handleCloseModalDisconnect = () => setModalDisconnect(false);

    const handleDisconnect = () => {
        handleClearLocalStorage();
        window.localStorage.removeItem(localStorageKeys.userId);
        window.location.href = "/";
        toast.success('Déconnexion réussie !');
    };

    return (
        <header className="site-header">
            <Navbar expand="lg" className="bg-white">
                <div className="container">
                    <Link to="/" className="navbar-brand">
                        <img src={logoApplication} style={{ maxHeight: "50px", height: "50px" }} alt="Logo" />
                    </Link>

                    {/* Bouton pour ouvrir l'OffCanvas sur mobile */}
                    {isMobile && (
                        <Button variant="primary" size="sm" onClick={() => setShowOffCanvas(true)}>
                            ☰
                        </Button>
                    )}

                    {!isMobile && (
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link as={Link} to="/" className={location.pathname === '/' ? 'active' : ''}>Accueil</Nav.Link>
                                <Nav.Link as={Link} to={`/${ROUTES.CAR_LIST_WEB}`} className={location.pathname === `/${ROUTES.CAR_LIST_WEB}` ? 'active' : ''}>Rechercher offre de réservation</Nav.Link>
                                <Nav.Link as={Link} to={`/${ROUTES.ABOUTUS}`} className={location.pathname === `/${ROUTES.ABOUTUS}` ? 'active' : ''}>En savoir plus </Nav.Link>
                                <Nav.Link as={Link} to={`/${ROUTES.CONTACT}`} className={location.pathname === `/${ROUTES.CONTACT}` ? 'active' : ''}>Contact </Nav.Link>

                                {/* Account Dropdown */}
                                <NavDropdown title={<><FaRegUserCircle size={20} /> Compte</>} id="account-dropdown">
                                    {!getAndCheckLocalStorage(localStorageKeys.userId) ? (
                                        <>
                                            <NavDropdown.Item as={Link} to={`/${ROUTES.LOGIN}`} onClick={() => setShowOffCanvas(false)}>Connexion</NavDropdown.Item>
                                        </>
                                    ) : (
                                        <>
                                            <NavDropdown.Item as={Link} to={`/${ROUTES.DASHBOARD}`}>Administration</NavDropdown.Item>
                                            <NavDropdown.Item as="button" onClick={handleShowModalDisconnect}>
                                                <MdExitToApp /> Déconnexion
                                            </NavDropdown.Item>
                                        </>
                                    )}
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    )}
                </div>
            </Navbar>

            {/* OffCanvas pour mobile */}
            <Offcanvas show={showOffCanvas} onHide={() => setShowOffCanvas(false)} placement="start">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <Link to="/" className="navbar-brand">
                            <img src={logoApplication} style={{ maxHeight: "50px", height: "50px" }} alt="Logo" />
                        </Link>

                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className="flex-column">
                        <Nav.Link as={Link} to="/" onClick={() => setShowOffCanvas(false)}>Accueil</Nav.Link>
                        <Nav.Link as={Link} to={`/${ROUTES.CAR_LIST_WEB}`} onClick={() => setShowOffCanvas(false)}>Rechercher offre de réservation</Nav.Link>
                        <Nav.Link as={Link} to={`/${ROUTES.ABOUTUS}`} onClick={() => setShowOffCanvas(false)}>En savoir plus</Nav.Link>
                        <Nav.Link as={Link} to={`/${ROUTES.CONTACT}`} onClick={() => setShowOffCanvas(false)}>Contact</Nav.Link>

                        {/* Account Dropdown dans l'OffCanvas */}
                        <NavDropdown title="Compte" id="account-dropdown">
                            {!getAndCheckLocalStorage(localStorageKeys.userId) ? (
                                <>
                                    <NavDropdown.Item as={Link} to={`/${ROUTES.LOGIN}`} onClick={() => setShowOffCanvas(false)}>Connexion</NavDropdown.Item>
                                </>
                            ) : (
                                <>
                                    <NavDropdown.Item as={Link} to={`/${ROUTES.DASHBOARD}`} onClick={() => setShowOffCanvas(false)}>Administration</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATIONS_REQUEST_LIST}`} onClick={() => setShowOffCanvas(false)}>Réservations</NavDropdown.Item>
                                    <NavDropdown.Item as="button" onClick={handleShowModalDisconnect}>
                                        <MdExitToApp /> Déconnexion
                                    </NavDropdown.Item>
                                </>
                            )}
                        </NavDropdown>
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>

            {/* Modal de confirmation de déconnexion */}
            <Modal show={modalDisconnect} onHide={handleCloseModalDisconnect} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Déconnexion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Voulez-vous vous déconnecter ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModalDisconnect}>Annuler</Button>
                    <Button variant="primary" onClick={() => { handleCloseModalDisconnect(); handleDisconnect(); }}>Se Déconnecter</Button>
                </Modal.Footer>
            </Modal>
        </header>
    );
};

export default HeaderWeb;
