import axios from "axios";
import { FETCH_TERM_AND_CONDITIONS_FAILURE, FETCH_TERM_AND_CONDITIONS_REQUEST, FETCH_TERM_AND_CONDITIONS_SUCCESS, FETCH_TERM_AND_CONDITIONS_SUCCESS_2, FETCH_TERM_AND_CONDITION_FAILURE, FETCH_TERM_AND_CONDITION_REQUEST, FETCH_TERM_AND_CONDITION_SUCCESS } from "../../app/actions/actions";
import { ROUTES } from "../../config/routingUrl";
import { dureeDeVie, setWithExpiration } from "../../utils/storage/localvalueFuction";
import { localStorageData, localStorageKeys } from "../../utils/storage/localvalue";
import { baseurl } from "../../utils/url/baseurl";
import { saveDataToFile } from "../DataLocal";
import { toast } from "react-toastify";




// All termes dans contion
export function fetchTermAndConidtionsAll(termsId, userAdd) {
    return async (dispatch) => {

        const params = new URLSearchParams();

        // Ajouter des paramètres de date si disponibles
        if (termsId) params.append("termsId", termsId);
        if (userAdd) params.append("userAdd", userAdd);

        dispatch({ type: FETCH_TERM_AND_CONDITIONS_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/terms-and-conditions/get_terms-and-conditions?${params.toString()}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_TERM_AND_CONDITIONS_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_TERM_AND_CONDITIONS_SUCCESS_2, payload: response.data.data });
            // saveDataToFile(response.data.data, localStorageData.TermAndConidtions);
        }).catch((error) => {
            //console.log(error);
            dispatch({ type: FETCH_TERM_AND_CONDITIONS_FAILURE, payload: error.message });
        });
    }
}


// get insurance by id 
export function fetchTermAndConidtionById(termsId, userAdd) {
    return async (dispatch) => {

        const params = new URLSearchParams();

        // Ajouter des paramètres de date si disponibles
        if (termsId) params.append("termsId", termsId);
        if (userAdd) params.append("userAdd", userAdd);


        dispatch({ type: FETCH_TERM_AND_CONDITION_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/terms-and-conditions/get_terms-and-condition?${params.toString()}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_TERM_AND_CONDITION_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                dispatch({ type: FETCH_TERM_AND_CONDITION_FAILURE, payload: error.message })
                //console.log(error);
            });
    }
}


export function TermAndConidtionUpdateById(termsId,userAdd, data) {
    return async (dispatch) => {
        const params = new URLSearchParams();

        // Ajouter des paramètres de date si disponibles
        if (termsId) params.append("termsId", termsId);
        if (userAdd) params.append("userAdd", userAdd);

        dispatch({ type: FETCH_TERM_AND_CONDITION_REQUEST });
        await axios.put(`${baseurl.url}/api/v1/terms-and-conditions/edit?${params.toString()}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_TERM_AND_CONDITION_SUCCESS, payload: response.data.data });
            toast.success(response?.data?.message || "Mise à jour des politique effectué avec succès", { position: "bottom-right" })
        })
            .catch((error) => {
                toast.error(error?.response?.data?.message || "Mise à jour des politiques non effectué", { position: "bottom-right" })
                dispatch({ type: FETCH_TERM_AND_CONDITION_FAILURE, payload: error.message })
                //console.log(error);
            });
    }
}

