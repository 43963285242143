import axios from "axios";
import { FETCH_VEHICLE_TYPES_FAILURE, FETCH_VEHICLE_TYPES_REQUEST, FETCH_VEHICLE_TYPES_SUCCESS, FETCH_VEHICLE_TYPES_SUCCESS_2, FETCH_VEHICLE_TYPE_FAILURE, FETCH_VEHICLE_TYPE_REQUEST, FETCH_VEHICLE_TYPE_SUCCESS,  } from "../../app/actions/actions";
import { ROUTES } from "../../config/routingUrl";
import { dureeDeVie, setWithExpiration } from "../../utils/storage/localvalueFuction";
import { localStorageData, localStorageKeys } from "../../utils/storage/localvalue";
import { baseurl } from "../../utils/url/baseurl";
import { saveDataToFile } from "../DataLocal";
import { toast } from "react-toastify";



// Create VehicleType
export const VehicleTypeCreate = (
    data) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_VEHICLE_TYPE_REQUEST });
        await axios
            .post(
                `${baseurl.url}/api/v1/vehicle_types/create`,
                data,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                toast.success(response?.data?.message||"Type d'engin ajouté avec succès",{position:"bottom-right"});
                dispatch({ type: FETCH_VEHICLE_TYPE_SUCCESS });
            })
            .catch((error) => {
                toast.error("Type d'engin non ajouté")
                dispatch({ type: FETCH_VEHICLE_TYPE_FAILURE, payload: error.message });
            });
    };
}



// update insurance 
export const VehicleTypeUpdateById = (idVehicleType, data) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_VEHICLE_TYPE_REQUEST });
        await axios
            .put(`${baseurl.url}/api/v1/vehicle_types/update/${idVehicleType}`, data, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                dispatch({ type: FETCH_VEHICLE_TYPE_SUCCESS });
                toast.success(response?.data?.message||"Type d'engin mis à jour avec succès",{position:"bottom-right"});
                dispatch(fetchVehicleTypesAll());
            })
            .catch((error) => {
                dispatch({ type: FETCH_VEHICLE_TYPE_FAILURE, payload: error.message });
                toast.error(error?.response?.data?.message||"Type d'engin non mis à jour",{position:"bottom-right"});
            });
    };
}





// All insurance of plateforme
export function fetchVehicleTypesAll() {
    return async (dispatch) => {
        dispatch({ type: FETCH_VEHICLE_TYPES_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/vehicle_types/get_vehicle_types`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_VEHICLE_TYPES_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_VEHICLE_TYPES_SUCCESS_2, payload: response.data.data });
            saveDataToFile(response.data.data, localStorageData.VehicleTypes);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_VEHICLE_TYPES_FAILURE, payload: error.message });
            });
    }
}


// get insurance by id 
export function fetchVehicleTypeById(idVehicleType) {
    return async (dispatch) => {
        dispatch({ type: FETCH_VEHICLE_TYPE_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/vehicle_types/get_vehicle_type/${idVehicleType}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_VEHICLE_TYPE_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                dispatch({ type: FETCH_VEHICLE_TYPE_FAILURE, payload: error.message })
                //console.log(error);
            });
    }
}

