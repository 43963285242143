import { FETCH_TERM_AND_CONDITIONS_FAILURE, FETCH_TERM_AND_CONDITIONS_REQUEST, FETCH_TERM_AND_CONDITIONS_SUCCESS, FETCH_TERM_AND_CONDITIONS_SUCCESS_2, FETCH_TERM_AND_CONDITION_FAILURE, FETCH_TERM_AND_CONDITION_REQUEST, FETCH_TERM_AND_CONDITION_SUCCESS } from "../actions/actions";



// termsAndConditionReducer.js
const initialState = {
    termsAndConditions: [],
    termsAndConditions2: [],
    termsAndCondition: {},
    termsAndConditionSelect: {},
    loadingTermsAndCondition:false,
    loadingTermsAndConditionSelect:false,
    loadingTermsAndConditions:false,
    errorTermsAndConditions:null,
    errorTermsAndCondition:null
    // ... autres états spécifiques à termsAndConditionReducer
  };
  
  const termsAndConditionReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_TERM_AND_CONDITION_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingTermsAndCondition:true,
          errorTermsAndConditions:""
          // Autres manipulations d'état pour la demande de récupération des termsAndConditions
        };
        case FETCH_TERM_AND_CONDITION_SUCCESS:
        return {
          ...state,
          loadingTermsAndCondition:false,
          errorTermsAndConditions:"",
          termsAndCondition:action.payload
        };
        case FETCH_TERM_AND_CONDITION_FAILURE:
        return {
          ...state,
          loadingTermsAndCondition:false,
          errorTermsAndConditions:action.payload
        };


      case FETCH_TERM_AND_CONDITIONS_REQUEST:
        return {
          ...state,
          loadingTermsAndConditions:true,
          errorTermsAndConditions:""
        };
       
      case FETCH_TERM_AND_CONDITIONS_SUCCESS:
        return {
          ...state,
          termsAndConditions: action.payload,
          loadingTermsAndConditions:false,
          errorTermsAndConditions:""
          // Autres manipulations d'état pour le succès de la récupération des termsAndConditions
        };
        case FETCH_TERM_AND_CONDITIONS_SUCCESS_2:
        return {
          ...state,
          termsAndConditions2: action.payload,
          loadingTermsAndConditions:false,
          errorTermsAndConditions:""
          // Autres manipulations d'état pour le succès de la récupération des termsAndConditions
        };
        
      case FETCH_TERM_AND_CONDITIONS_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingTermsAndConditions:false,
          errorTermsAndConditions:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des termsAndConditions
        };
      // ... autres cas pour d'autres actions liées aux termsAndConditions

        
      default:
        return state;
    }
  };
  
  export default termsAndConditionReducer;

  

