import axios from "axios";
import { FETCH_EXPENSES_FAILURE, FETCH_EXPENSES_REQUEST, FETCH_EXPENSES_SUCCESS, FETCH_EXPENSES_SUCCESS_2, FETCH_EXPENSE_FAILURE, FETCH_EXPENSE_REQUEST, FETCH_EXPENSE_SUCCESS, } from "../../app/actions/actions";
import { ROUTES } from "../../config/routingUrl";
import { dureeDeVie, getAndCheckLocalStorage, setWithExpiration } from "../../utils/storage/localvalueFuction";
import { localStorageData, localStorageKeys } from "../../utils/storage/localvalue";
import { baseurl, checkWordInURL } from "../../utils/url/baseurl";
import { saveDataToFile } from "../DataLocal";
import { toast } from "react-toastify";
import { profileRoleType } from "../../utils/dataApi/dataFormApi";










// All reservation of plateforme
export function fetchExpensessAll(startDate, endDate, damage, insurance, property, vehicle, createdBy, reservation, category) {
    return async (dispatch) => {
        dispatch({ type: FETCH_EXPENSES_REQUEST });

        const params = new URLSearchParams();
        if (startDate) params.append("startDate", startDate);
        if (endDate) params.append("endDate", endDate);
        if (damage) params.append("damage", damage); // Correction: Utilisation correcte de damage
        if (insurance) params.append("insurance", insurance); // Correction: Utilisation correcte d'insurance
        if (property) params.append("property", property);
        if (vehicle) params.append("vehicle", vehicle);
        if (createdBy) params.append("createdBy", createdBy);
        if (reservation) params.append("reservation", reservation); // Ajout du paramètre reservation
        if (category) params.append("category", category); // Ajout du paramètre category

        await axios
            .get(`${baseurl.url}/api/v1/expenses/get_expenses?${params.toString()}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`,
                },
            })
            .then((response) => {
                // console.log(response.data.data);
                dispatch({ type: FETCH_EXPENSES_SUCCESS, payload: response.data.data });
                dispatch({ type: FETCH_EXPENSES_SUCCESS_2, payload: response.data.data });
                saveDataToFile(response.data.data, localStorageData.ExpensesCuctomersPages);
            })
            .catch((error) => {
                dispatch({ type: FETCH_EXPENSES_FAILURE, payload: error.message });
            });
    };
}




// get reservation by id 
export function fetchExpensesCuctomersPageById(idExpense) {
    return async (dispatch) => {
        dispatch({ type: FETCH_EXPENSE_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/expenses/get_expense/${idExpense}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_EXPENSE_SUCCESS, payload: response.data.data });

        })
            .catch((error) => {
                dispatch({ type: FETCH_EXPENSE_FAILURE, payload: error.message })
                //console.log(error);
            });
    }
}




// get reservation by id 
export function ExpensesUpdateById(expenseId, userAdd, reservation, insurance, damage, maintenance, data) {
    return async (dispatch) => {
        const params = new URLSearchParams();
        if (expenseId) params.append("expenseId", expenseId);
        if (userAdd) params.append("userAdd", userAdd);
        if (reservation) params.append("reservation", reservation);
        if (insurance) params.append("insurance", insurance);
        if (damage) params.append("damage", damage);
        if (maintenance) params.append("maintenance", maintenance);

        dispatch({ type: FETCH_EXPENSE_REQUEST });
        await axios.put(`${baseurl.url}/api/v1/expenses/update?${params.toString()}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_EXPENSE_SUCCESS, payload: response.data.data });
            toast.success(response?.data?.message || "Dépense modifié avec succès", { position: "bottom-right" })
        })
            .catch((error) => {
                dispatch({ type: FETCH_EXPENSE_FAILURE, payload: error.message });
                toast.error(error?.response?.data?.message || "Dépense n'a pas été modifié ", { position: "bottom-right" })
                //console.log(error);
            });
    }
}



// get reservation by id 
export function ExpensesCreate(data) {
    return async (dispatch) => {
        dispatch({ type: FETCH_EXPENSE_REQUEST });
        await axios.post(`${baseurl.url}/api/v1/expenses/create`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_EXPENSE_SUCCESS, payload: response.data.data });
            toast.success(response?.data?.message || "Dépense ajouté avec succès", { position: "bottom-right" })
        })
            .catch((error) => {
                dispatch({ type: FETCH_EXPENSE_FAILURE, payload: error.message });
                toast.error(error?.response?.data?.message || "Dépense n'a pas été ajouté", { position: "bottom-right" })
                //console.log(error);
            });
    }
}




export function ExpensesDeleteById(expenseId, userAdd) {
    return async (dispatch) => {
        dispatch({ type: FETCH_EXPENSE_REQUEST });

        const params = new URLSearchParams();
        if (expenseId) params.append("expenseId", expenseId);
        if (userAdd) params.append("userAdd", userAdd);

        await axios
            .put(`${baseurl.url}/api/v1/expenses/disable?${params.toString()}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`,
                },
            })
            .then((response) => {
                // console.log(response.data.data);
                dispatch({ type: FETCH_EXPENSE_SUCCESS, payload: response.data.data });
                toast.success(response?.data?.message || "Dépense supprimer avec succès", { position: "bottom-right" })

                // dispatch({ type: FETCH_EXPENSE_SUCCESS_2, payload: response.data.data });
                // saveDataToFile(response.data.data, localStorageData.ExpensesCuctomersPages);
                // if (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER) {
                //     dispatch(fetchExpensessAll("", "", "", "", "", "", getAndCheckLocalStorage(localStorageKeys.userId)))
                // } else {
                //     dispatch(fetchExpensessAll())
                // }
            })
            .catch((error) => {
                dispatch({ type: FETCH_EXPENSE_FAILURE, payload: error.message });
                toast.error(error?.response?.data?.message || "Dépense non supprimer", { position: "bottom-right" })

            });
    };
}