import React, { useEffect, useState } from "react";
import { Card, Button, Dropdown, Spinner, Form, Row, Col } from "react-bootstrap";
import { MdEdit, MdDownload, MdSave } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import html2pdf from "html2pdf.js";

import { getAndCheckLocalStorage } from "../../../utils/storage/localvalueFuction";
import { localStorageKeys } from "../../../utils/storage/localvalue";
import { FETCH_TERM_AND_CONDITION_FAILURE, FETCH_TERM_AND_CONDITION_REQUEST, FETCH_TERM_AND_CONDITION_SUCCESS } from "../../../app/actions/actions";
import { TermAndConidtionUpdateById } from "../../../actions/request/TermAndConidtionRequest";
import { baseurl } from "../../../utils/url/baseurl";
import axios from "axios";

const TermsAndConditionsPage = () => {
  const dispatch = useDispatch();
  const termsAndCondition = useSelector((state) => state?.termsAndConditions?.termsAndCondition);
  const loadingTermsAndCondition = useSelector((state) => state?.termsAndConditions?.loadingTermsAndCondition);

  // Utilisation de FormData pour stocker les données
  const [formData, setFormData] = useState({
    agencyName: "",
    agencyContact: "",
    termsHtml: "",
  });

  const [isEditing, setIsEditing] = useState(false);

  // Récupérer les termes et conditions
  useEffect(() => {
    dispatch(fetchTermAndConidtionByIdGet("", getAndCheckLocalStorage(localStorageKeys.userId)));
  }, [dispatch]);

  // Récupération des données depuis l'API
  function fetchTermAndConidtionByIdGet(termsId, userAdd) {
    return async (dispatch) => {
      const params = new URLSearchParams();
      if (termsId) params.append("termsId", termsId);
      if (userAdd) params.append("userAdd", userAdd);

      dispatch({ type: FETCH_TERM_AND_CONDITION_REQUEST });

      await axios.get(`${baseurl.url}/api/v1/terms-and-conditions/get_terms-and-condition?${params.toString()}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
        }
      }).then((response) => {
        dispatch({ type: FETCH_TERM_AND_CONDITION_SUCCESS, payload: response.data.data });
        setFormData({
          agencyName: response.data.data.agencyName || "",
          agencyContact: response.data.data.agencyContact || "",
          termsHtml: response.data.data.termsHtml || "",
        });
      }).catch((error) => {
        dispatch({ type: FETCH_TERM_AND_CONDITION_FAILURE, payload: error.message });
      });
    }
  }

  // Mettre à jour l'état formData
  const handleInputChange = (key, value) => {
    setFormData((prevData) => ({ ...prevData, [key]: value }));
  };

  // Enregistrer les modifications
  const handleSaveChanges = () => {
    if (!formData?.agencyName) {
      toast.error("Veuillez renseignez le nom de l'agence.");
      return;
    }
    if (!formData?.agencyContact) {
      toast.error("Veuillez renseignez le contact.");
      return;
    }
    if (!formData?.termsHtml) {
      toast.error("Veuillez remplir les conditions.");
      return;
    }

    dispatch(TermAndConidtionUpdateById(formData?._id, getAndCheckLocalStorage(localStorageKeys.userId), formData));
    dispatch(fetchTermAndConidtionByIdGet(formData?._id, getAndCheckLocalStorage(localStorageKeys.userId)));

    // toast.success("Termes et conditions mis à jour !");
    setIsEditing(false);
  };

  // Générer un PDF
  const handleDownloadPDF = () => {
    if (!formData?.termsHtml) {
      toast.error("Aucune condition disponible pour générer un PDF.");
      return;
    }

    const content = `
      <h2>${formData.agencyName}</h2>
      <p>Contact : ${formData.agencyContact}</p>
      ${formData.termsHtml}
    `;

    const opt = {
      margin: 10,
      filename: "Conditions_Generales.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" }
    };

    html2pdf().from(content).set(opt).save();
  };

  return (
    <div>
      <Card>
        <Card.Header>
          <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <div>
              <h3>Gérer les Termes et Conditions</h3>
            </div>
            <div>
              <Dropdown>
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  Gérer les conditions
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setIsEditing(true)}>
                    <MdEdit /> Modifier les conditions
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleDownloadPDF}>
                    <MdDownload /> Télécharger en PDF
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </Card.Header>

        <Card.Body>
          {isEditing ? (
            <>
              <Form>
                <Row>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Nom de l{"'"}agence</Form.Label>
                      <Form.Control
                        type="text"
                        style={{ height: "40px" }}
                        value={formData.agencyName}
                        onChange={(e) => handleInputChange("agencyName", e.target.value)}
                        placeholder="Nom de l'agence"
                      />
                    </Form.Group>

                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Contact de l{"'"}agence</Form.Label>
                      <Form.Control
                        type="text"
                        style={{ height: "40px" }}
                        value={formData.agencyContact}
                        onChange={(e) => handleInputChange("agencyContact", e.target.value)}
                        placeholder="Email ou téléphone"
                      />
                    </Form.Group>

                  </Col>

                  <Col md={12}>
                    <Form.Group>
                      <Form.Label>Conditions générales</Form.Label>
                      <ReactQuill
                        theme="snow"
                        value={formData.termsHtml}
                        onChange={(content) => handleInputChange("termsHtml", content)}
                        style={{ height: "300px", marginBottom: "20px" }}
                      />
                    </Form.Group>


                    {
                      loadingTermsAndCondition ?
                        <Button variant="primary" size="sm" disabled>
                          <Spinner variant="light" />
                        </Button>
                        :
                        <Button variant="primary" size="sm" onClick={handleSaveChanges}>
                          <MdSave /> Enregistrer
                        </Button>
                    }
                  </Col>
                </Row>
              </Form>
            </>
          ) : (
            <div>
              <h4>{formData.agencyName}</h4>
              <p><strong>Contact :</strong> {formData.agencyContact}</p>
              <div dangerouslySetInnerHTML={{ __html: formData.termsHtml || "<p>Aucune condition définie.</p>" }} />
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default TermsAndConditionsPage;
