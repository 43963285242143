import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ImageGallery from 'react-image-gallery';
import { FaHome, FaRuler, FaCalendarAlt, FaMapMarkerAlt, FaTruck } from 'react-icons/fa';
import { Row, Col, Button, Container, Card, Badge, Accordion, Table, Form, Image, Modal, Tabs, Tab, Dropdown, Alert, Spinner } from 'react-bootstrap';
import './DetailVehiclePage.css'; // Optionnel pour les styles personnalisés
import { fetchVehicleById } from '../../../actions/request/VehicleRequest';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MdAcUnit, MdAdd, MdAirlineSeatReclineExtra, MdCameraRear, MdCarRepair, MdCategory, MdColorLens, MdCopyAll, MdDelete, MdEdit, MdEngineering, MdFlashOn, MdFormatListNumbered, MdFormatListNumberedRtl, MdGetApp, MdGpsFixed, MdLocationOn, MdMapsHomeWork, MdMarkAsUnread, MdOutlineFormatListNumbered, MdOutlineFormatListNumberedRtl, MdOutlineSupport, MdPanoramaWideAngle, MdPerson, MdPerson4, MdSearch, MdSettingsInputComponent, MdShare, MdTimeline, MdViewHeadline } from 'react-icons/md';
import { ROUTES } from '../../../config/routingUrl';
import { fetchInsurancesAllByVehicleId } from '../../../actions/request/InssuranceRequest';
import moment from 'moment';
import { RiEye2Fill } from 'react-icons/ri';
import { fetchReservationsAllByVehicleId } from '../../../actions/request/ReservationRequest';
import { useState } from 'react';
import { fetchDamagesAllByVehicleId } from '../../../actions/request/DamageRequest';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import { FETCH_ACCESS_SUCCESS, FETCH_PROPERTY_SUCCESS, FETCH_VEHICLE_SUCCESS } from '../../../app/actions/actions';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { copyToClipboard, getCleanUrl } from '../../../utils/functionData/otherFucntion';
import { formatDateView } from '../../../utils/functionData/dateFunction';
import { FaHorseHead } from 'react-icons/fa6';
import { profileRoleType } from '../../../utils/dataApi/dataFormApi';
import { AccessAccessUnDeleteItemById } from '../../../actions/request/AccessRequest';

// Fix for missing marker icon issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});




const getExpirationColor = (endDate) => {

    const today = moment();
    const expirationDate = moment(endDate);
    const daysDiff = expirationDate.diff(today, 'days');

    if (daysDiff <= 0) {
        return 'danger'; // Rouge : Date expirée
    } else if (daysDiff <= 5) {
        return 'warning'; // Vert : Moins de 20 jours
    } else {
        return 'primary'; // Vert clair : Plus de 20 jours
    }
};

const renderStatusBadge = (status) => {
    switch (status) {
        case 'UNAVAILABLE':
            return <Badge bg="danger">Indisponilble</Badge>;
        case 'AVAILABLE':
            return <Badge bg="success">Disponilble</Badge>;
        case 'IN_REPAIR':
            return <Badge bg="danger">Maintenance</Badge>;
        default:
            return <Badge bg="secondary">{status}</Badge>;
    }
};




const renderStatusBadgeDamage = (status) => {
    switch (status) {
        case 'Reported':
            return <Badge bg="danger">Signalé</Badge>;
        case 'Resolved':
            return <Badge bg="success">Résolu</Badge>;
        case 'In Progress':
            return <Badge bg="danger">Annulée</Badge>;
        case 'Paid':
            return <Badge bg="danger">Annulée</Badge>;
        default:
            return <Badge bg="secondary">{status}</Badge>;
    }
};






const DetailVehiclePage = () => {
    // Récupération de l'objet de la propriété via Redux
    const navigate = useNavigate();
    const paramVehicle = useParams();
    const dispatch = useDispatch();

    const loadingVehicle = useSelector((state) => state.vehicles.loadingVehicle);

    const loadingAccess = useSelector((state) => state.access.loadingAccess);
    const vehicle = useSelector((state) => state.vehicles.vehicle);
    const insurances = useSelector((state) => state.insurances.insurances);
    const reservations = useSelector((state) => state.reservations.reservations);
    const damages = useSelector((state) => state.damages.damages);



    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [occupancyRate, setOccupancyRate] = useState(0);

    const [showAccess, setshowAccess] = useState(false);
    const handleShowAccess = () => { setshowAccess(true) }
    const handleCloseAccess = () => { setshowAccess(false) }

    const [showWebSite, setshowWebSite] = useState(false);
    const handleShowWebsite = () => { setshowWebSite(true) }
    const handleCloseWebsite = () => { setshowWebSite(false) }

    const [showOccupanyRate, setshowOccupanyRate] = useState(false);
    const handlShowOccupanyRate = () => { setshowOccupanyRate(true) }
    const handlCloseOccupanyRate = () => { setshowOccupanyRate(false) }



    useEffect(() => {
        dispatch({ type: FETCH_ACCESS_SUCCESS });
        dispatch(fetchVehicleById(paramVehicle?.id || ""));
        dispatch(fetchInsurancesAllByVehicleId(paramVehicle?.id || ""));
        dispatch(fetchReservationsAllByVehicleId(paramVehicle?.id || ""));
        dispatch(fetchDamagesAllByVehicleId(paramVehicle?.id || ""));
    }, [dispatch])


    useEffect(() => {
        // Calculer le taux d'occupation global (par défaut) en prenant la première et la dernière réservation
        const globalStartDate = reservations[0]?.startDate || new Date().toISOString();
        const globalEndDate = reservations[reservations.length - 1]?.endDate || new Date().toISOString();
        const rate = calculateOccupancyRate(reservations, globalStartDate, globalEndDate);
        setOccupancyRate(rate);
    }, [reservations]);




    // cacule




    // Fonction pour calculer la différence en jours entre deux dates
    const calculateDaysBetween = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const diffTime = Math.abs(end - start);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Conversion en jours
        return diffDays;
    };

    // Fonction pour calculer le taux d'occupation
    const calculateOccupancyRate = (reservations, filterStartDate, filterEndDate) => {
        const filterStart = new Date(filterStartDate);
        const filterEnd = new Date(filterEndDate);
        const totalPeriodDays = calculateDaysBetween(filterStart, filterEnd);

        let totalReservedDays = 0;

        reservations.forEach((reservation) => {
            const reservationStart = new Date(reservation.startDate);
            const reservationEnd = new Date(reservation.endDate);

            // Vérifier si la réservation chevauche la période filtrée
            if (reservationEnd >= filterStart && reservationStart <= filterEnd) {
                const start = reservationStart > filterStart ? reservationStart : filterStart;
                const end = reservationEnd < filterEnd ? reservationEnd : filterEnd;
                totalReservedDays += calculateDaysBetween(start, end);
            }
        });

        // Calcul du taux d'occupation en pourcentage
        const occupancyRate = (totalReservedDays / totalPeriodDays) * 100;
        return occupancyRate.toFixed(2);
    };





    const handleFilter = () => {
        if (startDate && endDate) {
            const rate = calculateOccupancyRate(reservations, startDate, endDate);
            setOccupancyRate(rate);
        }
    };


    const handleDeleteItem = () => {
        if (!paramVehicle?.id || !vehicle?._id) {
            toast.error("Vehicule non trouvée", { position: "bottom-right" })
            return;
        }
        dispatch(AccessAccessUnDeleteItemById(paramVehicle?.id || vehicle?._id))
        handleCloseAccess();
        navigate(`/${ROUTES.DASHBOARD}/${ROUTES.CARS_LIST}`)
    }





    if (loadingVehicle) {
        return <p className="text-center"><Spinner variant='primary' />  Chargement...</p>;
    }

    if (!vehicle) {
        return <p className="text-center">Véhicule non trouvé.</p>;
    }


    // Préparation des images pour la galerie
    const images = vehicle && vehicle.images && vehicle.images.length > 0 ? vehicle.images.map((image) => ({
        original: image,
        thumbnail: image,
    })) : [];








    // cacule 





    return (
        <div className="vehicle-details">
            <div style={{ width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                <div></div>
                <div>
                    <Dropdown>
                        <Dropdown.Toggle variant="primary" className='btn btn-sm' id="dropdown-basic">
                            Actions
                        </Dropdown.Toggle>

                        <Dropdown.Menu>

                            {
                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ?
                                    <>
                                        <Dropdown.Item onClick={() => {
                                            dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: vehicle });
                                            dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: {} });
                                            navigate(`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATIONS_REQUEST_ADD}`, { state: vehicle })
                                        }} >
                                            <MdAdd /> <span> Nouvelle réservation </span>
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => { navigate(`/${ROUTES.DASHBOARD}/${ROUTES.CARS_EDIT}/${vehicle._id}`) }} ><MdEdit /> <span> Modifier </span> </Dropdown.Item>
                                        <Dropdown.Item onClick={() => { navigate(`/${ROUTES.DASHBOARD}/${ROUTES.INSURANCES_LIST_PLATEFORM_ADD}`, { state: vehicle }) }} > <MdOutlineSupport /> <span> Assurance </span> </Dropdown.Item>
                                        <Dropdown.Item onClick={() => { handleShowWebsite() }} > <MdShare /> <span>Copier le lien du vehicule </span> </Dropdown.Item>
                                        <Dropdown.Item onClick={() => { handleShowAccess() }} > <MdDelete /> <span>Suppimer </span> </Dropdown.Item>
                                    </> : ""
                            }

                        </Dropdown.Menu>
                    </Dropdown>




                </div>
            </div>
            <Row className="mt-4">
                {/* Section des images (à gauche) */}
                <Col md={6}>
                    <ImageGallery

                        items={images}
                        showThumbnails={true}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        renderItem={(item) => (
                            <div style={{
                                height: '300px', // Hauteur fixe
                                width: '100%',
                                overflow: 'hidden'
                            }}>
                                <img className="rounded-lg"
                                    src={item.original}
                                    alt=""
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        objectFit: 'contain'
                                    }}
                                />
                            </div>
                        )}

                    />


                    {
                        vehicle && vehicle.lat && vehicle.lng && (

                            <div>
                                <div className="py-3">
                                    <h6> <MdLocationOn />  Localisation </h6>
                                    <p> {vehicle && vehicle.address ? vehicle.address : ""}</p>
                                </div>
                                <MapContainer center={[vehicle.lat, vehicle.lng]} zoom={13} style={{ height: "400px", width: "100%" }}>
                                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                                    <Marker position={[vehicle.lat, vehicle.lng]}>
                                        <Popup>
                                            Location: {vehicle.lat}, {vehicle.lng}
                                        </Popup>
                                    </Marker>
                                </MapContainer>
                            </div>
                        )
                    }


                </Col>

                {/* Section des détails de la propriété (à droite) */}
                <Col md={6}>
                    <Card>
                        <Card.Body>

                            <h3>{vehicle?.brand}</h3>
                            <p><MdCategory /> Type: {vehicle?.type?.name}</p>
                            <p><MdMapsHomeWork /> Marque: {vehicle?.vehiclebrand}</p>
                            <p><FaMapMarkerAlt /> Model: {vehicle.model}</p>
                            <p><MdColorLens /> Couleur : {vehicle.color} </p>
                            {
                                vehicle.mileage ?
                                    <p><FaRuler /> Killometrage: {vehicle.mileage} Km</p>
                                    : ""
                            }

                            {
                                vehicle.registrationNumber ?
                                    <p><MdFormatListNumbered /> Plaque d{"'"}Immatriculation: {vehicle.registrationNumber} </p> : ""
                            }
                            {
                                vehicle?.numberIdVehicle ?
                                    <p><MdFormatListNumberedRtl /> N° Identification (VIN): {vehicle.numberIdVehicle} </p>
                                    : ""
                            }

                            {
                                vehicle?.vignetteImpot ?
                                    <p><MdFormatListNumberedRtl /> Vignette Impôt: {vehicle.vignetteImpot} </p> : ""
                            }
                            {
                                vehicle?.numbervignetteImpot ?
                                    <p><MdFormatListNumberedRtl />N° Vignette : {vehicle.numbervignetteImpot} </p> : ""
                            }
                            {
                                vehicle.status ?
                                    <p><MdViewHeadline /> Disponilité : {renderStatusBadge(vehicle.status)} </p> :
                                    ""
                            }

                            <p><MdTimeline /> Date de création: {moment(vehicle.createdAt).format("DD-MM-YYYY")}</p>
                            <p><FaCalendarAlt /> Année de fabrication: {vehicle.year}</p>
                            <p><FaTruck /> Frais de livraison: {vehicle.deliveryFee ? `${vehicle.deliveryFee} DZD` : 'Gratuit'}</p>

                            <hr />
                            <h4 className="mt-4">Infos sur réservation</h4>
                            <p>
                                Prix de base par jour: {vehicle.basePricePerDay} DZD <br />
                                {/*Commission: {vehicle.commissionPercentage}% <br />
                                Frais de livraison: {vehicle.deliveryFee} DZD */}
                            </p>


                            {(vehicle?.options && Object.values(vehicle.options).some(val => val)) || vehicle?.interiorType ? (
                                <Col md={12} className="mt-4">

                                    <h5>Options du l{"'"}engin</h5>
                                    <ul className="list-unstyled d-flex flex-column gap-2">
                                        {/* ✅ Options avec condition d'affichage */}
                                        {vehicle?.options?.gps && (
                                            <li className="py-2 px-1 border"><MdGpsFixed size={24} /> <strong>GPS</strong></li>
                                        )}
                                        {vehicle?.options?.airConditioning && (
                                            <li className="py-2 px-1 border"><MdAcUnit size={24} /> <strong>Climatisation</strong></li>
                                        )}
                                        {vehicle?.options?.turbo && (
                                            <li className="py-2 px-1 border"><MdFlashOn size={24} /> <strong>Turbo</strong></li>
                                        )}
                                        {vehicle?.options?.sunroof && (
                                            <li className="py-2 px-1 border"><MdPanoramaWideAngle size={24} /> <strong>Toit Ouvrant</strong></li>
                                        )}
                                        {vehicle?.options?.reverseCamera && (
                                            <li className="py-2 px-1 border"><MdCameraRear size={24} /> <strong>Caméra de Recul</strong></li>
                                        )}
                                        {vehicle?.options?.speedRegulator && (
                                            <li className="py-2 px-1 border"><MdCarRepair size={24} /> <strong>Régulateur de Vitesse</strong></li>
                                        )}
                                        {vehicle?.options?.horsepower >= 0 && (
                                            <li className="py-2 px-1 border"><FaHorseHead size={24} /> <strong>Chevaux :</strong> {vehicle?.options?.horsepower || 0} CV</li>
                                        )}

                                        {/* ✅ Affichage conditionnel du type d'intérieur */}
                                        {vehicle?.interiorType?.name && (
                                            <li className="py-2 px-1 border"><MdAirlineSeatReclineExtra size={24} /> <strong>Intérieur :</strong> {vehicle?.interiorType?.name}</li>
                                        )}

                                        {/* ✅ Affichage conditionnel du type d'intérieur */}
                                        {vehicle?.engineType?.name && (
                                            <li className="py-2 px-1 border"><MdEngineering size={24} /> <strong>Moteur :</strong> {vehicle?.engineType?.name}</li>
                                        )}
                                    </ul>
                                </Col>
                            ) : ""}


                            {/* Formulaire pour filtrer le taux d'occupation */}

                            {/*<hr />
                            <Col md={12}>
                                <h3 className="text-start my-4 ">Taux d{"'"}occupation </h3>
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    <div style={{ width: "100%" }}>
                                        <Form.Group controlId="startDate">
                                            <Form.Label>Date de début</Form.Label>
                                            <Form.Control
                                                type="datetime-local" style={{ height: "40px" }}
                                                value={startDate}
                                                onChange={(e) => setStartDate(e.target.value)}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div style={{ width: "100%" }}>
                                        <Form.Group controlId="endDate">
                                            <Form.Label>Date de fin</Form.Label>
                                            <Form.Control
                                                type="datetime-local" style={{ height: "40px" }}
                                                value={endDate}
                                                onChange={(e) => setEndDate(e.target.value)}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div style={{ width: "100%" }}>
                                        <div>
                                            <Button variant="primary" size="sm" className="mt-3"
                                                onClick={() => {
                                                    if (!endDate && !startDate) {
                                                        toast.error("Date obligatoire", { position: "bottom-right" })
                                                    } else {
                                                        handleFilter()
                                                        handlShowOccupanyRate();
                                                    }
                                                }}
                                            >
                                                <MdSearch /> Pourcentage d{"'"}occupation
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Col> */}




                            {/*<Button variant="primary" size="sm" className="mt-3 m-2"
                                onClick={() => {
                                    dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: vehicle });
                                    dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: {} });
                                    navigate(`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATIONS_REQUEST_ADD}`, { state: vehicle })
                                }} >
                                <MdAdd /> <span> Nouvelle reservation </span>
                            </Button>

                            <Button variant="primary" size="sm" className="mt-3 m-2"
                                onClick={() => { navigate(`/${ROUTES.DASHBOARD}/${ROUTES.CARS_EDIT}/${vehicle._id}`) }} >
                                <MdEdit /> <span> Modifier </span>
                            </Button>

                            <Button variant="primary" size="sm" className="mt-3 m-2"
                                onClick={() => { navigate(`/${ROUTES.DASHBOARD}/${ROUTES.INSURANCES_LIST_PLATEFORM_ADD}`, { state: vehicle }) }}>
                                <MdOutlineSupport /> <span> Assurance </span>
                            </Button> */}

                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <div className="container mt-4">
                <Tabs defaultActiveKey="owner" id="reservation-tabs" className="mb-3">
                    {/* Tab pour les informations sur le propriétaire */}
                    <Tab eventKey="owner" title="Propriétaire">

                        <Col md={12}>
                            <Card>
                                <Card.Body>
                                    <h3>Informations sur le propriétaire</h3>
                                    <div className="owner-info">
                                        {
                                            vehicle?.owner?.profilePicture ?
                                                <img
                                                    src={vehicle?.owner?.profilePicture}
                                                    alt={vehicle?.owner?.firstname}
                                                    className="owner-img rounded-circle" style={{ height: "80px", width: "80px" }}
                                                />
                                                : <MdPerson />
                                        }
                                        <div>
                                            <p>Nom: {vehicle?.owner?.firstname} {vehicle?.owner?.lastname}</p>
                                            <p>Email: {vehicle?.owner?.email}</p>
                                            <p>Téléphone: {vehicle?.owner?.phone}</p>
                                            <p>Adresse: {vehicle?.owner?.address}</p>
                                            <p>Entreprise: {vehicle?.owner?.companyName}</p>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>

                    </Tab>


                    <Tab eventKey="insurances" title="Assurances">
                        {/* Section des informations sur le propriétaire */}
                        <Col md={12}>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header><h4>Assurances</h4></Accordion.Header>
                                    <Accordion.Body>
                                        <Card>
                                            <Card.Body>

                                                {/* Tableau des rapports filtrés */}
                                                {insurances && insurances.length > 0 ? <Table striped bordered hover responsive >
                                                    <thead>
                                                        <tr>
                                                            <th>Titre</th>
                                                            <th>N° Police</th>
                                                            <th>Cout</th>
                                                            <th>Expiration</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {insurances && insurances.length > 0 && insurances.map(insurance => (
                                                            <tr key={insurance._id}>
                                                                <td>
                                                                    {
                                                                        insurance && insurance.type && insurance.type.name ?
                                                                            insurance.type.name : ""
                                                                    }
                                                                </td>
                                                                <td>{insurance.policyNumber}</td>
                                                                <td>{insurance.premiumAmount}</td>

                                                                <td>
                                                                    <Badge bg={getExpirationColor(insurance.endDate)}>
                                                                        {moment(insurance.endDate).format("DD-MM-YYYY")}
                                                                    </Badge>
                                                                </td>
                                                                <td>
                                                                    <Button variant="primary" size="sm" onClick={() => { navigate(`/${ROUTES.DASHBOARD}/${ROUTES.INSURANCES_LIST_PLATEFORM_DETAIL}/${insurance._id}`) }}>
                                                                        <RiEye2Fill /> <span className="text-sm"> Detail </span>
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table >
                                                    : <div style={{ width: "100%", height: "100%", justifyContent: "center", alignContent: "center" }}>
                                                        <div> Aucune Assurance</div>
                                                    </div>
                                                }
                                            </Card.Body>
                                        </Card>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Tab>


                    <Tab eventKey="damages" title="Dommages">
                        <Col md={12}>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header><h4>Dommages</h4></Accordion.Header>
                                    <Accordion.Body>
                                        <Card>
                                            <Card.Body>

                                                {/* Tableau des rapports filtrés */}
                                                {
                                                    damages && damages.length > 0 ?
                                                        <Table striped bordered hover responsive >
                                                            <thead>
                                                                <tr>
                                                                    <th>Catégorie</th>
                                                                    <th>Photos Avant</th>
                                                                    <th>Photos Après</th>
                                                                    <th>Cout</th>
                                                                    <th>Statut</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {damages && damages.length > 0 && damages.map((report, index) => (
                                                                    <tr key={report.id}>

                                                                        <td>
                                                                            {
                                                                                report && report.type && report.type.name ?
                                                                                    report.type.name : ""
                                                                            }
                                                                        </td>

                                                                        <td>
                                                                            <Image
                                                                                src={`${report.beforeUsePhoto[0]}`}
                                                                                alt={`Avant - ${report.beforeUsePhoto[0]}`}
                                                                                fluid
                                                                                thumbnail
                                                                                style={{ width: '80px', height: '60px' }}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Image
                                                                                src={`${report.afterUsePhoto[0]}`}
                                                                                alt={`Après - ${report.afterUsePhoto[0]}`}
                                                                                fluid
                                                                                thumbnail
                                                                                style={{ width: '80px', height: '60px' }}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <strong>{report.estimatedCost}</strong>
                                                                        </td>
                                                                        <td>
                                                                            {renderStatusBadgeDamage(report.status)}
                                                                        </td>
                                                                        <td>

                                                                            <Button primary="primary" size="sm" onClick={() => { navigate(`/${ROUTES.DASHBOARD}/${ROUTES.DAMAGES_LIST_PLATEFORM_DETAIL}/${report._id}`) }}>
                                                                                <RiEye2Fill />
                                                                            </Button>


                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                        :
                                                        <div style={{ width: "100%", height: "100%", justifyContent: "center", alignContent: "center" }}>
                                                            <div> Aucun dommage</div>
                                                        </div>
                                                }
                                            </Card.Body>
                                        </Card>
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>
                        </Col>
                    </Tab>




                    <Tab eventKey="reservation" title="Réservation">
                        {/*Taux d'occupation */}
                        {
                            reservations && reservations.length > 0 ?

                                <Col md={12}>
                                    <h4 className="text-start my-4 ">Réservation </h4>

                                    {/* Formulaire pour filtrer le taux d'occupation */}
                                    <Row>

                                        <Col md={12}>
                                            {/* Affichage des réservations sous forme de cartes */}
                                            <div className="row">
                                                {reservations.map((reservation) => (
                                                    <Col sm={6} md={4} lg={3} xl={3} >
                                                        <Card className="mb-4">
                                                            <Card.Img variant="top" className="rounded-lg" src={reservation.vehicle?.images[0] || reservation.property?.images[0]} alt="Image du véhicule" />
                                                            <Card.Body>
                                                                <Card.Title><h5>{reservation.ordre || ""}</h5></Card.Title>
                                                                <Card.Text>
                                                                    <strong></strong> {formatDateView(reservation?.startDate)}  - {moment(reservation?.startDate).format("HH:MM")} <br />
                                                                    <strong> au </strong> {formatDateView(reservation?.endDate)}  - {moment(reservation?.endDate).format("HH:MM")} <br />
                                                                    {/*<strong>Client :</strong> {reservation.client?.firstname} {reservation.client?.lastname}<br />*/}
                                                                    <strong>
                                                                        <Button size='sm' variant="primary" onClick={() => { navigate(`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATION_VIEW}/${reservation._id}`) }}>
                                                                            <RiEye2Fill /> Detail
                                                                        </Button>
                                                                    </strong>
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                ))}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                :
                                ""
                        }
                    </Tab>
                </Tabs>
            </div>






            {/*Taux d'occupation */}
            <Modal show={showOccupanyRate} onHide={handlCloseOccupanyRate} centered>
                <Modal.Header><h3 className="text-center">Taux d{"'"}occupation</h3></Modal.Header>
                <Modal.Body>
                    <Card className="mb-4">
                        <Card.Body>
                            <Card.Title>Taux d{"'"}occupation global :</Card.Title>
                            <p className="h4 " style={{ fontWeight: "600", fontSize: "30px" }}>
                                <strong>{occupancyRate}%</strong>
                            </p>
                        </Card.Body>
                    </Card>
                </Modal.Body>
                <Modal.Footer><Button type="button" variant="primay" size='sm' >Fermer</Button></Modal.Footer>
            </Modal>




            {/*url lien en question */}
            <Modal show={showWebSite} onHide={handleCloseWebsite} centered>
                <Modal.Body>
                    <div style={{ width: "100%" }}>
                        <div className="form-group mb-3">
                            <label htmlFor="password" className="form-label">Lien du véhicule</label>
                            <div className="input-group">
                                <input style={{ height: "40px" }}
                                    readOnly
                                    className={`form-control`}
                                    placeholder=""
                                    value={
                                        `${getCleanUrl()}${`/${ROUTES.CAR_DETAIL_VIEW}/${vehicle._id}/name/${vehicle.brand}/description/${vehicle.brand}`}`
                                    }
                                />
                                <button
                                    type="button" style={{ height: "40px" }}
                                    className="btn btn-sm btn-outline-primary"
                                    onClick={() => {
                                        copyToClipboard(
                                            `${getCleanUrl()}${`/${ROUTES.CAR_DETAIL_VIEW}/${vehicle._id}/name/${vehicle.brand}/description/${vehicle.brand}`}`
                                        )
                                        setshowWebSite(false);
                                    }}
                                >
                                    <MdCopyAll />
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Vehicle */}
            <Modal show={showAccess} onHide={handleCloseAccess} centered>
                <Modal.Body>
                    <div className="p-5">
                        <div>
                            <Alert className="text-center">Êtes vous sur de supprimer <strong className='font-weight-800'>{vehicle?.brand || ""}</strong>  ? </Alert>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" variant='light' size='sm'>Annuler</Button>
                    {
                        loadingAccess ?
                        <Button type="button" variant='danger' size="sm"  ><Spinner variant="light" /> </Button>
                        :
                        <Button type="button" variant='danger' size="sm" onClick={handleDeleteItem}>Confirmer </Button>
                    }
                </Modal.Footer>
            </Modal>





        </div>
    );
};

export default DetailVehiclePage;
