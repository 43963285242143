import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { baseurl } from '../../utils/url/baseurl';
import { ROUTES } from '../../config/routingUrl';
import { localStorageKeys } from '../../utils/storage/localvalue';
import { getAndCheckLocalStorage } from '../../utils/storage/localvalueFuction';
import { profileRoleType } from '../../utils/dataApi/dataFormApi';

const useVerifyUserStatus = (idUser) => {
    const [isConnected, setIsConnected] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (!idUser) return;

        // Fonction pour vérifier le statut du compte
        const fetchVerifyStatusById = async () => {
            try {
                const response = await axios.get(`${baseurl.url}/api/v1/access/check-status/${idUser}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                });
                const isActive = response?.data?.isActive;
                setIsConnected(isActive);
                if (!isActive && (
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.CUSTOMER ||
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT
                )) {
                    // localStorage.clear(); // Effacer toutes les données du localStorage
                    // console.warn("L'utilisateur a été bloqué. Déconnexion en cours...");
                    localStorage.removeItem(localStorageKeys.userId)
                    localStorage.removeItem(localStorageKeys.userRole)
                    localStorage.removeItem(localStorageKeys.userName)
                    navigate(`/${ROUTES.LOGIN}`); // Redirige vers la page de connexion
                }
            } catch (error) {
                // console.error("Erreur lors de la vérification du statut :", error?.response?.data?.message);
                setIsConnected(true); // On suppose que l'utilisateur est toujours connecté si une erreur survient
            }
        };

        // Vérifier le statut immédiatement et ensuite toutes les 30 secondes
        fetchVerifyStatusById();
        const interval = setInterval(fetchVerifyStatusById, 30000);

        return () => clearInterval(interval); // Nettoyage du useEffect
    }, [idUser, navigate]);

    return isConnected;
};

export default useVerifyUserStatus;
