import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Button, Image, ListGroup, Modal, Form, Dropdown } from 'react-bootstrap';
import { FaDownload, FaMoneyCheckAlt, FaFileInvoiceDollar, FaEdit } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { InsurancePayById, fetchInsuranceById } from '../../../actions/request/InssuranceRequest';
import Select from 'react-select'; // Pour le choix du mode de paiement
import { toast } from 'react-toastify';
import { fetchPaymentsAll } from '../../../actions/request/PaymentRequest';
import { fetchPaymentTypesAll } from '../../../actions/request/PaymentTypeRequest';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import { baseurl } from '../../../utils/url/baseurl';
import axios from 'axios';
import { ROUTES } from '../../../config/routingUrl';
import { useRef } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { formatDateView } from '../../../utils/functionData/dateFunction';
import { emptyImage, logoApplication } from '../../../utils/dataApi/dataFormApi';

const InsuranceInvoiceDetailPage = () => {
    const navigate = useNavigate();
    const paramInsurance = useParams();
    const dispatch = useDispatch();
    const insurance = useSelector((state) => state.insurances.insurance);
    const paymentTypes = useSelector((state) => state.paymentTypes.paymentTypes);

    const [showPayModal, setShowPayModal] = useState(false);
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [paymentProof, setPaymentProof] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [date, setdate] = useState();

    useEffect(() => {
        dispatch(fetchInsuranceById(paramInsurance?.id));
        dispatch(fetchPaymentTypesAll());
    }, [dispatch, paramInsurance?.id]);

    // Options pour le mode de paiement
    const paymentOptions = paymentTypes && paymentTypes.length > 0 ? paymentTypes.map((option) => {
        return (
            { value: option._id, label: option.name }
        )
    }) : []






    const [loading, setLoading] = useState(false);

    // Fonction pour gérer l'upload du fichier PDF avec Axios
    const handleFileUpload = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', baseurl.cloudinaryUploadPreset); // Ton preset d'upload Cloudinary

        setLoading(true); // Activer l'indicateur de chargement

        try {
            const response = await axios.post(`https://api.cloudinary.com/v1_1/${baseurl.cloudinaryCloudName}/auto/upload`, formData);
            const data = response.data;

            if (data.secure_url) {
                toast.success("Fichier uploadé avec succès :", { position: "bottom-right" })
                // console.log('Fichier uploadé avec succès :', data.secure_url);
                setPaymentProof(data.secure_url); // Stocker l'URL du fichier
            }

        } catch (error) {

            console.error('Erreur lors de l\'upload :', error);
            toast.error("Fichier non uploader", { position: "bottom-right" });

        } finally {
            setLoading(false); // Désactiver l'indicateur de chargement
        }
    };



    // Fonction de paiement
    const handlePayInsurance = () => {
        if (paymentMethod && paymentProof && date) {
            console.log(paymentMethod?.value);

            const insuraneData = {
                type: paymentMethod?.value,
                date: date,
                user: getAndCheckLocalStorage(localStorageKeys.userId),
                paymentFile: paymentProof
            }
            console.log(insuraneData)

            dispatch(InsurancePayById(paramInsurance?.id, insuraneData, toast))
            setShowPayModal(false);
        } else {
            toast.error("Veuillez choisir un mode de paiement et la date et télécharger la preuve.", { position: "bottom-right" })
            alert();
        }
    };

    // Fonction pour télécharger la facture en PDF
    const handleDownloadInvoice = () => {
        if (insurance) {
            setShowPrintModal(true);
        }
    };

    const invoiceRef = useRef();
    const handleDownloadInvoiceWithJsPDF = () => {
        if (!insurance) return;

        const doc = new jsPDF();

        // Ajouter le logo de l'application
        const imgWidth = 40;
        const imgHeight = 15;
        doc.addImage(logoApplication, 'PNG', 10, 10, imgWidth, imgHeight);

        // Titre centré
        doc.setFontSize(16);
        doc.text('Facture d\'Assurance', doc.internal.pageSize.width / 2, 20, { align: 'center' });

        doc.setFontSize(10);

        // Positionner les informations en deux colonnes
        const leftX = 10; // Alignement à gauche
        const rightX = doc.internal.pageSize.width - 80; // Alignement à droite

        let y = 40; // Position initiale en Y

        // Informations alignées à gauche
        doc.text(`Numéro de police: ${insurance.policyNumber || ''}`, leftX, y);
        doc.text(`Assuré: ${insurance.userId.firstname || ''} ${insurance.userId.lastname || ''}`, leftX, y + 10);
        doc.text(`Montant total: ${insurance.premiumAmount || '0'} DZD`, leftX, y + 20);

        // Informations alignées à droite
        doc.text(`Statut de paiement: ${insurance.paymentStatus == "Completed" || "Paid" ? "Payé" : 'Non payé'}`, rightX, y);
        doc.text(`Date de début: ${formatDateView(insurance.startDate)}`, rightX, y + 10);
        doc.text(`Date de fin: ${formatDateView(insurance.endDate)}`, rightX, y + 20);

        // Ajouter un tableau avec les détails
        doc.autoTable({
            startY: y + 40,
            head: [['Objet assuré', 'Détails']],
            body: insurance?.insuredObjectType === 'Vehicle'
                ? [
                    ['Véhicule', `${insurance.vehicle.brand || ''} - ${insurance.vehicle.model || ''}`],
                    ['Couleur', insurance?.vehicle?.color || ''],
                    ['Immatriculation', insurance?.vehicle?.registrationNumber || '']
                ]
                : [
                    ['Propriété', insurance.property.name || ''],
                    ['Adresse', insurance.property.address || ''],
                    ['Année de construction', insurance.property.yearBuilt || '']
                ],
            theme: 'grid'
        });

        // Pied de page
        doc.setFontSize(10);
        doc.text('Merci pour votre confiance.', 10, doc.internal.pageSize.height - 10);

        // Lien vers le site web
        doc.setTextColor(46, 128, 2);
        doc.textWithLink('Visitez notre site : www.rezaplus.com', 10, doc.internal.pageSize.height - 5, { url: 'https://www.rezaplus.com' });

        // Télécharger le PDF
        doc.save(`Facture_Assurance_${insurance.policyNumber || ""}.pdf`);
    };





    return (
        <div className="">
            {insurance ? (
                <Card className="p-4" ref={invoiceRef}>
                    <Card.Header className="text-center">
                        <div style={{ width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                            <div className=""><h3>{insurance?.type?.name || ""}</h3></div>
                            <div className="">
                                <Dropdown>
                                    <Dropdown.Toggle variant="primary" className='btn btn-sm' id="dropdown-basic">
                                        Actions
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {
                                            insurance?.status !== "Paid" || "Completed" && (
                                                <Dropdown.Item onClick={() => { navigate(`/${ROUTES.DASHBOARD}/${ROUTES.INSURANCES_LIST_PLATEFORM_EDIT}/${paramInsurance.id || insurance._id}`) }}><FaEdit /> Modifier </Dropdown.Item>
                                            )}
                                        {
                                            insurance?.status !== "Paid" || "Completed" && (
                                                <Dropdown.Item onClick={() => setShowPayModal(true)}><FaMoneyCheckAlt /> Payer</Dropdown.Item>
                                            )
                                        }
                                        <Dropdown.Item onClick={() => { handleDownloadInvoiceWithJsPDF() }}><FaDownload /> Télécharger</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Row className="mb-4">
                            <Col>
                                <h5><strong>Numéro de police : </strong>{insurance?.policyNumber || ""}</h5>
                                <h5><strong>Assuré : </strong>{insurance?.userId?.firstname || ""} {insurance?.userId?.lastname || ""}</h5>
                                <h5><strong>Montant à payer : </strong>{insurance?.premiumAmount || ""} DZD</h5>
                            </Col>
                            <Col className="text-right">
                                <p><strong>Date de début : </strong>{new Date(insurance?.startDate).toLocaleDateString()}</p>
                                <p><strong>Date de fin : </strong>{new Date(insurance?.endDate).toLocaleDateString()}</p>
                                <p><strong>Statut de paiement : </strong>{insurance?.paymentStatus == "Paid" || "Completed" ? "Payé" : "Impayé"}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <Card className="p-3">
                                    <h5 className="text-dark">Détails de l{"'"}objet assuré</h5>
                                    {insurance?.insuredObjectType === 'Vehicle' && insurance?.vehicle && (
                                        <>
                                            <Image
                                                src={insurance.vehicle.images[0] || emptyImage}
                                                className="img-fluid rounded-lg mb-3 border "
                                                alt={insurance.vehicle.brand || ""}
                                            />
                                            <p><strong>Véhicule : </strong>{insurance?.vehicle.brand || ""} {insurance?.vehicle?.model || ""} ({insurance?.vehicle?.year || ""})</p>
                                            <p><strong>Couleur : </strong>{insurance?.vehicle.color || ""}</p>
                                            <p><strong>Immatriculation : </strong>{insurance?.vehicle?.registrationNumber || ""}</p>
                                        </>
                                    )}
                                    {insurance?.insuredObjectType === 'Property' && insurance?.property && (
                                        <>
                                            <Image
                                                src={insurance.property.images[0] || emptyImage}
                                                className="img-fluid rounded-lg mb-3 border"
                                                alt={insurance.property.name || ""}
                                            />
                                            <p><strong>Propriété : </strong>{insurance?.property.name || ""}</p>
                                            <p><strong>Adresse : </strong>{insurance?.property.address || ""}</p>
                                            <p><strong>Année de construction : </strong>{insurance?.property?.yearBuilt || ""}</p>
                                        </>
                                    )}
                                </Card>
                            </Col>

                            <Col md={6}>
                                <Card className="p-3">
                                    <h5 className="text-dark">Détails de la couverture</h5>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item><strong>Type d{"'"}assurance : </strong>{insurance?.type?.description || ""}</ListGroup.Item>
                                        {/*<ListGroup.Item><strong>Prime annuelle : </strong>{insurance?.premiumAmount || ""} DZD</ListGroup.Item> */}
                                        <ListGroup.Item><strong>Date de début : </strong>{new Date(insurance?.startDate).toLocaleDateString()}</ListGroup.Item>
                                        <ListGroup.Item><strong>Date de fin : </strong>{new Date(insurance?.endDate).toLocaleDateString()}</ListGroup.Item>
                                        <ListGroup.Item><strong>Statut de paiement : </strong>{insurance?.paymentStatus == "Paid" || "Completed" ? "Payé" : "Impayé"}</ListGroup.Item>
                                    </ListGroup>
                                </Card>
                            </Col>
                        </Row>

                        <div className="mt-4 text-center">

                        </div>
                    </Card.Body>
                </Card>
            ) : (
                <p>Aucune assurance disponible.</p>
            )}

            {/* Modal pour confirmer le paiement */}
            <Modal show={showPayModal} onHide={() => setShowPayModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation du paiement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Mode de paiement</Form.Label>
                            <Select
                                options={paymentOptions}
                                onChange={setPaymentMethod}
                                placeholder="Choisissez le mode de paiement"
                            />
                        </Form.Group>
                        <Form.Group controlId="prixexpense">
                            <Form.Label>Date de la dépense </Form.Label>
                            <Form.Control style={{ height: "40px" }}
                                type="date"
                                value={date}
                                onChange={(e) => {
                                    setdate(e.target.value);
                                }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Justificatif de paiement</Form.Label>
                            <Form.Control
                                type="file"
                                accept="image/*"
                                onChange={(e) => handleFileUpload(e.target.files[0])}
                                disabled={loading} // Désactiver le champ pendant l'upload
                            />
                            {loading && <p>Chargement du fichier...</p>}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" size="sm" onClick={() => setShowPayModal(false)}>
                        Annuler
                    </Button>
                    <Button variant="primary" size="sm" disabled={loading} onClick={handlePayInsurance}>
                        Confirmer
                    </Button>
                </Modal.Footer>
            </Modal>


        </div>
    );
};

export default InsuranceInvoiceDetailPage;
