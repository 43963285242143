import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Card, Spinner, Alert } from "react-bootstrap";
import { fetchTermAndConidtionById } from "../../actions/request/TermAndConidtionRequest";
import { useDispatch, useSelector } from "react-redux";

const AgencyTermsPage = () => {

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const agencyId = params.get("agency"); // Récupérer l'ID de l'agence depuis l'URL

  const [termsData, setTermsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const termsAndCondition = useSelector((state) => state?.termsAndConditions?.termsAndCondition);
  const loadingTermsAndCondition = useSelector((state) => state?.termsAndConditions?.loadingTermsAndCondition);





  useEffect(() => {
    dispatch(fetchTermAndConidtionById("", agencyId))
  }, [dispatch, agencyId]);





  if (loadingTermsAndCondition) {
    return (
      <Container className="text-center mt-5" style={{ height: "100vh" }}>
        <Spinner animation="border" variant="primary" />
        <p>Chargement des termes et conditions...</p>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="text-center mt-5" style={{ height: "100vh" }}>
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container fluid className="mt-4" style={{ minHeight: "100vh" }}>
      <h3 className="text-center mb-4">Termes et Conditions de l{"'"}agence de reservaiton </h3>
      <Card className="p-4 mb-4">
        <h4 className="text-primary">{termsAndCondition?.userAdd?.company || "Agence Réservation"}</h4>
        <p>📧 Contact : {termsAndCondition?.agencyContact || ""}</p>

      </Card>

      {/* Sections dynamiques */}
     
        <div style={{ minHeight:"100vh" }} dangerouslySetInnerHTML={{ __html: termsAndCondition.termsHtml || "<p>Aucune condition définie.</p>" }} />
       
    </Container>
  );
};

// Fonction pour formater le titre des sections
const formatSectionTitle = (key) => {
  const sectionMap = {
    generalConditions: "Conditions Générales",
    eligibility: "Éligibilité",
    reservationAndPayment: "Réservations et Paiements",
    vehicleRental: "Location de Véhicules",
    propertyRental: "Location de Propriétés",
    extraFees: "Frais Supplémentaires",
    privacyPolicy: "Politique de Confidentialité",
    disputeResolution: "Résolution des Litiges",
    forceMajeure: "Force Majeure",
    seo: "SEO et Référencement",
    agencyCustomizations: "Personnalisation Agence",
  };
  return sectionMap[key] || key;
};

export default AgencyTermsPage;
