import { useEffect, useState, useRef } from "react";
import { Offcanvas, Form, Button, ListGroup, InputGroup } from "react-bootstrap";
import { FaPaperPlane, FaReply, FaUserCircle, FaRegSadTear, FaMeh, FaRegSmile, FaSmileBeam, FaGrinStars, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { createComment, fetchCommentsAll, replyToComment } from "../../actions/request/CommentRequest";
import { toast } from "react-toastify";
import { getAndCheckLocalStorage } from "../../utils/storage/localvalueFuction";
import { localStorageKeys } from "../../utils/storage/localvalue";
import { profileRoleType } from "../../utils/dataApi/dataFormApi";
import { MdPerson } from "react-icons/md";
import { formatDateView } from "../../utils/functionData/dateFunction";
import moment from "moment";

const CommentCanvas = ({ show, handleClose, reservation, agency, client, vehicle, property, startDate, endDate }) => {
    const dispatch = useDispatch();
    const comments = useSelector(state => state.comments.comments);
    const [commentText, setCommentText] = useState("");
    const [replyingTo, setReplyingTo] = useState(null);
    const [rating, setRating] = useState(3);
    const scrollRef = useRef(null);

    // Récupération automatique des commentaires toutes les 5 secondes
    useEffect(() => {
        const fetchComments = () => {
            dispatch(fetchCommentsAll("", client?._id || "", "", vehicle?._id || "", property?._id || "", startDate, endDate));
        };

        fetchComments();
        const interval = setInterval(fetchComments, 5000);
        return () => clearInterval(interval);
    }, [dispatch, client, reservation, vehicle, property, startDate, endDate]);

    // Scroll automatique au dernier message
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    }, [dispatch]);

    const handleSendComment = () => {
        if (!commentText.trim()) return;

        const commentData = {
            reservation: reservation?._id,
            agency: agency?._id || getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.USER ? getAndCheckLocalStorage(localStorageKeys.userId) : null,
            client: client?._id,
            comment: commentText,
            rating: rating,
        };

        if (replyingTo) {
            dispatch(replyToComment(replyingTo, { user: agency?._id || getAndCheckLocalStorage(localStorageKeys.userId), responseText: commentText }, toast));
            setReplyingTo(null);
            dispatch(fetchCommentsAll("", client?._id || "", "", vehicle?._id || "", property?._id || "", startDate, endDate));
        } else {
            dispatch(createComment(commentData, toast));
            dispatch(fetchCommentsAll("", client?._id || "", "", vehicle?._id || "", property?._id || "", startDate, endDate));
        }

        setCommentText("");
        setRating(3);
    };

    // Fonction pour afficher les étoiles sous forme d'icônes
    const renderRatingStars = (rating) => {
        const icons = [FaRegSadTear, FaMeh, FaRegSmile, FaSmileBeam, FaGrinStars];
        return icons.map((Icon, index) => (
            <Icon key={index} size={20} color={index + 1 <= rating ? "#FFD700" : "#CCCCCC"} style={{ cursor: "pointer" }} />
        ));
    };

    return (
        <Offcanvas show={show} onHide={handleClose} placement="end">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Commentaires</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex flex-column">
                {client && (
                    <div className="border-bottom py-3 my-1 px-3 text-center rounded-sm shadow-sm">
                        <strong> <MdPerson size={30} /> {reservation?.firstname || client?.firstname} {reservation?.lastname || client?.lastname}</strong>
                    </div>
                )}

                <div ref={scrollRef} style={{ flexGrow: 1, overflowY: "auto", maxHeight: "60vh" }}>
                    <ListGroup variant="light">
                        {comments.map(comment => (
                            <div key={comment._id} className={`d-flex flex-column ${comment.agency._id === agency._id ? "align-items-end" : "align-items-start"}`}>
                                <ListGroup.Item className={`mb-2 shadow-sm ${comment.agency._id === agency._id ? "bg-primary text-white align-items-end" : "bg-light text-dark align-items-start"}`} style={{
                                    maxWidth: "100%",
                                    padding: "5px",
                                    borderRadius: "10px",
                                    // background: comment.agency?._id === agency?._id ? "#007bff" : "#e9ecef",
                                    // color: comment.agency?._id === agency?._id ? "#fff" : "#000",
                                    textAlign: comment.agency?._id === agency?._id ? "right" : "left"
                                }}>
                                    <strong>{comment.agency?._id === agency?._id ? "Vous" : comment?.agency?.firstname}</strong>
                                    <p>{comment.comment}</p>
                                    <p className="font-10">{formatDateView(comment?.createdAt)} {moment(comment?.createdAt).format("HH:mm")}</p>

                                    <div className="mb-1">{renderRatingStars(comment.rating)}</div>

                                    <Button variant="light" size="sm" onClick={() => setReplyingTo(comment._id)}>
                                        <FaReply /> Répondre
                                    </Button>
                                </ListGroup.Item>

                                {/* Affichage des réponses aux commentaires */}
                                {comment?.responses && comment?.responses.length > 0 && (
                                    <>
                                        <div className="text-black" style={{ width: "80%", textAlign: "center" }}>Reponses </div>
                                        <div className="ml-4">
                                            {comment.responses.map((reply, index) => (
                                                <ListGroup.Item key={index} className="mb-2 shadow-sm" style={{
                                                    maxWidth: "80%",
                                                    padding: "5px",
                                                    borderRadius: "10px",
                                                    background: "#f1f1f1",
                                                    color: "#000",
                                                    textAlign: "left",
                                                    marginLeft: "15px"
                                                }}>
                                                    {/*<strong>{reply.user?.firstname || ""}</strong> */}
                                                    <p>{reply.responseText}</p>
                                                    <p className="font-10">{moment(reply.createdAt).format("DD/MM/YYYY HH:mm")}</p>
                                                </ListGroup.Item>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                    </ListGroup>
                </div>

                {replyingTo && (
                    <div className="mt-3 text-center">
                        <small>Répondre à "{[...comments].find(item => item._id === replyingTo)?.comment}"...</small>
                        <div><FaTimes size={20} onClick={() => setReplyingTo(null)} style={{ cursor: "pointer", color: "red" }} /></div>
                    </div>
                )}

                <div className="text-center mt-2">
                    {/*<strong>Note :</strong> */}
                    <div className="d-flex justify-content-center">
                        {[1, 2, 3, 4, 5].map((index) => {
                            const Icon = [FaRegSadTear, FaMeh, FaRegSmile, FaSmileBeam, FaGrinStars][index - 1];
                            return <Icon key={index} size={30} color={index <= rating ? "#FFD700" : "#CCCCCC"} style={{ cursor: "pointer" }} onClick={() => setRating(index)} />;
                        })}
                    </div>
                </div>
                <InputGroup className="mt-3">
                    <Form.Control placeholder="Écrire un commentaire..." value={commentText} onChange={(e) => setCommentText(e.target.value)} />
                    <Button variant="primary" size="sm" onClick={handleSendComment}>
                        <FaPaperPlane />
                    </Button>
                </InputGroup>


            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default CommentCanvas;
