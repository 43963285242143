import React, { useRef, useState, useEffect } from "react";
import SignaturePad from "react-signature-pad-wrapper";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import axios from "axios";
import { baseurl } from "../../../utils/url/baseurl";
import { getAndCheckLocalStorage } from "../../../utils/storage/localvalueFuction";
import { localStorageKeys } from "../../../utils/storage/localvalue";
import { handleImageUploadCloudOnly } from "../../../actions/upload/UploadFileCloud";
import { UserUpdateById } from "../../../actions/request/UserRequest";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_CUSTOMER_SUCCESS, FETCH_USER_SUCCESS } from "../../../app/actions/actions";
import { toast } from "react-toastify";
import { MdSave } from "react-icons/md";

const SettingsSignature = () => {
  const dispatch = useDispatch();
  const loadingUser = useSelector((state) => state.users.loadingUser);
  const sigPad = useRef(null);

  const [signature, setSignature] = useState({
    image: null, // Stocke la signature sous forme d'image
    name: "", // Nom de la signature
  });

  const userId = getAndCheckLocalStorage(localStorageKeys.userId);

  // Charger la signature enregistrée
  useEffect(() => {
    dispatch({ type: FETCH_USER_SUCCESS });
    dispatch({ type: FETCH_CUSTOMER_SUCCESS });

    axios
      .get(`${baseurl.url}/api/v1/users/get_user/${userId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${baseurl.TypeToken} ${baseurl.token}`,
        },
      })
      .then((response) => {
        if (response?.data?.data?.signatureElectronique) {
          setSignature({
            image: response?.data?.data?.signatureElectronique,
            name: "Signature enregistrée",
          });
        }
      })
      .catch((error) => {
        console.error("Erreur de chargement de la signature :", error);
      });
  }, [dispatch]);

  // Effacer la signature dessinée
  const clearSignature = () => {
    sigPad.current.clear();
    setSignature({ image: null, name: "" });
  };

  // Enregistrer la signature dessinée
  const saveSignature = () => {
    if (sigPad.current.isEmpty()) {
      toast.error("Veuillez dessiner votre signature", { position: "bottom-right" });
      return;
    }
    const signatureData = sigPad.current.toDataURL("image/png");
    setSignature({ image: signatureData, name: "Signature Electronique" });

    // Sauvegarde dans l'API
    dispatch(
      UserUpdateById(userId, { signatureElectronique: signatureData }, toast)
    );
  };

  // Analyser l'image pour vérifier si c'est une signature
  const analyzeImage = async (file) => {
    // try {
    //   const formData = new FormData();
    //   formData.append("image", file);

    //   // API d'analyse d'image (ex: Google Vision API, AWS Rekognition, etc.)
    //   const response = await axios.post(
    //     "https://api.example.com/analyze-image", // Remplace avec l'URL réelle de ton API
    //     formData
    //   );

    return true // response.data.isSignature; // Retourne `true` si c'est une signature, `false` sinon
    // } catch (error) {
    //   console.error("Erreur lors de l'analyse de l'image :", error);
    //   return false;
    // }
  };

  // Gérer l'upload d'une image
  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    toast.info("Analyse de l'image en cours...", { position: "bottom-right" });

    const isSignature = await analyzeImage(file);

    if (!isSignature) {
      toast.error("L'image téléchargée ne semble pas être une signature !", { position: "bottom-right" });
      return;
    }

    const imageUrl = await handleImageUploadCloudOnly(file);
    if (imageUrl) {
      setSignature({ image: imageUrl, name: file.name });

      // Sauvegarde dans l'API
      dispatch(UserUpdateById(userId, { signatureElectronique: imageUrl }, toast));
    }
  };

  return (
    <div className="container mt-4 border rounded-lg shadow-sm">
      <div style={{ width:"100%",display:"flex", justifyContent:"space-between" }}>

        <div><h4>Signature Électronique</h4></div>
        <div>
          <div className="flex flex-wrap">
            <Button variant="link" size="sm" className="mt-2" onClick={clearSignature} disabled={loadingUser}>
              Effacer
            </Button>
            {loadingUser ? (
              <Button variant="primary" size="sm" className="mt-2 ml-2">
                <Spinner animation="border" variant="light" size="sm" />
              </Button>
            ) : (
              <Button variant="primary" size="sm" className="mt-2 ml-2" onClick={saveSignature}>
                <MdSave />  Enregistrer
              </Button>
            )}
          </div>
        </div>

      </div>
      <Card>
        <Card.Body>
          <Row>
            <Col md={6} className="border ">
              <h6>Signer ici</h6>
              <SignaturePad ref={sigPad} options={{ penColor: "black" }} className="signature-canvas border p-2" />


            </Col>

            <Col md={6} className="border">
              <h6>Ou Téléchargez une Image</h6>
              <Form.Group>
                <Form.Control type="file" accept="image/*" onChange={handleImageUpload} />
              </Form.Group>
              {signature.image && <img src={signature.image} alt="Signature" className="img-thumbnail mt-2" width={200} />}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SettingsSignature;
