import React, { useState, useEffect } from 'react';
import { Button, Form, Modal, Table, Dropdown, Image, Col, Row, Card, Badge, Accordion } from 'react-bootstrap';
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import { BiSupport } from "react-icons/bi";
import { useDispatch, useSelector } from 'react-redux';
import { fetchContactsAll } from '../../../actions/request/ContactRequest';
import { formatDateView } from '../../../utils/functionData/dateFunction';
import moment from 'moment';

const ContactManagementPage = () => {
    const dispatch = useDispatch();
    const contactsData = useSelector((state) => state.contacts.contacts);

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [showMessageModal, setShowMessageModal] = useState(false);

    useEffect(() => {
        dispatch(fetchContactsAll());
    }, [dispatch]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleStatusChange = (selectedOption) => {
        setSelectedStatus(selectedOption);
    };

    const handleShowMessage = (message) => {
        setSelectedMessage(message);
        setShowMessageModal(true);
    };

    const handleCloseMessageModal = () => {
        setShowMessageModal(false);
    };

    return (
        <div className="container-fluid p-3" style={{ marginBottom: "200px" }}>
            <Card>
                <Card.Header>
                    <h3> <BiSupport /> Liste des messages envoyés depuis la page de contact </h3>
                </Card.Header>
                <Card.Body style={{ minHeight: "500px" }}>
                    <Row className="mb-4">
                        {/*<Col md={3}>
                            <Form.Group controlId="search">
                                <Form.Control
                                    type="text"
                                    placeholder="Recherche"
                                    value={searchTerm}
                                    style={{ height: "40px" }}
                                    onChange={handleSearchChange}
                                />
                            </Form.Group>
                        </Col>

                        <Col md={3}>
                            <Form.Group controlId="status">
                                <Select
                                    options={[
                                        { value: 'Active', label: 'Actif' },
                                        { value: 'Blocked', label: 'Bloqué' }
                                    ]}
                                    onChange={handleStatusChange}
                                    isClearable
                                    placeholder="Statut"
                                />
                            </Form.Group>
                        </Col> */}
                    </Row>

                    <Accordion defaultActiveKey="0">
                        <Table striped bordered hover className="mt-5" responsive>
                            <thead>
                                <tr>
                                    <th>Nom complet / Entreprise</th>
                                    <th>Email</th>
                                    <th>Sujet</th>
                                    <th>Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contactsData.map((contact, index) => (
                                    <React.Fragment key={contact._id}>
                                        <tr>
                                            <td>{contact.name || ""} </td>
                                            <td>{contact.email || ""} </td>
                                            <td>{contact.category}</td>
                                            <td>{formatDateView(contact?.createdAt)} -  {moment(contact?.createdAt).format("HH:MM")}</td>
                                            <td>
                                                <Button 
                                                    variant="info" 
                                                    size="sm" 
                                                    onClick={() => handleShowMessage(contact.message)}
                                                >
                                                    Voir le message
                                                </Button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5">
                                                <Accordion.Item eventKey={index.toString()}>
                                                    <Accordion.Header>
                                                        <strong> Messages envoyés à ce contact </strong>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        {contact.messages_sends.length > 0 ? (
                                                            contact.messages_sends.map((msg, idx) => (
                                                                <div key={idx} className="p-3 border rounded mb-3">
                                                                    <h6><strong>Objet :</strong> {msg.message.subject || "Sans objet"}</h6>
                                                                    <p><strong>Expéditeur :</strong> {msg.message.admin?.name || "Admin inconnu"}</p>
                                                                    <p><strong>Date :</strong> {new Date(msg.message.createdAt).toLocaleString()}</p>
                                                                    <div 
                                                                        className="border p-2 bg-light rounded"
                                                                        dangerouslySetInnerHTML={{ __html: msg.message.message }}
                                                                    />
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <p>Aucun message envoyé.</p>
                                                        )}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </Table>
                    </Accordion>
                </Card.Body>
            </Card>

            {/* Modale pour voir le message */}
            <Modal show={showMessageModal} onHide={handleCloseMessageModal} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedMessage ? (
                        <div 
                            className="border p-3 bg-light rounded"
                            dangerouslySetInnerHTML={{ __html: selectedMessage }}
                        />
                    ) : (
                        <p>Aucun message sélectionné.</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseMessageModal}>Fermer</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ContactManagementPage;
