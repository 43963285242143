import React, { useState, useEffect, useMemo } from 'react';
import { Line, Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title,
    Tooltip, Legend
} from 'chart.js';
import { Badge, Button, Card, Col, Dropdown, DropdownButton, Form, Image, Modal, Row, Spinner, Tab, Table, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReservationsAll, fetchReservationsAllByidClientId, fetchReservationsAllByidUserId } from
    '../../actions/request/ReservationRequest';
import { fetchDamagesAll, fetchDamagesAllByUserId } from '../../actions/request/DamageRequest';
import { fetchPropertysAll, fetchPropertysAllByOwnerId, fetchPropertysFilters } from
    '../../actions/request/PropertyRequest';
import { fetchVehiclesAll, fetchVehiclesAllByOwnerId, fetchVehiclesAllFilter } from
    '../../actions/request/VehicleRequest';
import { fetchInsurancesAll, fetchInsurancesAllByUserId } from '../../actions/request/InssuranceRequest';
import { PaymentDeleteById, fetchPaymentsAll } from '../../actions/request/PaymentRequest';
import moment from 'moment';
import { emptyImage, logoApplication, profileRoleType } from '../../utils/dataApi/dataFormApi';
import { localStorageData, localStorageKeys } from '../../utils/storage/localvalue';
import { getAndCheckLocalStorage } from '../../utils/storage/localvalueFuction';
import { RiEye2Fill, RiFileExcel2Fill, RiFilePdf2Fill } from 'react-icons/ri';
import { ROUTES } from '../../config/routingUrl';
import { Link, useNavigate } from 'react-router-dom';
import { MdCategory, MdColorize, MdCopyAll, MdDateRange, MdDelete, MdDeleteOutline, MdEdit, MdFilePresent, MdFilter, MdFilter9Plus, MdMap, MdMoney, MdOutlineSearch, MdReport, MdWeb } from 'react-icons/md';
import Select from "react-select";
import { toast } from 'react-toastify';
import html2canvas from 'html2canvas';
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { fetchExpenseCategorysAll } from '../../actions/request/ExpenseCategoryRequest';
import { ExpensesDeleteById, ExpensesUpdateById, fetchExpensessAll } from '../../actions/request/ExpenseRequest';
import { fetchExchangeRateGetCurency } from '../../actions/request/ExchangeRateRequest';
import { formatDateView } from '../../utils/functionData/dateFunction';
import { FETCH_EXPENSE_SUCCESS, FETCH_PAYMENTS_SUCCESS, FETCH_PAYMENT_SUCCESS } from '../../app/actions/actions';
import { fetchMaintenancesAll } from '../../actions/request/MaintenanceRequest';
import { fetchCustomersAll } from '../../actions/request/CustomerRequest';
import { fetchUsersAll } from '../../actions/request/UserRequest';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip, Legend);

const StatisticsReportPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const expenses = useSelector((state) => state.expenses.expenses);
    const expensecategorys = useSelector((state) => state.expensecategorys.expensecategorys);

    const reservations = useSelector((state) => state.reservations.reservations);
    const paymentsData = useSelector((state) => state.payments.payments);
    const damages = useSelector((state) => state.damages.damages);
    const propertys = useSelector((state) => state.propertys.propertys);
    const vehicles = useSelector((state) => state.vehicles.vehicles);
    const insurances = useSelector((state) => state.insurances.insurances);
    const users = useSelector((state) => state.users.users);
    const customers = useSelector((state) => state.customers.customers);
    const loadingExpense = useSelector((state) => state.expenses.loadingExpense);
    const loadingPayment = useSelector((state) => state.payments.loadingPayment);

    const [userSelect, setuserSelect] = useState()
    const [customerSelect, setcustomerSelect] = useState();
    const [propertySelect, setpropertySelect] = useState();
    const [vehicleSelect, setvehicleSelect] = useState();
    const [payments, setpayments] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [showLoading, setshowLoading] = useState(false);
    const [showWebSite, setshowWebSite] = useState(false);
    const [showClientModal, setShowClientModal] = useState(false);
    const [uniqueClients, setUniqueClients] = useState([]);

    const userId = getAndCheckLocalStorage(localStorageKeys.userId);
    const userRole = getAndCheckLocalStorage(localStorageKeys.profileRole);

    const [chartData, setChartData] = useState(null);

    const  callFunctionElements = ()=>{
        setuserSelect(null);
        setvehicleSelect(null)
        setpropertySelect(null);
        setcustomerSelect(null)
        setStartDate("")
        setEndDate("");

        dispatch({ type: FETCH_EXPENSE_SUCCESS })
        dispatch({ type: FETCH_PAYMENT_SUCCESS })

        if (userRole === profileRoleType.USER) {
            dispatch(fetchExpenseCategorysAll());
            dispatch(fetchExpensessAll(startDate, endDate, "", "", propertySelect?.value, vehicleSelect?.value,getAndCheckLocalStorage(localStorageKeys.userId)|| userSelect?.value));
            dispatch(fetchDamagesAllByUserId(userId));
            dispatch(fetchInsurancesAllByUserId(userId));
            dispatch(fetchPropertysAllByOwnerId(getAndCheckLocalStorage(localStorageKeys.userId)));
            dispatch(fetchVehiclesAllByOwnerId(getAndCheckLocalStorage(localStorageKeys.userId)));
            dispatch(fetchPaymentsAll(startDate,endDate,'',getAndCheckLocalStorage(localStorageKeys.userId)));
            dispatch(fetchReservationsAllByidUserId(userId));
        } else if (userRole === profileRoleType.CUSTOMER) {
            dispatch(fetchReservationsAllByidClientId(userId));
            dispatch(fetchPaymentsAll(startDate,endDate,getAndCheckLocalStorage(localStorageKeys.userId)));
        } else {
            dispatch(fetchExpensessAll(startDate, endDate, "", "", propertySelect?.value, vehicleSelect?.value, userSelect?.value));
            dispatch(fetchDamagesAll());
            dispatch(fetchInsurancesAll());
            dispatch(fetchPropertysFilters());
            dispatch(fetchVehiclesAllFilter());
            dispatch(fetchPaymentsAll());
            dispatch(fetchReservationsAll());
            dispatch(fetchCustomersAll());
            dispatch(fetchUsersAll());
        }

    }

    // Récupération des données lors du chargement du composant
    useEffect(() => {

        callFunctionElements();
        
    }, [dispatch]);

    useEffect(() => {
        const userId = getAndCheckLocalStorage(localStorageKeys.userId);
        const userRole = getAndCheckLocalStorage(localStorageKeys.profileRole);

        let paymentGetData = [];
        // if (userRole === profileRoleType.USER) {
        //     paymentGetData = paymentsData?.filter(pay => pay.reservation?.userAdd === userId) || [];
        // } else if (userRole === profileRoleType.CUSTOMER) {
        //     paymentGetData = paymentsData?.filter(pay => pay.reservation?.client?._id === userId) || [];
        // } else {
            paymentGetData = paymentsData || [];
        // }
        setpayments(paymentGetData);
    }, [paymentsData]);

    // Filtrage des clients uniques
    useEffect(() => {
        const uniqueClientsSet = new Set(reservations.map(reservation => reservation.client._id));
        const clientsArray = [...uniqueClientsSet].map(id => {
            const clientReservations = reservations.filter(res => res.client._id === id);
            const usagePercentage = (clientReservations.length / reservations.length * 100).toFixed(2);
            const client = clientReservations[0]?.client; // Get the first client details
            return client ? {
                id: client._id,
                firstname: client.firstname,
                lastname: client.lastname,
                email: client.email,
                phone: client.phone,
                address: client.address,
                usagePercentage,
            } : null;
        }).filter(client => client !== null);
        setUniqueClients(clientsArray);



    }, [reservations]);




    useEffect(() => {
        // Regrouper les dépenses par catégorie
        const categories = expenses.reduce((acc, expense) => {
            const categoryName = expense?.category?.name || expense?.insurance?.type?.name || expense?.damage?.type?.name || expense?.maintenance?.category?.name;
            if (!acc[categoryName]) {
                acc[categoryName] = 0;
            }
            acc[categoryName] += expense.amount;
            return acc;
        }, {});

        // Préparer les données pour le graphique circulaire
        const data = {
            labels: Object.keys(categories),
            datasets: [
                {
                    data: Object.values(categories),
                    backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
                    hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF']
                }
            ]
        };

        setChartData(data);
    }, [expenses]);





    const groupByMonth = (data, field) => {
        const grouped = Array(12).fill(0);
        data.forEach(item => {
            const month = moment(item.createdAt).month();
            grouped[month] += item[field];
        });
        return grouped;
    };

    const paymentsByMonth = payments ? groupByMonth(payments, 'amount') : [];
    const damagesAndInsurancesByMonth = expenses && expenses.length > 0 ? groupByMonth(expenses, 'amount') : [];
    const totalDamages = damages ? damages.reduce((sum, damage) => sum + damage.estimatedCost, 0) : 0;
    const totalInsurances = insurances ? insurances.reduce((sum, insurance) => sum + insurance.premiumAmount, 0) : 0;

    const lineChartData = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'Revenus (Paiements)',
                data: paymentsByMonth,
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
            },
            {
                label: 'Dépenses (Dommages et Assurances)',
                data: damagesAndInsurancesByMonth,
                borderColor: 'rgba(255, 99, 132, 1)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
            },
        ],
    };

    // Function to calculate days between two dates
    const calculateDays = (startDate, endDate) => {
        const start = moment(startDate);
        const end = moment(endDate);
        return end.diff(start, 'days') + 1; // Includes the first day
    };

    // Calculer le taux d'occupation



    // Utilisation de la fonction pour obtenir le taux d'occupation

    const reservationStatusCounts = (reservations) => {
        const statusCounts = { pending: 0, confirmed: 0, cancelled: 0, completed: 0 };
        reservations.forEach((reservation) => {
            if (reservation.status === 'PENDING') statusCounts.pending++;
            else if (reservation.status === 'CONFIRMED') statusCounts.confirmed++;
            else if (reservation.status === 'CANCELLED') statusCounts.cancelled++;
            else if (reservation.status === 'COMPLETED') statusCounts.completed++;
        });
        return statusCounts;
    };

    const statusCounts = reservationStatusCounts(reservations);

    const pieChartData = {
        labels: ['En attente', 'Confirmées', 'Annulées', 'Terminées'],
        datasets: [
            {
                data: [
                    statusCounts.pending,
                    statusCounts.confirmed,
                    statusCounts.cancelled,
                    statusCounts.completed
                ],
                backgroundColor: ['#FFCE56', '#36A2EB', '#FF6384', '#4BC0C0'],
                hoverBackgroundColor: ['#FFCE56', '#36A2EB', '#FF6384', '#4BC0C0'],
            },
        ],
    };

    const renderStatusBadge = (status) => {
        switch (status) {
            case 'PENDING':
                return <Badge bg="warning">En attente</Badge>;
            case 'CONFIRMED':
                return <Badge bg="success">Confirmée</Badge>;
            case 'CANCELLED':
                return <Badge bg="danger">Annulée</Badge>;
            case 'COMPLETED':
                return <Badge bg="primary">Terminée</Badge>;
            case 'Completed':
                return <Badge bg="primary">Effectué</Badge>;
            default:
                return <Badge bg="secondary">{status}</Badge>;
        }
    };

    // Options de filtre
    const vehicleOptions = vehicles.map(item => ({ value: item._id, label: item.brand })) || [];
    const propertyOptions = propertys.map(item => ({ value: item._id, label: item.name })) || [];
    const userOptions = users && users.length > 0 ? users.map((item) => {
        return ({
            value: item._id, label: `${item.companyName||""} ${item.email || ""} ${item.firstname || ""} ${item.lastname || ""} `.trim()
        })
    }) : []

    const customerOptions = customers && customers.length > 0 ? customers.map((item) => {
        return ({
            value: item._id,label: `${item.email || ""} ${item.firstname || ""} ${item.lastname || ""} `.trim()
        })
    }) : []


    const handleShowLoading = () => { setshowLoading(true) }
    const handleCloseLoading = () => { setshowLoading(false) }

    const handleSearchFilter = () => {
        handleShowLoading();
        if (userRole === profileRoleType.USER) {
            dispatch(fetchExpensessAll(startDate, endDate, "", "", propertySelect?.value, vehicleSelect?.value, userId));
            dispatch(fetchReservationsAllByidUserId(userId, startDate, endDate, propertySelect?.value, vehicleSelect?.value, "", customerSelect?.value));
            dispatch(fetchPaymentsAll(startDate, endDate,"",getAndCheckLocalStorage(localStorageKeys.userId)));
        } else if (userRole === profileRoleType.CUSTOMER) {
            dispatch(fetchReservationsAllByidClientId(userId, startDate, endDate, propertySelect?.value, vehicleSelect?.value, ""));
            dispatch(fetchPaymentsAll(startDate, endDate));
        } else {
            dispatch(fetchReservationsAll(startDate, endDate, propertySelect?.value, vehicleSelect?.value, "", customerSelect?.value, userSelect?.value));
            dispatch(fetchPaymentsAll(startDate, endDate, customerSelect?.value, userSelect?.value, propertySelect?.value, vehicleSelect?.value));
            dispatch(fetchMaintenancesAll(userSelect?.value, startDate, endDate, propertySelect?.value, vehicleSelect?.valeu));
            dispatch(fetchExpensessAll(startDate, endDate, "", "", propertySelect?.value, vehicleSelect?.value, userSelect?.value));
        }
        handleCloseLoading();
    }

    const handleShowWebsite = () => { setshowWebSite(true) }
    const handleCloseWebsite = () => { setshowWebSite(false) }

    const handleShowClientModal = () => setShowClientModal(true);
    const handleCloseClientModal = () => setShowClientModal(false);



    const [showVehicleModal, setShowVehicleModal] = useState(false);
    const [showPropertyModal, setShowPropertyModal] = useState(false);

    const handleShowVehicleModal = () => setShowVehicleModal(true);
    const handleCloseVehicleModal = () => setShowVehicleModal(false);

    const handleShowPropertyModal = () => setShowPropertyModal(true);
    const handleClosePropertyModal = () => setShowPropertyModal(false);




    const getCleanUrl = () => {
        const { protocol, hostname, port } = window.location;
        const portPart = port ? `:${port}` : '';
        return `${protocol}//${hostname}${portPart}`;
    };

    // Nouvelle fonction pour obtenir le nombre de clients uniques
    const getUniqueClientsCount = (reservations) => {
        const uniqueClients = new Set(reservations.map(reservation => reservation.client._id));
        return uniqueClients.size; // Renvoie le nombre de clients uniques
    };

    const uniqueClientsCount = getUniqueClientsCount(reservations);

    const customersOptions = uniqueClients && uniqueClients.length > 0 ? [...uniqueClients].map((item) => ({ value: item._id, label: item.firstname + " " + item.lastname + " tel :" + item.phone })) : []


    // Fonction pour calculer le total des jours d'occupation
    const calculateTotalOccupiedDays = () => {
        return reservations.reduce((total, reservation) => {
            const daysTaken = calculateDays(reservation.startDate, reservation.endDate);
            return total + daysTaken;
        }, 0);
    };

    // Utilisation de la fonction dans le composant
    const totalOccupiedDays = calculateTotalOccupiedDays();


    // Fonction pour calculer le nombre total de véhicules réservés
    const calculateTotalVehiclesReserved = () => {
        const vehicleIds = new Set(reservations.map(reservation => reservation.vehicle?._id));
        return vehicleIds.size; // Retourne le nombre unique de véhicules
    };

    // Fonction pour calculer le nombre total de propriétés réservées
    const calculateTotalPropertiesReserved = () => {
        const propertyIds = new Set(reservations.map(reservation => reservation.property?._id));
        return propertyIds.size; // Retourne le nombre unique de propriétés
    };

    // Utilisation des fonctions dans le composant
    const totalVehiclesReserved = calculateTotalVehiclesReserved();
    const totalPropertiesReserved = calculateTotalPropertiesReserved();


    const pluralize = (count, singular, plural) => {
        return count === 1 ? singular : plural || singular + 's';
    };







    // Fonction pour récupérer la liste des véhicules réservés
    const getReservedVehicles = () => {
        const vehicleIds = new Set(reservations.map(reservation => reservation.vehicle?._id));
        return Array.from(vehicleIds).map(id => {
            return vehicles.find(vehicle => vehicle._id === id);
        }).filter(vehicle => vehicle !== undefined); // Filtrer les véhicules valides
    };

    // Fonction pour récupérer la liste des propriétés réservées
    const getReservedProperties = () => {
        const propertyIds = new Set(reservations.map(reservation => reservation.property?._id));
        return Array.from(propertyIds).map(id => {
            return propertys.find(property => property._id === id);
        }).filter(property => property !== undefined); // Filtrer les propriétés valides
    };

    // Utilisation des fonctions dans le composant
    const reservedVehicles = getReservedVehicles();
    const reservedProperties = getReservedProperties();



    // Fonction pour compter les réservations confirmées
    const countConfirmedReservations = () => {
        return reservations.filter(reservation => reservation.status === 'CONFIRMED').length || 0;
    };

    // Fonction pour compter les réservations en cours
    const countOngoingReservations = () => {
        return reservations.filter(reservation => reservation.status === 'PENDING').length || 0; // Assurez-vous que 'IN_PROGRESS' est le bon statut utilisé
    };

    // Fonction pour compter les réservations terminées
    const countCompletedReservations = () => {
        return reservations.filter(reservation => reservation.status === 'COMPLETED').length || 0;
    };

    const countCancelledReservations = () => {
        return reservations.filter(reservation => reservation.status === 'CANCELLED').length || 0;
    };

    // Utilisation des fonctions dans le composant
    const totalConfirmedReservations = countConfirmedReservations();
    const totalOngoingReservations = countOngoingReservations();
    const totalCompletedReservations = countCompletedReservations();
    const totalCancelledReservations = countCancelledReservations();


    // Calculer le total des dépenses supplémentaires
    const totalExpenses = expenses?.reduce((acc, expense) => acc + expense.amount, 0) || 0;

    const totalPayments = paymentsData?.reduce((acc, payment) => acc + payment.amount, 0) || 0;

    // Calcul du total général avec la réduction
    const total = totalDamages + totalExpenses;



    // Expense export en exel 
    // Fonction pour exporter en Excel
    const exportToExcelExpense = () => {
        const worksheet = XLSX.utils.json_to_sheet(
            expenses.map((expense) => ({
                "Désignation": expense?.category?.name || expense?.insurance?.type?.name || expense?.damage?.type?.name || expense?.maintenance?.category?.name || "Autres",
                "Model / Adresse": expense?.vehicle?.brand || expense?.vehicle?.model || expense?.vehicle?.vehiclebrand || expense?.property?.name || expense?.property?.address || expense?.damage?.reservation?.vehicle?.brand || expense?.damage?.reservation?.property?.name || "",
                "Montant (DZD)": expense?.amount || 0,
                "Date estimé": moment(expense?.date).format("DD-MM-YYYY"),
                "Date de création ": moment(expense?.createdAt).format("DD-MM-YYYY"),
            }))
        );
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, worksheet, "Report");
        XLSX.writeFile(wb, "Dépense_rezaplus.xlsx");
    };

    // Fonction pour exporter en PDF
    const exportToPDFExpense = () => {

        const doc = new jsPDF({
            orientation: 'landscape', // ou simplement 'l'
            unit: 'pt',               // unité (points, mm, etc.)
            format: 'a4'              // format du papier
        });



        const imgWidth = 40;
        const imgHeight = 15;
        doc.addImage(logoApplication, 'PNG', 10, 10, imgWidth, imgHeight);

        // Titre centré
        doc.setFontSize(16);
        doc.text(`Dépenses supplémentaires ` + startDate + " " + endDate, doc.internal.pageSize.width / 2, 20, { align: 'center' });

        // Données de la table
        const tableData = expenses.map((expense) => [
            expense?.category?.name || expense?.insurance?.type?.name || expense?.damage?.type?.name || expense?.maintenance?.category?.name || "Autres",
            expense?.vehicle?.brand || expense?.vehicle?.model || expense?.vehicle?.vehiclebrand || expense?.property?.name || expense?.property?.address || expense?.damage?.reservation?.vehicle?.brand || expense?.damage?.reservation?.property?.name || "",
            expense?.amount.toFixed(2) || 0,
            formatDateView(expense?.date),
            formatDateView(expense?.createdAt),
        ]);

        // Options de table
        doc.autoTable({
            head: [
                [
                    "Désignation",
                    "Model / Adresse",
                    "Montant (DZD)",
                    "Date estimé",
                    "Date Création",
                ]
            ],
            body: tableData,
            startY: 50,
            theme: "grid",
        });

        // Pied de page
        doc.setFontSize(10);
        doc.text('Merci pour votre confiance.', 10, doc.internal.pageSize.height - 10);

        // Lien vers le site web
        doc.setTextColor(46, 128, 2);
        doc.textWithLink('Visitez notre site : www.rezaplus.com', 10, doc.internal.pageSize.height - 5, { url: 'https://www.rezaplus.com' });

        doc.save("Dépense_rezaplus.pdf");
    };







    // Paiements
    // Fonction pour exporter en Excel
    const exportToExcelPayment = () => {
        const worksheet = XLSX.utils.json_to_sheet(
            payments.map((paiement) => ({
                "Reservation": paiement?.reservation?.ordre || "",
                "Patrimoine": paiement?.vehicle?.brand || paiement?.vehicle?.model || paiement?.vehicle?.vehiclebrand || paiement?.property?.name || paiement?.property?.address || "",
                "Montant (DZD)": paiement?.amount || 0,
                "Début de réservation": moment(paiement?.reservation?.startDate || "").format("DD-MM-YYYY HH:MM"),
                "Fin de réservation": moment(paiement?.reservation?.endDate || "").format("DD-MM-YYYY HH:MM"),
                "Date de création ": moment(paiement?.createdAt).format("DD-MM-YYYY")
            }))
        );
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, worksheet, "Paiements");
        XLSX.writeFile(wb, `Paiment${startDate} ${endDate}_rezaplus.xlsx`);
    };

    // Fonction pour exporter en PDF
    const exportToPDFPayment = () => {
        const doc = new jsPDF({
            orientation: 'landscape', // ou simplement 'l'
            unit: 'pt',               // unité (points, mm, etc.)
            format: 'a4'              // format du papier
        });



        const imgWidth = 40;
        const imgHeight = 15;
        doc.addImage(logoApplication, 'PNG', 10, 10, imgWidth, imgHeight);

        // Titre centré
        doc.setFontSize(16);
        doc.text(`Paiements ` + startDate + " " + endDate, doc.internal.pageSize.width / 2, 20, { align: 'center' });

        // Données de la table
        const tableData = payments.map((paiement) => [
            paiement?.reservation?.ordre || "",
            paiement?.vehicle?.brand || paiement?.vehicle?.model || paiement?.vehicle?.vehiclebrand || paiement?.property?.name || paiement?.property?.address || "",
            paiement?.amount?.toFixed(2) || 0,
            formatDateView(paiement?.reservation?.startDate) + " " + moment(paiement?.reservation?.startDate || "").format("HH:MM"),
            formatDateView(paiement?.reservation?.endDate) + " " + moment(paiement?.reservation?.endDate || "").format("HH:MM"),
            formatDateView(paiement?.createdAt) + " ",
        ]);

        // Options de table
        doc.autoTable({
            head: [
                [
                    "Réservation",
                    "Patrimoine",
                    "Montant (DZD)",
                    "Début de réservation",
                    "Fin de réservation",
                    "Date Création",
                ]
            ],
            body: tableData,
            startY: 50,
            theme: "grid",
        });

        // Pied de page
        doc.setFontSize(10);
        doc.text('Merci pour votre confiance.', 10, doc.internal.pageSize.height - 10);

        // Lien vers le site web
        doc.setTextColor(46, 128, 2);
        doc.textWithLink('Visitez notre site : www.rezaplus.com', 10, doc.internal.pageSize.height - 5, { url: 'https://www.rezaplus.com' });

        doc.save(`Paiements${startDate} ${endDate}_rezaplus.pdf`);
    };







    const aggregatedData = useAggregatedData();
    // Calcul des totaux
    const totals = aggregatedData.reduce(
        (acc, entry) => {
            acc.totalPayments += entry.totalPayments;
            acc.totalExpenses += entry.totalExpenses;
            acc.totalDaysReserved += entry.totalDaysReserved;
            acc.totalDaysInReservationMonths += entry.totalDaysInReservationMonths; // Total des jours concernés par les mois
            acc.clientsCount += entry.clientsCount;
            return acc;
        },
        {
            totalPayments: 0,
            totalExpenses: 0,
            totalDaysReserved: 0,
            totalDaysInReservationMonths: 0, // Nouveau champ
            clientsCount: 0,
        }
    );

    // Nouvelle fonction de calcul du taux d'occupation total
    const calculateOccupancyRate = (totalDaysReserved, totalDaysInReservationMonths) => {
        // Calculer le taux d'occupation en fonction des jours réservés et des jours concernés
        return (totalDaysReserved / totalDaysInReservationMonths) * 100;
    };

    // Utilisation dans votre composant
    const totalOccupancyRate = calculateOccupancyRate(
        totals.totalDaysReserved,
        totals.totalDaysInReservationMonths
    );


    // Dépense action

    const [showExpenseDelete, setshowExpenseDelete] = useState(false)
    const handleShowExpenseDelete = () => { setshowExpenseDelete(true) }
    const handleCloseExpenseDelete = () => { setshowExpenseDelete(false) }

    const [showExpenseEdit, setshowExpenseEdit] = useState(false)
    const handleShowExpenseEdit = () => { setshowExpenseEdit(true) }
    const handleCloseExpenseEdit = () => { setshowExpenseEdit(false) }


    const [formDataExpense, setformDataExpense] = useState({
        amount: 0,
        // createdBy: getAndCheckLocalStorage(localStorageKeys.userId)
    });


    const [actionModalDelete, setactionModalDelete] = useState()
    const [expenseItem, setexpenseItem] = useState(null);


    const handleDeleteExpenseById = () => {
        const expenseId = expenseItem?._id;
        const userId = getAndCheckLocalStorage(localStorageKeys.userId) || expenseItem?.createdBy?._id;
        const userRole = getAndCheckLocalStorage(localStorageKeys.userRole);

        if (!expenseId) {
            toast.error("Dépense non trouvée");
            return;
        }
        if (!userId) {
            toast.error("Agence non disponible dans la dépense");
            return;
        }

        if (actionModalDelete === "edit") {
            dispatch(ExpensesUpdateById(expenseId, userId, "", "", "", "", formDataExpense));
        } else if (actionModalDelete === "delete") {
            dispatch(ExpensesDeleteById(expenseId, userId));
        }

        handleCloseExpenseDelete();

        // lISTES PAYMENT
        if (userRole === profileRoleType.USER) {
            dispatch(fetchExpensessAll(startDate, endDate, "", "", propertySelect?.value, vehicleSelect?.value, userId));
        } else if (userRole === profileRoleType.CUSTOMER) {
            // dispatch(fetchReservationsAllByidClientId(userId, startDate, endDate, propertySelect?.value, vehicleSelect?.value, ""));
        } else {
            dispatch(fetchExpensessAll(startDate, endDate, "", "", propertySelect?.value, vehicleSelect?.value));
        }
    };




    // delete paimeent
    const [showPaymentDelete, setshowPaymentDelete] = useState(false)
    const handleShowPaymentDelete = () => { setshowPaymentDelete(true) }
    const handleClosePaymentDelete = () => { setshowPaymentDelete(false) }


    const [paymentItem, setpaymentItem] = useState(null);


    const handleDeletePaymentById = () => {
        const paymentId = paymentItem?.reservation?._id;
        const userId = getAndCheckLocalStorage(localStorageKeys.userId);
        const userRole = getAndCheckLocalStorage(localStorageKeys.userRole);

        if (!paymentId) {
            toast.error("Paiement non trouvée");
            return;
        }
        if (!userId) {
            toast.error("Agence non disponible ");
            return;
        }
        dispatch(PaymentDeleteById(paymentId, userId));
        handleClosePaymentDelete();

        // dispatch({type:FETCH_EXPENSE_SUCCESS})
        // dispatch({type:FETCH_PAYMENT_SUCCESS})
        // lISTE ELEMENT PAYMENTS
        if (userRole === profileRoleType.USER) {
            dispatch(fetchPaymentsAll(startDate, endDate));
        } else if (userRole === profileRoleType.CUSTOMER) {
            dispatch(fetchReservationsAllByidClientId(userId));
            dispatch(fetchPaymentsAll());
        } else {
            dispatch(fetchPaymentsAll(startDate, endDate));
        }

    };








    return (
        <div className="" id="report-content">
            <Card className="mb-4">
                <Card.Header>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <h4 className="mb-4">Rapport</h4>
                        </div>
                        <div>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    {(getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                        getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.CUSTOMER ||
                        getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                        getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                        getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT
                    ) ?
                        <ul className="list-group">
                            <li className="list-group-item rounded-lg"
                                style={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between" }}>
                                <div style={{ width: "100%" }} className="mb-2">
                                    <div className="p-1">
                                        <h6>  <MdOutlineSearch /> <span>Recherche</span></h6>
                                    </div>
                                </div>
                                <div className="flex" style={{ display: "flex", flexWrap: "wrap", maxWidth: "100%" }}>

                                    {
                                        (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                                            getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                                            getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                                            getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT
                                        ) && (
                                            <Form>
                                                <Row>

                                                    <Col md={4}>
                                                        <Form.Group controlId="vehicleSelect" style={{ minWidth: "150px" }}>
                                                            <Form.Label>Véhicule</Form.Label>
                                                            <Select value={vehicleSelect} onChange={setvehicleSelect} options={vehicleOptions}
                                                                isClearable placeholder="Choix" />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={4}>
                                                        <Form.Group controlId="propertySelect" style={{ minWidth: "150px" }}>
                                                            <Form.Label>Propriété</Form.Label>
                                                            <Select value={propertySelect} isClearable isSearchable onChange={setpropertySelect}
                                                                options={propertyOptions} placeholder="Choix" />
                                                        </Form.Group>
                                                    </Col>
                                                    {(
                                                        getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                                                        getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT ||
                                                        getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN) &&
                                                        (
                                                            <Col md={4}>
                                                                <Form.Group controlId="startHour" style={{ minWidth: "200px" }}>
                                                                    <Form.Label>Agence</Form.Label>
                                                                    <Select
                                                                        value={userSelect}
                                                                        isClearable
                                                                        isSearchable
                                                                        onChange={(selectedOption) => setuserSelect(selectedOption)}
                                                                        options={userOptions}
                                                                        placeholder="Choix"
                                                                    />
                                                                </Form.Group>
                                                            </Col>)
                                                    }
                                                    {/*
                                                        (
                                                            getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                                                            getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT ||
                                                            getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN)
                                                        &&
                                                        (
                                                            <Col md={3}>
                                                                <Form.Group controlId="startHour" style={{ minWidth: "200px" }}>
                                                                    <Form.Label>Clients</Form.Label>
                                                                    <Select
                                                                        value={customerSelect}
                                                                        isClearable
                                                                        isSearchable
                                                                        onChange={(selectedOption) => setcustomerSelect(selectedOption)}
                                                                        options={customerOptions}
                                                                        placeholder="Choix"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        )
                                                        */}

                                                </Row>
                                            </Form>
                                        )
                                    }

                                </div>
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    <div className="p-1">
                                        <label>{"Début"}</label>
                                        <input type="date" style={{ height: "40px" }} className="form-control" value={startDate} placeholder="début"
                                            onChange={(e) => setStartDate(e.target.value)} />
                                    </div>
                                    <div className="p-1">
                                        <label>{"Fin"}</label>
                                        <input type="date" style={{ height: "40px" }} className="form-control" value={endDate} placeholder="fin"
                                            onChange={(e) => setEndDate(e.target.value)} />
                                    </div>
                                    <div className="m-1">
                                        <label>{""}</label>
                                        <div>
                                            <Button variant='primary' id="element-to-exclude" className="m-1" size='sm' onClick={handleSearchFilter}>
                                                <MdOutlineSearch />
                                            </Button>
                                            {
                                                (
                                                    userSelect || vehicleSelect || propertySelect || customerSelect || startDate || endDate
                                                ) && (
                                                    <Button variant="danger" size="sm" className="m-1 text-white" onClick={callFunctionElements}>
                                                        <MdDeleteOutline /> Effacer
                                                    </Button>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        : ""
                    }
                </Card.Body>
            </Card>

            {/* Cartes des statistiques */}

            <Tabs defaultActiveKey="generale" id="reservation-details-tabs" className="mb-3">

                {/* Tab pour Total des jours d'occupation */}
                <Tab eventKey="generale" title="Vue génerale">
                    <Row>
                        <Col md={3}>
                            <Card className="mb-4 border rounded-lg">
                                <Card.Body>
                                    <p className="card-text"><strong>Revenus</strong></p>
                                    <hr />
                                    <p className="card-text text-lg">
                                        {totalPayments} DZD
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card className="mb-4 border rounded-lg">
                                <Card.Body>
                                    <p className="card-text"><strong>Dépenses</strong></p>
                                    <hr />
                                    <p className="card-text text-lg">
                                        {totalExpenses} DZD
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card className="mb-4 border rounded-lg">
                                <Card.Body>
                                    <p className="card-text"><strong>Réservations</strong></p>
                                    <hr />
                                    <div className="card-text text-lg" style={{ display: "flex", flexDirection: "row" }}>
                                        <div>
                                            <span style={{ fontSize: "20px" }}>{reservations && reservations.length > 0 ?
                                                Number(reservations.length) : "Aucune"}</span>
                                        </div>
                                        <div style={{ display: "flex", flexWrap: "wrap" }} >

                                            <div><Badge bg="warning" className="text-white m-1"> {totalOngoingReservations} En cours </Badge> </div>
                                            <div><Badge bg="success" className="text-white m-1"> {totalConfirmedReservations} Confirmée(s) </Badge> </div>
                                            <div><Badge bg="primary" className="text-white m-1"> {totalCompletedReservations} Terminée(s) </Badge> </div>
                                            <div><Badge bg="danger" className="text-white m-1"> {totalCancelledReservations} Anulée(s) </Badge> </div>

                                        </div>
                                    </div>

                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={3}>
                            <Card className="mb-4 border rounded-lg">
                                <Card.Body>
                                    <p className="card-text"><strong>Jours</strong></p>
                                    <hr />
                                    <p className="card-text text-lg">
                                        <span style={{ fontSize: "20px" }}>{totals?.totalDaysReserved?.toFixed(0)}</span>
                                    </p>

                                </Card.Body>
                            </Card>
                        </Col>

                        {(
                            getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.USER ||
                            getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.ADMIN ||
                            getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.SUPER_ADMIN ||
                            getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.SUPPORT) &&
                            <Col md={4}>
                                <Card className="mb-4 border rounded-lg">
                                    <Card.Body>
                                        <p className="card-text"><strong>Clients ayant effectué des réservations </strong></p>
                                        <hr />
                                        <p className="card-text text-lg">
                                            <span style={{ fontSize: "20px" }}>{uniqueClientsCount > 0 ? uniqueClientsCount : "Aucun"}</span>

                                            <span>{
                                                uniqueClientsCount > 0 && (<Link to="#" onClick={handleShowClientModal}
                                                    className="text-sm text-decoration-underline">
                                                    <RiEye2Fill /> Voir tous
                                                </Link>)
                                            }
                                            </span>
                                        </p>
                                    </Card.Body>
                                </Card>
                            </Col>
                        }

                        <Col md={4}>
                            <Card className="mb-4 border rounded-lg">
                                <Card.Body>
                                    <p className="card-text"><strong>Véhicules réservés au total</strong></p>
                                    <hr />
                                    <p className="card-text text-lg">
                                        <span style={{ fontSize: "20px" }}>{reservedVehicles.length}
                                        </span>


                                        <span>{
                                            reservedVehicles.length > 0 && (<Link to="#" onClick={handleShowVehicleModal}
                                                className="text-sm text-decoration-underline">
                                                <RiEye2Fill /> Voir tous
                                            </Link>)
                                        }
                                        </span>

                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="mb-4 border rounded-lg">
                                <Card.Body>
                                    <p className="card-text"><strong>Propriétés réservés au total</strong></p>
                                    <hr />
                                    <p className="card-text text-lg">
                                        <span style={{ fontSize: "20px" }}>{reservedProperties.length} </span>
                                        <span>{
                                            reservedProperties.length > 0 && (<Link to="#" onClick={handleShowPropertyModal}
                                                className="text-sm text-decoration-underline">
                                                <RiEye2Fill />  Voir tous
                                            </Link>)
                                        }
                                        </span>
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Tab>

                {/*Depense de placteforme question */}
                <Tab eventKey="expenses" title="Dépenses Supplémentaires">
                    {
                        // Depenses
                        getAndCheckLocalStorage(localStorageKeys.userRole) == profileRoleType.SUPER_ADMIN ||
                            getAndCheckLocalStorage(localStorageKeys.userRole) == profileRoleType.ADMIN ||
                            getAndCheckLocalStorage(localStorageKeys.userRole) == profileRoleType.USER ||
                            getAndCheckLocalStorage(localStorageKeys.userRole) == profileRoleType.SUPPORT
                            ?
                            <Row>


                                <Col md={12}>

                                    <Card>
                                        <Card.Header>

                                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                                <h5>Dépenses supplémentaires</h5>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="primary" size="sm" id="dropdown-basic">
                                                        Exporter
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item as="button" onClick={exportToExcelExpense}>
                                                            Exporter en Excel
                                                        </Dropdown.Item>
                                                        <Dropdown.Item as="button" onClick={exportToPDFExpense}>
                                                            Exporter en PDF
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>

                                        </Card.Header>
                                        <Card.Body>
                                            <Card.Text>
                                                <strong>Montant  </strong>{totalExpenses} DZD
                                            </Card.Text>

                                            {/* Tableau des rapports filtrés */}
                                            {
                                                expenses && expenses.length > 0 ?
                                                    <Table striped bordered hover responsive >
                                                        <thead>
                                                            <tr>
                                                                <th>Frais Divers</th>
                                                                <th>Patrimoine</th>
                                                                <th>Montant</th>
                                                                <th>Date Estimé</th>
                                                                <th>Date Création</th>
                                                                <th>Statut</th>
                                                                <th>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {expenses && expenses.length > 0 && expenses.map((expense, index) => (
                                                                <tr >

                                                                    <td>
                                                                        {
                                                                            expense?.category?.name || expense?.insurance?.type?.name || expense?.damage?.type?.name || expense?.maintenance?.category?.name || "Autres"
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            expense?.vehicle?.brand || expense?.vehicle?.model || expense?.vehicle?.vehiclebrand || expense?.property?.name || expense?.property?.address || expense?.damage?.reservation?.vehicle?.brand || expense?.damage?.reservation?.property?.name || ""
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <strong>{expense?.amount || 0}</strong>
                                                                    </td>
                                                                    <td>
                                                                        <strong>{formatDateView(expense?.date)}</strong>
                                                                    </td>
                                                                    <td>
                                                                        <strong>{formatDateView(expense?.createdAt)}</strong>
                                                                    </td>
                                                                    <td>
                                                                        <Badge variant="primary">{expense?.status == "Completed" ? "Validé" : expense.status}</Badge>
                                                                    </td>
                                                                    <td>

                                                                        <DropdownButton
                                                                            id="dropdown-basic-button"
                                                                            title="Actions"
                                                                            size="sm"
                                                                            variant="primary"
                                                                        >
                                                                            {expense && (
                                                                                expense?.reservation || expense?.insurance || expense?.damage || expense?.maintenance
                                                                            ) && (
                                                                                    <Dropdown.Item className="text-dark"
                                                                                        onClick={() => { setexpenseItem(expense); setactionModalDelete("edit"); setformDataExpense({ ...formDataExpense, amount: expense?.amount || 0 }); handleShowExpenseDelete() }}
                                                                                    >
                                                                                        <MdEdit />  Modifier
                                                                                    </Dropdown.Item>
                                                                                )}

                                                                            {expense && (
                                                                                expense?.reservation
                                                                            ) && (
                                                                                    <Dropdown.Item className="text-dark"
                                                                                        onClick={() => { setexpenseItem(expense); setactionModalDelete("delete"); handleShowExpenseDelete() }}
                                                                                    >
                                                                                        <MdDelete />  Supprimer
                                                                                    </Dropdown.Item>
                                                                                )}
                                                                        </DropdownButton>


                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    :
                                                    <div style={{ width: "100%", height: "100%", justifyContent: "center", alignContent: "center" }}>
                                                        <div> Aucune dépense externe</div>
                                                    </div>
                                            }
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            : ""}
                </Tab>







                {/*Paiment de plateforme */}


                <Tab eventKey="payments" title="Paiements">
                    {
                        // Depenses
                        getAndCheckLocalStorage(localStorageKeys.userRole) == profileRoleType.SUPER_ADMIN ||
                            getAndCheckLocalStorage(localStorageKeys.userRole) == profileRoleType.ADMIN ||
                            getAndCheckLocalStorage(localStorageKeys.userRole) == profileRoleType.USER ||
                            getAndCheckLocalStorage(localStorageKeys.userRole) == profileRoleType.SUPPORT
                            ?
                            <Row>
                                <Col md={12}>

                                    <Card>
                                        <Card.Header>
                                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                                <div><h5>Paiements</h5></div>
                                                <div>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="primary" size="sm" id="dropdown-basic">
                                                            Exporter
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item as="button" onClick={exportToExcelPayment}>
                                                                Exporter en Excel
                                                            </Dropdown.Item>
                                                            <Dropdown.Item as="button" onClick={exportToPDFPayment}>
                                                                Exporter en PDF
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>

                                        </Card.Header>
                                        <Card.Body>
                                            <Card.Text>
                                                <strong>Montant  </strong>{totalPayments}
                                            </Card.Text>
                                            {
                                                paymentsData && paymentsData.length > 0 ?

                                                    <Table striped bordered hover responsive >
                                                        <thead>
                                                            <tr>
                                                                <th>Réservation</th>
                                                                <th>Patrimoine</th>
                                                                <th>Montant </th>
                                                                <th>Date</th>
                                                                <th>Statut</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {paymentsData && paymentsData.length > 0 && paymentsData.map((paiement) => (
                                                                <tr key={paiement._id}>
                                                                    <td>
                                                                        {paiement?.reservation?.ordre || ""}
                                                                    </td>
                                                                    <td>
                                                                        {paiement?.vehicle?.brand || paiement?.vehicle?.model || paiement?.vehicle?.vehiclebrand || paiement?.property?.name || paiement?.property?.address || ""}
                                                                    </td>
                                                                    <td>{Number(paiement?.amount).toFixed(2)}</td>
                                                                    <td>{formatDateView(paiement?.createdAt)}</td>
                                                                    <td>
                                                                        {renderStatusBadge(paiement.status)}
                                                                    </td>
                                                                    <td>
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle variant="primary" size="sm" id="dropdown-basic">
                                                                                Actions
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                <Dropdown.Item as="button" onClick={() => { setpaymentItem(paiement); handleShowPaymentDelete(); }}>
                                                                                    <MdDelete />   Supprimer
                                                                                </Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>

                                                                        {/* <Button variant='primary' size="sm" onClick={() => { navigate(`/${ROUTES.DASHBOARD}/${ROUTES.PAYMENTS_DETAIL_PLATEFORM}/${paiement._id}`) }}>
                                                                            <RiEye2Fill /> Détails
                                                            </Button>*/}
                                                                    </td>

                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    :
                                                    <p>Aucun paiement</p>
                                            }
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            : ""}

                </Tab>


                <Tab eventKey="cumuls" title="Cumuls">
                    <VehiclePropertyReport />
                </Tab>

                <Tab eventKey="graphiques" title="Graphiques" className='text-dark' style={{ color: "black" }}>
                    <Card className="">
                        <Card.Header><h4>Graphiques</h4></Card.Header>
                        <Card.Body>
                            <Row>

                                <Col md={6}>
                                    <Card className="mb-4 border rounded-lg shadow-sm">
                                        <Card.Body >

                                            <h6>Catégorie de dépenses</h6>
                                            <hr />
                                            {chartData ? (
                                                <Pie data={chartData} />
                                            ) : (
                                                <p>Aucune dépense</p>
                                            )}
                                        </Card.Body>
                                    </Card>
                                </Col>

                                {
                                    reservations && reservations.length > 0 ?
                                        <Col md={6}>
                                            <Card className="mb-4 border rounded-lg shadow-sm">
                                                <Card.Body >
                                                    <h6>Réservations par État</h6>
                                                    <hr />
                                                    {
                                                        pieChartData ? (
                                                            <Pie data={pieChartData} />
                                                        ) :
                                                            (
                                                                <p>Aucune réservation</p>
                                                            )
                                                    }

                                                </Card.Body>
                                            </Card>
                                        </Col> : ""
                                }

                                {
                                    (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER || profileRoleType.SUPER_ADMIN | - profileRoleType.ADMIN || profileRoleType.SUPPORT) &&
                                    (reservations && reservations.length > 0) &&
                                    (
                                        <Col md={12}>
                                            <Card className="mb-4 border rounded-lg shadow-sm">
                                                <Card.Body >
                                                    <h6>Revenus et Dépenses</h6>
                                                    <hr />
                                                    <Line data={lineChartData} />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                }
                            </Row>

                        </Card.Body>

                    </Card>

                </Tab>
            </Tabs>





            {/* Table pour afficher toutes les réservations */}


            <Modal show={showLoading} size='sm' onHide={handleCloseLoading} centered style={{ background: "transparent" }}>
                <Modal.Body>
                    <div
                        style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignContent: "center" }}>
                        <div>
                            <Spinner variant='primary' />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Modal pour afficher la liste des clients */}
            <Modal show={showClientModal} onHide={handleCloseClientModal} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Clients ayant effectué des réservations</Modal.Title>
                </Modal.Header>
                <Modal.Body className="py-5" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    {uniqueClients.length > 0 ? (
                        <Table className="table table-striped" responsive>
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>Prénom</th>
                                    <th>Email</th>
                                    <th>Téléphone</th>
                                    <th>Adresse</th>
                                </tr>
                            </thead>
                            <tbody>
                                {uniqueClients.map(client => (
                                    <tr >
                                        <td>{client?.firstname || ""}</td>
                                        <td>{client?.lastname || ""}</td>
                                        <td>{client?.email || ""}</td>
                                        <td>{client?.phone || ""}</td>
                                        <td>{client?.address || ""}</td>
                                        {/* Affichage du pourcentage d'utilisation   <td>{client?.usagePercentage || ""}</td>*/}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <div className="justify-content-center py-5">
                            <p className="text-center">Aucun client</p>
                        </div>
                    )
                    }
                </Modal.Body>
            </Modal>




            {/*// Affichage des modals*/}
            <Modal show={showVehicleModal} onHide={handleCloseVehicleModal} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Véhicules réservés au total</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped responsive>
                        <thead>
                            <tr>
                                <th>Designation</th>
                                <th>Marque</th>
                                <th>Model</th>
                                <th>Prix journalier (DZD)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reservedVehicles.length > 0 ? reservedVehicles.map(vehicle => (
                                <tr >
                                    <td>{vehicle?.brand || ""}</td>
                                    <td>{vehicle?.vehiclebrand || ""}</td>
                                    <td>{vehicle?.model || ""}</td>
                                    <td>{vehicle?.basePricePerDay || ""}</td>
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan="4">Aucun véhicule réservé</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>

            <Modal show={showPropertyModal} onHide={handleClosePropertyModal} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Propriétés réservés au total</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Adresse</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reservedProperties.length > 0 ? reservedProperties.map(property => (
                                <tr key={property._id}>
                                    <td>{property?.name || ""}</td>
                                    <td>{property?.address || ""}</td>
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan="3">Aucune propriété réservée</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>


            {/*Modal supression expense */}
            <Modal show={showExpenseDelete} onHide={handleCloseExpenseDelete} centered>
                <Modal.Header>
                    <h3>
                        {actionModalDelete === "delete" ? "Supprimer cette dépense" : ""}
                        {actionModalDelete === "edit" ? "Modifier cette dépense" : ""}
                    </h3>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        {
                            (expenseItem?.vehicle || expenseItem?.property) &&
                            (<Col md={12}>
                                <Row>
                                    <Col md={2}>
                                        {expenseItem?.vehicle?.images && expenseItem?.vehicle?.images.length > 0 ? (
                                            <Image
                                                src={expenseItem?.vehicle?.images[0] || emptyImage}
                                                className="img-fluid rounded-md"
                                                style={{ width: "80px", height: "80px" }}
                                                alt={expenseItem?.vehicle?.brand || ""}
                                            />
                                        ) : ""}

                                        {
                                            expenseItem?.property?.images && expenseItem?.property?.images.length > 0 ? (
                                                <Image
                                                    src={expenseItem?.property?.images[0] || emptyImage}
                                                    className="img-fluid rounded-md"
                                                    style={{ width: "80px", height: "80px" }}
                                                    alt={expenseItem?.property?.name || ""}
                                                />
                                            ) : ""}
                                    </Col>
                                    <Col md={10}>
                                        <ul className="list-group list-group-flush">
                                            {expenseItem?.vehicle?.brand && (
                                                <li className="list-group-item">{expenseItem?.vehicle?.brand}</li>
                                            )}
                                            {expenseItem?.vehicle?.model && (
                                                <li className="list-group-item">Model : {expenseItem?.vehicle?.model}</li>
                                            )}
                                            {expenseItem?.vehicle?.color && (
                                                <li className="list-group-item">
                                                    <MdColorize /> {expenseItem?.vehicle?.color}
                                                </li>
                                            )}
                                            {expenseItem?.property?.name && (
                                                <li className="list-group-item">Titre : {expenseItem?.property?.name}</li>
                                            )}
                                            {expenseItem?.property?.address && (
                                                <li className="list-group-item">
                                                    <MdMap /> {expenseItem?.property?.address}
                                                </li>
                                            )}

                                            <li className="list-group-item">
                                                <MdCategory />Frais Divers :  {
                                                    expenseItem?.category?.name || expenseItem?.insurance?.type?.name || expenseItem?.damage?.type?.name || expenseItem?.maintenance?.category?.name || "Autres"
                                                }
                                            </li>
                                            <li className="list-group-item">
                                                <MdDateRange /> Date estimé :  {formatDateView(expenseItem?.date)}
                                            </li>

                                            <li className="list-group-item">
                                                <MdMoney /> Prix (DZD) :  {Number(expenseItem?.amount).toFixed(0)}
                                            </li>


                                            {
                                                expenseItem?.reservation?._id && (
                                                    <>
                                                        <li className="list-group-item">
                                                            <MdDateRange /> Réservation  :  {expenseItem?.reservation?.ordre}
                                                            <br />
                                                            <Link className="text-decoration-underline" to={`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATION_VIEW}/${expenseItem.reservation._id || ""}`}><RiEye2Fill /> Détails</Link>
                                                        </li>
                                                    </>
                                                )
                                            }

                                        </ul>
                                    </Col>
                                </Row>
                            </Col>
                            )
                        }
                        {
                            actionModalDelete == "edit" && (
                                <Col md={12}>
                                    <Form.Group controlId="prixexpense">
                                        <Form.Label>Prix de la dépense </Form.Label>
                                        <Form.Control style={{ height: "40px" }}
                                            type="number"
                                            min={0}
                                            value={formDataExpense.amount}
                                            onChange={(e) => {
                                                const value = parseFloat(e.target.value);
                                                if (value < 0) {
                                                    setformDataExpense({ ...formDataExpense, amount: 0 })
                                                } else {
                                                    setformDataExpense({ ...formDataExpense, amount: value })
                                                }
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                            )
                        }

                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseExpenseDelete} size="sm" variant="light">
                        Annuler
                    </Button>

                    {/* Bouton Supprimer */}
                    {actionModalDelete === "delete" ? (
                        loadingExpense ? (
                            <Button size="sm" variant="danger" disabled>
                                <Spinner variant="light" size="sm" />
                            </Button>
                        ) : (
                            <Button onClick={handleDeleteExpenseById} size="sm" variant="danger">
                                Supprimer
                            </Button>
                        )
                    ) : null}

                    {/* Bouton Modifier */}
                    {actionModalDelete === "edit" ? (
                        loadingExpense ? (
                            <Button size="sm" variant="primary" disabled>
                                <Spinner variant="light" size="sm" />
                            </Button>
                        ) : (
                            <Button onClick={handleDeleteExpenseById} size="sm" variant="primary">
                                Modifier
                            </Button>
                        )
                    ) : null}
                </Modal.Footer>

            </Modal>



            {/*Modal delete paimeent */}
            <Modal show={showPaymentDelete} onHide={handleClosePaymentDelete} centered>
                <Modal.Header>
                    <h3>
                        Supprimer cet paiement
                    </h3>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        {
                            (paymentItem?.vehicle || paymentItem?.property) &&
                            (<Col md={12}>
                                <Row>
                                    <Col md={2}>
                                        {paymentItem?.vehicle?.images && paymentItem?.vehicle?.images.length > 0 ? (
                                            <Image
                                                src={paymentItem?.vehicle?.images[0] || emptyImage}
                                                className="img-fluid rounded-md"
                                                style={{ width: "80px", height: "80px" }}
                                                alt={paymentItem?.vehicle?.brand || ""}
                                            />
                                        ) : ""}

                                        {
                                            paymentItem?.property?.images && paymentItem?.property?.images.length > 0 ? (
                                                <Image
                                                    src={paymentItem?.property?.images[0] || emptyImage}
                                                    className="img-fluid rounded-md"
                                                    style={{ width: "80px", height: "80px" }}
                                                    alt={paymentItem?.property?.name || ""}
                                                />
                                            ) : ""}
                                    </Col>
                                    <Col md={10}>
                                        <ul className="list-group list-group-flush">
                                            {paymentItem?.vehicle?.brand && (
                                                <li className="list-group-item">{paymentItem?.vehicle?.brand}</li>
                                            )}
                                            {paymentItem?.vehicle?.model && (
                                                <li className="list-group-item">Model : {paymentItem?.vehicle?.model}</li>
                                            )}
                                            {paymentItem?.vehicle?.color && (
                                                <li className="list-group-item">
                                                    <MdColorize /> {paymentItem?.vehicle?.color}
                                                </li>
                                            )}
                                            {paymentItem?.property?.name && (
                                                <li className="list-group-item">Titre : {paymentItem?.property?.name}</li>
                                            )}
                                            {paymentItem?.property?.address && (
                                                <li className="list-group-item">
                                                    <MdMap /> {paymentItem?.property?.address}
                                                </li>
                                            )}

                                            <li className="list-group-item">
                                                Frais Divers :  {
                                                    paymentItem?.category?.name || paymentItem?.insurance?.type?.name || paymentItem?.damage?.type?.name || paymentItem?.maintenance?.category?.name || "Autres"
                                                }
                                            </li>
                                            <li className="list-group-item">
                                                <MdDateRange /> Date estimé :  {formatDateView(paymentItem?.createdAt)}
                                            </li>

                                            <li className="list-group-item">
                                                <MdMoney /> Prix (DZD) :  {Number(paymentItem?.amount).toFixed(0)}
                                            </li>
                                            <li className="list-group-item">
                                                <MdDateRange /> Réservation  :  {paymentItem?.reservation?.ordre}
                                                {
                                                    paymentItem?.reservation?._id && (
                                                        <>
                                                            <br />
                                                            <Link className="text-decoration-underline" to={`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATION_VIEW}/${paymentItem.reservation._id || ""}`}><RiEye2Fill /> Détails</Link>
                                                        </>
                                                    )
                                                }
                                            </li>

                                        </ul>
                                    </Col>
                                </Row>
                            </Col>
                            )
                        }
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClosePaymentDelete} size="sm" variant="light">
                        Annuler
                    </Button>

                    {/* Bouton delete */}
                    {
                        // loadingPayment ? 
                        //     <Button size="sm" variant="primary" disabled>
                        //         <Spinner variant="light" size="sm" />
                        //     </Button>
                        //  : 
                        <Button type="button" onClick={handleDeletePaymentById} size="sm" variant="primary">
                            Supprimer
                        </Button>

                    }
                </Modal.Footer>

            </Modal>







        </div>
    )
};

export default StatisticsReportPage;





export const useAggregatedData = () => {
    const reservations = useSelector((state) => state.reservations.reservations);
    const expenses = useSelector((state) => state.expenses.expenses);

    const getDaysInMonth = (year, month) => {
        // Retourne le nombre de jours dans un mois donné
        return new Date(year, month + 1, 0).getDate();
    };

    const calculateOccupancyRate = (reservedDays, totalDays) => {
        // Calculer le taux d'occupation en fonction des jours réservés et des jours totaux
        return (reservedDays / totalDays) * 100;
    };

    const splitReservationByMonth = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const reservationsByMonth = [];

        while (start <= end) {
            const year = start.getFullYear();
            const month = start.getMonth();
            const daysInMonth = getDaysInMonth(year, month);

            // Calculer les jours réservés pour le mois actuel
            const firstDayOfMonth = new Date(year, month, 1);
            const lastDayOfMonth = new Date(year, month, daysInMonth);
            const daysInCurrentMonth =
                (Math.min(end, lastDayOfMonth) - Math.max(start, firstDayOfMonth)) /
                (1000 * 60 * 60 * 24) + 1;

            reservationsByMonth.push({
                year,
                month,
                daysReserved: daysInCurrentMonth,
            });

            // Passer au mois suivant
            start.setMonth(start.getMonth() + 1);
            start.setDate(1);
        }

        return reservationsByMonth;
    };

    const aggregatedData = useMemo(() => {
        const data = {};

        // Parcours des réservations
        reservations.forEach((reservation) => {
            const { vehicle, property, startDate, endDate, status, client, totalPrice } = reservation;
            const isValidReservation = ["CONFIRMED", "COMPLETED"].includes(status);

            const key = vehicle?._id || property?._id; // Clé basée sur le véhicule ou la propriété
            if (!key) return;

            // Initialisation si l'élément n'existe pas encore
            if (!data[key]) {
                data[key] = {
                    item: vehicle || property,
                    totalPayments: 0,
                    totalExpenses: 0,
                    totalDaysReserved: 0, // Nombre total de jours réservés
                    clients: new Set(),
                    totalDaysInReservationMonths: 0, // Nombre total de jours dans les mois couverts par les réservations
                    dates: [], // Tableau des dates (startDate, endDate)
                };
            }

            if (isValidReservation) {
                // Fractionner les jours réservés par mois
                const monthlyReservations = splitReservationByMonth(startDate, endDate);

                // Ajouter les jours réservés et les jours du mois pour chaque segment
                monthlyReservations.forEach(({ daysReserved, year, month }) => {
                    data[key].totalDaysReserved += daysReserved;
                    data[key].totalDaysInReservationMonths += getDaysInMonth(year, month);
                });

                // Ajouter les informations sur les dates (startDate, endDate)
                data[key].dates.push({ startDate, endDate });

                data[key].totalPayments += totalPrice;
                data[key].clients.add(client?._id);
            }
        });

        // Parcours des dépenses
        expenses.forEach((expense) => {
            const { vehicle, property, amount } = expense;
            const key = vehicle?._id || property?._id;
            if (key && data[key]) {
                data[key].totalExpenses += amount;
            }
        });

        // Transformation des données pour calcul des indicateurs supplémentaires
        return Object.values(data).map((entry) => ({
            ...entry,
            clientsCount: entry.clients.size, // Nombre unique de clients
            occupancyRate: calculateOccupancyRate(
                entry.totalDaysReserved,
                entry.totalDaysInReservationMonths
            ),
        }));
    }, [reservations, expenses]);



    return aggregatedData;
};





const VehiclePropertyReport = () => {
    const aggregatedData = useAggregatedData();


    const [euroToDinar, setEuroToDinar] = useState(0);


    useEffect(() => {
        setEuroToDinar(getAndCheckLocalStorage(localStorageData.ExchangeRateGetNow) || 0)
        async function getExchangeRate() {
            const { buyRate } = await fetchExchangeRateGetCurency("EUR");
            setEuroToDinar(buyRate);
        }
        getExchangeRate();
    }, [aggregatedData])

    // Taux de conversion fictifs
    const conversionRates = {
        DinarToEuro: 1 / euroToDinar, // Calculé comme 0.00391
        DinarToDollar: 1 / 244, // Exemple : si 1 Dollar = 300 Dinars
    };

    // Fonction pour convertir les montants
    const convertCurrency = (amount, rate) => (amount * rate).toFixed(2);

    // Calcul des totaux
    // Calcul des totaux
    const totals = aggregatedData.reduce(
        (acc, entry) => {
            acc.totalPayments += entry.totalPayments;
            acc.totalExpenses += entry.totalExpenses;
            acc.totalDaysReserved += entry.totalDaysReserved;
            acc.totalDaysInReservationMonths += entry.totalDaysInReservationMonths; // Total des jours concernés par les mois
            acc.clientsCount += entry.clientsCount;
            return acc;
        },
        {
            totalPayments: 0,
            totalExpenses: 0,
            totalDaysReserved: 0,
            totalDaysInReservationMonths: 0, // Nouveau champ
            clientsCount: 0,
        }
    );

    // Nouvelle fonction de calcul du taux d'occupation total
    const calculateOccupancyRate = (totalDaysReserved, totalDaysInReservationMonths) => {
        // Calculer le taux d'occupation en fonction des jours réservés et des jours concernés
        return (totalDaysReserved / totalDaysInReservationMonths) * 100;
    };

    // Utilisation dans votre composant
    const totalOccupancyRate = calculateOccupancyRate(
        totals.totalDaysReserved,
        totals.totalDaysInReservationMonths
    );



    // Fonction pour exporter en Excel
    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(
            aggregatedData.map((entry) => ({
                "Designation": entry.item.brand || entry.item.name,
                "Model / Adresse": entry.item.model || entry.item.address,
                "Total Paiements (DZD)": entry.totalPayments,
                "Total Paiements (€)": convertCurrency(entry.totalPayments, conversionRates.DinarToEuro),
                "Total Dépenses (DZD)": entry.totalExpenses,
                "Total Dépenses (€)": convertCurrency(entry.totalExpenses, conversionRates.DinarToEuro),
                "Nombre de Jours Réservés": entry.totalDaysReserved.toFixed(0),
                "Taux d'Occupation (%)": entry.occupancyRate.toFixed(2),
                "Nombre de Clients": entry.clientsCount
            }))
        );
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, worksheet, "Report");
        XLSX.writeFile(wb, "Cumuls_activite_rezaplus.xlsx");
    };

    // Fonction pour exporter en PDF
    const exportToPDF = () => {
        const doc = new jsPDF({
            orientation: 'landscape', // ou simplement 'l'
            unit: 'pt',               // unité (points, mm, etc.)
            format: 'a4'              // format du papier
        });



        const imgWidth = 40;
        const imgHeight = 15;
        doc.addImage(logoApplication, 'PNG', 10, 10, imgWidth, imgHeight);

        // Titre centré
        doc.setFontSize(16);
        doc.text(`Cumuls des activités `, doc.internal.pageSize.width / 2, 20, { align: 'center' });


        // Sauvegarde du fichier PDF avec le nom du fichier donné

        // Données de la table
        const tableData = aggregatedData.map((entry) => [
            entry.item.brand || entry.item.name,
            entry.item.model || entry.item.address,
            entry.totalPayments.toFixed(0),
            convertCurrency(entry.totalPayments, conversionRates.DinarToEuro),
            entry.totalExpenses.toFixed(0),
            convertCurrency(entry.totalExpenses, conversionRates.DinarToEuro),
            entry.totalDaysReserved.toFixed(0),
            entry.occupancyRate ? entry.occupancyRate.toFixed(2) : 0.00,
            entry.clientsCount
        ]);

        // Options de table
        doc.autoTable({
            head: [
                [
                    "Designation",
                    "Model / Adresse",
                    "Total Paiements (DZD)",
                    "Total Paiements (€)",
                    "Total Dépenses (DZD)",
                    "Total Dépenses (€)",
                    "Nombre de Jours Réservés",
                    "Taux d'Occupation (%)",
                    "Nombre de Clients"
                ]
            ],
            body: tableData,
            startY: 50,
            theme: "grid",
        });

        // Ajouter la ligne des totaux
        doc.autoTable({
            head: [
                [
                    "Designation",
                    "Model / Adresse",
                    "Total Paiements (DZD)",
                    "Total Paiements (€)",
                    "Total Dépenses (DZD)",
                    "Total Dépenses (€)",
                    "Nombre de Jours Réservés",
                    "Taux d'Occupation (%)",
                    "Nombre de Clients"
                ]
            ],
            body: [
                [
                    "TOTAUX",
                    "",
                    totals?.totalPayments.toFixed(2),
                    convertCurrency(totals?.totalPayments, conversionRates?.DinarToEuro),
                    totals?.totalExpenses.toFixed(2),
                    convertCurrency(totals.totalExpenses, conversionRates?.DinarToEuro),
                    totals?.totalDaysReserved.toFixed(0),
                    totalOccupancyRate.toFixed(2),
                    totals?.clientsCount
                ]
            ],
            startY: doc.lastAutoTable.finalY + 10, // Positionner en dessous de la table précédente
            theme: "grid",
        });

        // Pied de page
        doc.setFontSize(10);
        doc.text('Merci pour votre confiance.', 10, doc.internal.pageSize.height - 10);

        // Lien vers le site web
        doc.setTextColor(46, 128, 2);
        doc.textWithLink('Visitez notre site : www.rezaplus.com', 10, doc.internal.pageSize.height - 5, { url: 'https://www.rezaplus.com' });

        doc.save("Cumuls_activite_rezaplus.pdf");
    };
    // logoApplication

    return (
        <div className="container-fluid mt-5">
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <h5 className="mb-4 text-left">Cumuls des activités</h5>
                <Dropdown>
                    <Dropdown.Toggle variant="primary" size="sm" id="dropdown-basic">
                        Exporter
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item as="button" onClick={exportToExcel}>
                            Exporter en Excel
                        </Dropdown.Item>
                        <Dropdown.Item as="button" onClick={exportToPDF}>
                            Exporter en PDF
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <Table responsive hover bordered striped>
                <thead>
                    <tr>
                        <th>Designation</th>
                        <th>Model / Adresse</th>
                        <th>Total Paiements (DZD)</th>
                        <th>Total Paiements (€)</th>
                        <th>Total Dépenses (DZD)</th>
                        <th>Total Dépenses (€)</th>
                        <th>Nombre de Jours Réservés</th>
                        <th>Taux d{"'"}Occupation (%)</th>
                        <th>Nombre de Clients</th>
                    </tr>
                </thead>
                <tbody>
                    {aggregatedData.map((entry, index) => (
                        <tr key={index}>
                            <td>{entry.item.brand || entry.item.name}</td>
                            <td>{entry.item.model || entry.item.address}</td>
                            <td>{entry.totalPayments.toFixed(2)} DZD</td>
                            <td>
                                {convertCurrency(entry.totalPayments, conversionRates.DinarToEuro)} €
                            </td>
                            <td>{entry.totalExpenses.toFixed(2)} DZD</td>
                            <td>
                                {convertCurrency(entry.totalExpenses, conversionRates.DinarToEuro)} €
                            </td>
                            <td>{entry.totalDaysReserved.toFixed(0)} jours</td>
                            <td>{entry?.occupancyRate ? entry.occupancyRate.toFixed(2) : 0.00}%</td>
                            <td>{entry.clientsCount}</td>
                        </tr>
                    ))}
                </tbody>
                <tfoot className="font-bold">
                    <tr>
                        <td colSpan="2"><strong>TOTAUX</strong></td>
                        <td>{totals.totalPayments.toFixed(2)} DZD</td>
                        <td>
                            {convertCurrency(totals.totalPayments, conversionRates.DinarToEuro)} €
                        </td>
                        <td>{totals.totalExpenses.toFixed(2)} DZD</td>
                        <td>
                            {convertCurrency(totals.totalExpenses, conversionRates.DinarToEuro)} €
                        </td>
                        <td>{totals.totalDaysReserved.toFixed(0)} jours</td>
                        <td>{totalOccupancyRate.toFixed(2)}%</td>
                        <td>{totals.clientsCount}</td>
                    </tr>
                </tfoot>
            </Table>

            {/*modal de suppression*/}




        </div>
    );
};

