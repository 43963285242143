import React, { useState, useEffect } from 'react';
import { Button, Card, Row, Col, Modal, Form, Spinner } from 'react-bootstrap';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { MdErrorOutline, MdSearch, MdTimer } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVehiclesAllResearch, fetchVehiclesAllResearchAvailable } from '../../actions/request/VehicleRequest';
import { useLocation, useNavigate } from 'react-router-dom';
import { emptyImage } from '../../utils/dataApi/dataFormApi';
import moment from 'moment';
import { ROUTES } from '../../config/routingUrl';
import { FETCH_PROPERTYS_SUCCESS, FETCH_PROPERTY_SUCCESS, FETCH_VEHICLES_SUCCESS, FETCH_VEHICLE_SUCCESS } from '../../app/actions/actions';
import { fetchPropertysAllResearch, fetchPropertysAllResearchAvailable } from '../../actions/request/PropertyRequest';
import { fetchengineTypesAll } from '../../actions/request/EngineTypeRequest';
import { fetchInteriorTypesAll } from '../../actions/request/InteriorTypeRequest';
import { fetchVehicleBrandsAll } from '../../actions/request/VehicleBrandRequest';
import Select from "react-select";
import { Range } from "react-range"
import { fetchVehicleModelsAll } from '../../actions/request/VehicleModelRequest';

const VehicleSearchResultsPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const vehicles = useSelector((state) => state.vehicles.vehiclesAvailable);
    const propertys = useSelector((state) => state.propertys.propertysAvailable);
    const loadingVehicles = useSelector((state) => state.vehicles.loadingVehicles);
    const loadingPropertys = useSelector((state) => state.propertys.loadingPropertys);
    const vehiclebrandList = useSelector((state) => state.vehiclebrands.vehiclebrands);
    const interiorTypes = useSelector((state) => state.interiorTypes.interiorTypes);
    const engineTypes = useSelector((state) => state.engineTypes.engineTypes);
    const vehiclemodels = useSelector((state) => state.vehiclemodels.vehiclemodels);

    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 12;
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(0);
    const [showProperties, setShowProperties] = useState(false);
    // année de construction
    const [minYearBuild, setminYearBuild] = useState(1900);
    const [maxYearBuild, setmaxYearBuild] = useState(0);
    // nombre de passagers
    const [minPassenger, setminPassenger] = useState(0);
    const [maxPassenger, setmaxPassenger] = useState(0);
    // kilometrage
    const [minMileage, setminMileage] = useState(0)
    const [maxMileage, setmaxMileage] = useState(0)
    // Categorie
    const [interiorType, setinteriorType] = useState("");
    // moteur
    const [engineType, setengineType] = useState("");
    // marque
    const [vehiclebrand, setvehiclebrand] = useState('');
    // option des propriété et des véhicule en question
    const [optionsItem, setoptionsItem] = useState()
    // Model
    const [model, setmodel] = useState(null)

    const [priceRange, setPriceRange] = useState([minPrice || 500, maxPrice || 200000]);
    const [passengerRange, setPassengerRange] = useState([minPassenger || 1, maxPassenger || 200]);
    const [mileageRange, setMileageRange] = useState([minMileage || 0, maxMileage || 10000]);

    const location = useLocation();
    const { agenceDepart, startDateTime, endDateTime, patrimoine, lat, lng } = location.state;



    const vehiclebrandOptions = vehiclebrandList && vehiclebrandList.length > 0 ? vehiclebrandList.map((vehhiclebrand) => ({
        label: vehhiclebrand.name,
        value: vehhiclebrand.name
    })) : []

    const enginetypeOptions = engineTypes && engineTypes.length > 0 ? engineTypes.map((engine) => ({
        label: engine?.name,
        value: engine?._id
    })) : []

    const interiortypeOptions = interiorTypes && interiorTypes.length > 0 ? interiorTypes.map((interiortype) => ({
        label: interiortype?.name,
        value: interiortype?._id
    })) : []


    const vehiclemodelsOptions = vehiclemodels && vehiclemodels.length > 0 ? vehiclemodels.map((interiortype) => ({
        label: interiortype?.name,
        value: interiortype?._id
    })) : []


    useEffect(() => {
        setLoading(true);

        dispatch(fetchengineTypesAll())
        dispatch(fetchInteriorTypesAll())
        dispatch(fetchVehicleBrandsAll())
        dispatch(fetchVehicleModelsAll())

        if (patrimoine == "Vehicle") {
            setShowProperties(false)
            dispatch({ type: FETCH_PROPERTYS_SUCCESS, payload: [] });
            dispatch(fetchVehiclesAllResearchAvailable(startDateTime, endDateTime, "", "", model?.value, vehiclebrand?.value, minPassenger, maxPassenger, minMileage, maxMileage, minPrice, maxPrice, lat, lng, optionsItem, interiorType?.Value, engineType?.value));
            setLoading(false);
        } else {
            setShowProperties(true);
            dispatch({ type: FETCH_VEHICLES_SUCCESS, payload: [] });
            dispatch(fetchPropertysAllResearchAvailable(startDateTime, endDateTime, "", "", lat, lng, minPrice, maxPrice, "", "", "", optionsItem));

            setLoading(false);
        }

    }, [dispatch, startDateTime, endDateTime]);


    const hanldSearch = () => {
        setLoading(true);
        if (patrimoine == "Vehicle") {
            setShowProperties(false)
            dispatch({ type: FETCH_PROPERTYS_SUCCESS, payload: [] });
            dispatch(fetchVehiclesAllResearchAvailable(startDateTime, endDateTime, "", "", model?.value, vehiclebrand?.value, minPassenger, maxPassenger, minMileage, maxMileage, minPrice, maxPrice, lat, lng, optionsItem, interiorType?.Value, engineType?.value));
            setLoading(false);
        } else {
            setShowProperties(true);
            dispatch({ type: FETCH_VEHICLES_SUCCESS, payload: [] });
            dispatch(fetchPropertysAllResearchAvailable(startDateTime, endDateTime, "", "", lat, lng, minPrice, maxPrice, "", "", "", optionsItem));

            setLoading(false);
        }
        setLoading(false);

    }


    useEffect(() => {
        const maxPriceValue = Math.max(...vehicles.map(v => v.basePricePerDay), 0);
        setMaxPrice(maxPriceValue);
    }, [vehicles]);

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const filteredVehicles = vehicles || []
    const filteredProperties = propertys || []

    const currentItems = showProperties ? filteredProperties : filteredVehicles;
    const itemsToDisplay = currentItems.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(currentItems.length / itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <>
            {/* Modal de chargement transparent */}
            {/*Loading  */}
            <Modal
                show={loadingVehicles || loadingPropertys}
                size="sm"
                centered
                style={{
                    background: "transparent",
                    border: "none",
                    boxShadow: "none",
                }}
                backdrop="static"
                keyboard={false}
                contentClassName="bg-transparent border-0 shadow-none" // Supprime le fond du modal
            >
                <Modal.Body
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "transparent", // Supprime toute couleur de fond
                        border: "none",
                        boxShadow: "none",
                        padding: "0px", // Supprime tout padding qui pourrait ajouter un fond blanc
                    }}
                >
                    <Spinner
                        animation="border"
                        variant="primary"
                        style={{
                            width: "3rem",
                            height: "3rem",
                            zIndex: 9999, // Pour s'assurer qu'il est bien au-dessus
                            filter: "drop-shadow(0px 0px 5px rgba(0,0,0,0.3))" // Ajoute un léger contour pour visibilité
                        }}
                    />
                </Modal.Body>
            </Modal>

            <div className="container-fluid mt-2">
                {/* Section d'informations supplémentaires */}
                <div className="py-3 mb-4">
                    <Card className="p-3">
                        <h5>Informations de la recherche {patrimoine}</h5>
                        <Row>
                            {agenceDepart && (
                                <Col md={4}>
                                    <strong>Localisation:</strong> {agenceDepart}
                                </Col>
                            )}
                            {startDateTime && (
                                <Col md={4}>
                                    <span><MdTimer /> </span> <strong>Date de début:</strong> {moment(startDateTime).format('DD MMM YYYY HH:mm')}
                                </Col>
                            )}
                            {endDateTime && (
                                <Col md={4}>
                                    <span><MdTimer /> </span> <strong>Date de fin:</strong> {moment(endDateTime).format('DD MMM YYYY HH:mm')}
                                </Col>
                            )}
                        </Row>
                        {/* Filtre pour les prix et choix d'affichage */}
                        <div className="my-3">
                            <Row>
                                {/* Filtre Prix */}
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Prix</Form.Label>
                                        <Range
                                            step={500}
                                            min={500}
                                            max={200000}
                                            values={priceRange}
                                            onChange={(values) => {
                                                setPriceRange(values);
                                                setMinPrice(values[0]);
                                                setMaxPrice(values[1]);
                                            }}
                                            renderTrack={({ props, children }) => (
                                                <div
                                                    {...props}
                                                    style={{
                                                        height: '6px',
                                                        width: '100%',
                                                        backgroundColor: '#ddd',
                                                        borderRadius: '3px',
                                                        alignSelf: 'center',
                                                    }}
                                                >
                                                    {children}
                                                </div>
                                            )}
                                            renderThumb={({ props }) => (
                                                <div
                                                    {...props}
                                                    style={{
                                                        height: '20px',
                                                        width: '20px',
                                                        backgroundColor: '#129103',
                                                        borderRadius: '50%',
                                                        border: '2px solid #fff',
                                                        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
                                                        cursor: 'grab',
                                                    }}
                                                />
                                            )}
                                        />
                                        <div className="d-flex justify-content-between mt-2">
                                            <span>Min: {priceRange[0]} DZD</span>
                                            <span>Max: {priceRange[1]} DZD</span>
                                        </div>
                                    </Form.Group>
                                </Col>


                                {/* Filtre Nombre de Passagers */}
                                {
                                    patrimoine === "Vehicle" && (
                                        <Col md={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Nombre de Siège</Form.Label>
                                                <Range
                                                    step={1}
                                                    min={1}
                                                    max={200}
                                                    values={passengerRange}
                                                    onChange={(values) => {
                                                        setPassengerRange(values);
                                                        setminPassenger(values[0]);
                                                        setmaxPassenger(values[1]);
                                                    }}
                                                    renderTrack={({ props, children }) => (
                                                        <div
                                                            {...props}
                                                            style={{
                                                                height: '6px',
                                                                width: '100%',
                                                                backgroundColor: '#ddd',
                                                                borderRadius: '3px',
                                                                alignSelf: 'center',
                                                            }}
                                                        >
                                                            {children}
                                                        </div>
                                                    )}
                                                    renderThumb={({ props }) => (
                                                        <div
                                                            {...props}
                                                            style={{
                                                                height: '20px',
                                                                width: '20px',
                                                                backgroundColor: '#129103',
                                                                borderRadius: '50%',
                                                                border: '2px solid #fff',
                                                                boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
                                                                cursor: 'grab',
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <div className="d-flex justify-content-between mt-2">
                                                    <span>Min: {passengerRange[0]}</span>
                                                    <span>Max: {passengerRange[1]}</span>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    )
                                }


                                {/* Filtre Kilométrage */}
                                {
                                    patrimoine === "Vehicle" && (
                                        <Col md={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Kilométrage</Form.Label>
                                                <Range
                                                    step={100}
                                                    min={0}
                                                    max={10000}
                                                    values={mileageRange}
                                                    onChange={(values) => {
                                                        setMileageRange(values);
                                                        setminMileage(values[0]);
                                                        setmaxMileage(values[1]);
                                                    }}
                                                    renderTrack={({ props, children }) => (
                                                        <div
                                                            {...props}
                                                            style={{
                                                                height: '6px',
                                                                width: '100%',
                                                                backgroundColor: '#ddd',
                                                                borderRadius: '3px',
                                                                alignSelf: 'center',
                                                            }}
                                                        >
                                                            {children}
                                                        </div>
                                                    )}
                                                    renderThumb={({ props }) => (
                                                        <div
                                                            {...props}
                                                            style={{
                                                                height: '20px',
                                                                width: '20px',
                                                                backgroundColor: '#129103',
                                                                borderRadius: '50%',
                                                                border: '2px solid #fff',
                                                                boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
                                                                cursor: 'grab',
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <div className="d-flex justify-content-between mt-2">
                                                    <span>Min: {mileageRange[0]} km</span>
                                                    <span>Max: {mileageRange[1]} km</span>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    )
                                }

                            </Row>
                            {
                                patrimoine == "Vehicle" && (
                                    <Row className="">
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Moteur</Form.Label>
                                                <Select
                                                    value={engineType}
                                                    onChange={(selectedOption) => setengineType(selectedOption)}
                                                    options={enginetypeOptions}
                                                    placeholder="choix"
                                                    isClearable
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Marque</Form.Label>
                                                <Select
                                                    value={vehiclebrand}
                                                    onChange={(selectedOption) => setvehiclebrand(selectedOption)}
                                                    options={vehiclebrandOptions}
                                                    placeholder="choix"
                                                    isClearable
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Catégorie</Form.Label>
                                                <Select
                                                    value={interiorType}
                                                    onChange={(selectedOption) => setinteriorType(selectedOption)}
                                                    options={interiortypeOptions}
                                                    placeholder="choix"
                                                    isClearable
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Modèle</Form.Label>
                                                <Select
                                                    value={model}
                                                    onChange={(selectedOption) => setmodel(selectedOption)}
                                                    options={vehiclemodelsOptions}
                                                    placeholder="choix"
                                                    isClearable
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                )
                            }
                        </div>
                        <Row className="mt-3">
                            <Form className="mb-4">
                                {/* Sélecteur de type (Véhicule ou Propriété) */}
                                <Button type="button" variant="primary" size="sm" onClick={() => { hanldSearch() }}><MdSearch /> Recherche</Button>
                            </Form>
                        </Row>

                    </Card>
                </div>

                <div className="py-3">
                    <h3 className="text-center mb-4">
                        {currentItems.length > 0 ? `${currentItems.length} ELEMENTS TROUVÉS` : 'AUCUN RÉSULTAT'}
                    </h3>
                </div>

                {currentItems.length === 0 ? (
                    <div className="text-center justify-content-center align-content-center" style={{ height: '400px' }}>
                        <MdErrorOutline size={50} color="grey" />
                        <p>Aucun élément trouvé pour votre recherche. Veuillez essayer avec d{"'"}autres critères.</p>
                    </div>
                ) : (
                    <>
                        <Row>
                            {itemsToDisplay.map((item, index) => (
                                <Col md={3} key={item._id} className="mb-4 ">

                                    <Card className="h-100 shadow-sm ">
                                        {item && item.images && item.images.length > 0 ? (
                                            <Card.Img variant="top" className="img-fluid rounded-lg" style={{ height: "200px", width: "100%", objectFit: "fill" }} src={item.images[0]} alt={item.name || item.brand} />
                                        ) : (
                                            <Card.Img variant="top" className="img-fluid rounded-lg" style={{ height: "200px", width: "100%", objectFit: "fill" }} src={emptyImage} alt={item.name || item.brand} />
                                        )}
                                        <Card.Body className=" icon-bx-wraper hover-overlay-effect">
                                            <Card.Title className="text-start ">
                                                <h6 className="line-clamp-2 text-start text-truncate">{!showProperties ? `${item.brand} ${item.model}` : item.name}</h6>
                                            </Card.Title>
                                            <Card.Text className="text-truncate ">
                                                {!showProperties ? (
                                                    <>
                                                        <strong>Couleur : </strong>{item.color}<br />
                                                        <strong>Prix : </strong>{item.basePricePerDay} DZD<br />
                                                    </>
                                                ) : (
                                                    <div className="text-truncate">
                                                        <strong>Adresse : </strong><span className="text-truncate">{item.address}</span><br />
                                                        <strong>Prix : </strong>{item.basePricePerDay} DZD<br />
                                                    </div>
                                                )}
                                                <div className="effect bg-primary"></div>
                                            </Card.Text>
                                            <Button
                                                variant="primary"
                                                size="sm"
                                                className="btn w-100 border-light justify-content-center"
                                                onClick={() => {
                                                    if (!showProperties) {
                                                        dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: item });
                                                        dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: {} });
                                                        navigate(`/${ROUTES.CAR_DETAIL_VIEW}/${item._id}/name/${item.brand}/description/${item.brand}`);
                                                    } else {
                                                        dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: {} });
                                                        dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: item });
                                                        navigate(`/${ROUTES.CAR_DETAIL_VIEW}/${item._id}/name/${item.name}/description/${item.name}`);
                                                    }
                                                }}
                                            >
                                                <div>{!showProperties ? "Voir l'offre" : "Voir l'offre"}</div>
                                            </Button>

                                        </Card.Body>

                                    </Card>

                                </Col>
                            ))}
                        </Row>

                        {/* Pagination */}
                        <div className="d-flex justify-content-center align-items-center mt-4">
                            <Button variant="primary" size="sm" onClick={handlePreviousPage} disabled={currentPage === 1}>
                                <FaArrowLeft />
                            </Button>
                            <span className="mx-3">{currentPage} / {totalPages}</span>
                            <Button variant="primary" size="sm" onClick={handleNextPage} disabled={currentPage === totalPages}>
                                <FaArrowRight />
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default VehicleSearchResultsPage;
