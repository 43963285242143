import { FETCH_COMMENTS_FAILURE, FETCH_COMMENTS_REQUEST, FETCH_COMMENTS_SUCCESS, FETCH_COMMENTS_SUCCESS_2, FETCH_COMMENT_FAILURE, FETCH_COMMENT_REQUEST, FETCH_COMMENT_SUCCESS } from "../actions/actions";



// commentReducer.js
const initialState = {
    comments: [],
    comments2: [],
    comment: {},
    commentSelect: {},
    loadingMaintenance:false,
    loadingMaintenanceSelect:false,
    loadingMaintenances:false,
    errorMaintenances:null,
    errorMaintenance:null
    // ... autres états spécifiques à commentReducer
  };
  
  const commentReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_COMMENT_REQUEST:
        // Dispatch une action pour définir loading sur true dans le globalReducer
        return {
          ...state,
          loadingMaintenance:true,
          errorMaintenance:""
          // Autres manipulations d'état pour la demande de récupération des comments
        };
        case FETCH_COMMENT_SUCCESS:
        return {
          ...state,
          loadingMaintenance:false,
          errorMaintenance:"",
          comment:action.payload
        };
        case FETCH_COMMENT_FAILURE:
        return {
          ...state,
          loadingMaintenance:false,
          errorMaintenance:action.payload
        };


      case FETCH_COMMENTS_REQUEST:
        return {
          ...state,
          loadingMaintenances:true,
          errorMaintenances:""
        };
       
      case FETCH_COMMENTS_SUCCESS:
        return {
          ...state,
          comments: action.payload,
          loadingMaintenances:false,
          errorMaintenances:""
          // Autres manipulations d'état pour le succès de la récupération des comments
        };
        case FETCH_COMMENTS_SUCCESS_2:
        return {
          ...state,
          comments2: action.payload,
          loadingMaintenances:false,
          errorMaintenances:""
          // Autres manipulations d'état pour le succès de la récupération des comments
        };
        
      case FETCH_COMMENTS_FAILURE:
        // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
        return {
          ...state,
          loadingMaintenances:false,
          errorMaintenances:action.payload
          // Autres manipulations d'état pour l'échec de la récupération des comments
        };
      // ... autres cas pour d'autres actions liées aux comments

        
      default:
        return state;
    }
  };
  
  export default commentReducer;
  