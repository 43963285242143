import React, { useEffect, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { UserUpdateById, UserUpdatePassword } from '../../../actions/request/UserRequest';
import { toast } from 'react-toastify';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import { handleImageUploadCloudOnly } from '../../../actions/upload/UploadFileCloud';
import { FETCH_USER_FAILURE, FETCH_USER_REQUEST, FETCH_USER_SUCCESS } from '../../../app/actions/actions';
import { profileRoleType } from '../../../utils/dataApi/dataFormApi';
import axios from 'axios';
import { baseurl } from '../../../utils/url/baseurl';


function ProfileReservation() {
    const [key, setKey] = useState('profile');
    const dispatch = useDispatch();
    const loadingUser = useSelector((state) => state.users.loadingUser);



    const [formData, setFormData] = useState({
        activateReservation: false,
    });


    useEffect(() => {
        dispatch(fetchUserByGet())
    }, [dispatch])

    const handleChange = (e) => {
        const { name, checked } = e.value;
        setFormData({
            ...formData,
            [name]: checked,
        });
    };



    function fetchUserByGet() {
        return async (dispatch) => {
            dispatch({ type: FETCH_USER_REQUEST });
            await axios.get(
                `${baseurl.url}/api/v1/users/get_user/${getAndCheckLocalStorage(localStorageKeys.userId)}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }).then((response) => {
                    console.log(response.data.data);
                    const responseData = response.data.data;
                    dispatch({ type: FETCH_USER_SUCCESS, payload: response.data.data });
                    // Mettre à jour formData correctement en utilisant prevState
                    setFormData(prevState => ({
                        ...prevState,
                        activateReservation: responseData?.activateReservation || false
                    }));
                })
                .catch((error) => {
                    dispatch({ type: FETCH_USER_FAILURE, payload: error.message })
                    // console.log(error);
                });
        }
    }



    const handleSubmit = (e) => {
        e.preventDefault();
        // Submit logic here
        dispatch(UserUpdateById(getAndCheckLocalStorage(localStorageKeys.userId), formData, toast))
    };



    return (

        <div className="border shadow-sm rounded-lg p-5">
            <Form onSubmit={handleSubmit}>
                {loadingUser ? (
                    <div className="text-center">
                        <Spinner animation="border" variant="primary" />
                        <p>Chargement en cours...</p>
                    </div>
                ) : (
                    <>
                        <div className="row">
                            <div className="col-md-12 " style={{ display: "flex", alignItems: "center" }}>
                                <input
                                    style={{ height: "20px", width: "20px" }}
                                    className="p-2"
                                    type="checkbox"
                                    name="activateReservation"
                                    checked={formData.activateReservation}
                                    onChange={(e) => {
                                        setFormData({ ...formData, activateReservation: e.target.checked })
                                    }}
                                />
                                <label className="p-2">Activer 10% d{"'"}accompte sur chaque réservation </label>

                            </div>
                        </div>
                        <hr className="my-5" />
                        <button type="submit" className="btn  btn-sm btn-primary mt-5">Enregistrer</button>
                    </>
                )}
            </Form>
        </div>
    );
}

export default ProfileReservation;
