import axios from "axios";
import { FETCH_PROPERTY_TYPES_FAILURE, FETCH_PROPERTY_TYPES_REQUEST, FETCH_PROPERTY_TYPES_SUCCESS, FETCH_PROPERTY_TYPES_SUCCESS_2, FETCH_PROPERTY_TYPE_FAILURE, FETCH_PROPERTY_TYPE_REQUEST, FETCH_PROPERTY_TYPE_SUCCESS } from "../../app/actions/actions";
import { ROUTES } from "../../config/routingUrl";
import { dureeDeVie, setWithExpiration } from "../../utils/storage/localvalueFuction";
import { localStorageData, localStorageKeys } from "../../utils/storage/localvalue";
import { baseurl } from "../../utils/url/baseurl";
import { saveDataToFile } from "../DataLocal";
import { toast } from "react-toastify";



// Create PropertyType
export const PropertyTypeCreate = (
    data) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_PROPERTY_TYPE_REQUEST });
        await axios
            .post(
                `${baseurl.url}/api/v1/property_types/create`,
                data,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                dispatch({ type: FETCH_PROPERTY_TYPE_SUCCESS });
                toast.success(response?.data?.message || "Type de propriété ajouté", { position: "bottom-right" });
                dispatch(fetchPropertyTypesAll())
            })
            .catch((error) => {
                dispatch({ type: FETCH_PROPERTY_TYPE_FAILURE, payload: error.message });
                toast.error(error?.response?.data?.message || "Type de propriété non ajouté", { position: "bottom-right" })
                dispatch(fetchPropertyTypesAll())
            });
    };
}



// update insurance 
export const PropertyTypeUpdateById = (idPropertyType, data) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_PROPERTY_TYPE_REQUEST });
        await axios
            .put(`${baseurl.url}/api/v1/property_types/update/${idPropertyType}`, data, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                dispatch({ type: FETCH_PROPERTY_TYPE_SUCCESS });
                toast.success(response?.data?.message || "Type de propriété mis à jour", { position: "bottom-right" });
                dispatch(fetchPropertyTypesAll())
            })
            .catch((error) => {
                dispatch({ type: FETCH_PROPERTY_TYPE_FAILURE, payload: error.message });
                toast.error(error?.response?.data?.message || "Type de propriété non mis à jour", { position: "bottom-right" })
                dispatch(fetchPropertyTypesAll())
            });
    };
}





// All insurance of plateforme
export function fetchPropertyTypesAll() {
    return async (dispatch) => {
        dispatch({ type: FETCH_PROPERTY_TYPES_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/property_types/get_property_types`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_PROPERTY_TYPES_SUCCESS, payload: response.data.data });
            dispatch({ type: FETCH_PROPERTY_TYPES_SUCCESS_2, payload: response.data.data });
            saveDataToFile(response.data.data, localStorageData.PropertyTypes);
        })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_PROPERTY_TYPES_FAILURE, payload: error.message });
            });
    }
}


// get insurance by id 
export function fetchPropertyTypeById(idPropertyType) {
    return async (dispatch) => {
        dispatch({ type: FETCH_PROPERTY_TYPE_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/property_types/get_property_type/${idPropertyType}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            //console.log(response.data.data);
            dispatch({ type: FETCH_PROPERTY_TYPE_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                dispatch({ type: FETCH_PROPERTY_TYPE_FAILURE, payload: error.message })
                //console.log(error);
            });
    }
}

