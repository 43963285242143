import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Modal, Form, Row, Col, Table, Dropdown, Badge, Spinner } from 'react-bootstrap';
import { MdAirplaneTicket, MdCardTravel, MdClose, MdEngineering, MdLandslide, MdMoneyOff, MdOutlineDiscount, MdOutlineSupport, MdSearch, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCodePromosAll, fetchCodePromosOfIdUserAll, createPromoCode, fetchCodePromoByUpdateId, fetchCodePromoById } from '../../../actions/request/CodePromoRequest';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import { toast } from 'react-toastify';
import { RiFileDamageFill, RiIndeterminateCircleFill, RiLuggageDepositLine } from 'react-icons/ri';
import ImageGallery from 'react-image-gallery'
import { FaTools } from 'react-icons/fa';
import { PropertyTypeCreate, PropertyTypeUpdateById, fetchPropertyTypesAll } from '../../../actions/request/PropertyTypeRequest';
import { DepositValidatedById, fetchDepositsAll } from '../../../actions/request/DepositRequest';
import moment from 'moment';
import { formatDateView } from '../../../utils/functionData/dateFunction';
import { FETCH_DEPOSITS_SUCCESS, FETCH_PROPERTY_SUCCESS, FETCH_VEHICLE_SUCCESS } from '../../../app/actions/actions';
import { ROUTES } from '../../../config/routingUrl';
import { Link, useNavigate } from 'react-router-dom';
import { handleImageUploadCloud } from '../../../actions/upload/UploadFileCloud';
import { profileRoleType } from '../../../utils/dataApi/dataFormApi';
import Select from "react-select";
import { fetchPropertysAllByOwnerId, fetchPropertysFilters } from '../../../actions/request/PropertyRequest';
import { fetchVehiclesAllByOwnerId, fetchVehiclesAllFilter } from '../../../actions/request/VehicleRequest';
import { fetchCustomersAll } from '../../../actions/request/CustomerRequest';


const DepositsPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loadingDeposit = useSelector((state) => state.deposits.loadingDeposit);
    const propertyTypes = useSelector((state) => state.deposits.deposits);
    const vehicles = useSelector((state) => state.vehicles.vehicles);
    const propertys = useSelector((state) => state.propertys.propertys);
    const customers = useSelector((state) => state.customers.customers);
    const [showModal, setShowModal] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [customerSelect, setcustomerSelect] = useState()
    const customerOptions = customers && customers.length > 0
        ? customers.map((item) => {
            return {
                value: item._id,
                label: `${item.email || ""} ${item.firstname || ""} ${item.lastname || ""}`.trim()
            };
        })
        : [];


    const [promoCodeData, setPromoCodeData] = useState({
        paymentDueDate: '',
        paymentFiles: '',
        comments: "",
        reservation: "",
        customerId : ""
    });
    const [selectedPromoCode, setSelectedPromoCode] = useState(null);
    const [showCode, setShowCode] = useState({});
    const itemsPerPage = 100;

    const vehicleOptions = vehicles && vehicles.length > 0 ? vehicles.map((item) => {
        return ({
            value: item._id, label: item.brand
        })
    }) : [];

    const propertyOptions = propertys && propertys.length > 0 ? propertys.map((item) => {
        return ({
            value: item._id, label: item.name
        })
    }) : [];





    useEffect(() => {
        dispatch({ type: FETCH_DEPOSITS_SUCCESS })

        if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.USER) {

            dispatch(fetchPropertysAllByOwnerId(getAndCheckLocalStorage(localStorageKeys.userId)));
            dispatch(fetchVehiclesAllByOwnerId(getAndCheckLocalStorage(localStorageKeys.userId)));
            dispatch(fetchDepositsAll("", "", getAndCheckLocalStorage(localStorageKeys.userId)));
        } else if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.CUSTOMER) {
            dispatch(fetchDepositsAll("", "", "", getAndCheckLocalStorage(localStorageKeys.userId)));
        }
        else {
            dispatch(fetchCustomersAll())
            dispatch(fetchPropertysFilters());
            dispatch(fetchVehiclesAllFilter());
            dispatch({ type: FETCH_DEPOSITS_SUCCESS })
            dispatch(fetchDepositsAll());
        }

    }, [dispatch]);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => {
        setShowModal(false);
        setPromoCodeData({ name: "", description: "" });
        setSelectedPromoCode(null);
    };


    const [showModalView, setShowModalView] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState(null);

    // Fonction pour afficher le modal avec les détails du paiement
    const handleViewPaymentDetails = (payment) => {
        setSelectedPayment(payment); // Stocker les détails du paiement sélectionné
        setShowModalView(true); // Ouvrir le modal
    };

    const handleUploadFileDeposit = async (e) => {
        const files = e.target.files; // Récupérer les fichiers sélectionnés
        if (!files || files.length === 0) {
            toast.error("Aucun fichier sélectionné.");
            return;
        }

        try {
            // Télécharger les images sur le cloud
            const photos = await handleImageUploadCloud(files, toast);

            // Mettre à jour l'état avec les URLs des images téléchargées
            setPromoCodeData((prevState) => ({
                ...prevState,
                paymentFiles: photos,
            }));
        } catch (error) {
            console.error("Erreur lors du téléchargement des fichiers :", error);
            toast.error("Une erreur est survenue lors du traitement des images.");
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPromoCodeData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleCreateOrUpdatePromoCode = () => {
        if (selectedPromoCode) {
            if (!promoCodeData.paymentDueDate && !promoCodeData.comments) {
                toast.error("Veillez renseignez tous les champs s'il vous plait . ", { position: "bottom-right" })
                return;
            }
            if (promoCodeData.paymentFiles?.length === 0) {
                toast.error("Veillez uploader la fichiers s'il vous plais ", { position: "bottom-right" })
                return;
            }
            dispatch(DepositValidatedById(selectedPromoCode?._id, promoCodeData, toast));
        }
        // else {
        //     // const userAdd = getAndCheckLocalStorage(localStorageKeys.userId);
        //     if (!promoCodeData.name && !promoCodeData.description) {
        //         toast.error("Veillez renseignez tous les champs s'il vous plait . ", { position: "bottom-right" })
        //         return;
        //     }
        //     dispatch(PropertyTypeCreate({ ...promoCodeData }));
        // }
        handleCloseModal();
    };

    const handleEditPromoCode = (promoCode) => {
        setSelectedPromoCode(promoCode);
        setPromoCodeData({
            paymentDueDate: moment(promoCode.paymentDueDate).format("YYYY-MM-DD"),
            comments: promoCode.comments,
            paymentFiles: promoCode.paymentFiles,
            reservation: promoCode.paymentMethodDetails?.reservation?._id,
        });
        handleShowModal();
    };

    const handleValidatePromoCode = (code) => {
        dispatch(fetchCodePromoById(code));
    };

    const handleToggleShowCode = (promoId) => {
        setShowCode((prevState) => ({ ...prevState, [promoId]: !prevState[promoId] }));
    };

    const paginatedPromoCodes = propertyTypes?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) || [];


    // function filter
    const handleSearchFilter = () => {
        // handleShowLoading();
        if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.USER) {
            // administrateur de la plateforme en question
            dispatch(fetchDepositsAll(

                startDate, endDate, getAndCheckLocalStorage(localStorageKeys.userId),
            ))


        } else if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.CUSTOMER) {
            dispatch(fetchDepositsAll(
                startDate, endDate, "", getAndCheckLocalStorage(localStorageKeys.userId)
            ));
        }
        else {
            dispatch(fetchDepositsAll(startDate, endDate));

        }
        // handleCloseLoading();
    }



    return (
        <div>
            <Card>
                <Card.Header>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <h3 className="mb-4"><RiLuggageDepositLine /> Accomptes</h3>
                        </div>

                    </div>
                </Card.Header>

                <Card.Body style={{ minHeight: "400px" }} >
                    <Row className="mb-3">
                        {
                            (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT
                            ) &&
                            vehicleOptions && vehicleOptions.length > 0 && (
                                <Col md={2}>
                                    <Form.Group controlId="startHour" style={{ minWidth: "200px" }}>
                                        <Form.Label>Véhicules</Form.Label>
                                        <Select
                                            value={selectedVehicle}
                                            onChange={setSelectedVehicle}
                                            isClearable
                                            options={vehicleOptions}
                                            placeholder="Véhicule"
                                            isDisabled={!!selectedProperty} // Désactiver si une propriété est sélectionnée
                                        />
                                    </Form.Group>
                                </Col>
                            )
                        }


                        {
                            (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT
                            ) &&
                            propertyOptions && propertyOptions.length > 0 && (
                                <Col md={2}>
                                    <Form.Group controlId="startHour" style={{ minWidth: "200px" }}>
                                        <Form.Label>Propriétés</Form.Label>
                                        <Select
                                            value={selectedProperty}
                                            onChange={setSelectedProperty}
                                            isClearable
                                            options={propertyOptions}
                                            placeholder="Propriété"
                                            isDisabled={!!selectedVehicle} // Désactiver si un véhicule est sélectionné
                                        />
                                    </Form.Group>
                                </Col>)
                        }


                        {
                            (
                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT ||
                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN)
                            &&
                            (
                                <Col md={2}>
                                    <Form.Group controlId="startHour" style={{ minWidth: "200px" }}>
                                        <Form.Label>Clients</Form.Label>
                                        <Select
                                            value={customerSelect}
                                            isClearable
                                            isSearchable
                                            onChange={(selectedOption) => setcustomerSelect(selectedOption)}
                                            options={customerOptions}
                                            placeholder="Choix"
                                            isDisabled={(!!selectedProperty && !!selectedVehicle)}
                                        />
                                    </Form.Group>
                                </Col>
                            )
                        }

                        <Col md={2}>
                            <Form.Group>

                                <Form.Label>Début</Form.Label>
                                <Form.Control
                                    style={{ height: "40px" }}
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group>

                                <Form.Label>Fin</Form.Label>
                                <Form.Control
                                    style={{ height: "40px" }}
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </Form.Group>
                        </Col>


                        <Col md={2}>
                            <Form.Group>

                                <Form.Label>{""}</Form.Label>
                                <div>
                                    <Button onClick={handleSearchFilter} className="mt-3" size="sm"> <MdSearch /> </Button>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>

                    {paginatedPromoCodes && paginatedPromoCodes.length > 0 ? (
                        <div className="align-content-between">
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Réservations</th>
                                        <th>Montant (Euro)</th>
                                        <th>Moyen de paiement</th>
                                        <td>Patrimoine</td>
                                        <th>Agences</th>
                                        <th>Clients</th>
                                        <td>Abouti (Réservation)</td>
                                        <th>Date du réglement</th>
                                        <th>Date émis</th>
                                        <th>Statuts</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedPromoCodes.map((promo) => (
                                        <tr key={promo._id}>
                                            <td>
                                                {
                                                    promo?.paymentMethodDetails?.reservation?.ordre ?
                                                        <span style={{ cursor: "pointer" }} className='nav-underline font-18 text-decoration-underline pointer-event' to={"#"}
                                                            onClick={() => {
                                                                if (promo.paymentMethodDetails.reservation && promo.paymentMethodDetails.reservation.vehicle) {
                                                                    dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: promo.paymentMethodDetails.reservation.vehicle });
                                                                    dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: {} });
                                                                    navigate(`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATION_VIEW}/${promo.paymentMethodDetails.reservation._id}`)
                                                                } else {
                                                                    dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: {} });
                                                                    dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: promo.paymentMethodDetails.reservation.property });
                                                                    navigate(`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATION_VIEW}/${promo.paymentMethodDetails.reservation._id}`)
                                                                }
                                                            }}
                                                        >  {promo?.paymentMethodDetails?.reservation?.ordre || "Accune"}
                                                        </span> :
                                                        "Accune"
                                                }
                                            </td>
                                            <td>
                                                {Number(promo?.amount || 0).toFixed(4)}
                                            </td>
                                            <td>
                                                {
                                                    promo?.paymentMethod == "card" ?
                                                        "Carte de crédit" :
                                                        promo?.paymentMethod == "bank" ?
                                                            "Banque" :
                                                            promo?.paymentMethod == "cash" ?
                                                                "Espèces" :
                                                                "Espèces"
                                                }
                                            </td>
                                            <td>
                                                {promo?.paymentMethodDetails?.reservation?.vehicle?.brand || promo?.paymentMethodDetails?.reservation?.property?.name || "Aucun"}
                                            </td>
                                            <td>

                                                <span> {promo?.paymentMethodDetails?.reservation?.client?.firstname || ""} </span>
                                                <span> {promo?.paymentMethodDetails?.reservation?.client?.lastname || ""}  </span>
                                                <span> {promo?.paymentMethodDetails?.reservation?.client?.email || ""} </span>
                                            </td>

                                            <td>
                                                <span> {promo?.paymentMethodDetails?.reservation?.userAdd?.email || ""} </span>
                                                <span> {promo?.paymentMethodDetails?.reservation?.userAdd?.companyName || ""} </span>
                                                <span> {promo?.paymentMethodDetails?.reservation?.userAdd?.firstname || ""} </span>
                                                <span> {promo?.paymentMethodDetails?.reservation?.userAdd?.lastname || ""} </span>
                                            </td>
                                            <td>
                                                <Badge bg={promo?.isConfirmed ? "success" : "danger"}>{promo?.isConfirmed ? "Abouti" : "Non Abouti"} </Badge>
                                            </td>
                                            <td>
                                                <span> {promo?.paymentDueDate ? formatDateView(promo?.paymentDueDate || "") : ""}</span>
                                                <span> {/*promo?.paymentDueDate ? moment(promo?.paymentDueDate).format("HH:MM"):""*/} </span>
                                            </td>

                                            <td>{formatDateView(promo?.createdAt || "")}</td>
                                            <td>{promo?.status == "COMPLETED" ? <Badge bg="success">Payé</Badge> : <Badge bg="danger">Impayé</Badge>}</td>
                                            <td>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="primary" size="sm">
                                                        Actions
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => handleEditPromoCode(promo)}>Valider</Dropdown.Item>
                                                        {/* Afficher l'option uniquement si paymentFiles.length > 0 ou s'il y a des commentaires */}
                                                        {
                                                            (promo.paymentFiles?.length > 0 || promo.comments) &&
                                                            (
                                                                <Dropdown.Item onClick={() => handleViewPaymentDetails(promo)}>
                                                                    Voir les détails du paiement
                                                                </Dropdown.Item>
                                                            )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            <div className="d-flex justify-content-start mt-4">
                                <Button
                                    variant="primary"
                                    size="sm" className="p-2"
                                    disabled={currentPage === 1}
                                    onClick={() => setCurrentPage(currentPage - 1)}
                                >
                                    Précédent
                                </Button>

                                <Button
                                    variant="primary"
                                    size="sm" className="p-2"
                                    disabled={currentPage * itemsPerPage >= propertyTypes.length}
                                    onClick={() => setCurrentPage(currentPage + 1)}
                                >
                                    Suivant
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <Alert variant="light" style={{ display: "flex", justifyContent: "center" }}>Aucun accompte </Alert>
                    )}
                </Card.Body>
            </Card>

            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h4>{selectedPromoCode ? "Validé accompte" : ""} </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="discountPercentage">
                            <Form.Label>Fichier du paiement<span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                style={{ height: "40px" }}
                                type="file"
                                multiple // Permettre le téléchargement de plusieurs fichiers
                                accept="image/*" // Autoriser uniquement les fichiers images
                                placeholder=""
                                onChange={(e) => handleUploadFileDeposit(e)}
                            />
                            <div>
                                {promoCodeData?.paymentFiles?.length > 0 && (
                                    <div>
                                        <h6>Fichiers sélectionnées :</h6>
                                        <ul
                                            className='flex'
                                            style={{ display: 'flex', flexWrap: 'wrap' }}
                                        >
                                            {promoCodeData?.paymentFiles?.map((image, index) => (
                                                <li key={index} className='p-1'>
                                                    <span
                                                        className='text-danger text-xs'
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            // On supprime l'image correspondant à l'index
                                                            const updatedImages =
                                                                promoCodeData.paymentFiles.filter(
                                                                    (_, i) => i !== index
                                                                )
                                                            setPromoCodeData({
                                                                ...promoCodeData,
                                                                paymentFiles: updatedImages
                                                            })
                                                        }}
                                                    >
                                                        <MdClose size={30} />
                                                    </span>
                                                    <div>
                                                        <img
                                                            src={image}
                                                            style={{ height: '60px', width: '60px' }}
                                                        />
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </Form.Group>
                        <Form.Group controlId="discountPercentage">
                            <Form.Label>Date du paiement<span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                style={{ height: "40px" }}
                                type="date"
                                value={promoCodeData.paymentDueDate}
                                name="paymentDueDate"
                                placeholder=''
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="expiryDate">
                            <Form.Label>Commentaire <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                as={"textarea"}
                                style={{ height: "50px" }}
                                type="text"
                                name="comments"
                                value={promoCodeData.comments}
                                plaeholer="Commentaire"
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" size="sm" onClick={handleCloseModal}>Annuler</Button>
                    {
                        loadingDeposit ?
                            <Button variant="primary" size="sm" >
                                <Spinner variant='light' />
                            </Button>
                            :
                            <Button variant="primary" size="sm" onClick={handleCreateOrUpdatePromoCode}>{selectedPromoCode ? 'Mettre à Jour' : 'Créer'}</Button>
                    }
                </Modal.Footer>
            </Modal>


            {/* Modal pour afficher les détails du paiement */}
            <Modal show={showModalView} onHide={() => setShowModalView(false)} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><h4>Détails du paiement </h4></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedPayment && (
                        <>
                            {/* Section pour afficher les commentaires */}
                            {selectedPayment.comments && (
                                <div className="mb-3">
                                    <strong>Commentaires :</strong>
                                    <p>{selectedPayment.comments}</p>
                                </div>
                            )}

                            {/* Section pour afficher la date du paiement */}
                            {selectedPayment.paymentDueDate && (
                                <div className="mb-3">
                                    <strong>Date du paiement :</strong>
                                    <p>{formatDateView(selectedPayment.paymentDueDate)}</p>
                                </div>
                            )}

                            {/* Section pour afficher les images avec react-image-gallery */}
                            {selectedPayment.paymentFiles?.length > 0 && (
                                <div>
                                    <strong>Fichiers joints :</strong>
                                    <ImageGallery
                                        items={selectedPayment.paymentFiles.map((file) => ({
                                            original: file,
                                            thumbnail: file,
                                        }))}
                                        showPlayButton={false}
                                        showFullscreenButton={false}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="sm" onClick={() => setShowModalView(false)}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default DepositsPage;