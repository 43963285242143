import axios from "axios";
import { FETCH_MAINTENANCES_FAILURE, FETCH_MAINTENANCES_REQUEST, FETCH_MAINTENANCES_SUCCESS, FETCH_MAINTENANCE_FAILURE, FETCH_MAINTENANCE_REQUEST, FETCH_MAINTENANCE_SUCCESS } from "../../app/actions/actions";
import { ROUTES } from "../../config/routingUrl";
import { dureeDeVie, getAndCheckLocalStorage, setWithExpiration } from "../../utils/storage/localvalueFuction";
import { localStorageData, localStorageKeys } from "../../utils/storage/localvalue";
import { baseurl } from "../../utils/url/baseurl";
import { saveDataToFile } from "../DataLocal";
import { profileRoleType } from "../../utils/dataApi/dataFormApi";
import { toast } from "react-toastify";





// Create Maintenance
export const MaintenanceCreate = (data) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_MAINTENANCE_REQUEST });
        await axios
            .post(
                `${baseurl.url}/api/v1/maintenances`,
                data,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                toast.success(response?.data?.message || "Maintenance ajoutée avec succès", { position: "bottom-right" });
                dispatch({ type: FETCH_MAINTENANCE_SUCCESS, payload: response.data });
                if (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER) {
                    dispatch(fetchMaintenancesAll(getAndCheckLocalStorage(localStorageKeys.userId)))
                } else {
                    dispatch(fetchMaintenancesAll())
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || "Maintenance ajoutée avec succès", { position: "bottom-right" });
                dispatch({ type: FETCH_MAINTENANCE_FAILURE, payload: error.message });
                if (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER) {
                    dispatch(fetchMaintenancesAll(getAndCheckLocalStorage(localStorageKeys.userId)))
                } else {
                    dispatch(fetchMaintenancesAll())
                }
            });
    };
}


export const MaintenanceUpdateById = (idMaintenance, data) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_MAINTENANCE_REQUEST });
        await axios
            .put(
                `${baseurl.url}/api/v1/maintenances/update/${idMaintenance}`,
                data,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                    }
                }
            )
            .then((response) => {
                toast.success(response?.data?.message || "Maintenance modifier avec succès", { position: "bottom-right" });
                dispatch({ type: FETCH_MAINTENANCE_SUCCESS, payload: response.data });
                if (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER) {
                    dispatch(fetchMaintenancesAll(getAndCheckLocalStorage(localStorageKeys.userId)))
                } else {
                    dispatch(fetchMaintenancesAll())
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || "Maintenance non mis a jour ", { position: "bottom-right" });
                dispatch({ type: FETCH_MAINTENANCE_FAILURE, payload: error.message });
                if (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER) {
                    dispatch(fetchMaintenancesAll(getAndCheckLocalStorage(localStorageKeys.userId)))
                } else {
                    dispatch(fetchMaintenancesAll())
                }
            });
    };
}


// Cancel Maintenance
export const MaintenanceCancelById = (idMaintenance, toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_MAINTENANCE_REQUEST });
        await axios
            .patch(`${baseurl.url}/api/v1/maintenances/maintenances/${idMaintenance}/cancel`, {}, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                toast.success(response?.data?.message || "Maintenance annulée avec succès", { position: "bottom-right" });
                dispatch({ type: FETCH_MAINTENANCE_SUCCESS, payload: response.data });
                if (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER) {
                    dispatch(fetchMaintenancesAll(getAndCheckLocalStorage(localStorageKeys.userId)))
                } else {
                    dispatch(fetchMaintenancesAll())
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || "Maintenance non annulée ", { position: "bottom-right" });
                dispatch({ type: FETCH_MAINTENANCE_FAILURE, payload: error.response.data.message });
                if (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER) {
                    dispatch(fetchMaintenancesAll(getAndCheckLocalStorage(localStorageKeys.userId)))
                } else {
                    dispatch(fetchMaintenancesAll())
                }
            });
    };
}


// Delete Maintenance
export const MaintenanceDeleteById = (idMaintenance, toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_MAINTENANCE_REQUEST });
        await axios
            .delete(`${baseurl.url}/api/v1/maintenances/delete/${idMaintenance}`, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                toast.success(response?.data?.message || "Maintenance supprimée avec succès", { position: "bottom-right" });
                dispatch({ type: FETCH_MAINTENANCE_SUCCESS });
                if (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER) {
                    dispatch(fetchMaintenancesAll(getAndCheckLocalStorage(localStorageKeys.userId)))
                } else {
                    dispatch(fetchMaintenancesAll())
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || "Maintenance non supprimée ", { position: "bottom-right" });
                dispatch({ type: FETCH_MAINTENANCE_FAILURE, payload: error.response.data.message });
                if (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER) {
                    dispatch(fetchMaintenancesAll(getAndCheckLocalStorage(localStorageKeys.userId)))
                } else {
                    dispatch(fetchMaintenancesAll())
                }
            });
    };
}


// Fetch all maintenances with optional filters
export function fetchMaintenancesAll(userAdd, startDate, endDate, property, vehicle, status) {
    return async (dispatch) => {
        dispatch({ type: FETCH_MAINTENANCES_REQUEST });

        // Construire les paramètres de la requête
        const params = new URLSearchParams();
        if (userAdd) params.append("userAdd", userAdd);
        if (startDate) params.append("startDate", startDate);
        if (endDate) params.append("endDate", endDate);
        if (property) params.append("property", property);
        if (vehicle) params.append("vehicle", vehicle);
        if (status) params.append("status", status);

        await axios.get(`${baseurl.url}/api/v1/maintenances/get_maintenances?${params.toString()}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        })
            .then((response) => {
                dispatch({ type: FETCH_MAINTENANCES_SUCCESS, payload: response.data.data });
                //console.log(response.data.data);
                saveDataToFile(response.data, localStorageData.Maintenances); // Sauvegarder localement si besoin
            })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_MAINTENANCES_FAILURE, payload: error.response?.data?.message || error.message });
            });
    };
}



// Get maintenance by id
export function fetchMaintenanceById(idMaintenance) {
    return async (dispatch) => {
        dispatch({ type: FETCH_MAINTENANCE_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/maintenances/get_maintenance/${idMaintenance}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            dispatch({ type: FETCH_MAINTENANCE_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                dispatch({ type: FETCH_MAINTENANCE_FAILURE, payload: error.response.data.message })
                //console.log(error);
            });
    }
}
