import React, { useState, useEffect } from 'react';
import { Form, Button, Spinner, Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Select from "react-select";
import { fetchCodePostalsAll } from '../../actions/request/CodePostalRequest';
import { UserCreate } from '../../actions/request/UserRequest';
import { logoApplication, maxLengthLitte } from '../../utils/dataApi/dataFormApi';
import { CustomerCreate } from '../../actions/request/CustomerRequest';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../config/routingUrl';
import ReCAPTCHA from 'react-google-recaptcha';
import HCaptcha from "@hcaptcha/react-hcaptcha"
import { baseurl } from '../../utils/url/baseurl';
import { FETCH_CUSTOMER_SUCCESS, FETCH_USER_SUCCESS } from '../../app/actions/actions';
import axios from 'axios';

function RegistrationPrestatairePage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const codepostals = useSelector((state) => state.codepostals.codepostals);
    const loadingUser = useSelector((state) => state.users.loadingUser);
    const [isShowPassword, setisShowPassword] = useState(false)
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [captchaToken, setCaptchaToken] = useState(null); // Stocke le token du captcha
    const [selectedLanguage, setSelectedLanguage] = useState("fr"); // Langue sélectionnée
    const keayrecaptcha = baseurl.ApiKeyRecaptcha;


    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        codePostal: '',
        gender: 'Male',
        dateOfBirth: '',
        password: '',
        confirmPassword: '',
        profileType: 'Prestataire',
        role: 'INDIVIDUAL',
        companyName: '',
        contactName: '',
        companyAddress: '',
        numberTVA: '',
        licenceCommerciale: null,
        isPrestataire: true,
    });

    useEffect(() => {
        dispatch({ type: FETCH_USER_SUCCESS });
        dispatch({ type: FETCH_CUSTOMER_SUCCESS });
        dispatch(fetchCodePostalsAll());
    }, [dispatch]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        // Vérifier si le champ est un texte et limiter sa longueur à 100 caractères
        const newValue = type === 'text' && value.length > maxLengthLitte ? value.slice(0, 100) : value;

        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : newValue, // Appliquer la valeur limitée
        });

        // Optionnel : Afficher un message si la limite est atteinte
        if (type === 'text' && value.length > maxLengthLitte) {
            console.warn(`Le champ ${name || ""} ne peut pas dépasser ${maxLengthLitte} caractères.`);
            toast.error(`Le champ ${name || ""} ne peut pas dépasser ${maxLengthLitte} caractères.`)
        }
    };

    const handleSelectChange = (selectedOption, { name }) => {
        setFormData({
            ...formData,
            [name]: selectedOption ? selectedOption.value : '',
        });
    };


    // Fonction appelée lorsque le captcha est résolu
    const onVerify = (token) => {
        setCaptchaToken(token); // Stocke le token généré par hCaptcha
        // console.log("Captcha Token:", token);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.email || !formData.password || !formData.phone || !formData.codePostal || !formData.confirmPassword) {
            toast.error("Veuillez remplir tous les champs obligatoires", { position: "bottom-right" });
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            toast.error("Les mots de passe ne correspondent pas", { position: "bottom-right" });
            return;
        }

        if (!captchaToken) {
            toast.error("Veuillez valider le reCAPTCHA avant de continuer !", { position: "bottom-right" });
            return;
        }

        // if (!recaptchaValue) {
        //     toast.error("Veuillez valider le reCAPTCHA pour prouver que vous n'êtes pas un robot !", { position: "bottom-right" });
        //     return;
        // }

        // Détermine si l'utilisateur est un client ou un prestataire
        try {
            const response = await axios.post(
                `${baseurl.url}/api/validate-captcha`,
                { captchaToken },
                {
                    headers: { "Content-Type": "application/json" },
                }
            );

            const data = response.data;

            // if (data.success ) {
            //     toast.success("Captcha validé avec succès !", { position: "bottom-right" });

            if (formData.isPrestataire) {
                formData.profileType = "Prestataire";
                if (!formData.role) {
                    toast.error("Choix du type de profile obligatoire", { position: "bottom-right" })
                } else {
                    dispatch(UserCreate(formData, navigate, toast));
                }
            } else {
                formData.profileType = "Client";
                dispatch(CustomerCreate(formData, navigate, toast));
            }
            // } else {
            //     toast.error(data.message || "La validation du captcha a échoué.", { position: "bottom-right" });
            // }
        } catch (error) {
            // console.error("Une errur s'est produite",);
            toast.error(error?.response?.data?.message || "Une erreur est survenue. Veuillez réessayer.", { position: "bottom-right" });
        }
    };

    const codePostalOptions = codepostals.map((code) => ({
        value: code._id,
        label: `+${code.indicatif} - (${code.country})`,
    }));

    const genderOptions = [
        { value: 'Male', label: 'Homme' },
        { value: 'Female', label: 'Femme' },
    ];

    const roleOptions = [
        { value: 'INDIVIDUAL', label: 'Particulier' },
        { value: 'COMPANY', label: 'Entreprise' },
    ];

    return (
        <div className="page-content bg-white">
            <section className="content-inner bg-white text-white py-5">
                <div className="">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-8 col-md-10">
                            <div className="login-form bg-white p-4 rounded shadow-sm">
                                <div className="text-center mb-4">
                                    <img src={logoApplication} alt="Inscription" style={{ width: '50px', height: '50px' }} />
                                    <h4>Inscription pour agence de location</h4>
                                    <p>Veuillez créer un compte pour accéder à nos services</p>
                                </div>

                                <Card className="p-5">
                                    <Form onSubmit={handleSubmit}>
                                        {loadingUser ? (
                                            <div className="text-center">
                                                <Spinner animation="border" variant="primary" />
                                                <p>Chargement en cours...</p>
                                            </div>
                                        ) : (
                                            <>
                                                <div className=" row">

                                                    <div className="col-md-12 form-group">
                                                        <label className="text-dark">Email <span className="text-danger">*</span></label>
                                                        <input
                                                            style={{ height: "40px" }}
                                                            type={"email"}
                                                            className="form-control"
                                                            name={"email"}
                                                            value={formData.email}
                                                            onChange={handleChange}
                                                            required={true}
                                                        />
                                                    </div>

                                                    <div className="col-md-5 form-group">
                                                        <label className="text-dark">Indicatif <span className="text-danger">*</span></label>
                                                        <Select className="text-dark" style={{ widht: "100%" }}
                                                            name="codePostal"
                                                            isClearable
                                                            options={codePostalOptions}
                                                            onChange={handleSelectChange}
                                                            placeholder="Choix"
                                                            required={true}
                                                        />
                                                    </div>

                                                    <div className="col-md-7 form-group">
                                                        <label className="text-dark">Téléphone <span className="text-danger">*</span></label>
                                                        <input
                                                            style={{ height: "40px", with: "100%" }}
                                                            type={"number"}
                                                            className="form-control"
                                                            name={"phone"}
                                                            value={formData.phone}
                                                            onChange={handleChange}
                                                            required={true}
                                                        />
                                                    </div>

                                                    {formData.isPrestataire && (
                                                        <div className="col-md-12 form-group">
                                                            <label className="text-dark">Choix du type de profile</label>
                                                            <Select className="text-dark"
                                                                name="role"
                                                                isClearable
                                                                options={roleOptions}
                                                                onChange={handleSelectChange}
                                                                placeholder="Choix"
                                                            />
                                                        </div>
                                                    )}


                                                    <div className="col-md-12 form-group">
                                                        <label className="text-dark">Mot de passe <span className="text-danger">*</span></label>
                                                        <input
                                                            style={{ height: "40px" }}
                                                            type={isShowPassword ? "text" : "password"}
                                                            className="form-control"
                                                            name={"password"}
                                                            value={formData.password}
                                                            onChange={handleChange}
                                                            required={true}
                                                        />
                                                    </div>

                                                    <div className="col-md-12 form-group">
                                                        <label className="text-dark">Confirmer mot de passe <span className="text-danger">*</span></label>
                                                        <input
                                                            style={{ height: "40px" }}
                                                            type={isShowPassword ? "text" : "password"}
                                                            className="form-control"
                                                            name={"confirmPassword"}
                                                            value={formData.confirmPassword}
                                                            onChange={handleChange}
                                                            required={true}
                                                        />
                                                    </div>

                                                    {/*<div className="col-md-12 form-group">
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="prestataireCheck"
                                                            label="Je souhaite devenir Prestataire ( pour les entreprise location )"
                                                            name="isPrestataire"
                                                            checked={formData.isPrestataire}
                                                            onChange={handleChange}
                                                            className="text-dark"
                                                        />
                                                    </div> */}




                                                    <div className="col-md-12 form-group">
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="prestataireCheck"
                                                            label="Voir mot de passe"
                                                            name="isPrestataire"
                                                            checked={isShowPassword}
                                                            onChange={(e) => { setisShowPassword(e.target.checked) }}
                                                            className="text-dark"
                                                        />
                                                    </div>
                                                </div>

                                                {/* reCAPTCHA */}
                                                <div className="col-md-12 form-group">
                                                    {/*<ReCAPTCHA
                                                        sitekey={keayrecaptcha} // Remplacez par votre clé reCAPTCHA
                                                        onChange={setRecaptchaValue}
                                                    />*/}
                                                    <HCaptcha
                                                        // sitekey="a4064d96-aed6-4a86-80a2-c1f2b434ace4" // Remplacez par votre clé de site hCaptcha
                                                        sitekey={keayrecaptcha} // Remplacez par votre clé de site hCaptcha
                                                        onVerify={onVerify} // Appelée lorsque le captcha est résolu
                                                        hl={selectedLanguage}
                                                    />
                                                </div>


                                                <hr className="my-5" />
                                                <div className="text-center">
                                                    <Button variant="primary" size="sm" type="submit" className="w-100 justify-content-center" >
                                                        <div className='text-center'>
                                                            S{"'"}inscrire
                                                        </div>
                                                    </Button>
                                                </div>
                                                <div className="text-center mt-4">
                                                    <span variant="link" size="sm" onClick={() => navigate('/login')} className="text-dark">
                                                        Vous avez déjà un compte ? <Link to={`/${ROUTES.LOGIN}`}>Connectez-vous ici</Link>
                                                    </span>
                                                </div>
                                            </>
                                        )}
                                    </Form>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default RegistrationPrestatairePage;