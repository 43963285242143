import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Modal, Table, Dropdown, Image, Row, Col, Form } from 'react-bootstrap';
import { MdDeleteOutline, MdOutlineNotificationsActive, MdOutlineSearch } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotificationsAll } from '../../../actions/request/NotificationRequest';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import { RiEye2Fill } from 'react-icons/ri';
import { emptyImage, profileRoleType } from '../../../utils/dataApi/dataFormApi';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../config/routingUrl';
import { fetchPropertysAllByOwnerId, fetchPropertysFilters } from '../../../actions/request/PropertyRequest';
import { fetchVehiclesAllByOwnerId, fetchVehiclesAllFilter } from '../../../actions/request/VehicleRequest';
import { fetchUsersAll } from '../../../actions/request/UserRequest';
import { fetchCustomersAll } from '../../../actions/request/CustomerRequest';
import Select from "react-select";



const NotificationPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const notifications = useSelector((state) => state.notifications.notifications);
    const vehicles = useSelector((state) => state.vehicles.vehicles);
    const propertys = useSelector((state) => state.propertys.propertys);
    const users = useSelector((state) => state.users.users);
    const customers = useSelector((state) => state.customers.customers);
    const [showModal, setShowModal] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 50;

    const [propertySelect, setpropertySelect] = useState();
    const [vehicleSelect, setvehicleSelect] = useState();
    const [userSelect, setuserSelect] = useState()
    const [customerSelect, setcustomerSelect] = useState()


    const callFunctionElements = () => {
        setuserSelect(null);
        setvehicleSelect(null)
        setpropertySelect(null);
        setcustomerSelect(null)
        setStartDate("")
        setEndDate("")

        if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.USER) {
            dispatch(fetchNotificationsAll(getAndCheckLocalStorage(localStorageKeys.userId)));
            dispatch(fetchPropertysAllByOwnerId(getAndCheckLocalStorage(localStorageKeys.userId)));
            dispatch(fetchVehiclesAllByOwnerId(getAndCheckLocalStorage(localStorageKeys.userId)));

        } else if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.CUSTOMER) {
            dispatch(fetchNotificationsAll("", getAndCheckLocalStorage(localStorageKeys.userId)));
        }
        else {
            dispatch(fetchPropertysFilters());
            dispatch(fetchVehiclesAllFilter());
            dispatch(fetchNotificationsAll());
            dispatch(fetchUsersAll());
            dispatch(fetchCustomersAll());
        }
    }


    useEffect(() => {
        // Fetch all notifications for the current user
        callFunctionElements();
    }, [dispatch]);

    //  filtre des option
    const vehicleOptions = vehicles && vehicles.length > 0 ? vehicles.map((item) => {
        return ({
            value: item._id, label: item.brand
        })
    }) : []

    const propertyOptions = propertys && propertys.length > 0 ? propertys.map((item) => {
        return ({
            value: item._id, label: item.name
        })
    }) : []

    const userOptions = users && users.length > 0 ? users.map((item) => {
        return ({
            value: item._id, label: item?.email || "" + " " + item?.companyName || ""
        })
    }) : []

    const customerOptions = customers && customers.length > 0 ? customers.map((item) => {
        return ({
            value: item._id, label: item?.email || "" + " " + item?.firstname || "" + " " + item?.lastname || ""
        })
    }) : []

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const handleShowModal = (notification) => {
        setSelectedNotification(notification);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedNotification(null);
    };

    const paginatedNotifications = notifications.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);


    const handleSearchFilter = () => {
        if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.USER) {
            // administrateur de la plateforme en question
            dispatch(fetchNotificationsAll(getAndCheckLocalStorage(localStorageKeys.userId), customerSelect?.value, vehicleSelect?.value, propertySelect?.value, "", "", "", startDate, endDate));
        } else if (getAndCheckLocalStorage(localStorageKeys.profileRole) === profileRoleType.CUSTOMER) {
            dispatch(fetchNotificationsAll(userSelect?.value, getAndCheckLocalStorage(localStorageKeys.userId), vehicleSelect?.value, propertySelect?.value, "", "", "", startDate, endDate));
        }
        else {
            dispatch(fetchNotificationsAll(userSelect?.value, customerSelect?.value, vehicleSelect?.value, propertySelect?.value, "", "", "", startDate, endDate));
        }
    }


    return (
        <div>
            <Card>
                <Card.Header>
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <div>
                            <h3 className="mb-4"> <MdOutlineNotificationsActive /> Notifications </h3>
                        </div>
                        {
                            (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.CUSTOMER ||
                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                                getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT
                            ) ?
                                <ul className="list-group">
                                    <li className="list-group-item rounded-lg" style={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between" }}>
                                        <div className="flex" style={{ display: "flex", flexWrap: "wrap", maxWidth: "100%" }}>

                                            {
                                                (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN ||
                                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT
                                                ) && (
                                                    <Form>
                                                        <Row>
                                                            {/*<Col md={3}>
                                                                <Form.Group controlId="startHour" style={{ minWidth: "200px" }}>
                                                                    <Form.Label>Véhicule</Form.Label>
                                                                    <Select
                                                                        value={vehicleSelect}
                                                                        onChange={(selectedOption) => setvehicleSelect(selectedOption)}
                                                                        options={vehicleOptions}
                                                                        isClearable
                                                                        isSearchable
                                                                        placeholder="Choix"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={3}>
                                                                <Form.Group controlId="startHour" style={{ minWidth: "200px" }}>
                                                                    <Form.Label>Propriété</Form.Label>
                                                                    <Select
                                                                        value={propertySelect}
                                                                        isClearable
                                                                        isSearchable
                                                                        onChange={(selectedOption) => setpropertySelect(selectedOption)}
                                                                        options={propertyOptions}
                                                                        placeholder="Choix"
                                                                    />
                                                                </Form.Group>
                                                            </Col> */}
                                                            {
                                                                (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                                                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT ||
                                                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN) &&
                                                                (
                                                                    <Col md={6}>
                                                                        <Form.Group controlId="startHour" style={{ minWidth: "200px" }}>
                                                                            <Form.Label>Agence</Form.Label>
                                                                            <Select
                                                                                value={userSelect}
                                                                                isClearable
                                                                                isSearchable
                                                                                onChange={(selectedOption) => setuserSelect(selectedOption)}
                                                                                options={userOptions}
                                                                                placeholder="Choix"
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>)
                                                            }
                                                            {/*
                                                                (
                                                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.ADMIN ||
                                                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPPORT ||
                                                                    getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.SUPER_ADMIN)
                                                                &&
                                                                (
                                                                    <Col md={6}>
                                                                        <Form.Group controlId="startHour" style={{ minWidth: "200px" }}>
                                                                            <Form.Label>Clients</Form.Label>
                                                                            <Select
                                                                                value={customerSelect}
                                                                                isClearable
                                                                                isSearchable
                                                                                onChange={(selectedOption) => setcustomerSelect(selectedOption)}
                                                                                options={customerOptions}
                                                                                placeholder="Choix"
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                )
                                                             */}
                                                        </Row>
                                                    </Form>
                                                )
                                            }

                                        </div>
                                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                                            <div className="p-1">
                                                <label>{""}</label>
                                                <input type="date" placeholer="début" style={{ height: "40px" }} className="form-control" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                            </div>
                                            <div className="p-1">
                                                <label>{""}</label>
                                                <input type="date" placeholder='fin' style={{ height: "40px" }} className="form-control" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                            </div>
                                            <div className="m-1">
                                                <label>{""}</label>
                                                <div>
                                                    <Button variant='primary' size='sm' className="m-1" onClick={handleSearchFilter} >
                                                        <MdOutlineSearch />
                                                    </Button>
                                                    {
                                                        (
                                                            userSelect || vehicleSelect || propertySelect || customerSelect || startDate || endDate
                                                        ) && (
                                                            <Button variant="danger" size="sm" className="m-1 text-white" onClick={callFunctionElements}>
                                                                <MdDeleteOutline /> Effacer
                                                            </Button>
                                                        )
                                                    }
                                                </div>

                                            </div>

                                        </div>
                                    </li>
                                </ul>
                                : ""
                        }
                    </div>
                </Card.Header>
                <Card.Body style={{ minHeight: "400px" }}>
                    {paginatedNotifications && paginatedNotifications.length > 0 ? (
                        <div className="align-content-between">
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Message</th>
                                        <th>Type</th>
                                        <th>Date</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedNotifications.map((notification) => (
                                        <tr key={notification._id}>
                                            <td>

                                                {
                                                    notification.reservation && notification.reservation.vehicle && notification.reservation.vehicle.images ?
                                                        <Image className="rounded-md" src={notification.reservation.vehicle.images[0] || emptyImage} style={{ height: "70px", width: "70px" }} /> : ""
                                                }
                                                {
                                                    notification.reservation && notification.reservation.property && notification.reservation.property.images ?
                                                        <Image className="rounded-md" src={notification.reservation.property.images[0] || emptyImage} style={{ height: "70px", width: "70px" }} /> : ""
                                                }
                                                {
                                                    notification.maintenance && notification.maintenance.vehicle && notification.maintenance.vehicle.images ?
                                                        <Image className="rounded-md" src={notification.maintenance.vehicle.images[0] || emptyImage} style={{ height: "70px", width: "70px" }} /> : ""
                                                }
                                                {
                                                    notification.maintenance && notification.maintenance.property && notification.maintenance.property.images ?
                                                        <Image className="rounded-md" src={notification.maintenance.property.images[0] || emptyImage} style={{ height: "70px", width: "70px" }} /> : ""
                                                }
                                                {
                                                    notification.property && notification.property && notification.property.images && notification.property.images.length > 0 ?
                                                        <Image className="rounded-md" src={notification.property.images[0] || emptyImage} style={{ height: "70px", width: "70px" }} /> : ""
                                                }
                                                {
                                                    notification.vehicle && notification.vehicle && notification.vehicle.images && notification.vehicle.images.length > 0 ?
                                                        <Image className="rounded-md" src={notification.property.images[0] || emptyImage} style={{ height: "70px", width: "70px" }} /> : ""
                                                }
                                            </td>
                                            <td>{notification.message}</td>
                                            <td>{notification.type}</td>
                                            <td>{new Date(notification.createdAt).toLocaleDateString()}</td>
                                            <td>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="primary" size="sm">
                                                        Actions
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => handleShowModal(notification)}>Voir Détails</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            <div className="d-flex justify-content-start mt-4">
                                <Button
                                    variant="primary"
                                    size="sm" className="p-2"
                                    disabled={currentPage === 1}
                                    onClick={() => setCurrentPage(currentPage - 1)}
                                >
                                    Précédent
                                </Button>
                                <Button
                                    variant="primary"
                                    size="sm" className="p-2"
                                    disabled={currentPage * itemsPerPage >= notifications.length}
                                    onClick={() => setCurrentPage(currentPage + 1)}
                                >
                                    Suivant
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <Alert variant="light" style={{ display: "flex", justifyContent: "center" }}>Aucune notification</Alert>
                    )}
                </Card.Body>
            </Card>

            {/* Modal to show detailed notification */}
            {selectedNotification && (
                <Modal show={showModal} onHide={handleCloseModal} size='md' centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Détails de la Notification</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="my-2">
                            <div>
                                {
                                    selectedNotification.reservation && selectedNotification.reservation.vehicle && selectedNotification.reservation.vehicle.images ?
                                        <Image className="rounded-lg" src={selectedNotification.reservation.vehicle.images[0] || emptyImage} /> : ""
                                }
                                {
                                    selectedNotification.reservation && selectedNotification.reservation.property && selectedNotification.reservation.property.images ?
                                        <Image className="rounded-lg" src={selectedNotification.reservation.property.images[0] || emptyImage} /> : ""
                                }


                                {
                                    selectedNotification.maintenance && selectedNotification.maintenance.vehicle && selectedNotification.maintenance.vehicle.images ?
                                        <Image className="rounded-lg" src={selectedNotification.maintenance.vehicle.images[0] || emptyImage} /> : ""
                                }
                                {
                                    selectedNotification.maintenance && selectedNotification.maintenance.property && selectedNotification.maintenance.property.images ?
                                        <Image className="rounded-lg" src={selectedNotification?.maintenance.property.images[0] || emptyImage} /> : ""
                                }


                                {
                                    selectedNotification.property && selectedNotification.property && selectedNotification.property.images && selectedNotification.property.images.length > 0 ?
                                        <Image className="rounded-lg" src={selectedNotification?.property?.images[0] || emptyImage} /> : ""
                                }
                                {
                                    selectedNotification.vehicle && selectedNotification.vehicle && selectedNotification.vehicle.images && selectedNotification.vehicle.images.length > 0 ?
                                        <Image className="rounded-lg" src={selectedNotification?.property?.images[0] || emptyImage} /> : ""
                                }
                            </div>
                        </div>
                        <p><strong>Message :</strong> {selectedNotification?.message || ""}</p>
                        <p><strong>Type :</strong> {selectedNotification?.type || ""}</p>
                        <p><strong>Date de Création :</strong> {new Date(selectedNotification?.createdAt).toLocaleString()}</p>
                        {selectedNotification?.reservation && <p><strong>Réservation :</strong> {selectedNotification?.reservation?.ordre || ""} <Button size="sm" variant='primary' onClick={() => {
                            navigate(`/${ROUTES.DASHBOARD}/${ROUTES.RESERVATION_VIEW}/${selectedNotification.reservation._id}`)
                        }}><RiEye2Fill /></Button></p>}
                        {selectedNotification.vehicle && <p><strong>Véhicule :</strong> {selectedNotification?.vehicle?.brand || ""}</p>}
                        {selectedNotification.property && <p><strong>Propriété :</strong> {selectedNotification?.property?.name || ""}</p>}
                        {selectedNotification.maintenance && <p><strong>Maintenance :</strong> {selectedNotification?.maintenance?.description || ""}</p>}
                        {selectedNotification.insurance && <p><strong>Assurance :</strong> {selectedNotification?.insurance?.policyNumber || ""} - {selectedNotification?.insurance?.type?.name || ""}</p>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="light" size="sm" onClick={handleCloseModal}>Fermer</Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
};

export default NotificationPage;
