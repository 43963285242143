import { Container, Row, Col, Card, Badge, Button } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import { MdHome, MdLocationOn, MdBuild, MdPerson, MdFormatSize, MdOutlineMoney, MdLocalParking, MdBalcony, MdPool, MdSecurity, MdFitnessCenter, MdAir, MdFireplace, MdOutlineWifi, MdOutlineTheaters, MdOutlineLocalLaundryService, MdOutlineWbSunny, MdOutlineVisibility, MdOutlineDoorFront, MdOutlineSmartToy, MdYard, MdDeck, } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { FETCH_PROPERTY_SUCCESS, FETCH_VEHICLE_SUCCESS } from '../../../app/actions/actions';
import { useNavigate } from 'react-router-dom';
import { RiEye2Fill } from 'react-icons/ri';
import { ROUTES } from '../../../config/routingUrl';
import { emptyImage } from '../../../utils/dataApi/dataFormApi';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix for missing marker icon issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const PropertyDetails = ({ property }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const propertys = useSelector((state) => state.propertys.propertys)
  useEffect(() => {
    if (propertys && propertys.length > 0) {
      const objetTrouve = propertys && propertys.length > 0 ? [...propertys].find(objet => objet._id === property?._id) :
        property;
      dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: objetTrouve });
    }
  }, [dispatch]);

  const images = property && property.images && property.images.length > 0 ? property.images.map((image) => ({
    original: image,
    thumbnail: image
  })) : [{
    original: emptyImage,
    thumbnail: emptyImage
  }];

  const shareUrl = window.location.href;

  return (
    <Container className="my-5">
      <Row>
        <Col md={6}>
          <Card className="shadow-sm" style={{ height: '100%', }}>
            <Card.Body>
              {/* Galerie d'images */}
              <ImageGallery items={images} showThumbnails={true} showFullscreenButton={false} showPlayButton={false}
                renderItem={(item) => (
                  <div style={{
                    height: '300px', // Hauteur fixe
                    width: '100%',
                    overflow: 'hidden'
                  }}>
                    <img src={item.original} alt="" style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'contain'
                    }} />
                  </div>
                )}

              />


              {
                property && property.lat && property.lng && (

                  <div>
                    <div className="py-3">
                      <h6> <MdLocationOn />  Localisation </h6>
                      {/* <p> {property && property.address ? property.address : ""}</p> */}
                    </div>
                    <MapContainer center={[property.lat, property.lng]} zoom={13} style={{ height: "400px", width: "100%" }}>
                      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                      <Marker position={[property.lat, property.lng]}>
                        <Popup>
                          Location: {property.lat}, {property.lng}
                        </Popup>
                      </Marker>
                    </MapContainer>
                  </div>
                )
              }
            </Card.Body>
          </Card>
        </Col>
        {/* 🏡 Détails de la propriété */}
        <Col md={6}>
          <Card className="shadow-sm rounded-5 m-b40 m-sm-b0">
            <div className="sticky-top">
              <Card.Body>
                <div className="dlab-post-title">
                  <h3 className="post-title">
                    <MdHome size={30} /> {property?.name}
                  </h3>
                  <h6 className="sub-title">{property?.type?.name || ""}</h6>
                  <div className="dlab-divider bg-gray tb15">
                    <i className="icon-dot c-square"></i>
                  </div>
                </div>

                {/* ✅ Caractéristiques de la propriété */}
                <div className="flex flex-wrap feature-list m-b40 m-lg-b30">
                  <div className="p-2 border m-1">
                    <label>Adresse</label>
                    <p className="value"><MdLocationOn size={24} /> {property?.address || "N/A"}</p>
                  </div>
                  <div className="p-2 border m-1">
                    <label>Superficie</label>
                    <p className="value"><MdFormatSize size={24} /> {property?.size || "N/A"} m²</p>
                  </div>
                  <div className="p-2 border m-1">
                    <label>Année de construction</label>
                    <p className="value"><MdBuild size={24} /> {property?.yearBuilt || "N/A"}</p>
                  </div>
                </div>

                {/* ✅ Section Prix & Réservation */}
                <div className="m-b40 m-lg-b30">
                  <h4><MdOutlineMoney size={24} /> Prix: {property?.basePricePerDay} DZD</h4>

                  <button
                    type="button"
                    className="btn btn-primary price-btn m-b10 m-r10"
                    onClick={() => {
                      dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: {} });
                      dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: property });
                      navigate(`/${ROUTES.RESERVATION_FORM_PAGE_WEB}`, { state: property });
                    }}
                  >
                    Réserver
                  </button>
                </div>

                {/* ✅ Section Propriétaire */}
                {/*<ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between">
                    <div><MdPerson size={24} /> <strong>Propriétaire</strong></div>
                    <Button size='sm' onClick={() => {
                      navigate(`/${ROUTES.CAR_DETAIL_USER_PATRIMOINE_LIST}/${property.addBy || ""}/person-or-company/${property.name || ""}`)
                    }}>
                      <RiEye2Fill /> Voir
                    </Button>
                  </li>
                </ul> */}
              </Card.Body>
            </div>
          </Card>
        </Col>

        {/* 🛠️ Options supplémentaires du bien immobilier */}
        {(property?.options && Object.values(property.options).some(val => val)) ? (
          <Col md={12} className="mt-4">
            <Card className="shadow-sm">
              <Card.Body>
                <h5>Détails</h5>
                <ul className="list-unstyled d-flex flex-column gap-2">
                  {property?.options?.hasParking && (
                    <li className="py-2 px-1 border"><MdLocalParking size={24} /> <strong>Parking </strong></li>
                  )}
                  {property?.options?.hasBalcony && (
                    <li className="py-2 px-1 border"><MdBalcony size={24} /> <strong>Balcon</strong></li>
                  )}
                  {property?.options?.hasElevator && (
                    <li className="py-2 px-1 border"><MdBuild size={24} /> <strong>Ascenseur</strong></li>
                  )}
                  {property?.options?.hasSwimmingPool && (
                    <li className="py-2 px-1 border"><MdPool size={24} /> <strong>Piscine</strong></li>
                  )}
                  {property?.options?.hasGarden && (
                    <li className="py-2 px-1 border"><MdYard size={24} /> <strong>Jardin</strong></li>
                  )}
                  {property?.options?.hasSecurity && (
                    <li className="py-2 px-1 border"><MdSecurity size={24} /> <strong>Sécurité 24h/24</strong></li>
                  )}
                  {property?.options?.hasGym && (
                    <li className="py-2 px-1 border"><MdFitnessCenter size={24} /> <strong>Salle de sport</strong></li>
                  )}
                  {property?.options?.hasAirConditioning && (
                    <li className="py-2 px-1 border"><MdAir size={24} /> <strong>Climatisation</strong></li>
                  )}
                  {property?.options?.hasFireplace && (
                    <li className="py-2 px-1 border"><MdFireplace size={24} /> <strong>Cheminée</strong></li>
                  )}
                  {property?.options?.hasTerrace && (
                    <li className="py-2 px-1 border"><MdDeck size={24} /> <strong>Terrasse</strong></li>
                  )}
                  {property?.options?.hasInternet && (
                    <li className="py-2 px-1 border"><MdOutlineWifi size={24} /> <strong>Connexion Internet</strong></li>
                  )}
                  {property?.options?.hasCinemaRoom && (
                    <li className="py-2 px-1 border"><MdOutlineTheaters size={24} /> <strong>Salle de cinéma</strong></li>
                  )}
                  {property?.options?.hasLaundryRoom && (
                    <li className="py-2 px-1 border"><MdOutlineLocalLaundryService size={24} /> <strong>Buanderie</strong></li>
                  )}
                  {property?.options?.hasSolarPanels && (
                    <li className="py-2 px-1 border"><MdOutlineWbSunny size={24} /> <strong>Panneaux solaires</strong></li>
                  )}
                  {property?.options?.hasVideoSurveillance && (
                    <li className="py-2 px-1 border"><MdOutlineVisibility size={24} /> <strong>Vidéo surveillance</strong></li>
                  )}
                  {property?.options?.hasElectricGate && (
                    <li className="py-2 px-1 border"><MdOutlineDoorFront size={24} /> <strong>Portail électrique</strong></li>
                  )}
                  {property?.options?.hasSmartHome && (
                    <li className="py-2 px-1 border"><MdOutlineSmartToy size={24} /> <strong>Domotique intelligente</strong></li>
                  )}
                </ul>
              </Card.Body>
            </Card>
          </Col>
        ) : ""}


      </Row>
    </Container>
  );
};

export default PropertyDetails;