import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Modal, Form, Row, Col, Table, Dropdown } from 'react-bootstrap';
import { MdLandslide, MdOutlineDiscount, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCodePromosAll, fetchCodePromosOfIdUserAll, createPromoCode, fetchCodePromoByUpdateId, fetchCodePromoById } from '../../../actions/request/CodePromoRequest';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import { toast } from 'react-toastify';
import { CodePostalCreate, CodePostalUpdateById, fetchCodePostalsAll } from '../../../actions/request/CodePostalRequest';

const CodePostalPage = () => {
    const dispatch = useDispatch();
    const codepostals = useSelector((state) => state.codepostals.codepostals);
    const [showModal, setShowModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [promoCodeData, setPromoCodeData] = useState({
        indicatif: '',
        description: '',
        country: ""
    });
    const [selectedPromoCode, setSelectedPromoCode] = useState(null);
    const [showCode, setShowCode] = useState({});
    const itemsPerPage = 50;

    useEffect(() => {
        dispatch(fetchCodePostalsAll(getAndCheckLocalStorage(localStorageKeys.userId)));
    }, [dispatch]);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => {
        setShowModal(false);
        setPromoCodeData({ indicatif: 225, country: '', description: "" });
        setSelectedPromoCode(null);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPromoCodeData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleCreateOrUpdatePromoCode = () => {
        if (selectedPromoCode) {
            if (!promoCodeData.indicatif && !promoCodeData.description && !promoCodeData.country) {
                toast.error("Veillez renseignez tous les champs s'il vous plait . ", { position: "bottom-right" })
                return;
            }
            dispatch(CodePostalUpdateById(selectedPromoCode?._id, promoCodeData, toast));
        }
        else {
            // const userAdd = getAndCheckLocalStorage(localStorageKeys.userId);
            if (!promoCodeData.indicatif && !promoCodeData.description && !promoCodeData.country) {
                toast.error("Veillez renseignez tous les champs s'il vous plait . ", { position: "bottom-right" })
                return;
            }
            dispatch(CodePostalCreate({ ...promoCodeData }, toast));
        }
        handleCloseModal();
    };

    const handleEditPromoCode = (promoCode) => {
        setSelectedPromoCode(promoCode);
        setPromoCodeData({
            indicatif: promoCode.indicatif,
            description: promoCode.description,
            country: promoCode.country,
        });
        handleShowModal();
    };

    const handleValidatePromoCode = (code) => {
        dispatch(fetchCodePromoById(code));
    };

    const handleToggleShowCode = (promoId) => {
        setShowCode((prevState) => ({ ...prevState, [promoId]: !prevState[promoId] }));
    };

    const paginatedPromoCodes = codepostals.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <div>
            <Card>
                <Card.Header>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <h3 className="mb-4"><MdLandslide /> Indicatifs</h3>
                        </div>
                        <div>
                            <Button title="Nouveau code promo"
                                variant="primary"
                                size='sm'
                                className="ml-2"
                                onClick={handleShowModal}
                            >
                                + Ajouter
                            </Button>
                        </div>
                    </div>
                </Card.Header>

                <Card.Body style={{ minHeight: "400px" }} >
                    {paginatedPromoCodes && paginatedPromoCodes.length > 0 ? (
                        <div className="align-content-between">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Indicatif</th>
                                        <th>Pays</th>
                                        <th>Description</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedPromoCodes.map((promo) => (
                                        <tr key={promo._id}>
                                            <td>
                                                {promo?.indicatif || ""}
                                            </td>
                                            <td>{promo?.country || ""}</td>
                                            <td>{promo?.description || ""}</td>
                                            <td>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="primary" size="sm">
                                                        Actions
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => handleEditPromoCode(promo)}>Modifier</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            <div className="d-flex justify-content-start mt-4">
                                <Button
                                    variant="primary"
                                    size="sm" className="p-2"
                                    disabled={currentPage === 1}
                                    onClick={() => setCurrentPage(currentPage - 1)}
                                >
                                    Précédent
                                </Button>
                                <Button
                                    variant="primary"
                                    size="sm" className="p-2"
                                    disabled={currentPage * itemsPerPage >= codepostals.length}
                                    onClick={() => setCurrentPage(currentPage + 1)}
                                >
                                    Suivant
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <Alert variant="light" style={{ display: "flex", justifyContent: "center" }}>Aucun code promo</Alert>
                    )}
                </Card.Body>
            </Card>

            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedPromoCode ? 'Modifier indicatif' : 'Nouveau indicatif'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="discountPercentage">
                            <Form.Label>Indicatif (Ex: 225) <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                style={{ height: "40px" }}
                                type="number"
                                name="indicatif"
                                placeholder=''
                                value={promoCodeData.indicatif}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="discountPercentage">
                            <Form.Label>Pays <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                style={{ height: "40px" }}
                                type="text"
                                name="country"
                                placeholder=''
                                value={promoCodeData.country}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="expiryDate">
                            <Form.Label>Description <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                as={"textarea"}
                                style={{ height: "50px" }}
                                type="text"
                                name="description"
                                value={promoCodeData.description}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" size="sm" onClick={handleCloseModal}>Annuler</Button>
                    <Button variant="primary" size="sm" onClick={handleCreateOrUpdatePromoCode}>{selectedPromoCode ? 'Mettre à Jour' : 'Créer'}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CodePostalPage;