import React from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Container, Row, Col } from "react-bootstrap";

const PrivacyPolicyPage = () => {
  const defaultLayout = defaultLayoutPlugin();

  // Chemin du fichier PDF
  const pdfFilePath = "/terms-and-conditions.pdf"; // Placez le fichier dans le dossier public


  return (
    <div>
      <Row className="mt-4">
        <Col md={12} className="text-center">
          <h3>Conditions d{"'"}utilisation</h3>
          <p>Consultez les conditions générales d{"'"}utilisation de notre plateforme ci-dessous.</p>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={12}>
          <div style={{ border: "1px solid #ccc", minHeight: "100%" }}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
              <Viewer fileUrl={pdfFilePath}  />
            </Worker>
          </div>
        </Col>
      </Row>
    </div>
  );
  
};

export default PrivacyPolicyPage;
