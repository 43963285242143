import React, { useState, useRef, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import SignaturePad from "react-signature-pad-wrapper";
import { jsPDF } from "jspdf";
import axios from "axios";
import { getAndCheckLocalStorage } from "../../../utils/storage/localvalueFuction";
import { localStorageKeys } from "../../../utils/storage/localvalue";
import { toast } from "react-toastify";
import { baseurl } from "../../../utils/url/baseurl";
import { UserUpdateById } from "../../../actions/request/UserRequest";
import { useDispatch } from "react-redux";

const ContractModal = ({ show, handleClose, contractData }) => {
    const dispatch = useDispatch();
    const sigPad = useRef(null);
    const [signature, setSignature] = useState(null);
    const [formData, setFormData] = useState(contractData);
    const [agencySignature, setAgencySignature] = useState(null);
    const userId = getAndCheckLocalStorage(localStorageKeys.userId);

    useEffect(() => {
        handleGetSignature();
    }, []);

    const handleGetSignature = () => {
        axios.get(`${baseurl.url}/api/v1/users/get_user/${userId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        })
        .then(response => setAgencySignature(response?.data?.data?.signatureElectronique))
        .catch(error => console.error("Erreur de chargement de la signature de l’agence", error));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const saveSignature = () => {
        if (sigPad.current.isEmpty()) {
            toast.error("Veuillez dessiner votre signature", { position: "bottom-right" });
            return;
        }
        const signatureData = sigPad.current.toDataURL("image/png");
        setSignature(signatureData);
        dispatch(UserUpdateById(userId, { signatureElectronique: signatureData }, toast));
    };

    // Fonction pour déterminer si c'est une propriété ou un véhicule
    const getContractType = () => {
        return formData.propertyType.toLowerCase().includes("voiture") || formData.propertyType.toLowerCase().includes("véhicule")
            ? "véhicule"
            : "propriété";
    };

    const generateContractPDF = () => {
        const doc = new jsPDF();

        // Ajout de la bordure
        doc.rect(5, 5, 200, 287); 

        doc.setFontSize(10);
        doc.setTextColor(255, 0, 0);
        doc.text("NOUVEAU CLIENT : n’oubliez pas de mettre vos compteurs d’énergie à votre nom dès la signature du bail", 10, 15);
        doc.text("Tél démarches : 02 30 95 10 98 (non surtaxé, lundi-vendredi 9h-19h, samedi 10h-17h)", 10, 20);
        doc.setTextColor(0, 0, 0);
        doc.text("Page 1 sur 10 – Contrat de location conforme Loi Alur Paraphes", 10, 25);

        doc.setFontSize(18);
        doc.setTextColor(0, 0, 128);
        doc.text("CONTRAT DE LOCATION", 50, 35);
        doc.setFontSize(14);
        doc.text(`Pour ${getContractType() === "véhicule" ? "véhicule" : "propriété"} en location`, 50, 45);

        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        doc.text(`Je soussigné, ${formData.clientName}, domicilié à ${formData.clientAddress},`, 20, 60);
        doc.text(`téléphone : ${formData.clientPhone}, accepte la location du ${getContractType()} suivant :`, 20, 70);

        doc.setFontSize(10);
        doc.text(`• Type de bien : ${formData.propertyType}`, 20, 85);
        doc.text(`• Adresse : ${formData.propertyAddress}`, 20, 95);
        // doc.text(`• Durée de location : ${formData.rentalPeriod}`, 20, 105);
        doc.text(`• Date de début : ${formData.startDate}`, 20, 115);
        doc.text(`• Date de fin : ${formData.endDate}`, 20, 125);
        doc.text(`• Prix de la location : ${formData.rentalPrice}`, 20, 135);
        doc.text(`• Dépôt de garantie : ${formData.deposit}`, 20, 145);

        doc.line(20, 170, 190, 170); 

        if (agencySignature) {
            doc.text("Signature de l'Agence :", 20, 180);
            doc.addImage(agencySignature, "PNG", 20, 185, 50, 20);
        }

        doc.addPage();
        doc.setFontSize(14);
        doc.text("Page de Signature du Client", 60, 30);

        // if (signature) {
        //     doc.text("Signature du Client :", 20, 50);
        //     doc.addImage(signature, "PNG", 20, 55, 50, 20);
        // } else {
            doc.text("Signature du Client : ___________________", 20, 50);
        // }

        doc.save("contrat_location.pdf");
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Contrat de Location</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Nom du Client</Form.Label>
                                <Form.Control style={{ height:"40px" }} type="text" name="clientName" value={formData.clientName} onChange={handleChange} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Adresse</Form.Label>
                                <Form.Control style={{ height:"40px" }} type="text" name="clientAddress" value={formData.clientAddress} onChange={handleChange} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Téléphone</Form.Label>
                                <Form.Control style={{ height:"40px" }} type="text" name="clientPhone" value={formData.clientPhone} onChange={handleChange} />
                            </Form.Group>
                        </Col>

                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Type de bien</Form.Label>
                                <Form.Control style={{ height:"40px" }} type="text" name="propertyType" value={formData.propertyType} readOnly />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Adresse</Form.Label>
                                <Form.Control style={{ height:"40px" }} type="text" name="propertyAddress" value={formData.propertyAddress} readOnly />
                            </Form.Group>
                            {/*<Form.Group>
                                <Form.Label>Durée de location</Form.Label>
                                <Form.Control type="text" name="rentalPeriod" value={formData.rentalPeriod} onChange={handleChange} />
                            </Form.Group> */}
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col md={12}>
                            <h6>Signature de l’Agence</h6>
                            {agencySignature ? (
                                <img src={agencySignature} alt="Signature Agence" className="img-thumbnail mt-2" width={200} />
                            ) : (
                                <p>Signature non disponible</p>
                            )}
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Fermer
                </Button>
                <Button variant="success" onClick={generateContractPDF}>
                    📄 Télécharger le Contrat
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ContractModal;
