import { Container, Row, Col, Card, Badge, Button } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import { MdDirectionsCar, MdModeOfTravel, MdAvTimer, MdColorLens, MdOutlineMoney, MdPerson, MdShare, MdGpsFixed, MdAcUnit, MdFlashOn, MdPanoramaWideAngle, MdCameraRear, MdCarRepair, MdSettingsInputComponent, MdAirlineSeatReclineExtra, MdEngineering } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_PROPERTY_SUCCESS, FETCH_VEHICLE_SUCCESS } from '../../../app/actions/actions';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../../config/routingUrl';
import { RiEye2Fill } from 'react-icons/ri';
import { emptyImage } from '../../../utils/dataApi/dataFormApi';
import { FaHorseHead } from 'react-icons/fa6';

const VehicleDetails = ({ vehicle }) => {

  const navigate = useNavigate();

  const paramVehcile = useParams();

  const dispatch = useDispatch();
  // 🔗 Générer le lien de partage
  const shareUrl = window.location.href;

  // const vehicles = useSelector((state) => state.vehicles.vehicles)

  // useEffect(() => {
  //     console.log(paramVehcile?.id);
  //     const objetTrouve = vehicles && [...vehicles].length > 0 ? [...vehicles].find(objet => objet._id === paramVehcile?.id) : vehicle ;
  //     dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: objetTrouve });
  // }, [paramVehcile]);


  const images = vehicle && vehicle.images && vehicle.images.length > 0 ? vehicle.images.map((image) => ({
    original: image,
    thumbnail: image
  })) : [{
    original: emptyImage,
    thumbnail: emptyImage
  }];

  return (
    <section className="">
      <div className="container">
        <div className="row">
          <Col md={6}>
            <Card className="shadow-sm" style={{ height: '100%', }}>
              <Card.Body>
                {/* Galerie d'images */}
                <ImageGallery
                  items={images}
                  showThumbnails={true}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  renderItem={(item) => (
                    <div style={{
                      height: '300px', // Hauteur fixe
                      width: '100%',
                      overflow: 'hidden'
                    }}>
                      <img
                        src={item.original}
                        alt=""
                        style={{
                          height: '100%',
                          width: '100%',
                          objectFit: 'contain'
                        }}
                      />
                    </div>
                  )}

                />
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="shadow-sm rounded-5  m-b40 m-sm-b0">
              <div className="sticky-top">
                <Card.Body>
                  <div className="dlab-post-title">
                    <h3 className="post-title">
                      <Link to="javascript:void(0);">{vehicle?.brand}</Link>
                    </h3>
                    <h6 className="sub-title">{vehicle?.type?.name || ""}</h6>
                    <div className="dlab-divider bg-gray tb15">
                      <i className="icon-dot c-square"></i>
                    </div>
                  </div>

                  {/* ✅ Caractéristiques du véhicule */}
                  <div className=" flex flex-wrap feature-list m-b40 m-lg-b30">
                    <div className="p-2 border m-1">
                      <label>Modèle</label>
                      <p className="value">{vehicle?.model || ""}</p>
                    </div>
                    <div className="p-2 border m-1">
                      <label>Année</label>
                      <p className="value">{vehicle?.year || ""}</p>
                    </div>
                    <div className="p-2 border m-1">
                      <label>Couleur</label>
                      <p className="value">{vehicle?.color || ""}</p>
                    </div>
                    <div className="p-2 border m-1">
                      <label>Kilométrage</label>
                      <p className="value">{vehicle?.mileage || ""} km</p>
                    </div>
                    <div className="p-2 border m-1">
                      <label>Moteur</label>
                      <p className="value">{vehicle?.engineType?.name || ""}</p>
                    </div>
                    {
                      (vehicle?.options?.automaticTransmission ) &&
                      (
                        <div className="p-2 border m-1">
                          <label>Transmission Automatique</label>
                          <p className="value">
                            {vehicle?.options?.automaticTransmission && "Automatique"}
                          </p>
                        </div>
                      )
                    }

                    {
                      (vehicle?.options?.manualTransmission) &&
                      (
                        <div className="p-2 border m-1">
                          <label>Transmission Manuelle</label>
                          <p className="value">
                            {vehicle?.options?.manualTransmission && "Manuelle"}
                          </p>
                        </div>
                      )
                    }

                    <div className="p-2 border m-1">
                      <label>Places</label>
                      <p className="value">{vehicle?.passenger}</p>
                    </div>
                  </div>

                  {/* ✅ Section Prix & Réservation */}
                  <div className="m-b40 m-lg-b30">
                    <h4><MdOutlineMoney /> Prix: {vehicle?.basePricePerDay} DZD</h4>

                    <button
                      type="button"
                      className="btn btn-primary price-btn m-b10 m-r10"
                      onClick={() => {
                        dispatch({ type: FETCH_VEHICLE_SUCCESS, payload: vehicle });
                        dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: {} });
                        navigate(`/${ROUTES.RESERVATION_FORM_PAGE_WEB}`, { state: vehicle });
                      }}
                    >
                      Réserver
                    </button>

                    {/*<Link to="#" className="btn btn-primary light shadow-none m-b10">
                      <i className="bi bi-cart"></i> Ajouter au panier
                    </Link> */}
                  </div>

                  {/* ✅ Section Propriétaire */}
                  {/*<ul className="list-group">
                    <li className="list-group-item" style={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between" }}>
                      <div> <MdPerson /> Propriétaire </div>
                      <div>
                        <Button variant="primary" size="sm"
                          onClick={() => {
                            navigate(`/${ROUTES.CAR_DETAIL_USER_PATRIMOINE_LIST}/${vehicle.addBy || ""}/person-or-company/${vehicle.brand || ""}`)
                          }}
                        >
                          Détail
                        </Button>
                      </div>
                    </li>
                  </ul> */}

                  {/* ✅ Section Partage */}
                  <div className="social-list mt-4">
                    <span>Partager</span>
                    <ul style={{ display: "flex", gap: "15px", listStyle: "none", padding: 0 }}>
                      <li>
                        <a href={`https://api.whatsapp.com/send?text=${encodeURIComponent(shareUrl)}`} target="_blank" rel="noopener noreferrer">
                          <img src="https://cdn-icons-png.flaticon.com/512/733/733585.png" alt="WhatsApp" width="30" />
                        </a>
                      </li>
                      <li>
                        <a href={`mailto:?subject=Découvrez ce véhicule&body=${encodeURIComponent(shareUrl)}`}>
                          <img src="https://cdn-icons-png.flaticon.com/512/732/732200.png" alt="Email" width="30" />
                        </a>
                      </li>
                      <li>
                        <a href={`https://www.instagram.com/?url=${encodeURIComponent(shareUrl)}`} target="_blank" rel="noopener noreferrer">
                          <img src="https://cdn-icons-png.flaticon.com/512/174/174855.png" alt="Instagram" width="30" />
                        </a>
                      </li>
                      <li>
                        <a href={`https://join.skype.com/share?url=${encodeURIComponent(shareUrl)}`} target="_blank" rel="noopener noreferrer">
                          <img src="https://cdn-icons-png.flaticon.com/512/2111/2111726.png" alt="Skype" width="30" />
                        </a>
                      </li>
                    </ul>
                  </div>

                </Card.Body>
              </div>
            </Card>
          </Col>
          {/*options supplementaires */}


          {(vehicle?.options && Object.values(vehicle.options).some(val => val)) || vehicle?.interiorType ? (
            <Col md={12} className="mt-4">
              <Card className="shadow-sm">
                <Card.Body>
                  <h5>Détails </h5>
                  <ul className="list-unstyled d-flex flex-column gap-2">
                    {/* ✅ Options avec condition d'affichage */}
                    {vehicle?.options?.gps && (
                      <li className="py-2 px-1 border"><MdGpsFixed size={24} /> <strong>GPS</strong></li>
                    )}
                    {vehicle?.options?.airConditioning && (
                      <li className="py-2 px-1 border"><MdAcUnit size={24} /> <strong>Climatisation</strong></li>
                    )}
                    {vehicle?.options?.turbo && (
                      <li className="py-2 px-1 border"><MdFlashOn size={24} /> <strong>Turbo</strong></li>
                    )}
                    {vehicle?.options?.sunroof && (
                      <li className="py-2 px-1 border"><MdPanoramaWideAngle size={24} /> <strong>Toit Ouvrant</strong></li>
                    )}
                    {vehicle?.options?.reverseCamera && (
                      <li className="py-2 px-1 border"><MdCameraRear size={24} /> <strong>Caméra de Recul</strong></li>
                    )}
                    {vehicle?.options?.speedRegulator && (
                      <li className="py-2 px-1 border"><MdCarRepair size={24} /> <strong>Régulateur de Vitesse</strong></li>
                    )}
                    {vehicle?.options?.horsepower >=0 && (
                      <li className="py-2 px-1 border"><FaHorseHead size={24} /> <strong>Chevaux :</strong> {vehicle?.options?.horsepower} CV</li>
                    )}

                    {/* ✅ Affichage conditionnel du type d'intérieur */}
                    {vehicle?.interiorType?.name && (
                      <li className="py-2 px-1 border"><MdAirlineSeatReclineExtra size={24} /> <strong>Intérieur :</strong> {vehicle?.interiorType?.name}</li>
                    )}

                    {/* ✅ Affichage conditionnel du type d'intérieur */}
                    {vehicle?.engineType?.name && (
                      <li className="py-2 px-1 border"><MdEngineering size={24} /> <strong>Moteur :</strong> {vehicle?.engineType?.name}</li>
                    )}
                  </ul>
                </Card.Body>
              </Card>
            </Col>
          ) : ""}


        </div>
      </div>
    </section>
  );
};

export default VehicleDetails;
