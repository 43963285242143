import React, { useEffect, useState } from 'react';
import { Form, Container, Row, Col, InputGroup, FormControl, Spinner } from 'react-bootstrap';
import { colorApp, maxLengthLitte } from '../../utils/dataApi/dataFormApi';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_USER_SUCCESS } from '../../app/actions/actions';
import { UserResetPasswordForget, UserSendCodeverfiy, UserverfiyCode } from '../../actions/request/UserRequest';
import { FaEnvelope, FaKey, FaCheckCircle } from 'react-icons/fa';
import './ForgotPasswordPage.css';
import { toast } from 'react-toastify';

const ForgotPasswordPage = () => {
    const dispatch = useDispatch();
    const loadingUser = useSelector((state) => state.users.loadingUser);

    const [showModal, setshowModal] = useState(false);
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');
    const [code, setCode] = useState(new Array(4).fill(''));
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isPawword, setisPawword] = useState(false);

    useEffect(() => {
        dispatch({ type: FETCH_USER_SUCCESS });
        setStep(1);
    }, [dispatch]);

    const handleEmailSubmit = (e) => {
        e.preventDefault();
        dispatch(UserSendCodeverfiy(email, email, setStep, toast));
    };

    const handleCodeSubmit = (e) => {
        e.preventDefault();
        dispatch(UserverfiyCode(email, email, code.join(''), setStep, toast));
    };

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        dispatch(UserResetPasswordForget(email, newPassword, setStep, setshowModal, toast));
        // setStep(1);
        setEmail('');
        setCode(new Array(4).fill(''));
        setNewPassword('');
        setConfirmPassword('');
    };

    const handleCodeChange = (e, index) => {
        if (/^[0-9]$/.test(e.target.value) || e.target.value === '') {
            const newCode = [...code];
            newCode[index] = e.target.value;
            setCode(newCode);

            if (e.target.value !== '' && index < 3) {
                document.getElementById(`code-${index + 1}`).focus();
            } else if (index === 3 && e.target.value !== '') {
                document.getElementById(`code-${index}`).blur();
            }
        }
    };

    return (
        <Container style={{ height: "500px" }}>
            <Row className="justify-content-md-center mt-120">
                <Col md={6}>
                    <h5 className="text-center my-4">Mot de passe oublié</h5>
                    <hr />
                    {step === 1 && (
                        <Form onSubmit={handleEmailSubmit} className="flex ">
                            <Form.Group controlId="formEmail">
                                <Form.Label>Email <FaEnvelope /></Form.Label>
                                <Form.Control
                                    style={{ height: "40px" }}
                                    type="email"
                                    placeholder="Entrez votre email"
                                    value={email}
                                    
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (inputValue.length <= maxLengthLitte) {
                                            setEmail(inputValue);
                                        }
                                    }}
                                    required
                                />
                            </Form.Group>
                            <button
                                className={`btn ${colorApp.btn_secondary} mt-3`}
                                type="submit"
                                disabled={loadingUser}
                            >
                                {loadingUser ? <Spinner animation="border" size="sm" /> : "Envoyer le code"}
                            </button>
                        </Form>
                    )}
                    {step === 2 && (
                        <Form onSubmit={handleCodeSubmit}>
                            <Form.Group controlId="formCode">
                                <Form.Label>Code de vérification <FaKey /></Form.Label>
                                <InputGroup>
                                    {code.map((digit, index) => (
                                        <FormControl
                                            key={index}
                                            id={`code-${index}`}
                                            type="text"
                                            maxLength="1"
                                            className="text-center"
                                            value={digit}
                                            onChange={(e) => handleCodeChange(e, index)}
                                            required
                                        />
                                    ))}
                                </InputGroup>
                            </Form.Group>
                            <button
                                className={`btn ${colorApp.btn_secondary} mt-3`}
                                type="submit"
                                disabled={loadingUser}
                            >
                                {loadingUser ? <Spinner animation="border" size="sm" /> : "Vérifier le code"}
                            </button>
                        </Form>
                    )}
                    {step === 3 && (
                        <Form onSubmit={handlePasswordSubmit}>
                            <Form.Group controlId="formNewPassword">
                                <Form.Label>Nouveau mot de passe <FaKey /></Form.Label>
                                <Form.Control
                                    type={isPawword ? "text" : "password"}
                                    placeholder="Entrez le nouveau mot de passe"
                                    value={newPassword}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (inputValue.length <= maxLengthLitte) {
                                            setNewPassword(inputValue);
                                        }
                                    }}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formConfirmPassword">
                                <Form.Label>Confirmez le nouveau mot de passe <FaCheckCircle /></Form.Label>
                                <Form.Control
                                    type={isPawword ? "text" : "password"}
                                    placeholder="Confirmez le nouveau mot de passe"
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (inputValue.length <= maxLengthLitte) {
                                            setConfirmPassword(inputValue);
                                        }
                                    }}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formConfirmPassword">
                                <Form.Check
                                    type="checkbox"
                                    id="prestataireCheck"
                                    label="Voir mot de passe"

                                    checked={isPawword}
                                    onChange={(e) => setisPawword(e.target.checked)}
                                />
                                <Form.Label>Afficher mot de passe </Form.Label>

                            </Form.Group>

                            <button
                                className={`btn ${colorApp.btn_secondary} mt-3`}
                                type="submit"
                                disabled={loadingUser}
                            >
                                {loadingUser ? <Spinner animation="border" size="sm" /> : "Modifier le mot de passe"}
                            </button>
                        </Form>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default ForgotPasswordPage;