import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Modal, Form, Row, Col, Table, Dropdown } from 'react-bootstrap';
import { MdAirplaneTicket, MdCurrencyExchange, MdLandslide, MdMoneyOff, MdOutlineDiscount, MdOutlineSupport, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCodePromosAll, fetchCodePromosOfIdUserAll, createPromoCode, fetchCodePromoByUpdateId, fetchCodePromoById } from '../../../actions/request/CodePromoRequest';
import { getAndCheckLocalStorage } from '../../../utils/storage/localvalueFuction';
import { localStorageKeys } from '../../../utils/storage/localvalue';
import { toast } from 'react-toastify';
import { RiFileDamageFill } from 'react-icons/ri';
import { fetchExchangesAll } from '../../../actions/request/ExchangeRateRequest';

const ExchangeRatePage = () => {


    const dispatch = useDispatch();
    const exchangerates = useSelector((state) => state.exchangerates.exchangerates);
    const [showModal, setShowModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [promoCodeData, setPromoCodeData] = useState({
        name: '',
        description: '',
    });
    const itemsPerPage = 50;

    useEffect(() => {
        dispatch(fetchExchangesAll());
    }, [dispatch]);

    const paginatedPromoCodes = exchangerates.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) || [];



    return (
        <div>
            <Card>
                <Card.Header>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <h3 className="mb-4"><MdCurrencyExchange /> Taux de change</h3>
                        </div>
                        <div>
                        </div>
                    </div>
                </Card.Header>

                <Card.Body style={{ minHeight: "400px" }} >
                    {paginatedPromoCodes && paginatedPromoCodes.length > 0 ? (
                        <div className="align-content-between">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Devise</th>
                                        <th>Taux d{"'"}achat (DZD)</th>
                                        <th>Taux de vente (DZD)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedPromoCodes.map((promo) => (
                                        <tr key={promo._id}>
                                            <td>
                                                {promo?.currency || ""}
                                            </td>
                                            <td>{promo?.buyRate || ""}</td>
                                            <td>{promo?.sellRate || ""}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            <div className="d-flex justify-content-start mt-4">
                                <Button
                                    variant="primary"
                                    size="sm" className="p-2"
                                    disabled={currentPage === 1}
                                    onClick={() => setCurrentPage(currentPage - 1)}
                                >
                                    Précédent
                                </Button>
                                <Button
                                    variant="primary"
                                    size="sm" className="p-2"
                                    disabled={currentPage * itemsPerPage >= exchangerates.length}
                                    onClick={() => setCurrentPage(currentPage + 1)}
                                >
                                    Suivant
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <Alert variant="light" style={{ display: "flex", justifyContent: "center" }}>Aucun taux de change</Alert>
                    )}
                </Card.Body>
            </Card>


        </div>
    );
};

export default ExchangeRatePage;