

export const localStorageKeys = {
    profileRole: 'profileRole',
    userRole: 'userRole',

    userCoverPicture: 'userCoverPicture',
    loginTime: 'loginTime',
    loginHour: 'loginHour',
    startupTime: 'startupTime',
    userId: 'userId',
    userName: 'userName',
    categoryId: 'categoryId',
    invoiceId: 'invoiceId',
    roleUser: "roleUser",
    language: "language",

    adminId: "adminId",
    adminCoverPicture: "adminCoverPicture",
    adminRole: "adminRole",
    adminName: "adminName",


    agenceDepArt: "agenceDepArt",
    agenceRetour: "agenceRetour",
    latSelect: "latSelect",
    longSelect: "longSelect",
    latSelectRetour: "latSelectRetour",
    longSelectRetour: "longSelectRetour",
    dateDepart: "dateDepart",
    dateRetour: "dateRetour",

    firstNameReservaiton: "firstNameReservaiton",
    lastNameReservation: "lastNameReservation",
    phoneReservation: "phoneReservation",
    dateBirthDayreservation: "dateBirthDayreservation",
    idCardReservation: "idCardReservation",
    driverLicenseNumberReservation: "driverLicenseNumberReservation",
    emailReservation: "emailReservation",






};


export const localStorageData = {
    Users: "Users",
    Customers: "Customers",
    Vehicles: "Vehicles",
    Propertys: "Propertys",


    heureDepart: "heureDepart",
    heureRetour: "heureRetour",

    codePostalReservation: "codePostalReservation",

    ExchangeRateGetNow: "ExchangeRateGetNow",
    ExchangeRateGetAll: "ExchangeRateGetAll",

    TermsAndConditionsGet: "TermsAndConditionsGet",
    TermsAndConditionsAll: "TermsAndConditionsAll",
    ContactAll: "Contact"




}
