import {
  FETCH_DEPOSITS_FAILURE, FETCH_DEPOSITS_REQUEST, FETCH_DEPOSITS_SUCCESS, FETCH_DEPOSITS_SUCCESS_2,
  FETCH_DEPOSITS_SUCCESS_D, FETCH_DEPOSIT_FAILURE, FETCH_DEPOSIT_REQUEST, FETCH_DEPOSIT_SUCCESS
} from
  "../actions/actions";

// depositReducer.js
const initialState = {
  deposits: [],
  deposits2: [],
  deposit: {},
  depositSelect: {},
  loadingDeposit: false,
  loadingDepositSelect: false,
  loadingDeposits: false,
  errorDeposits: null,
  errorDeposit: null
  // ... autres états spécifiques à depositReducer
};

const depositReducer = (state = initialState, action) => {
  switch (action.type) {

    case FETCH_DEPOSIT_REQUEST:
      // Dispatch une action pour définir loading sur true dans le globalReducer
      return {
        ...state,
        loadingDeposit: true,
        errorDeposit: ""
        // Autres manipulations d'état pour la demande de récupération des deposits
      };

    case FETCH_DEPOSIT_SUCCESS:
      return {
        ...state,
        loadingDeposit: false,
        errorDeposit: "",
        deposit: action.payload
      };

    case FETCH_DEPOSIT_FAILURE:
      return {
        ...state,
        loadingDeposit: false,
        errorDeposit: action.payload
      };

    // DEPOSIT
    // case FETCH_DEPOSIT_REQUEST_SELECT:
    // // Dispatch une action pour définir loading sur true dans le globalReducer
    // return {
    // ...state,
    // loadingDepositSelect:true,
    // errorDeposits:""
    // // Autres manipulations d'état pour la demande de récupération des deposits
    // };
    // case FETCH_DEPOSIT_SUCCESS_SELECT:
    // return {
    // ...state,
    // loadingDepositSelect:false,
    // errorDeposits:"",
    // depositSelect:action.payload
    // };
    // case FETCH_DEPOSIT_FAILURE_SELECT:
    // return {
    // ...state,
    // loadingDepositSelect:false,
    // errorDeposits:action.payload
    // };

    case FETCH_DEPOSITS_REQUEST:
      return {
        ...state,
        loadingDeposits: true,
        errorDeposits: ""
      };

    case FETCH_DEPOSITS_SUCCESS:
      return {
        ...state,
        deposits: action.payload,
        loadingDeposits: false,
        errorDeposits: ""
        // Autres manipulations d'état pour le succès de la récupération des deposits
      };

    case FETCH_DEPOSITS_SUCCESS_2:
      return {
        ...state,
        deposits2: action.payload,
        loadingDeposits: false,
        errorDeposits: ""
        // Autres manipulations d'état pour le succès de la récupération des deposits
      };

    case FETCH_DEPOSITS_SUCCESS_D:
      return {
        ...state,
        depositsD: action.payload,
        loadingDeposits: false,
        errorDeposits: ""
        // Autres manipulations d'état pour le succès de la récupération des deposits
      };

    case FETCH_DEPOSITS_FAILURE:
      // Dispatch une action pour définir loading sur false dans le globalReducer et enregistrer l'erreur
      return {
        ...state,
        loadingDeposits: false,
        errorDeposits: action.payload
        // Autres manipulations d'état pour l'échec de la récupération des deposits
      };
    // ... autres cas pour d'autres actions liées aux deposits

    default:
      return state;
  }
};

export default depositReducer;