import React from 'react';
import { getAndCheckLocalStorage } from '../../utils/storage/localvalueFuction';
import { profileRoleType } from '../../utils/dataApi/dataFormApi'
import { exportToExcelData, exportToPDFData } from '../../utils/functionData/ExportFileDocument';
import { useSelector } from 'react-redux';
import { Card, Col, Dropdown, Row, Table } from 'react-bootstrap';
import { localStorageKeys } from '../../utils/storage/localvalue';

const PatrimoineCountStatisticsCard = () => {


    const propertysAvailables = useSelector((state) => state.propertys.propertysAvailable);
    const propertysUnAvailables = useSelector((state) => state.propertys.propertysUnAvailable);
    const vehiclesAvailables = useSelector((state) => state.vehicles.vehiclesAvailable);
    const vehiclesUnAvailables = useSelector((state) => state.vehicles.vehiclesUnAvailable);


    return (
        <Card className="mt-4 border rounded-lg shadow-sm">
            <Card.Body>

                <Row>
                    {
                        (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                            profileRoleType.ADMIN || profileRoleType.SUPER_ADMIN || profileRoleType.SUPPORT) &&
                        <Col md={6} className="mt-4">
                            <div className="mb-5" style={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between" }}>
                                <div><h6 className="">Véhicules loués</h6></div>
                                <div>
                                    {
                                        vehiclesUnAvailables && vehiclesUnAvailables.length > 0 &&
                                        <Dropdown >
                                            <Dropdown.Toggle variant="primary" className='btn btn-sm' id="dropdown-basic">
                                                Exporter
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => { exportToExcelData(vehiclesUnAvailables, "vehicle", "Listes_des_véhicules_loués") }}>Exporter en Excel</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { exportToPDFData(vehiclesUnAvailables, "vehicle", "Listes_des_véhicules_loués") }}>Export en pdf</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    }
                                </div>
                            </div>
                            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th>Designation</th>
                                            <th>Marque</th>
                                            <th>Model</th>
                                            <th>Prix / jour(DZD)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            vehiclesUnAvailables && vehiclesUnAvailables.length > 0
                                                ? vehiclesUnAvailables.map(vehicle => (
                                                    <tr >
                                                        <td>{vehicle?.brand || ""}</td>
                                                        <td>{vehicle?.vehiclebrand || ""}</td>
                                                        <td>{vehicle?.model || ""}</td>
                                                        <td>{vehicle?.basePricePerDay || ""}</td>
                                                    </tr>
                                                )) : (
                                                    <tr>
                                                        <td colSpan="4"> Accun véhicule loués</td>
                                                    </tr>
                                                )}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    }


                    {

                        (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                            profileRoleType.ADMIN || profileRoleType.SUPER_ADMIN || profileRoleType.SUPPORT) &&
                        <Col md={6} className="mt-4">
                            <div className="mb-5" style={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between" }}>
                                <div><h6 className="">Véhicules non loués</h6></div>
                                <div>
                                    {
                                        vehiclesAvailables && vehiclesAvailables.length > 0 &&
                                        <Dropdown >
                                            <Dropdown.Toggle variant="primary" className="btn btn-sm" id="dropdown-basic">
                                                Exporter
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => { exportToExcelData(vehiclesAvailables, "vehicle", "Listes_des_véhicules_non_loués") }}>Exporter en Excel</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { exportToPDFData(vehiclesAvailables, "vehicle", "Listes_des_véhicules_non_loués") }}>Export en Pdf</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    }
                                </div>
                            </div>
                            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                                <Table hover >
                                    <thead>
                                        <tr>
                                            <th>Designation</th>
                                            <th>Marque</th>
                                            <th>Model</th>
                                            <th>Prix / jour(DZD)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            vehiclesAvailables && vehiclesAvailables.length > 0
                                                ? vehiclesAvailables.map(vehicle => (
                                                    <tr >
                                                        <td>{vehicle?.brand || ""}</td>
                                                        <td>{vehicle?.vehiclebrand || ""}</td>
                                                        <td>{vehicle?.model || ""}</td>
                                                        <td>{vehicle?.basePricePerDay || ""}</td>
                                                    </tr>
                                                )) : (
                                                    <tr>
                                                        <td colSpan="4"> Accun véhicule non loués</td>
                                                    </tr>
                                                )}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    }





                    {/*Propriétés */}
                    {

                        (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                            profileRoleType.ADMIN || profileRoleType.SUPER_ADMIN || profileRoleType.SUPPORT) &&
                        <Col md={6} className="mt-4">
                            <div className="mb-5" style={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between" }}>
                                <div><h6 className="">Propriétés louées</h6></div>
                                <div>
                                    {
                                        propertysUnAvailables && propertysUnAvailables.length > 0 &&
                                        <Dropdown >
                                            <Dropdown.Toggle variant="primary" className='btn btn-sm' id="dropdown-basic">
                                                Exporter
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => { exportToExcelData(propertysUnAvailables, "property", "Listes_des_propriétes_loués") }}>Exporter en Excel</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { exportToPDFData(propertysUnAvailables, "property", "Listes_des_propriétes_loués") }}>Export en Pdf</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    }
                                </div>
                            </div>
                            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th>Designation</th>
                                            <th>Addresse</th>
                                            <th>Prix / jour(DZD)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            propertysUnAvailables && propertysUnAvailables.length > 0
                                                ? propertysUnAvailables.map(vehicle => (
                                                    <tr >
                                                        <td>{vehicle?.name || ""}</td>
                                                        <td>{vehicle?.address || ""}</td>
                                                        <td>{vehicle?.basePricePerDay || ""}</td>
                                                    </tr>
                                                )) : (
                                                    <tr>
                                                        <td colSpan="4"> Accun propriété loué</td>
                                                    </tr>
                                                )}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    }


                    {

                        (getAndCheckLocalStorage(localStorageKeys.profileRole) == profileRoleType.USER ||
                            profileRoleType.ADMIN || profileRoleType.SUPER_ADMIN || profileRoleType.SUPPORT) &&
                        <Col md={6} className="mt-4">
                            <div className="mb-5" style={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between" }}>
                                <div><h6 className="">Propriétés non louées</h6></div>
                                <div>
                                    {
                                        propertysAvailables && propertysAvailables.length > 0 &&
                                        <Dropdown >
                                            <Dropdown.Toggle variant="primary" className='btn btn-sm' id="dropdown-basic">
                                                Exporter
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => { exportToExcelData(propertysAvailables, "property", "Listes_des_propriétes_non_loués") }}>Exporter en Excel</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { exportToPDFData(propertysAvailables, "property", "Listes_des_propriétes_non_loués") }}>Export en Pdf</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    }
                                </div>
                            </div>
                            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                                <Table hover >
                                    <thead>
                                        <tr>
                                            <th>Designation</th>
                                            <th>Addresse</th>
                                            <th>Prix / jour(DZD)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            propertysAvailables && propertysAvailables.length > 0
                                                ? propertysAvailables.map(vehicle => (
                                                    <tr >
                                                        <td>{vehicle?.name || ""}</td>
                                                        <td>{vehicle?.address || ""}</td>
                                                        <td>{vehicle?.basePricePerDay || ""}</td>
                                                    </tr>
                                                )) : (
                                                    <tr>
                                                        <td colSpan="4"> Accune propriété loué</td>
                                                    </tr>
                                                )}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    }
                </Row>
            </Card.Body>
        </Card>
    )
}

export default PatrimoineCountStatisticsCard
