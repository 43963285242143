import axios from "axios";
import { FETCH_DEPOSITS_FAILURE, FETCH_DEPOSITS_REQUEST, FETCH_DEPOSITS_SUCCESS, FETCH_DEPOSITS_SUCCESS_2, FETCH_DEPOSIT_FAILURE, FETCH_DEPOSIT_REQUEST, FETCH_DEPOSIT_SUCCESS, FETCH_RESERVATION_FAILURE, FETCH_RESERVATION_REQUEST, FETCH_RESERVATION_SUCCESS } from "../../app/actions/actions";
import { ROUTES } from "../../config/routingUrl";
import { dureeDeVie, getAndCheckLocalStorage, setWithExpiration } from "../../utils/storage/localvalueFuction";
import { localStorageData, localStorageKeys } from "../../utils/storage/localvalue";
import { baseurl, checkWordInURL } from "../../utils/url/baseurl";
import { saveDataToFile } from "../DataLocal";
import { toast } from "react-toastify";



// extends reservation 
export const DepositValidatedById = (idDeposit, data, toast) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_RESERVATION_REQUEST });
        dispatch({ type: FETCH_DEPOSIT_REQUEST });
        await axios
            .put(`${baseurl.url}/api/v1/credit-cards/validate-credit-cards/${idDeposit}`, data, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
                }
            })
            .then((response) => {
                dispatch({ type: FETCH_DEPOSIT_SUCCESS });
                dispatch({ type: FETCH_RESERVATION_SUCCESS });
                toast.success(response?.data?.data?.message || "Operation effectué avec succès", { position: "bottom-right" });
                dispatch(fetchDepositsAll());
                dispatch(fetchDepositById(idDeposit));


            })
            .catch((error) => {
                dispatch({ type: FETCH_DEPOSIT_FAILURE, payload: error.message });
                dispatch({ type: FETCH_RESERVATION_FAILURE, payload: error.message });
                toast.error(error.response.data.message || "Operation non éffectuer", { position: "bottom-right" })
            });
    };
}




// All reservation of plateforme
export function fetchDepositsAll(startDate, endDate, userAdd, client, reservation, status) {
    return async (dispatch) => {
        dispatch({ type: FETCH_DEPOSITS_REQUEST });

        const params = new URLSearchParams();
        if (startDate) params.append("startDate", startDate);
        if (endDate) params.append("endDate", endDate);
        if (client) params.append("client", client);
        if (userAdd) params.append("userAdd", userAdd);
        if (status) params.append("status", status);
        if (reservation) params.append("reservation", reservation);

        await axios.get(`${baseurl.url}/api/v1/credit-cards/get-credit-cards?${params.toString()}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        })
            .then((response) => {
                //console.log(response.data.data);
                dispatch({ type: FETCH_DEPOSITS_SUCCESS, payload: response.data.data });
                dispatch({ type: FETCH_DEPOSITS_SUCCESS_2, payload: response.data.data });
                saveDataToFile(response.data.data, localStorageData.Deposits);
            })
            .catch((error) => {
                //console.log(error);
                dispatch({ type: FETCH_DEPOSITS_FAILURE, payload: error.message });
            });
    }
}





// get reservation by id 
export function fetchDepositById(idDeposit) {
    return async (dispatch) => {
        dispatch({ type: FETCH_DEPOSIT_REQUEST });
        await axios.get(`${baseurl.url}/api/v1/credit-cards/get-credit-card/${idDeposit}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${baseurl.TypeToken} ${baseurl.token}`
            }
        }).then((response) => {
            // console.log(response.data.data);
            dispatch({ type: FETCH_DEPOSIT_SUCCESS, payload: response.data.data });
        })
            .catch((error) => {
                dispatch({ type: FETCH_DEPOSIT_FAILURE, payload: error.message })
                //console.log(error);
            });
    }
}

